import { createEffect, sample } from 'effector';
import { ECGPackageType, transformECGToChartData } from 'features/vital';
import { getUserZonedDate } from 'shared/lib';
import { get, set } from 'idb-keyval';
import { VITALS_MODAL_WIDTH } from '../../ui/vitals/styles';
import { $ECGData, $lastEcgDate, addECGPackage } from '.';

const DEBUG_ECG_KEY = `ecg-debug-${Date.now()}`;

const debugSaveFx = createEffect(async (data: ECGPackageType) => {
  const existing = (await get(DEBUG_ECG_KEY)) ?? [];

  return set(DEBUG_ECG_KEY, { ecg: [...existing, data] });
});

sample({
  clock: addECGPackage,
  target: debugSaveFx,
});

sample({
  clock: addECGPackage,
  fn: ({ LastUpdate }) => getUserZonedDate(new Date(LastUpdate)),
  target: $lastEcgDate,
});

sample({
  clock: addECGPackage,
  source: $ECGData,
  fn: (existing, meta) => [
    ...existing,
    ...transformECGToChartData({
      meta,
      width: VITALS_MODAL_WIDTH,
      startIndex: existing.length,
    }),
  ],
  target: $ECGData,
});
