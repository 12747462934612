import { styled, useStyletron } from '../theme/theme';

export const Item = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '14px 16px',
  border: `1px solid ${$theme.colors.gray200}`,
  borderRadius: '8px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
  backgroundColor: $theme.colors.white900,
  cursor: 'default',
}));

export const Content = styled('div', () => ({
  flexGrow: 1,
}));

export const DeleteButton = styled('button', ({ $theme }) => ({
  width: '20px',
  marginLeft: '16px',
  color: $theme.colors.gray400,
  transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',

  ':hover': {
    color: $theme.colors.red500,
  },
}));

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const dragIconClass = css({
    width: '20px',
    marginRight: '8px',
    color: theme.colors.gray400,
  });

  return {
    dragIconClass,
  };
};
