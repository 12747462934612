import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import { authLink } from './links/auth-link';
import { EchoLink } from './links/echo-link';
import { errorLink } from './links/error-link';
import { exportLink } from './links/export-link';
import { notifyLink } from './links/notify-link';
import { PusherLink } from './links/pusher-link';
import { redoxLink } from './links/redox-link';
import { scalarsLink } from './links/scalars-link';
import { subdomainLink } from './links/subdomain-link';
import { pageStylePagination } from './pagination';

const echoLink: ApolloLink = new EchoLink();
const pusherLink: ApolloLink = new PusherLink();
const sentryLink = new SentryLink();

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
}) as unknown as ApolloLink;

export const client = new ApolloClient({
  link: from([
    scalarsLink,
    subdomainLink,
    sentryLink,
    authLink,
    exportLink,
    ...(process.env.REACT_APP_WS_CONNECTION === 'pusher'
      ? [pusherLink]
      : [echoLink]),
    redoxLink,
    errorLink,
    notifyLink,
    uploadLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          appointmentsConnection: relayStylePagination(['status']),
          participants: relayStylePagination(['search', 'exclude']),
          contacts: relayStylePagination(['search', 'exclude']),
          chatMessages: { keyArgs: ['chat_id'] },
          documentPackets: pageStylePagination(false),
          documents: pageStylePagination(['orderBy']),
          chat(existing, { canRead }) {
            if (!existing) {
              return undefined;
            }
            return canRead(existing) ? existing : null;
          },
        },
      },
      UserSettings: {
        keyFields: ['user_id'],
      },
    },
  }),
});
