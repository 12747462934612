import { v4 as uuidV4 } from 'uuid';

const KEY = 'TAB_UUID';

export const setTabUUID = () => {
  const uuid = uuidV4();

  sessionStorage.setItem(KEY, uuid);
};

/** Return unique UUID for current tab in browser */
export const getTabUUID = () => sessionStorage.getItem(KEY) ?? '';
