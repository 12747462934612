import { useCallback, useMemo } from 'react';
import { DESKTOP, IS_NO_TOUCH, MOBILE, TEXT, useStyletron } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { BaseAppointmentCardAlertType } from './model';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    transitionProperty: 'box-shadow',
    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
    transitionDuration: '200ms',

    [IS_NO_TOUCH]: {
      ':hover': {
        boxShadow:
          '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
      },
    },
  });

  const topClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px',

    [DESKTOP]: {
      padding: '16px 24px',
    },
  });

  const alertClass: (type: BaseAppointmentCardAlertType) => string =
    useCallback(
      (type) =>
        css({
          display: 'flex',
          alignItems: 'center',
          height: '32px',
          marginLeft: '8px',
          marginRight: '8px',
          marginTop: '8px',
          justifyContent: 'center',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '8px',
          ...TEXT.sm_book,
          background:
            type === BaseAppointmentCardAlertType.SUCCESS
              ? theme.colors.green50
              : theme.colors.yellow50,
          borderColor:
            type === BaseAppointmentCardAlertType.SUCCESS
              ? theme.colors.green200
              : theme.colors.yellow200,

          [MOBILE]: {
            height: 'auto',
            textAlign: 'center',
            padding: '6px 16px',
          },
        }),
      [css, theme]
    );

  const dividerStyles: StyleObject = useMemo(
    () => ({
      borderTopWidth: '2px',
    }),
    []
  );

  const wrapperClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [DESKTOP]: {
      flexDirection: 'row',
      gap: '24px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });

  const titleClass = css({
    ...TEXT.xl_medium,
    color: theme.colors.gray700,
  });

  const timeClass = css({
    ...TEXT.sm_book,
    color: theme.colors.gray400,

    [DESKTOP]: {
      ...TEXT.base_book,
    },
  });

  const dateContentClass = css({
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
  });

  const providerContentWrapperClass = css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  });

  const wrapperContentClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  });

  const serviceNameClass = css({
    ...TEXT.xl2_medium,
  });

  const providerTextInfoClass = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  });

  const providerNameClass = css({
    ...TEXT.lg_medium,
  });

  const providerHintClass = css({
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  return {
    rootClass,
    topClass,
    wrapperClass,
    titleClass,
    timeClass,
    dateContentClass,
    serviceNameClass,
    providerContentWrapperClass,
    providerTextInfoClass,
    providerNameClass,
    providerHintClass,
    wrapperContentClass,
    dividerStyles,
    alertClass,
  };
};
