import {
  buildClientSchema,
  GraphQLScalarType,
  IntrospectionQuery,
  Kind,
} from 'graphql';
import { withScalars } from 'apollo-link-scalars';
import { format } from 'date-fns';
import introspectionResult from 'shared/api/graphql.schema.json';
import {
  CustomDate,
  ICustomDate,
  IResizableImage,
  ResizableImage,
} from 'shared/api';

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery
);

export const scalarsLink = withScalars({
  schema,
  typesMap: {
    DateTimeToTz: new GraphQLScalarType<ICustomDate | null, string | null>({
      name: 'DateTimeToTz',
      serialize: (parsed) => (parsed ? (parsed as Date)?.toISOString() : null),
      parseValue: (raw) =>
        raw ? new CustomDate(new Date(raw as string)) : null,
      parseLiteral(ast) {
        if (ast.kind === Kind.STRING) {
          return new CustomDate(new Date(ast.value));
        }
        return null;
      },
    }),
    DateTimeFromTz: new GraphQLScalarType<Date | null, string | null>({
      name: 'DateTimeFromTz',
      serialize: (parsed) =>
        parsed ? format(parsed as Date, 'yyyy-MM-dd HH:mm:ss') : null,
      parseValue: (raw) => (raw ? new Date(raw as string) : null),
      parseLiteral(ast) {
        if (ast.kind === Kind.STRING) {
          return new Date(ast.value);
        }
        return null;
      },
    }),
    DateTime: new GraphQLScalarType<Date | null, string | null>({
      name: 'DateTime',
      serialize: (parsed) =>
        parsed ? format(parsed as Date, 'yyyy-MM-dd HH:mm:ss') : null,
      parseValue: (raw) => (raw ? new Date(raw as string) : null),
      parseLiteral(ast) {
        if (ast.kind === Kind.STRING) {
          return new Date(ast.value);
        }
        return null;
      },
    }),
    ResizableImage: new GraphQLScalarType<
      IResizableImage | null,
      string | null
    >({
      name: 'ResizableImage',
      serialize: (parsed) => (parsed ? (parsed as string) : null),
      parseValue: (raw) => (raw ? new ResizableImage(raw as string) : null),
      parseLiteral(ast) {
        if (ast.kind === Kind.STRING) {
          return new ResizableImage(ast.value);
        }
        return null;
      },
    }),
    Date: new GraphQLScalarType<Date | null, string | null>({
      name: 'Date',
      serialize: (parsed) =>
        parsed ? format(parsed as Date, 'yyyy-MM-dd') : null,
      parseValue: (raw) => (raw ? new Date(raw as string) : null),
      parseLiteral(ast) {
        if (ast.kind === Kind.STRING) {
          return new Date(ast.value);
        }
        return null;
      },
    }),
  },
});
