import { useMemo } from 'react';
import { useStyletron, TEXT, MOBILE } from 'shared/ui';

export const useStyles = ({ totalPages }: { totalPages: number }) => {
  const [css, theme] = useStyletron();

  const rootClass = useMemo(
    () =>
      css({
        display: 'flex',
        justifyContent: totalPages > 1 ? 'space-between' : 'center',
        padding: '16px 24px',
        alignItems: 'center',
        minHeight: '52px',
        borderTopWidth: '0.5px',
        borderStyle: 'solid',
        borderColor: theme.colors.gray200,

        [MOBILE]: {
          flexDirection: 'column',
          gap: '16px',
          padding: '16px',
        },
      }),
    [css, totalPages, theme]
  );

  const paginationListWrapperClass = useMemo(
    () =>
      css({
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))',
        borderRadius: '8px',
        height: '40px',
        display: 'flex',
      }),
    [css]
  );

  const textClass = useMemo(() => css({ ...TEXT.sm_book }), [css]);

  return { rootClass, textClass, paginationListWrapperClass };
};
