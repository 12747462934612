import { styled, TEXT } from 'shared/ui';

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const Header = styled('header', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const TitleWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
});

export const Title = styled('h3', ({ $theme }) => ({
  ...TEXT.base_medium,

  color: $theme.colors.gray700,
}));

export const MetaWrapper = styled('ul', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
});

export const MetaItem = styled('li', {
  ...TEXT.base_book,

  display: 'flex',
  gap: '8px',
  whiteSpace: 'nowrap',
});

export const MetaLabel = styled('div', ({ $theme }) => ({
  color: $theme.colors.gray400,
}));

export const MetaValue = styled('div', ({ $theme }) => ({
  color: $theme.colors.gray700,
}));
