import { useStyletron, TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStepperItemStyles = () => {
  const [css, theme] = useStyletron();

  const itemStyles: StyleObject = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    width: '100%',
    position: 'relative',
    ...TEXT.base_book,
  };

  const itemNumberClass = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid currentColor',
    flexShrink: 0,
  });

  const itemCompletedIconWrapperClass = css({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: theme.colors.accent,
  });

  const iconClass = css({
    color: theme.colors.white,
    width: '20px',
    height: '20px',
  });

  return {
    itemStyles,
    itemNumberClass,
    itemCompletedIconWrapperClass,
    iconClass,
  };
};
