import { useCallback } from 'react';
import { useStyletron, TEXT } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const RootStyles = css({
    textAlign: 'center',
  });

  const TitleStyles = css({
    ...TEXT.xl_medium,
  });

  const ContentStyles = css({
    ...TEXT.base_book,
    marginTop: '8px',
    color: theme.colors.gray400,
  });

  const FooterStyles = css({
    marginTop: '40px',
  });

  const iconWrapperClass = useCallback(
    ({
      backgroundColor = theme.colors.green100,
      color = theme.colors.green500,
    }: {
      backgroundColor?: string;
      color?: string;
    }) =>
      css({
        margin: '0 auto 16px',
        width: '1em',
        height: '1em',
        fontSize: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor,
        color,
      }),
    [css, theme]
  );

  const iconSVGClass = css({
    display: 'block',
    pointerEvents: 'none',
    width: '0.5em',
    height: '0.5em',
  });

  return {
    iconWrapperClass,
    iconSVGClass,
    RootStyles,
    TitleStyles,
    ContentStyles,
    FooterStyles,
  };
};
