import { TEXT, useStyletron } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useModalStyles = () => {
  const modalStyle: StyleObject = {
    maxWidth: '720px',
    padding: 0,
  };

  return { modalStyle };
};

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const headerClass = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: `2px solid ${theme.colors.gray200}`,
    backgroundColor: theme.colors.gray50,
  });

  const headerTitleClass = css({
    marginTop: '8px',
    ...TEXT.xl2_medium,
  });

  const bodyClass = css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
    backgroundColor: theme.colors.white900,
  });

  const buttonsClass = css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: '12px 24px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderTop: `2px solid ${theme.colors.gray200}`,
    backgroundColor: theme.colors.gray50,
  });

  const iconStyles: StyleObject = {
    width: '40px',
    height: '40px',
    color: theme.colors.gray400,
  };
  return { headerClass, headerTitleClass, bodyClass, buttonsClass, iconStyles };
};
