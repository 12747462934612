import { ReactNode } from 'react';
import { ModalCloseEvent } from '../model';

export type ModalDefaultProps = {
  title: string | ReactNode;
  content?: string | ReactNode;
  footer?: ReactNode;
  icon?: {
    svg: (className: string) => ReactNode;
    type: MODAL_ICON_TYPE;
  };
  onClose?: (eventType: ModalCloseEvent) => void;
};

export enum MODAL_ICON_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
}
