import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import {
  PARTICIPANT_MEETING_STATUS,
  ParticipantMeetingStatusProps,
} from './model';
import { getRootStyles, Root, StyledMeetingIcon, Text } from './styles';

const statusIconMap = new Map([
  [PARTICIPANT_MEETING_STATUS.IN_MEETING, <StyledMeetingIcon />],
]);

export const ParticipantMeetingStatus: FC<ParticipantMeetingStatusProps> = ({
  status,
  isInline = false,
}) => {
  const { t } = useTranslation(['appointments']);
  const statusIcon = useMemo(
    () => (status ? statusIconMap.get(status) : null),
    [status]
  );
  const rootStyles = useMemo(
    () => getRootStyles(status, isInline),
    [isInline, status]
  );

  if (!status) {
    return null;
  }

  return (
    <Root style={rootStyles}>
      {statusIcon}
      <Text>{t(`appointments:CARD_STATUS.${status}`)}</Text>
    </Root>
  );
};

export { PARTICIPANT_MEETING_STATUS } from './model';
