import { FC } from 'react';
import {
  Input,
  SearchIcon,
  IconButton,
  TableSortIcon,
  FilterIcon,
  SORT_TYPE,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const TableSearch: FC<{
  sortOrder?: 'ASC' | 'DESC' | null;
  onClickSort?: () => void;
  onClickFilter?: () => void;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
}> = ({
  sortOrder,
  onClickSort,
  onClickFilter,
  searchValue,
  onSearchChange,
}) => {
  const { t } = useTranslation(['common']);
  const { searchRowClass, searchIconClass } = useStyles();

  return (
    <div className={searchRowClass}>
      <Input
        startEnhancer={<SearchIcon className={searchIconClass} />}
        placeholder={t('common:SEARCH.SEARCH')}
        value={searchValue}
        onChange={(e) => onSearchChange?.(e.currentTarget.value)}
      />
      <IconButton type="button" onClick={onClickSort}>
        <TableSortIcon
          type={sortOrder === 'ASC' ? SORT_TYPE.ASC : SORT_TYPE.DESC}
        />
      </IconButton>
      <IconButton type="button" onClick={onClickFilter}>
        <FilterIcon />
      </IconButton>
    </div>
  );
};
