import { useCallback } from 'react';
import { HIDE_SCROLLBAR, useStyletron, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { mergeDeepRight } from 'ramda';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = useCallback(
    (width: string, styles?: StyleObject) =>
      css(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mergeDeepRight<StyleObject, any>(
          {
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            width,
            height: '100%',
            margin: 'auto',
            ...HIDE_SCROLLBAR,
            backgroundColor: theme.colors.white900,
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            overflow: 'auto',
            maxWidth: '100%',

            [MOBILE]: {
              width: '100%',
            },
          },
          styles ?? {}
        )
      ),
    [css, theme]
  );

  const spinnerWrapperClass = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  });

  return {
    rootClass,
    spinnerWrapperClass,
  };
};
