import { FC } from 'react';
import { useStyletron } from 'shared/ui';

export const StepperDivider: FC = () => {
  const [css, theme] = useStyletron();
  return (
    <span
      className={css({
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.colors.gray200,
      })}
    >
      <svg
        width="18"
        height="74"
        viewBox="0 0 18 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m1 1 16 36L1 73" stroke="currentColor" />
      </svg>
    </span>
  );
};
