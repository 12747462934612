import { useBookingUrl } from 'features/public-booking';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCancelAppointmentMutation } from 'shared/api';
import { MODAL_TYPE, useModal } from 'shared/lib';
import {
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  CalendarIcon,
  CloseIcon,
  MODAL_ICON_TYPE,
  useStyletron,
  useConfirm,
  TEXT,
} from 'shared/ui';

const Footer: FC<{
  appointmentId: string;
  close: () => void;
}> = ({ appointmentId, close }) => {
  const [css, theme] = useStyletron();
  const [cancelRequest, { loading: isLoading }] = useCancelAppointmentMutation({
    onCompleted: () => {
      close();
    },
  });
  const { t } = useTranslation(['appointments']);
  const bookUrl = useBookingUrl();

  const navigate = useNavigate();
  const { confirm } = useConfirm();

  return (
    <div className={css({ display: 'grid', gap: '16px' })}>
      <Button
        startEnhancer={
          <CalendarIcon className={css({ width: '20px', height: '20px' })} />
        }
        kind={BUTTON_KIND.SECONDARY}
        size={BUTTON_SIZE.SMALL}
        isLoading={isLoading}
        onClick={async () => {
          await cancelRequest({ variables: { id: appointmentId } });
          navigate(`/${bookUrl}`);
          close();
        }}
      >
        {t('appointments:RSVP_MODAL.RESCHEDULE')}
      </Button>
      <Button
        isDeleteButton
        size={BUTTON_SIZE.SMALL}
        style={{
          backgroundColor: theme.colors.red500,
          ':hover': { backgroundColor: theme.colors.red600 },
        }}
        isLoading={isLoading}
        onClick={() => {
          close();
          confirm({
            title: t('appointments:CARD_MENU.CANCEL'),
            caption: (
              <>
                {t('appointments:VIEW.SURE_CANCEL_SESSION')}{' '}
                <span className={css({ ...TEXT.base_medium })}>
                  #{appointmentId}
                </span>
              </>
            ),
            submitText: t('appointments:CARD_MENU.CANCEL'),
            isLoading,
          }).then((res) => {
            if (res.result) {
              cancelRequest({ variables: { id: appointmentId } });
            }
          });
        }}
      >
        {t('appointments:RSVP_MODAL.CANCEL')}
      </Button>
    </div>
  );
};

export const useRsvp = () => {
  const { open, close } = useModal();
  const { t } = useTranslation(['appointments']);

  const setNoStatus = useCallback(
    ({ appointmentId }: { appointmentId: string }) => {
      open(MODAL_TYPE.DEFAULT, {
        title: t('appointments:RSVP_MODAL.TITLE'),
        content: t('appointments:RSVP_MODAL.DESCRIPTION'),
        icon: {
          type: MODAL_ICON_TYPE.ERROR,
          svg: (className) => <CloseIcon className={className} />,
        },
        footer: <Footer appointmentId={appointmentId} close={close} />,
      });
    },
    [open, close, t]
  );

  return { setNoStatus };
};
