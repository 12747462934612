import { useStyletron } from 'shared/ui';
import { theme } from '../theme';
import { VITALS_MODAL_WIDTH } from '../vitals/styles';

export const useStyles = () => {
  const [css] = useStyletron();

  const getRootClass = (isShowVitals?: boolean) =>
    css({
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: theme.colors.black,

      '@media screen and (min-width: 1600px)': {
        right: isShowVitals ? `${VITALS_MODAL_WIDTH}px` : 0,
      },
    });

  return {
    getRootClass,
  };
};
