import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client';
import Echo from 'laravel-echo';
import { SubscriptionObserver } from 'zen-observable-ts';
// eslint-disable-next-line
// @ts-ignore
import io from 'socket.io-client';
import { getIdToken } from 'shared/lib';

type RequestResult = FetchResult<
  { [key: string]: unknown },
  Record<string, unknown>,
  Record<string, unknown>
>;

type Subscription = {
  channel: string;
  observer: SubscriptionObserver<FetchResult>;
};

class EchoLink extends ApolloLink {
  private _echo?: Echo;

  private _subscriptions: Subscription[] = [];

  constructor() {
    super();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).io = io;
  }

  private _createConnection(): void {
    const token = getIdToken();

    this._echo = new Echo({
      broadcaster: 'socket.io',
      host:
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_WSS_URL
          : process.env.REACT_APP_WSS_URL, // 'localhost:9999',
      transports: ['websocket'],
      auth: {
        headers: {
          authorization: token ? `Bearer ${token}` : null,
        },
      },
    });
  }

  private _addChannel(
    subscriptionChannel: string,
    observer: SubscriptionObserver<FetchResult>
  ): void {
    this._subscriptions.push({
      channel: subscriptionChannel,
      observer,
    });

    const check = setInterval(() => {
      if (observer.closed) {
        this._leaveSubscription(subscriptionChannel, observer);
        clearInterval(check);
      }
    }, 1000);
  }

  request(operation: Operation, forward: NextLink): Observable<RequestResult> {
    return new Observable((observer) => {
      // Check the result of the operation
      forward(operation).subscribe({
        next: (data) => {
          // If the operation has the subscription extension, it's a subscription
          const subscriptionChannel = this._getChannelName(data)?.substring(8);
          if (subscriptionChannel) {
            this._createSubscription(subscriptionChannel, observer);
          } else {
            // No subscription found in the response, pipe data through
            observer.next(data);
            observer.complete();
          }
        },
        error: observer.error,
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private _getChannelName(data: RequestResult): string | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (data?.extensions as any)?.lighthouse_subscriptions?.channel ?? null;
  }

  private _createSubscription(
    subscriptionChannel: string,
    observer: SubscriptionObserver<FetchResult>
  ): void {
    if (!this._subscriptions.find((s) => s.channel === subscriptionChannel)) {
      this._addChannel(subscriptionChannel, observer);
    }

    if (!this._echo) {
      this._createConnection();
    }

    this._echo
      ?.private(subscriptionChannel)
      .listen('.lighthouse-subscription', (payload: FetchResult) => {
        if (observer.closed) {
          this._leaveSubscription(subscriptionChannel, observer);
          return;
        }

        const result = payload.data;
        if (result) {
          observer.next({
            data: result.data,
            extensions: result.extensions,
          });
        }
      });
  }

  private _leaveSubscription(
    channel: string,
    observer: SubscriptionObserver<FetchResult>
  ) {
    const subscription = this._subscriptions.find((s) => s.channel === channel);
    this._echo?.leave(channel);
    observer.complete();
    if (subscription) {
      this._subscriptions = this._subscriptions.slice(
        this._subscriptions.indexOf(subscription),
        1
      );
    }
  }
}

export { EchoLink };
