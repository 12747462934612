import { FC, lazy } from 'react';
import { ModalPanelProps, MODAL_PANELS } from './model';

export const MODALS: { [k in MODAL_PANELS]: FC<ModalPanelProps[k]> } = {
  [MODAL_PANELS.CREATE_PROVIDER]: lazy(() => import('./provider/create')),
  [MODAL_PANELS.CREATE_APPOINTMENT]: lazy(() => import('./appointment/create')),
  [MODAL_PANELS.UPDATE_APPOINTMENT]: lazy(() => import('./appointment/update')),
  [MODAL_PANELS.REQUEST_APPOINTMENT]: lazy(
    () => import('./appointment/request')
  ),
  [MODAL_PANELS.SHOW_APPOINTMENT_USAGE]: lazy(
    () => import('./appointment/usage')
  ),
  [MODAL_PANELS.DOCUMENTS]: lazy(() => import('./documents')),
  [MODAL_PANELS.VIEW_APPOINTMENT]: lazy(() => import('./appointment/view')),
  [MODAL_PANELS.VIEW_NOTE_TEMPLATE]: lazy(() => import('./note-template')),
  [MODAL_PANELS.CREATE_USER_NOTE]: lazy(() => import('./notes/create')),
  [MODAL_PANELS.SHOW_USER_NOTES]: lazy(() => import('./notes/view')),
  [MODAL_PANELS.CREATE_PARTICIPANT]: lazy(
    () => import('./participants/create')
  ),
  [MODAL_PANELS.UPDATE_PARTICIPANT]: lazy(
    () => import('./participants/update')
  ),
  [MODAL_PANELS.PROVIDER_AVAILABILITY]: lazy(
    () => import('./provider/availability')
  ),
  [MODAL_PANELS.EDIT_VISIT_SUMMARY_REPORT]: lazy(() =>
    import('./visit-summary-report').then(({ VisitSummaryReportModal }) => ({
      default: VisitSummaryReportModal,
    }))
  ),
  [MODAL_PANELS.VIEW_VISIT_SUMMARY_REPORT]: lazy(() =>
    import('./visit-summary-report').then(
      ({ VisitSummaryReportViewModal }) => ({
        default: VisitSummaryReportViewModal,
      })
    )
  ),
  [MODAL_PANELS.CREATE_USER]: lazy(() => import('./users/create')),
  [MODAL_PANELS.UPDATE_USER]: lazy(() => import('./users/update')),
};
