import { useState, FC } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { mergeDeepRight } from 'ramda';
import { useStyletron } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { TOOLBAR_OPTIONS } from './options';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';

export const TextEditor: FC<
  EditorProps & { style?: StyleObject; hasError?: boolean }
> = ({ toolbar, style, hasError = false, ...props }) => {
  const [css, theme] = useStyletron();
  const [isFocused, setIsFocused] = useState(false);

  const getFocusStyle = (isFocus: boolean) => {
    if (!isFocus) {
      return {};
    }
    return {
      borderTopColor: `${theme.colors.accent} !important`,
      borderRightColor: `${theme.colors.accent} !important`,
      borderBottomColor: `${theme.colors.accent} !important`,
      borderLeftColor: `${theme.colors.accent} !important`,
    };
  };

  const getErrorStyle = (isError: boolean) => {
    if (!isError) {
      return {};
    }
    return {
      borderTopColor: `${theme.colors.red500} !important`,
      borderRightColor: `${theme.colors.red500} !important`,
      borderBottomColor: `${theme.colors.red500} !important`,
      borderLeftColor: `${theme.colors.red500} !important`,
    };
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '240px',
        padding: '16px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        borderTopColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        borderLeftColor: theme.colors.gray200,
        transition: 'border-color 0.2s cubic-bezier(0, 0, 1, 1)',
        ':hover': {
          borderTopColor: theme.colors.gray300,
          borderRightColor: theme.colors.gray300,
          borderBottomColor: theme.colors.gray300,
          borderLeftColor: theme.colors.gray300,
        },
        ...getFocusStyle(isFocused),
        ...getErrorStyle(hasError),
        ...style,
      })}
    >
      <Editor
        {...props}
        toolbarStyle={{ margin: '0 -4px 16px', padding: 0, border: 0 }}
        toolbar={mergeDeepRight(TOOLBAR_OPTIONS, toolbar ?? {})}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
};
