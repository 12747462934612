import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Avatar } from 'shared/ui';
import { AppointmentMessage } from 'entities/appointment';
import { BaseAppointmentCardProps } from './model';
import { useStyles } from './styles';

export const AppointmentCard: FC<BaseAppointmentCardProps> = ({
  appointment,
  alert,
  children,
  customDate,
  customBody,
}) => {
  const { t } = useTranslation(['appointment']);
  const {
    rootClass,
    topClass,
    wrapperClass,
    titleClass,
    timeClass,
    dateContentClass,
    serviceNameClass,
    providerContentWrapperClass,
    wrapperContentClass,
    providerTextInfoClass,
    providerNameClass,
    dividerStyles,
    providerHintClass,
    alertClass,
  } = useStyles();

  return (
    <div className={rootClass}>
      {!!alert && (
        <div className={alertClass(alert.type)}>
          <span>{alert.text}</span>
        </div>
      )}

      <div className={topClass}>
        <div className={wrapperClass}>
          <div className={wrapperContentClass}>
            {(!!customDate || !!appointment.start_time) && (
              <div className={dateContentClass}>
                <span className={titleClass}>
                  {format(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    customDate?.start ?? appointment.start_time!.date,
                    'EEEE, d LLLL yyyy'
                  )}
                </span>
                {(!!customDate || !!appointment.planned_end_time) && (
                  <span className={timeClass}>
                    {format(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      customDate?.start ?? appointment.start_time!.date,
                      'hh:mm aaa'
                    )}{' '}
                    —{' '}
                    {format(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      customDate?.end ?? appointment.planned_end_time!.date,
                      'hh:mm aaa'
                    )}
                  </span>
                )}
              </div>
            )}
            {!!appointment.host && (
              <div className={providerContentWrapperClass}>
                <Avatar name={appointment.host.name} />
                <div className={providerTextInfoClass}>
                  <span className={providerNameClass}>
                    {appointment.host.name}
                  </span>
                  <span className={providerHintClass}>
                    {t('appointment:PROVIDER')}
                  </span>
                </div>
              </div>
            )}
          </div>
          <span className={serviceNameClass}>{appointment.service?.name}</span>
        </div>
        {!!appointment.message && (
          <AppointmentMessage>{appointment.message}</AppointmentMessage>
        )}
        {customBody}
      </div>

      <Divider styles={dividerStyles} />
      {children}
    </div>
  );
};
