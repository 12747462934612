import { ReactNode, useCallback } from 'react';
import {
  styled,
  useStyletron,
  COLORS,
  TEXT,
  CloseIcon as BaseCloseIcon,
  RsvpNoIcon,
  RsvpYesIcon,
  AlertIcon,
  InfoIcon,
} from 'shared/ui';
import { NOTIFICATION_TYPE } from '../../model';

export const Root = styled('div', {
  boxShadow:
    '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
  minWidth: '324px',
  overflow: 'hidden',
  backgroundColor: COLORS.white900,
  position: 'relative',
});

export const Wrapper = styled('div', {
  display: 'flex',
});

export const Filler = styled('div', {
  width: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Container = styled('div', {
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexBasis: 0,
});

export const TextContainer = styled('div', {
  paddingRight: '30px',
  display: 'flex',
  flexDirection: 'column',
});

export const Title = styled('div', {
  ...TEXT.base_medium,
});

export const Message = styled('div', {
  ...TEXT.sm_book,
  color: COLORS.gray400,
});

export const CloseButton = styled('button', {
  position: 'absolute',
  top: '16px',
  right: '16px',
  color: COLORS.gray400,
  transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',

  ':hover': {
    color: COLORS.gray500,
  },
});

export const ActionsContainer = styled('div', {
  paddingTop: '16px',
  display: 'flex',
  gap: '10px',
  justifyContent: 'end',
});

export const ButtonsContainer = styled('div', {
  display: 'flex',
  gap: '16px',
});

export const LinksContainer = styled('div', {
  display: 'flex',
  gap: '16px',
});

export const CloseIcon = styled(BaseCloseIcon, {
  width: '24px',
  height: '24px',
});

export const ViewLink = styled('button', ({ $theme }) => ({
  ...TEXT.sm_book,
  color: $theme.colors.accent500,
  textDecoration: 'none',
  transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
  ':hover': {
    color: $theme.colors.accent600,
  },
  ':active': {
    color: $theme.colors.accent500,
  },
}));

export const useStyles = () => {
  const [css] = useStyletron();

  const iconClass = css({ width: '24px', height: '24px' });

  const fillerComputedClass: (type: NOTIFICATION_TYPE) => string = useCallback(
    (type) =>
      css({
        ...new Map<
          NOTIFICATION_TYPE,
          { color: string; backgroundColor: string }
        >([
          [
            NOTIFICATION_TYPE.ERROR,
            { backgroundColor: COLORS.red50, color: COLORS.red500 },
          ],
          [
            NOTIFICATION_TYPE.WARNING,
            { backgroundColor: COLORS.yellow50, color: COLORS.yellow500 },
          ],
          [
            NOTIFICATION_TYPE.INFO,
            { backgroundColor: COLORS.accent50, color: COLORS.accent500 },
          ],
          [
            NOTIFICATION_TYPE.SUCCESS,
            { backgroundColor: COLORS.green50, color: COLORS.green500 },
          ],
        ]).get(type),
      }),
    [css]
  );

  const getIcon = useCallback(
    (type) => {
      const ICONS_MAP = new Map<NOTIFICATION_TYPE, ReactNode>([
        [NOTIFICATION_TYPE.ERROR, <RsvpNoIcon className={iconClass} />],
        [NOTIFICATION_TYPE.INFO, <InfoIcon className={iconClass} />],
        [NOTIFICATION_TYPE.SUCCESS, <RsvpYesIcon className={iconClass} />],
        [NOTIFICATION_TYPE.WARNING, <AlertIcon className={iconClass} />],
      ]);
      return ICONS_MAP.get(type);
    },
    [iconClass]
  );
  return { fillerComputedClass, getIcon };
};
