import { createEvent, createStore } from 'effector';
import { StoredModal } from '.';

export const open = createEvent<StoredModal>();
export const close = createEvent<string>();
export const closeAll = createEvent();

export const $modals = createStore<StoredModal[]>([])
  .on(open, (modals, modal) => [...modals, modal])
  .on(close, (modals, id) => modals.filter(({ id: modalId }) => modalId !== id))
  .on(closeAll, () => []);
