import { FC, useCallback } from 'react';
import { Root, Switcher, ThemeInput, useStyles } from './styles';
import {
  useSessionRoomDispatch,
  useSessionRoomState,
} from '../../../../context';

export const ThemeSwitcher: FC = () => {
  const { getMoverStyles, getCircleStyles } = useStyles();
  const { chatOptions } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();

  const handleChangeTheme = useCallback(() => {
    dispatch({
      type: 'REFRESH_CHAT_OPTIONS',
      payload: {
        isDarkTheme: !chatOptions.isDarkTheme,
      },
    });
  }, [chatOptions.isDarkTheme, dispatch]);

  return (
    <Root>
      <span>Light</span>
      <Switcher>
        <ThemeInput
          type="checkbox"
          onChange={handleChangeTheme}
          checked={chatOptions.isDarkTheme}
        />
        <span className={getMoverStyles(chatOptions.isDarkTheme ?? false)} />
        <span className={getCircleStyles(chatOptions.isDarkTheme ?? false)} />
      </Switcher>
      <span>Dark</span>
    </Root>
  );
};
