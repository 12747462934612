import {
  CreateAppointmentProps,
  UpdateAppointmentProps,
  AppointmentViewProps,
  RequestAppointmentProps,
} from './appointment';
import { DocumentTemplateProps } from './documents';
import { NoteTemplateProps } from './note-template/model';
import { CreateProviderProps } from './provider/create/model';
import { CreateNoteProps, UserNotesProps } from './notes';
import { CreateParticipantProps } from './participants/create';
import { UpdateParticipantProps } from './participants/update';
import { ProviderAvailabilityProps } from './provider/availability/model';
import { IVSRModal, IVSRViewModal } from './visit-summary-report';
import { CreateUserProps } from './users/create';
import { UpdateUserProps } from './users/update';

export enum MODAL_PANELS {
  CREATE_PROVIDER = 'create-provider',
  CREATE_APPOINTMENT = 'create-appointment',
  UPDATE_APPOINTMENT = 'update-appointment',
  REQUEST_APPOINTMENT = 'request-appointment',
  DOCUMENTS = 'documents',
  SHOW_APPOINTMENT_USAGE = 'show-appointment-usage',
  VIEW_APPOINTMENT = 'view-appointment',
  VIEW_NOTE_TEMPLATE = 'view-note-template',
  CREATE_USER_NOTE = 'create-user-note',
  SHOW_USER_NOTES = 'show-user-notes',
  CREATE_PARTICIPANT = 'create-participant',
  UPDATE_PARTICIPANT = 'update-participant',
  PROVIDER_AVAILABILITY = 'provider-availability',
  EDIT_VISIT_SUMMARY_REPORT = 'edit-visit-summary-report',
  VIEW_VISIT_SUMMARY_REPORT = 'view-visit-summary-report',
  CREATE_USER = 'create-user',
  UPDATE_USER = 'update-user',
}

type BaseModalPanelProps = { [k in MODAL_PANELS]: unknown };

interface CustomModalPanelProps extends BaseModalPanelProps {
  [MODAL_PANELS.CREATE_PROVIDER]: CreateProviderProps;
  [MODAL_PANELS.SHOW_APPOINTMENT_USAGE]: {
    id: string;
  };
  [MODAL_PANELS.VIEW_APPOINTMENT]: AppointmentViewProps;
  [MODAL_PANELS.DOCUMENTS]: DocumentTemplateProps;
  [MODAL_PANELS.VIEW_NOTE_TEMPLATE]: NoteTemplateProps;
  [MODAL_PANELS.CREATE_APPOINTMENT]: CreateAppointmentProps;
  [MODAL_PANELS.UPDATE_APPOINTMENT]: UpdateAppointmentProps;
  [MODAL_PANELS.REQUEST_APPOINTMENT]: RequestAppointmentProps;
  [MODAL_PANELS.CREATE_USER_NOTE]: CreateNoteProps;
  [MODAL_PANELS.SHOW_USER_NOTES]: UserNotesProps;
  [MODAL_PANELS.CREATE_PARTICIPANT]: CreateParticipantProps;
  [MODAL_PANELS.UPDATE_PARTICIPANT]: UpdateParticipantProps;
  [MODAL_PANELS.PROVIDER_AVAILABILITY]: ProviderAvailabilityProps;
  [MODAL_PANELS.EDIT_VISIT_SUMMARY_REPORT]: IVSRModal;
  [MODAL_PANELS.VIEW_VISIT_SUMMARY_REPORT]: IVSRViewModal;
  [MODAL_PANELS.CREATE_USER]: CreateUserProps;
  [MODAL_PANELS.UPDATE_USER]: UpdateUserProps;
}

export type ModalPanelProps = CustomModalPanelProps;
