import { styled, TEXT } from '..';

export const Root = styled('div', ({ $theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: $theme.colors.red500,
  ...TEXT.xs_medium,
  color: $theme.colors.white900,
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
}));
