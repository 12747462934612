import { COLORS, styled, TEXT } from 'shared/ui';

export const WIDTH = 205;

export const Root = styled('div', {
  position: 'absolute',
  opacity: 0,
  display: 'flex',
  flexDirection: 'column',
  borderWidth: '1px',
  borderStyle: 'solid',
  width: `${WIDTH}px`,
  transition: 'opacity .1s, left .05s, top .05s',
  transform: 'translateY(-100%)',
  borderColor: COLORS.gray200,
  boxShadow:
    '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
  borderRadius: '4px',
  padding: '16px',
  backgroundColor: COLORS.white900,
  gap: '8px',
  pointerEvents: 'none',
  zIndex: 2000,
});

export const Row = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  ...TEXT.xs_medium,
});

export const Label = styled('div', {
  color: COLORS.gray400,
});
