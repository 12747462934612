import { FC, useCallback, useState } from 'react';
import { useMobileMediaQuery } from 'shared/ui';
import { ActionButtonInterface, ActionButtonItemInterface } from './model';
import {
  IconWrap,
  MainButton,
  Root,
  Title,
  MenuButton,
  ContextMenu,
  ContextMenuInner,
  ContextMenuTitle,
  ContextMenuList,
  ContextMenuItem,
  DeviceButton,
  MenuButtonIcon,
  DeviceButtonIconWrap,
  DeviceButtonIcon,
  DeviceButtonLabel,
  NotificationBalloon,
  useStyles,
} from './styles';

const ActionButtonList: FC<{
  list: ActionButtonItemInterface[];
}> = ({ list }) => {
  const { getActiveButtonStyle } = useStyles();

  if (list.length < 1) {
    return null;
  }

  return (
    <ContextMenuList>
      {list.map(({ label, deviceId, isSelected = false, onClick }) => (
        <ContextMenuItem key={deviceId}>
          <DeviceButton
            type="button"
            onClick={onClick}
            className={getActiveButtonStyle(isSelected)}
          >
            <DeviceButtonIconWrap>
              {isSelected ? <DeviceButtonIcon /> : null}
            </DeviceButtonIconWrap>
            <DeviceButtonLabel>{label}</DeviceButtonLabel>
          </DeviceButton>
        </ContextMenuItem>
      ))}
    </ContextMenuList>
  );
};

export const ActionButton: FC<ActionButtonInterface> = ({
  icon,
  isActive = false,
  hasNotification = false,
  onClick,
  children,
  contextMenu,
}) => {
  const { getActiveMenuStyles, getActiveMainButtonStyle } = useStyles();
  const isMobile = useMobileMediaQuery();
  const isShowContextButton = contextMenu && !isMobile;
  const [isShowMenu, setIsShowMenu] = useState(false);
  const toggleContextMenu = useCallback(
    () => setIsShowMenu(!isShowMenu),
    [isShowMenu]
  );

  return (
    <Root>
      <MainButton
        type="button"
        onClick={onClick}
        style={getActiveMainButtonStyle(isActive)}
      >
        <IconWrap>{icon ?? null}</IconWrap>
        <Title>{children}</Title>
        {hasNotification ? <NotificationBalloon /> : null}
      </MainButton>

      {isShowContextButton ? (
        <MenuButton
          type="button"
          onClick={toggleContextMenu}
          style={getActiveMenuStyles(isShowMenu)}
        >
          <MenuButtonIcon />
        </MenuButton>
      ) : null}

      {isShowContextButton && isShowMenu ? (
        <ContextMenu>
          <ContextMenuInner>
            <ContextMenuTitle>{contextMenu.title}</ContextMenuTitle>
            <ActionButtonList list={contextMenu.list} />
          </ContextMenuInner>
        </ContextMenu>
      ) : null}
    </Root>
  );
};
