import { useContext } from 'react';
import { BookingLocationCtx } from './context';

export const useBookingLocation = () => {
  const ctx = useContext(BookingLocationCtx);

  if (!ctx) {
    throw new Error(
      'useBookingLocation must be used within the BookingLocationProvider'
    );
  }

  return ctx.location;
};
