import { useStyletron, TEXT, DESKTOP, MOBILE } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    position: 'relative',
    padding: '16px 24px 0',

    [DESKTOP]: {
      '::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '40px',
        background:
          'linear-gradient(180deg, rgba(249, 250, 251, 0) 0%, var(--tru-white900) 100%)',
        pointerEvents: 'none',
      },
    },

    [MOBILE]: {
      padding: 0,
    },
  });

  const searchIconClass = css({
    width: '24px',
    color: theme.colors.gray400,
  });

  const listClass = css({
    margin: '16px -24px 0',

    [DESKTOP]: {
      maxHeight: '272px',
      paddingBottom: '16px',
      overflow: 'auto',

      '::-webkit-scrollbar': {
        display: 'none',
      },
    },

    [MOBILE]: {
      marginLeft: '-16px',
      marginRight: '-16px',
    },
  });

  const getItemClass = (isSelected: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      padding: '8px 24px',
      backgroundColor: isSelected ? theme.colors.gray100 : 'initial',
      transition: 'background-color 0.2s cubic-bezier(0, 0, 1, 1)',
      cursor: 'pointer',

      [MOBILE]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },

      ':hover': {
        backgroundColor: isSelected
          ? theme.colors.gray100
          : theme.colors.gray50,
      },
    });

  const itemNameClass = css({
    ...TEXT.base_medium,
  });

  const itemEmailClass = css({
    marginTop: '4px',
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  const checkIconClass = css({
    width: '24px',
    marginLeft: 'auto',
    color: theme.colors.accent,
  });

  const messageClass = css({
    position: 'relative',
    zIndex: 1,
    padding: '16px 0',
  });

  return {
    rootClass,
    searchIconClass,
    listClass,
    getItemClass,
    itemNameClass,
    itemEmailClass,
    checkIconClass,
    messageClass,
  };
};
