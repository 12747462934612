import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeDeepRight } from 'ramda';
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  registerables,
  ChartOptions,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Button, BUTTON_COLOR, BUTTON_SIZE, DownloadIcon } from 'shared/ui';

import { LineChartInterface } from './model';
import { getDefaultOptions } from './utils';
import { customCanvasBackgroundColor } from './plugins';
import {
  Wrapper,
  Header,
  Title,
  MetaWrapper,
  MetaItem,
  MetaLabel,
  MetaValue,
  TitleWrapper,
} from './styles';
import 'chartjs-adapter-date-fns';
import { TooltipPortal } from './tooltip';

// Register all modules in chart
ChartJS.register(...registerables);
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale
);

export const LineChart: FC<LineChartInterface> = ({
  title,
  meta,
  data,
  options,
  tooltip,
  plugins = [],
  canShowDownloadButton = true,
}) => {
  const chartRef = useRef<ChartJS>(null);
  const { t } = useTranslation(['common']);
  const defaultOptions = useMemo(() => getDefaultOptions(), []);
  const [canShowTooltip, setCanShowTooltip] = useState(false);

  const mergedOptions = useMemo(
    () =>
      (options
        ? mergeDeepRight(options, defaultOptions)
        : defaultOptions) as ChartOptions,
    [options, defaultOptions]
  );

  const handleDownload = useCallback(() => {
    if (chartRef.current) {
      const link = document.createElement('a');
      link.download = (
        title
          ? `${title}-${new Date().getTime()}.png`
          : `${new Date().getTime()}.png`
      )
        .replace(' ', '_')
        .toLowerCase();
      link.href = chartRef.current.canvas.toDataURL();
      link.click();
    }
  }, [title]);

  const handleEnter = useCallback(() => {
    setCanShowTooltip(true);
  }, []);

  const handleLeave = useCallback(() => {
    setCanShowTooltip(false);
  }, []);

  return (
    <Wrapper>
      {title || meta ? (
        <Header>
          <TitleWrapper>
            {title && <Title>{title}</Title>}
            {canShowDownloadButton && (
              <Button
                size={BUTTON_SIZE.SMALL}
                startEnhancer={<DownloadIcon />}
                onClick={handleDownload}
                color={BUTTON_COLOR.WHITE}
              >
                {t('common:BUTTONS.DOWNLOAD')}
              </Button>
            )}
          </TitleWrapper>

          {meta?.length ? (
            <MetaWrapper>
              {meta.map(({ label, value }) => (
                <MetaItem key={label}>
                  <MetaLabel>{label}</MetaLabel>
                  <MetaValue>{value}</MetaValue>
                </MetaItem>
              ))}
            </MetaWrapper>
          ) : null}
        </Header>
      ) : null}

      <Chart
        type="line"
        data={data}
        options={mergedOptions}
        plugins={[...plugins, customCanvasBackgroundColor]}
        ref={chartRef}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      />
      {canShowTooltip && !!tooltip && (
        <TooltipPortal icon={tooltip.icon} label={tooltip.label} />
      )}
    </Wrapper>
  );
};
