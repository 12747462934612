import { useStyletron } from '../theme/theme';

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    transition: 'all',
  });

  return {
    rootClass,
  };
};
