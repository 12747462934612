import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSharedData } from 'shared/lib';
import { StyleObject } from 'styletron-standard';
import { Root, Title, Appointments, Count } from './styles';

export const DeviceWelcome: FC<{
  user: UserSharedData;
  todayAppointmentsCount?: number;
  rootStyles?: StyleObject;
}> = ({ user, todayAppointmentsCount = 0, rootStyles }) => {
  const { t } = useTranslation(['appointments']);

  if (!user) {
    return null;
  }

  return (
    <Root style={rootStyles}>
      <Title>{user.name}</Title>

      <Appointments>
        <Count>
          {t('appointments:APPOINTMENTS_COUNT', {
            count: todayAppointmentsCount,
          })}
        </Count>{' '}
        {t('appointments:FOR_TODAY')}
      </Appointments>
    </Root>
  );
};
