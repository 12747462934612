import { createApi, createStore } from 'effector';
import {
  NOTIFICATION_INSERTION,
  NOTIFICATION_TYPE,
  StoredUserNotification,
  UserNotification,
} from '../model';
import { insertToList } from './utils';

export const $list = createStore<StoredUserNotification[]>([]);

export const notificationsApi = createApi($list, {
  add: (
    list,
    {
      type,
      notification,
      insert,
    }: {
      type: NOTIFICATION_TYPE;
      notification: UserNotification;
      insert: NOTIFICATION_INSERTION;
    }
  ) => insertToList(list, { type, ...notification }, insert),
  remove: (list, removedId: string) =>
    list.filter(({ id }) => id !== removedId),
  setToRemove: (list, itemId: string) => {
    const itemIndex = list.findIndex(({ id }) => id === itemId);
    if (itemIndex > -1) {
      return [
        ...list.slice(0, itemIndex),
        { ...list[itemIndex], hasBeenRemoved: true },
        ...list.slice(itemIndex + 1),
      ];
    }
    return list;
  },
});
