import { ModalDefaultProps, ModalProps } from 'shared/ui';

export enum MODAL_TYPE {
  BASE = 'base',
  DEFAULT = 'default',
}

export type OpenDefaultModalProps = Omit<ModalDefaultProps, 'onClose'> & {
  noCloseAfterSubmit?: boolean;
};

export type OpenBaseModalProps = Omit<ModalProps, 'onClose'>;

export type OpenModalProps = {
  [MODAL_TYPE.BASE]: OpenBaseModalProps;
  [MODAL_TYPE.DEFAULT]: OpenDefaultModalProps;
};

export type ModalParams =
  | {
      type: MODAL_TYPE.BASE;
      props: OpenModalProps[MODAL_TYPE.BASE];
    }
  | {
      type: MODAL_TYPE.DEFAULT;
      props: OpenModalProps[MODAL_TYPE.DEFAULT];
    };

export type StoredModal = ModalParams & {
  id: string;
};
