import { FC } from 'react';
import { RsvpTypesEnum } from 'shared/api';
import {
  RsvpYesIcon,
  RsvpNoIcon,
  RsvpMaybeIcon,
  useStyletron,
  MOBILE,
} from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const RsvpStatusIcon: FC<{
  rsvp?: RsvpTypesEnum | null;
  size?: {
    mobile: string;
    desktop: string;
  };
}> = ({ rsvp, size = { mobile: '20px', desktop: '16px' } }) => {
  const [css, theme] = useStyletron();

  const sizeStyle: StyleObject = {
    width: size.desktop,
    height: size.desktop,

    [MOBILE]: {
      width: size.mobile,
      height: size.mobile,
    },
  };

  if (!rsvp) {
    return null;
  }

  switch (rsvp) {
    case RsvpTypesEnum.RsvpStatusAccepted:
      return (
        <RsvpYesIcon
          className={css({ color: theme.colors.green500, ...sizeStyle })}
        />
      );
    case RsvpTypesEnum.RsvpStatusDeclined:
      return (
        <RsvpNoIcon
          className={css({ color: theme.colors.red500, ...sizeStyle })}
        />
      );
    case RsvpTypesEnum.RsvpStatusTentative:
    case RsvpTypesEnum.RsvpStatusNeedsAction:
    default:
      return <RsvpMaybeIcon className={css(sizeStyle)} />;
  }
};
