import { onError } from '@apollo/client/link/error';
import { GraphQLErrorExtensions } from 'graphql';
import { queueExport } from 'process/export-notify';
import { MessageWithContext, i18n } from 'shared/lib';
import { notifications } from 'shared/ui';

const checkValidationError = (extensions: GraphQLErrorExtensions) => {
  if (extensions.validation) {
    const errors = extensions.validation as Record<string, string[]>;
    Object.values(errors).forEach((errorArr) => {
      if (errorArr[0]) {
        notifications.error({ title: errorArr[0] });
      }
    });
  }
};

export const notifyLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      const code = extensions.code as string;
      if (!code) {
        /** @todo use codes for error messages */
        checkValidationError(extensions);
        return;
      }

      let errorMessage = `common:ERROR.${extensions.code}`;
      let errorMessageContext: Record<string, string | number> = {};

      const customErrorMessageMap: Record<string, string | MessageWithContext> =
        operation.getContext()?.customErrorMessage;

      if (Object.keys(customErrorMessageMap ?? {}).includes(code)) {
        errorMessage =
          (customErrorMessageMap[code] as MessageWithContext)?.message ??
          customErrorMessageMap[code];
        errorMessageContext =
          (customErrorMessageMap[code] as MessageWithContext)?.context ?? {};
      }

      if (i18n.exists(errorMessage, errorMessageContext)) {
        if (code === 'EXPORT_DATA') {
          if (extensions.fileName) {
            queueExport(extensions.fileName as string);
          }

          notifications.info({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            title: i18n.t(errorMessage as any) ?? '',
          });
          return;
        }
        notifications.error({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title: i18n.t((errorMessage as any) ?? '', errorMessageContext),
        });
      }
    });
  }
});
