import { StyleObject } from 'styletron-standard';
import { TEXT, useStyletron } from '../theme';

export const useTooltipStyles = () => {
  const [, theme] = useStyletron();

  const bodyStyles: StyleObject = {
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    backgroundColor: theme.colors.gray600,
    color: theme.colors.white,
    ...TEXT.xs_book,
  };

  const innerStyles: StyleObject = {
    paddingTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    backgroundColor: theme.colors.gray600,
  };

  const arrowStyles: StyleObject = {
    backgroundColor: theme.colors.gray600,
  };

  return { bodyStyles, innerStyles, arrowStyles };
};
