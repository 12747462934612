import { useCallback, useEffect, useMemo, useRef, useReducer } from 'react';
import { DEFAULT_PAGE_SIZE } from './consts';
import { getPaginationMeta } from './get-meta';
import { paginationStateReducer } from './reducer';
import { PageActions, PaginationMeta, PaginationState } from './types';

type UsePaginationConfig = {
  totalItems?: number;
  initialPage?: number;
  initialPageSize?: number;
  canUseSearchParams?: boolean;
};

type PaginationActions = {
  setPage: (page: number) => void;
  setNextPage: () => void;
  setPreviousPage: () => void;
  setPageSize: (pageSize: number) => void;
};

export const usePagination: (
  config: UsePaginationConfig
) => PaginationState & PaginationMeta & PaginationActions = ({
  totalItems,
  initialPage = 1,
  initialPageSize = DEFAULT_PAGE_SIZE,
  canUseSearchParams = false,
}) => {
  /** @todo(@webatom) use useSearchParams from react-router v6 */
  // eslint-disable-next-line no-empty
  if (canUseSearchParams) {
  }

  const initialState = useMemo(
    () => ({
      totalItems: totalItems ?? 0,
      pageSize: initialPageSize,
      currentPage: initialPage,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [paginationState, dispatch] = useReducer(
    paginationStateReducer,
    initialState
  );

  const totalItemsRef = useRef(totalItems);

  useEffect(() => {
    if (
      typeof totalItems !== 'number' ||
      totalItems === totalItemsRef.current
    ) {
      return;
    }
    totalItemsRef.current = totalItems;
    dispatch({
      type: PageActions.SET_TOTAL_ITEMS,
      totalItems: totalItemsRef.current,
    });
  }, [totalItems]);

  return {
    ...paginationState,
    ...useMemo(() => getPaginationMeta(paginationState), [paginationState]),
    setPage: useCallback((page: number) => {
      dispatch({
        type: PageActions.SET_PAGE,
        page,
      });
    }, []),
    setNextPage: useCallback(() => {
      dispatch({ type: PageActions.NEXT_PAGE });
    }, []),
    setPreviousPage: useCallback(() => {
      dispatch({ type: PageActions.PREVIOUS_PAGE });
    }, []),
    setPageSize: useCallback((pageSize: number) => {
      dispatch({ type: PageActions.SET_PAGE_SIZE, pageSize });
    }, []),
  };
};
