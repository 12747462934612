import { FC, useEffect, useMemo, useRef, useCallback } from 'react';
import { Publisher } from '@opentok/client';
import { useSharedUser } from 'shared/lib';
import { useStyles } from './styles';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';
import { StreamWrap } from '../stream-wrap';

export const Participants: FC = () => {
  const { users, publisher, talkingUser, pinnedStreamId } =
    useSessionRoomState();
  const userData = useSharedUser();
  const dispatch = useSessionRoomDispatch();
  const talkingArr = useRef({
    id: publisher?.context.stream?.streamId,
    name: userData.user?.name,
  });
  const mergedUsers = useMemo(
    () => (publisher ? [publisher, ...users] : [...users]),
    [publisher, users]
  );
  const { rootClass, innerClass } = useStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({
        type: 'SET_TALKING_USER',
        payload: {
          talkingUser: talkingArr.current,
        },
      });
    }, 200);

    return () => clearInterval(interval);
  }, [dispatch]);

  const handleTalk = useCallback((id, name) => {
    talkingArr.current = {
      id,
      name,
    };
  }, []);

  return (
    <div className={rootClass}>
      <div className={innerClass}>
        {mergedUsers.map((userItem) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const streamId =
            (userItem.context as Publisher).stream?.streamId || null;

          if (!streamId) {
            return null;
          }

          return (
            <StreamWrap
              key={streamId}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              context={userItem?.context as Publisher}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              videoEl={userItem!.videoEl}
              hasAudio={userItem?.hasAudio}
              hasVideo={userItem?.hasVideo}
              isMain={
                pinnedStreamId
                  ? pinnedStreamId === streamId
                  : streamId === talkingUser?.id
              }
              isPinned={pinnedStreamId === streamId}
              isFull={mergedUsers.length === 1}
              onTalking={handleTalk}
            />
          );
        })}
      </div>
    </div>
  );
};
