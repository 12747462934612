import { useStyletron, TEXT, DESKTOP, MOBILE } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    display: 'flex',

    [MOBILE]: {
      flexDirection: 'column',
    },
  });

  const controlWrapperClass = css({
    [MOBILE]: {
      position: 'relative',
      marginBottom: '10px',

      '::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: '64px',
        backgroundImage: `linear-gradient(to left, ${theme.colors.white900} 0%, rgba(255, 255, 255, 0) 100%)`,
        pointerEvents: 'none',
      },
    },
  });

  const controlListClass = css({
    display: 'flex',
    overflow: 'auto',

    [DESKTOP]: {
      flexDirection: 'column',
      gap: '16px',
      minWidth: '234px',
      padding: '40px 24px',
      borderRight: `1px solid ${theme.colors.gray200}`,
    },

    [MOBILE]: {
      flexDirection: 'row',
      paddingBottom: '6px',
    },
  });

  const controlItemClass = css({
    flexShrink: 0,
  });

  const getControlButtonClass = (isActive?: boolean) =>
    css({
      ...TEXT.base_medium,
      color: isActive ? theme.colors.accent500 : 'inherit',

      [MOBILE]: {
        padding: '6px 16px',
        borderRadius: '8px',
        ...TEXT.base_book,
        backgroundColor: isActive ? theme.colors.accent50 : 'initial',
        color: isActive ? theme.colors.accent700 : 'inherit',
      },
    });

  const calendarContainerClass = css({
    flexGrow: 1,
    padding: '40px',

    [MOBILE]: {
      padding: '16px',
      border: `1px solid ${theme.colors.gray200}`,
      borderRadius: '8px',
    },
  });

  return {
    rootClass,
    controlWrapperClass,
    controlListClass,
    controlItemClass,
    getControlButtonClass,
    calendarContainerClass,
  };
};
