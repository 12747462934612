import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MeetingRoomChatIcon } from 'shared/ui';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';
import { ActionButton } from '../action-button';

export const ToggleChatButton: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { isShowChat, chatOptions } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();
  const toggleChatVisibility = useCallback(() => {
    // Clear notification balloon
    if (!isShowChat) {
      dispatch({
        type: 'REFRESH_CHAT_OPTIONS',
        payload: {
          isShowNotification: false,
        },
      });
    }

    dispatch({
      type: 'TOGGLE_SHOW_CHAT',
      payload: {
        isShow: !isShowChat,
      },
    });
  }, [dispatch, isShowChat]);

  return (
    <ActionButton
      icon={<MeetingRoomChatIcon />}
      isActive={isShowChat}
      onClick={toggleChatVisibility}
      hasNotification={chatOptions.isShowNotification}
    >
      {t('session-room:CHAT.TITLE')}
    </ActionButton>
  );
};
