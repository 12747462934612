import {
  createTheme,
  createThemedStyled,
  createThemedUseStyletron,
  createThemedWithStyle,
  lightThemePrimitives,
  Theme,
} from 'baseui';
import { Colors } from 'baseui/theme';
import { Cookies } from 'react-cookie';
import { hexToRgb, saveAccentColor } from 'shared/lib';
import { COLORS } from './colors';

export type TrustvideoTheme = Theme & {
  colors: Theme['colors'] & typeof COLORS;
};

type Palette = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

export const getTheme = (palette?: Palette) => {
  const accentPalette = palette
    ? {
        accent50: `#${palette[50]}`,
        accent100: `#${palette[100]}`,
        accent200: `#${palette[200]}`,
        accent300: `#${palette[300]}`,
        accent400: `#${palette[400]}`,
        accent500: `#${palette[500]}`,
        accent600: `#${palette[600]}`,
        accent700: `#${palette[700]}`,
        accent800: `#${palette[800]}`,
        accent900: `#${palette[900]}`,
      }
    : {};

  if (accentPalette.accent500) {
    saveAccentColor(accentPalette.accent500);
  }

  Object.entries(accentPalette).forEach(([variable, color]) => {
    document.documentElement.style.setProperty(`--tru-${variable}`, color);
  });

  const custom = {
    ...lightThemePrimitives,
    ...COLORS,
    ...accentPalette,
    primary: COLORS.gray700,
    accent: accentPalette.accent500 || COLORS.accent500,
    accentHover: '#3399C2',
    white: COLORS.white900,
    primaryFontFamily: 'Circular Std',
  };

  const cookies = new Cookies();

  const overrides: {
    [k: string]: Record<string, string | number | null>;
    colors:
      | Partial<Record<keyof Colors, string | null>>
      | { [k: string]: string };
  } = {
    borders: {
      popoverBorderRadius: '8px',
      inputBorderRadius: '8px',
    },
    grid: {
      columns: 12,
      gutters: 24,
      margins: 0,
      gaps: 0,
      maxWidth: '100%',
    },
    colors: {
      buttonPrimaryFill: custom.accent,
      buttonPrimaryText: custom.white,
      buttonPrimaryHover: custom.accent600,
      buttonPrimaryActive: custom.accent600,

      buttonSecondaryFill: custom.white,
      buttonSecondaryText: custom.gray700,
      buttonSecondaryHover: custom.white,
      buttonSecondaryActive: custom.white,

      buttonTertiaryFill: custom.gray200,
      buttonTertiaryText: custom.primary,
      buttonTertiaryHover: custom.gray300,
      buttonTertiaryActive: custom.gray300,

      tickFill: custom.white,
      tickFillHover: custom.white,
      tickFillActive: custom.white,
      tickFillSelected: custom.white,
      tickFillSelectedHover: custom.white,
      tickMarkFill: '#42A8D1',
      tickFillSelectedHoverActive: custom.white,

      tableStripedBackground: custom.gray50,
      tableHeadBackgroundColor: custom.gray100,

      buttonPrimarySelectedFill:
        cookies.get('primaryButtonTextColor') ?? custom.primary,
      buttonPrimarySelectedText:
        cookies.get('primaryButtonTextColor') ?? custom.white,
      buttonPrimaryForeground:
        cookies.get('primaryButtonBgColor') ?? custom.accent,
      buttonPrimaryBg:
        cookies.get('primaryButtonBackgroundColor') ?? custom.accent,
      secondaryA: cookies.get('secondaryColor') ?? custom.primary,
      secondary: cookies.get('secondaryColor') ?? custom.primary,
      contentPrimary: cookies.get('primaryColor') ?? custom.primary,
      backgroundInversePrimary: cookies.get('primaryColor') ?? custom.primary,
      inputFill: custom.white,
      tickBorder: cookies.get('primaryColor') ?? custom.primary,
      breadcrumbsText: cookies.get('primaryColor') ?? custom.primary,
      breadcrumbsSeparatorFill: cookies.get('primaryColor') ?? custom.primary,
      linkText: cookies.get('primaryColor') ?? custom.primary,
      linkVisited: cookies.get('primaryColor') ?? custom.primary,
      linkHover: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.92'
      ),
      linkActive: cookies.get('primaryColor') ?? custom.primary,
      fileUploaderBorderColorActive:
        cookies.get('primaryColor') ?? custom.primary,
      fileUploaderBorderColorDefault: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.92'
      ),
      fileUploaderMessageColor: cookies.get('primaryColor') ?? custom.primary,
      borderSelected: cookies.get('primaryColor') ?? custom.primary,
      borderFocus: cookies.get('primaryColor') ?? custom.primary,
      // Calendar
      calendarForeground: cookies.get('primaryColor') ?? custom.primary,
      calendarForegroundDisabled: cookies.get('primaryColor') ?? custom.primary,
      calendarHeaderForeground: cookies.get('primaryColor') ?? custom.primary,
      calendarHeaderBackgroundActive: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.92'
      ),
      calendarHeaderForegroundDisabled: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.92'
      ),
      calendarDayBackgroundPseudoSelected: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.92'
      ),
      calendarDayForegroundPseudoSelected:
        cookies.get('primaryColor') ?? custom.primary,
      calendarDayBackgroundPseudoSelectedHighlighted: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.92'
      ),
      calendarDayForegroundPseudoSelectedHighlighted:
        cookies.get('primaryColor') ?? custom.primary,
      calendarDayBackgroundSelected:
        cookies.get('primaryColor') ?? custom.primary,
      calendarDayBackgroundSelectedHighlighted:
        cookies.get('primaryColor') ?? custom.primary,
      // Menu
      menuFillHover: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.20'
      ),
      menuFontDefault: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.92'
      ),
      menuFontDisabled: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.72'
      ),
      menuFontHighlighted: hexToRgb(
        cookies.get('primaryColor') ?? custom.primary,
        '0.72'
      ),
      menuFontSelected: cookies.get('primaryColor') ?? custom.primary,
    },
  };

  return createTheme(custom, overrides);
};

export const styled = createThemedStyled<TrustvideoTheme>();
export const withStyle = createThemedWithStyle<TrustvideoTheme>();
export const useStyletron = createThemedUseStyletron<TrustvideoTheme>();
