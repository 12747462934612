import { useHasAnyPermissions, useHasAnyRoles, useHasPermissions } from '.';

export const DOCUMENT_TEMPLATES_ROLES = [
  'Admin',
  'Account Administrator',
  'Clinical Supervisor',
];

export const useCanViewAppointments = () => {
  const viewOwnAppointments = useHasAnyPermissions([
    'appointment-view-own',
    'appointment-view-any',
  ]);

  return viewOwnAppointments;
};

export const useCanViewParticipants = () => {
  const check = useHasAnyPermissions(['patient-view-any', 'patient-view-own']);

  return check;
};

export const useCanViewProviders = () => {
  const check = useHasPermissions(['user-view-all']);

  return check;
};

export const useCanViewDispatcherView = () => {
  const check = useHasAnyRoles(['Dispatcher']);

  return check;
};

export const useCanViewUsers = () => {
  const check = useHasPermissions(['user-view-all']);

  return check;
};

export const useCanViewDocuments = () => {
  const check = useHasPermissions(['document-view-all']);

  return check;
};

export const useCanViewDocumentTemplates = () => {
  const rolesCheck = useHasAnyRoles(DOCUMENT_TEMPLATES_ROLES);
  const permissionsCheck = useHasPermissions(['document-view-all']);

  return rolesCheck && permissionsCheck;
};

export const useCanViewDocumentPackets = () => {
  const check = useHasPermissions(['document-packet-view-all']);

  return check;
};

export const useCanViewDocumentSubmissions = () => {
  const check = useHasPermissions(['document-submission-view-all']);

  return check;
};

export const useCanViewSessionNotes = () => {
  const check = useHasPermissions(['note-view-own', 'note-view-any']);

  return check;
};

export const useCanViewReports = () => {
  const check = useHasPermissions(['appointment-report-own']);

  return check;
};

export const useCanCreateOwnAppointment = () => {
  const check = useHasPermissions(['appointment-create-own']);

  return check;
};
