import { FC } from 'react';
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover';
import { Service } from 'shared/api';
import {
  styled,
  Input,
  INPUT_SIZE,
  DollarIcon,
  FormControl,
  Desktop,
  Mobile,
  useMobileMediaQuery,
} from 'shared/ui';
import { useModal, MODAL_TYPE } from 'shared/lib';
import { useTranslation } from 'react-i18next';
import { ServiceList, ServiceButton } from './ui';
import { useStyles } from './styles';

type ServiceSelectProps = {
  options: Service[];
  serviceValue: Service[];
  priceValue: number;
  onChangeService: (value: Service[]) => void;
  onChangePrice: (value: number) => void;
  serviceError?: string;
  priceError?: string;
  isPaymentEnabled: boolean;
};

export const ServiceSelect: FC<ServiceSelectProps> = ({
  options,
  serviceValue,
  priceValue,
  onChangeService,
  onChangePrice,
  serviceError,
  priceError,
  isPaymentEnabled,
}) => {
  const { t } = useTranslation(['common', 'appointments']);
  const {
    getPopoverOverrides,
    serviceRowClass,
    serviceBoxClass,
    priceBoxClass,
    priceInputStyle,
    priceIconClass,
    subtitleStyle,
  } = useStyles();

  const isMobile = useMobileMediaQuery();

  const { open: openModal, close: closeModal } = useModal();
  const Subtitle = styled('h4', subtitleStyle);
  const hasServiceError = !!serviceError;

  return (
    <div className={serviceRowClass}>
      <div className={serviceBoxClass}>
        <Subtitle>{t('appointments:CREATE.SERVICE')}</Subtitle>
        <FormControl error={serviceError}>
          <>
            <Desktop>
              <StatefulPopover
                placement={PLACEMENT.bottomLeft}
                overrides={getPopoverOverrides(
                  serviceValue.length !== 0,
                  isPaymentEnabled
                )}
                popoverMargin={8}
                ignoreBoundary
                content={({ close }) => (
                  <ServiceList
                    value={serviceValue}
                    options={options}
                    onClick={(value) => {
                      onChangeService(value);
                      close();
                    }}
                  />
                )}
                accessibilityType="menu"
                triggerType={TRIGGER_TYPE.click}
              >
                <div>
                  <ServiceButton
                    hasError={hasServiceError}
                    value={serviceValue}
                    isPaymentEnabled={isPaymentEnabled}
                  />
                </div>
              </StatefulPopover>
            </Desktop>

            <Mobile>
              <ServiceButton
                hasError={hasServiceError}
                isPaymentEnabled={isPaymentEnabled}
                value={serviceValue}
                onClick={() =>
                  openModal(MODAL_TYPE.BASE, {
                    title: t('appointments:DATE_MODAL.TITLE'),
                    children: (
                      <ServiceList
                        value={serviceValue}
                        options={options}
                        onClick={(value) => {
                          onChangeService(value);
                          closeModal();
                        }}
                      />
                    ),
                  })
                }
              />
            </Mobile>
          </>
        </FormControl>
      </div>

      {!!serviceValue.length && isPaymentEnabled && (
        <div className={priceBoxClass}>
          <Subtitle>{t('appointments:CREATE.PRICE')}</Subtitle>
          <FormControl error={priceError}>
            <Input
              size={isMobile ? INPUT_SIZE.MEDIUM : INPUT_SIZE.LARGE}
              hasError={!!priceError}
              value={priceValue}
              onChange={({ currentTarget }) => {
                onChangePrice(+currentTarget.value.replace(/\D/g, ''));
              }}
              startEnhancer={() => <DollarIcon className={priceIconClass} />}
              style={priceInputStyle}
            />
          </FormControl>
        </div>
      )}
    </div>
  );
};
