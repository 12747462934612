import { FC, useEffect, useMemo } from 'react';
import { NotificationChannelsEnum } from 'shared/api';
import { Desktop, Radio, RadioGroup } from 'shared/ui';
import { hideGeneratedEmail, PHONE_COUNTRY } from 'shared/lib';
import { useCurrentOrganization } from 'entities/organization';
import { useInviteMethods } from './invite-methods';
import { ParticipantInviteProps } from './model';
import { useStyles } from './styles';
import { FieldDefault } from './field-default';
import { FieldPhone } from './field-phone';

export const ParticipantInvite: FC<ParticipantInviteProps> = ({
  value,
  participant,
  onChange,
  disabled = false,
  hasError = false,
  withNone = true,
  onBlur,
}) => {
  const {
    getRadioGroupOverrides,
    getRadioOverrides,
    radioContentClass,
    getLabelClass,
    getHintClass,
    inputWrapperClass,
  } = useStyles();

  const { organization } = useCurrentOrganization();
  const methods = useInviteMethods();
  const noneMethod = methods[0];
  const filteredMethods = useMemo(
    () =>
      methods.filter((method) => {
        if (method.type === NotificationChannelsEnum.None) {
          return false;
        }
        if (
          method.type === NotificationChannelsEnum.Sms &&
          !organization.superadmin_settings_sms_enabled
        ) {
          return false;
        }
        return true;
      }),
    [methods, organization.superadmin_settings_sms_enabled]
  );
  const phone = useMemo(() => {
    if (participant?.phone) {
      return participant.phone;
    }

    if (!participant?.sms) {
      return null;
    }

    const isStartWithCountyCode = participant.sms.startsWith(PHONE_COUNTRY);

    return isStartWithCountyCode
      ? participant.sms
      : `${PHONE_COUNTRY}${participant.sms}`;
  }, [participant]);

  const checkedValue =
    value?.channel ?? participant?.default_notification_channel;

  useEffect(() => {
    if (
      value?.channel === NotificationChannelsEnum.Sms &&
      !organization.superadmin_settings_sms_enabled
    ) {
      onChange(null);
    }
  }, [onChange, organization.superadmin_settings_sms_enabled, value?.channel]);

  return (
    <RadioGroup
      onChange={({ currentTarget }) => {
        const channel = currentTarget.value as NotificationChannelsEnum;
        onChange({
          channel,
          value:
            channel === NotificationChannelsEnum.Mail
              ? participant?.email ?? undefined
              : phone ?? undefined,
        });
      }}
      value={checkedValue}
      name="notification-type"
      overrides={getRadioGroupOverrides(hasError)}
      disabled={disabled}
    >
      {filteredMethods.map(({ type, label, hint, placeholder }) => {
        const isActive = checkedValue === type;
        const inputValue =
          type === NotificationChannelsEnum.Mail
            ? hideGeneratedEmail(value?.value)
            : value?.value;

        const Component =
          type === NotificationChannelsEnum.Sms ? FieldPhone : FieldDefault;

        return (
          <Radio
            key={type}
            value={type}
            overrides={getRadioOverrides(isActive)}
          >
            <div className={radioContentClass}>
              <p className={getLabelClass(isActive, disabled)}>{label}</p>
              <Desktop>
                {!isActive && <p className={getHintClass(isActive)}>{hint}</p>}
              </Desktop>
            </div>
            {isActive && (
              <div className={inputWrapperClass}>
                <Component
                  placeholder={placeholder as string}
                  value={inputValue}
                  onBlur={onBlur}
                  onChange={(v) =>
                    onChange({
                      channel: type,
                      value: v,
                    })
                  }
                  hasError={hasError}
                />
                <Desktop>
                  <p className={getHintClass(isActive)}>{hint}</p>
                </Desktop>
              </div>
            )}
          </Radio>
        );
      })}

      {withNone && (
        <Radio
          value={noneMethod.type}
          overrides={getRadioOverrides(checkedValue === noneMethod.type)}
        >
          <div className={radioContentClass}>
            <p
              className={getLabelClass(
                checkedValue === noneMethod.type,
                disabled
              )}
            >
              {noneMethod.label}
            </p>
            <Desktop>
              <p className={getHintClass(checkedValue === noneMethod.type)}>
                {noneMethod.hint}
              </p>
            </Desktop>
          </div>
        </Radio>
      )}
    </RadioGroup>
  );
};
