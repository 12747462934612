import { styled, SearchIcon, COLORS } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const StyledSearchIcon = styled(SearchIcon, {
  width: '20px',
  color: COLORS.gray400,
  pointerEvents: 'none',
});

export const useStyles = () => {
  const searchInputStyled: StyleObject = {
    width: '100%',
    maxWidth: '240px',
  };

  return {
    searchInputStyled,
  };
};
