import { FC } from 'react';
import {
  useStyletron,
  Button,
  BUTTON_SIZE,
  BUTTON_KIND,
  mergeStyles,
  Mobile,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal';
import { ConfirmProps, CONFIRM_KIND, CONFIRM_SIZE } from './model';
import {
  CONFIRM_SIZE_STYLES,
  CONFIRM_FIGURE_STYLES,
  CONFIRM_ICONS,
  CONFIRM_ICON_STYLES,
  CONFIRM_BUTTON_STYLES,
  CONFIRM_LOADING_STYLES,
} from './util';
import { useStyles } from './styles';
import { ModalCloseEvent } from '../model';

export const Confirm: FC<ConfirmProps> = ({
  title,
  caption,
  submitText,
  noText,
  onCancel,
  onSubmit,
  isLoading,
  kind = CONFIRM_KIND.ALERT,
  size = CONFIRM_SIZE.LARGE,
}) => {
  const [css, theme] = useStyletron();
  const { t } = useTranslation(['common']);
  const {
    rootStyle,
    bodyClass,
    figureStyle,
    iconStyle,
    titleClass,
    captionClass,
    footerClass,
    closeButtonClass,
  } = useStyles();

  const Icon = CONFIRM_ICONS[kind];

  return (
    <Modal onClose={(event) => onCancel?.(event)}>
      <div
        className={css(
          mergeStyles([rootStyle, CONFIRM_SIZE_STYLES({ size }) ?? {}])
        )}
      >
        <Mobile>
          <button
            className={closeButtonClass}
            onClick={() => onCancel?.(ModalCloseEvent.CloseButtonClickEvent)}
            type="button"
          >
            {t('common:BUTTONS.CLOSE')}
          </button>
        </Mobile>
        <div className={bodyClass}>
          <figure
            className={css(
              mergeStyles([
                figureStyle,
                CONFIRM_FIGURE_STYLES({ kind, theme }) ?? {},
              ])
            )}
          >
            <Icon
              className={css(
                mergeStyles([
                  iconStyle,
                  CONFIRM_ICON_STYLES({ kind, theme }) ?? {},
                ])
              )}
            />
          </figure>

          <div>
            <h3 className={titleClass}>{title}</h3>
            <p className={captionClass}>{caption}</p>
          </div>
        </div>
        <div className={footerClass}>
          <Button
            size={BUTTON_SIZE.SMALL}
            kind={BUTTON_KIND.SECONDARY}
            onClick={() => onCancel?.(ModalCloseEvent.CloseButtonClickEvent)}
          >
            {noText || t('common:BUTTONS.CANCEL')}
          </Button>
          <Button
            size={BUTTON_SIZE.SMALL}
            onClick={onSubmit}
            isLoading={isLoading}
            style={CONFIRM_BUTTON_STYLES({ kind, theme })}
            loadingStyle={CONFIRM_LOADING_STYLES({ kind, theme })}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
