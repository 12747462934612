import { useHideAppointmentMutation } from 'shared/api';

export const useHideAppointment = (id: string) => {
  const [hide, { loading: isLoading }] = useHideAppointmentMutation({
    variables: { id },
    optimisticResponse: {
      hideAppointment: {
        __typename: 'Appointment',
        id,
      },
    },
    update(cache, { data }) {
      if (data?.hideAppointment) {
        const normalizedId = cache.identify({
          id: data?.hideAppointment.id,
          __typename: 'Appointment',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  return { hide, isLoading };
};
