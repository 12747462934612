import { TEXT, useStyletron } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const buttonClass = css({
    width: '100%',
    height: '80px',
    padding: '16px 24px',
    borderWidth: '1px',
    borderRadius: '8px',
    borderStyle: 'solid',
    borderColor: theme.colors.gray200,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    transition: `background-color 0.2s cubic-bezier(0, 0, 1, 1)`,

    ':hover': {
      backgroundColor: theme.colors.gray50,
    },
  });

  const textBlockClass = css({
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
  });

  const titleClass = css({
    ...TEXT.base_medium,
  });

  const captionClass = css({
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  const iconClass = css({
    color: theme.colors.gray400,
    height: '24px',
    width: '24px',
    transition: `color 0.2s cubic-bezier(0, 0, 1, 1)`,

    ':hover': {
      color: theme.colors.red500,
    },
  });

  return {
    buttonClass,
    textBlockClass,
    titleClass,
    captionClass,
    iconClass,
  };
};
