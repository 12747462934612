import { FC, useCallback } from 'react';
import {
  MODAL_ICON_TYPE,
  Button,
  BUTTON_SIZE,
  BUTTON_KIND,
  PatientsIcon,
  CalendarIcon,
  CheckIcon,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { APPOINTMENT_TABS } from 'entities/appointment';
import { useParticipantModal } from 'widgets/participant-modal';
import { Participant } from 'shared/api';
import { MODAL_TYPE, useModal } from 'shared/lib';
import { useStyles } from './styles';

const Footer: FC<{
  appointmentId: string;
  openSession?: (id: string, tab?: APPOINTMENT_TABS) => void;
  onClose: () => void;
  participants: Participant[];
}> = ({ openSession, appointmentId, onClose, participants }) => {
  const { t } = useTranslation(['appointments']);
  const { buttonRowClass, iconClass, buttonStyle } = useStyles();

  const openParticipantModal = useParticipantModal({
    appointmentId,
    participants,
  });

  return (
    <>
      <div>
        <Button
          size={BUTTON_SIZE.SMALL}
          kind={BUTTON_KIND.SECONDARY}
          type="button"
          startEnhancer={<PatientsIcon className={iconClass} />}
          style={buttonStyle}
          onClick={() => {
            onClose();
            openParticipantModal();
          }}
        >
          <span>{t('appointments:VIEW.BUTTONS.ADD_PARTICIPANT')}</span>
        </Button>
      </div>
      <div className={buttonRowClass}>
        <Button
          size={BUTTON_SIZE.SMALL}
          kind={BUTTON_KIND.SECONDARY}
          type="button"
          startEnhancer={<CalendarIcon className={iconClass} />}
          style={buttonStyle}
          disabled
        >
          <span>{t('appointments:CREATE.SUCCESS_MODAL.ADD_TO_CALENDAR')}</span>
        </Button>
      </div>
      <div className={buttonRowClass}>
        <Button
          size={BUTTON_SIZE.SMALL}
          onClick={() => {
            openSession?.(appointmentId);
            onClose();
          }}
          type="button"
          style={buttonStyle}
        >
          {t('appointments:CREATE.SUCCESS_MODAL.SHOW_SESSION_DETAILS')}
        </Button>
      </div>
    </>
  );
};

export const useCreateSuccess = (
  openSession?: (id: string, tab?: APPOINTMENT_TABS) => void
) => {
  const { t } = useTranslation(['appointments']);
  const { open, close } = useModal();

  const openSuccess = useCallback(
    ({
      id,
      date,
      participants,
    }: {
      date: string;
      id: string;
      participants: Participant[];
    }) => {
      open(MODAL_TYPE.DEFAULT, {
        title: t('appointments:CREATE.SUCCESS_MODAL.TITLE'),
        content: date,
        icon: {
          type: MODAL_ICON_TYPE.SUCCESS,
          svg: (className) => <CheckIcon className={className} />,
        },
        footer: (
          <Footer
            onClose={close}
            appointmentId={id}
            openSession={openSession}
            participants={participants}
          />
        ),
      });
    },
    [open, t, openSession, close]
  );

  return openSuccess;
};
