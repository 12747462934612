import { FC } from 'react';
import { ReactSortable, ReactSortableProps } from 'react-sortablejs';
import { omit, uniqWith, equals } from 'ramda';
import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DndList: FC<ReactSortableProps<any>> = ({
  children,
  list,
  setList,
  ...props
}) => (
  <ReactSortable
    list={list.map((item) => ({ ...item, chosen: true }))}
    setList={(newState, sortable, store) => {
      const newList = uniqWith(equals)(
        newState.map((item) => omit(['chosen', 'selected'], item))
      );
      if (equals(list, newList)) {
        return;
      }
      setList(newList, sortable, store);
    }}
    animation={200}
    delay={100}
    delayOnTouchOnly
    forceFallback
    {...props}
  >
    {children}
  </ReactSortable>
);
