import { utcToZonedTime } from 'date-fns-tz';

const USER_TIMEZONE_KEY = 'user_timezone';

export const setUserTimezone: (timezone: string) => void = (timezone) => {
  sessionStorage.setItem(USER_TIMEZONE_KEY, timezone);
};

export const getUserTimezone: () => string = () =>
  sessionStorage.getItem(USER_TIMEZONE_KEY) ?? 'America/New_York';

export const getUserZonedDate: (date: Date) => Date = (date) =>
  utcToZonedTime(date, getUserTimezone());

export const getUserZonedDateNow = () => getUserZonedDate(new Date());
