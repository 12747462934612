import { MouseEventHandler, ReactNode } from 'react';
import { To } from 'react-router-dom';
import { BUTTON_COLOR, BUTTON_KIND } from '../button';

export enum NOTIFICATION_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export enum NOTIFICATION_INSERTION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum NOTIFICATION_REMOVAL_SOURCE {
  TIMEOUT = 'timeout',
  CLICK = 'click',
  MANUAL = 'manual',
}

export type DURATION = number | 'none';

type NotificationMessage = ReactNode;

export type NotificationLink = {
  message: NotificationMessage;
  to: To;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export type NotificationButton = {
  message: NotificationMessage;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  kind?: BUTTON_KIND;
  color?: BUTTON_COLOR;
};

export interface UserNotification {
  id: string;
  title: NotificationMessage;
  duration: DURATION;
  message?: NotificationMessage;
  onClose?: (type: NOTIFICATION_REMOVAL_SOURCE) => void;
  links?: NotificationLink[];
  buttons?: NotificationButton[];
}

export interface StoredUserNotification extends UserNotification {
  type: NOTIFICATION_TYPE;
  hasBeenRemoved?: boolean;
}
