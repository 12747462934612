import { ReactNode } from 'react';
import { Appointment, AppointmentRequest } from 'shared/api';

export enum BaseAppointmentCardAlertType {
  SUCCESS = 'success',
  WARNING = 'warning',
}

export type BaseAppointmentCardProps = {
  appointment: Appointment | AppointmentRequest;
  alert?: {
    type: BaseAppointmentCardAlertType;
    text: string;
  };
  customDate?: { start: Date; end: Date };
  customBody?: ReactNode;
};
