import { useMemo } from 'react';
import { AvailabilitySlot } from 'shared/api';

export const useSlots = (
  options: readonly AvailabilitySlot[] | null | undefined
) =>
  useMemo(() => {
    const slots = options || [];
    const earlier: AvailabilitySlot[] = [];
    const morning: AvailabilitySlot[] = [];
    const afternoon: AvailabilitySlot[] = [];
    const evening: AvailabilitySlot[] = [];
    const later: AvailabilitySlot[] = [];

    for (let i = 0; i < slots.length; i++) {
      const slotHour = slots[i].time.date.getHours();
      switch (true) {
        case slotHour >= 0 && slotHour < 9: {
          earlier.push(slots[i]);
          break;
        }
        case slotHour >= 9 && slotHour < 12: {
          morning.push(slots[i]);
          break;
        }
        case slotHour >= 12 && slotHour < 18: {
          afternoon.push(slots[i]);
          break;
        }
        case slotHour >= 18 && slotHour < 21: {
          evening.push(slots[i]);
          break;
        }
        default:
          later.push(slots[i]);
          break;
      }
    }

    return { morning, afternoon, evening, earlier, later };
  }, [options]);
