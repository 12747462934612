import { useStyletron, TEXT, DESKTOP, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { PopoverOverrides } from 'baseui/popover';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const modalStyle: StyleObject = {
    maxWidth: '720px',
    padding: 0,
  };

  const getPopoverOverrides = (width: number): PopoverOverrides => ({
    Body: {
      style: {
        zIndex: 102,
        width: `${width}px`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: 'none',
        backgroundColor: 'initial',
      },
    },
    Inner: {
      style: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '24px',
        paddingRight: '24px',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderTopColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        borderLeftColor: theme.colors.gray200,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        backgroundColor: theme.colors.white900,
      },
    },
  });

  const headerClass = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: `2px solid ${theme.colors.gray200}`,
    backgroundColor: theme.colors.gray50,
  });

  const headerIconClass = css({
    width: '40px',
    color: theme.colors.gray400,
  });

  const headerTitleClass = css({
    marginTop: '8px',
    ...TEXT.xl2_medium,
  });

  const headerCaptionClass = css({
    marginTop: '4px',
    ...TEXT.base_book,
    color: theme.colors.gray400,
  });

  const bodyClass = css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
    backgroundColor: theme.colors.white900,

    [MOBILE]: {
      padding: 0,
    },
  });

  const searchClass = css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',

    [MOBILE]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  });

  const popoverContainer = css({
    flexGrow: 1,
  });

  const inputWrapperClass = css({
    flexGrow: 1,
  });

  const searchIconClass = css({
    width: '24px',
    color: theme.colors.gray400,
  });

  const textClass = css({
    ...TEXT.sm_book,
    color: theme.colors.gray400,

    [MOBILE]: {
      alignSelf: 'center',
      margin: '-8px 0',
    },
  });

  const listWrapperClass = css({
    maxHeight: '260px',
    margin: '-8px -24px',
    overflow: 'auto',

    [MOBILE]: {
      maxHeight: '303px',

      '::after': {
        content: '""',
        position: 'absolute',
        left: '-1px',
        right: '-1px',
        bottom: '-1px',
        height: '33px',
        borderRadius: '4px',
        background: 'linear-gradient(rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
        pointerEvents: 'none',
      },
    },
  });

  const fetchMoreSpinnerClass = css({
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 24px 16px',
  });

  const getItemClass = (isSelected: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      minHeight: '65px',
      padding: '8px 24px',
      backgroundColor: isSelected ? theme.colors.gray100 : 'initial',
      transition: 'background-color 0.2s cubic-bezier(0, 0, 1, 1)',
      cursor: 'pointer',

      ':not(:first-child)': {
        borderTop: `1px solid ${theme.colors.gray200}`,
      },

      ':hover': {
        backgroundColor: isSelected
          ? theme.colors.gray100
          : theme.colors.gray50,
      },
    });

  const nameClass = css({
    ...TEXT.base_medium,
  });

  const captionClass = css({
    marginTop: '4px',
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  const checkIconClass = css({
    width: '24px',
    marginLeft: 'auto',
    color: theme.colors.accent,
  });

  const footerClass = css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',

    [DESKTOP]: {
      padding: '12px 24px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      borderTop: `2px solid ${theme.colors.gray200}`,
      backgroundColor: theme.colors.gray50,
    },

    [MOBILE]: {
      marginTop: '24px',
    },
  });

  const buttonStyle: StyleObject = {
    [MOBILE]: {
      flexGrow: 1,
    },
  };

  const spinnerStyle: StyleObject = {
    position: 'relative',
    zIndex: 1,
    marginRight: 'auto',
    marginLeft: 'auto',
    borderTopColor: theme.colors.accent,
  };

  const messageClass = css({
    position: 'relative',
    zIndex: 1,
  });

  const fieldsClass = css({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  });

  return {
    modalStyle,
    getPopoverOverrides,
    headerClass,
    headerIconClass,
    headerTitleClass,
    headerCaptionClass,
    bodyClass,
    searchClass,
    popoverContainer,
    inputWrapperClass,
    searchIconClass,
    textClass,
    listWrapperClass,
    fetchMoreSpinnerClass,
    getItemClass,
    nameClass,
    captionClass,
    checkIconClass,
    footerClass,
    buttonStyle,
    spinnerStyle,
    messageClass,
    fieldsClass,
  };
};
