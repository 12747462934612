import { useCallback } from 'react';
import { TEXT, useStyletron, MOBILE } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();
  const tabClass: (isActive: boolean) => string = useCallback(
    (isActive) =>
      css({
        ...TEXT.xl4_bold,
        color: isActive ? theme.colors.gray700 : theme.colors.gray400,
        transitionProperty: 'color',
        transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
        transitionDuration: '200ms',
        ':hover': {
          color: isActive ? theme.colors.gray700 : theme.colors.gray500,
        },
        display: 'inline-flex',
        gap: '8px',

        [MOBILE]: {
          ...TEXT.xl3_bold,
        },
      }),
    [theme, css]
  );

  return { tabClass };
};
