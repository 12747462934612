import { useStyletron, TEXT, MOBILE } from 'shared/ui';
import { PopoverOverrides } from 'baseui/popover';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rowClass = css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flexWrap: 'wrap',
  });

  const nameClass = css({
    ...TEXT.xl_medium,
  });

  const roleClass = css({
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  const buttonsClass = css({
    marginLeft: 'auto',

    [MOBILE]: {
      width: '100%',
    },
  });

  const popoverOverrides: PopoverOverrides = {
    Body: {
      style: {
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      },
    },
    Inner: {
      style: {
        width: '720px',
        backgroundColor: theme.colors.white900,
      },
    },
  };

  const popoverHeaderClass = css({
    padding: '16px 24px',
    borderBottom: `2px solid ${theme.colors.gray200}`,
    backgroundColor: theme.colors.gray50,
  });

  const popoverTitleClass = css({
    ...TEXT.xl_medium,
  });

  const popoverFooterClass = css({
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: '12px 24px',
    borderTop: `2px solid ${theme.colors.gray200}`,
    backgroundColor: theme.colors.gray50,
  });

  const changeButtonStyle: StyleObject = {
    [MOBILE]: {
      width: '100%',
    },
  };

  return {
    rowClass,
    nameClass,
    roleClass,
    buttonsClass,
    popoverOverrides,
    popoverHeaderClass,
    popoverTitleClass,
    popoverFooterClass,
    changeButtonStyle,
  };
};
