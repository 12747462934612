import { ECGPackageType } from 'features/vital';

export enum VitalSensors {
  BLOOD_PRESSURE = 'blood_pressure',
  BLOOD_OXY = 'blood_oxy',
  TEMPERATURE = 'temperature',
  GLUCOSE = 'glucose',
  ECG = 'ECG',
}

export type BloodPressureType = {
  Sys: number;
  Dia: number;
  Plus: number;
};

export type BloodOxyType = {
  SpO2: number;
  PR: number;
};

type SingleValueType = {
  Value: number;
};

export type TemperatureType = SingleValueType;

export type GlucoseType = SingleValueType;

export type VitalMessage =
  | {
      typeString: VitalSensors.BLOOD_PRESSURE;
      data: BloodPressureType;
    }
  | {
      typeString: VitalSensors.BLOOD_OXY;
      data: BloodOxyType;
    }
  | {
      typeString: VitalSensors.TEMPERATURE;
      data: TemperatureType;
    }
  | {
      typeString: VitalSensors.GLUCOSE;
      data: GlucoseType;
    }
  | {
      typeString: VitalSensors.ECG;
      data: ECGPackageType;
    };
