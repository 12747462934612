import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  BUTTON_SIZE,
  Input,
  INPUT_SIZE,
  InviteIcon,
  notifications,
} from 'shared/ui';
import { useCopyToClipboard } from 'usehooks-ts';
import { MODAL_TYPE, useModal } from 'shared/lib';
import { useStyles } from './styles';

const Content: FC<{ link: string; close: () => void }> = ({ link, close }) => {
  const { t } = useTranslation(['common', 'appointments']);
  const {
    rowClass,
    inputStyle,
    titleClass,
    iconContainerClass,
    iconClass,
    buttonStyle,
  } = useStyles();
  const [, copy] = useCopyToClipboard();

  return (
    <>
      <p className={titleClass}>
        {t('appointments:PARTICIPANT_MENU.COPY_LINK')}
      </p>
      <div className={rowClass}>
        <Input size={INPUT_SIZE.LARGE} style={inputStyle} value={link} />
        <div className={iconContainerClass}>
          <InviteIcon className={iconClass} />
        </div>
        <Button
          size={BUTTON_SIZE.SMALL}
          type="button"
          style={buttonStyle}
          onClick={() =>
            copy(link).then(() => {
              notifications.success(t('common:COPIED'));
              close();
            })
          }
        >
          {t('appointments:VIEW.BUTTONS.COPY_URL')}
        </Button>
      </div>
    </>
  );
};

export const useCopyInviteUrl = () => {
  const { open, close } = useModal();
  const { modalStyle, closeStyle } = useStyles();

  const copy = useCallback(
    (link: string) => {
      open(MODAL_TYPE.BASE, {
        children: <Content link={link} close={close} />,
        style: modalStyle,
        closeStyle,
      });
    },
    [open, modalStyle, closeStyle, close]
  );

  return copy;
};
