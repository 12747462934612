import { styled } from 'shared/ui';
import { theme } from '../theme';

export const Root = styled('div', {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: theme.colors.blue,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
});

export const Title = styled('h1', {
  fontSize: '32px',
  fontWeight: 700,
  lineHeight: 1.25,
  color: theme.colors.white,
  textAlign: 'center',
});

export const ReturnLink = styled('a', {
  padding: '8px',
  borderRadius: '8px',
  width: '100%',
  maxWidth: '224px',
  margin: '32px auto 0',
  textAlign: 'center',
  backgroundColor: theme.colors.gray,
  display: 'block',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 700,
  color: theme.colors.white,
  textDecoration: 'none',
});
