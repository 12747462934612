import { styled, useStyletron } from 'shared/ui';
import { theme } from 'pages/session-room/ui/theme';

export const Root = styled('label', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  position: 'relative',
  overflow: 'hidden',
  margin: '0 0 0 auto',
  zIndex: 1,
  cursor: 'pointer',
});

export const Switcher = styled('span', {
  display: 'block',
  position: 'relative',
  padding: '7px 15px',
});

export const ThemeInput = styled('input', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  opacity: 0,
});

export const useStyles = () => {
  const [css] = useStyletron();

  const getMoverStyles = (isDarkTheme: boolean) =>
    css({
      display: 'block',
      width: '32px',
      height: '16px',
      borderRadius: '16px',
      backgroundColor: isDarkTheme ? theme.colors.primary : '#e5e7eb',
    });

  const getCircleStyles = (isDarkTheme: boolean) =>
    css({
      display: 'block',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      position: 'absolute',
      left: '3px',
      top: '3px',
      backgroundColor: theme.colors.white,
      boxShadow: `0 0 0 3px ${
        isDarkTheme ? 'rgba(255, 255, 255, 0.3)' : 'rgba(55, 65, 81, 0.16)'
      }`,
      transition: 'transform 0.2s',
      transform: isDarkTheme ? 'translateX(30px)' : 'translateX(0)',

      '::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 'calc(50% - 4px)',
        left: 'calc(50% - 1px)',
        width: '2px',
        height: '8px',
        backgroundColor: isDarkTheme ? theme.colors.primary : theme.colors.blue,
      },
    });

  return {
    getMoverStyles,
    getCircleStyles,
  };
};
