import { TableBuilder } from 'baseui/table-semantic';
import { forwardRef, LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableProps } from './model';
import { useStyles } from './styles';
import { TableWrapper } from './wrapper';

import { TableSortIcon } from './sort-icon';
import { SORT_TYPE } from './sort-icon/model';
import { TableSearch } from './search';

export const Table = forwardRef(
  <RowT,>(
    {
      children,
      totalItems,
      onChangePage,
      currentPage,
      emptyMessage,
      search = false,
      onClickSort,
      onClickFilter,
      searchValue,
      onSearchChange,
      ...tableProps
    }: TableProps<RowT>,
    ref: LegacyRef<TableBuilder<RowT>>
  ) => {
    const { headStyles, headerCellSharedStyle, bodyCellStyles } = useStyles();
    const { t } = useTranslation();

    return (
      <>
        {search && (
          <TableSearch
            sortOrder={tableProps.sortOrder}
            onClickSort={onClickSort}
            onClickFilter={onClickFilter}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
          />
        )}
        <TableBuilder
          {...tableProps}
          ref={ref}
          emptyMessage={emptyMessage ?? t('TABLE.NO_DATA')}
          overrides={{
            Root: {
              component: TableWrapper,
              props: {
                totalItems,
                onChangePage,
                currentPage,
              },
            },
            TableHead: { style: headStyles },
            TableHeadCell: { style: { ...headerCellSharedStyle } },
            TableBodyCell: { style: bodyCellStyles },
            TableHeadCellSortable: { style: { ...headerCellSharedStyle } },
            SortAscIcon: {
              component: TableSortIcon,
              props: { type: SORT_TYPE.ASC },
            },
            SortDescIcon: {
              component: TableSortIcon,
              props: { type: SORT_TYPE.DESC },
            },
            SortNoneIcon: {
              component: TableSortIcon,
            },
          }}
        >
          {children}
        </TableBuilder>
      </>
    );
  }
);
