import { useStyletron, TEXT, DESKTOP, MOBILE, HIDE_SCROLLBAR } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { FormControlOverrides } from 'baseui/form-control';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  });

  const headerClass = css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  });

  const titleClass = css({
    ...TEXT.xl_medium,
  });

  const meetNowButtonStyle: StyleObject = {
    [DESKTOP]: {
      height: '28px',
      width: '100%',
      marginBottom: '16px',
    },
  };

  const getBodyClass = (width: string) =>
    css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: width,
      gap: '24px',

      [MOBILE]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      },
    });

  const dateContainerClass = css({
    padding: '24px',
    border: `1px solid ${theme.colors.gray200}`,
    borderRadius: '8px',

    [MOBILE]: {
      padding: '16px',
    },
  });

  const timeContainerClass = css({
    [DESKTOP]: {
      position: 'relative',

      '::after': {
        content: '""',
        position: 'absolute',
        left: '1px',
        right: '1px',
        bottom: '1px',
        height: '40px',
        borderRadius: '8px',
        background:
          'linear-gradient(180deg, rgba(249, 250, 251, 0) 0%, #FFFFFF 100%)',
        pointerEvents: 'none',
      },
    },
  });

  const timeColumnClass = css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [DESKTOP]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  });

  const getFormControlOverrides = (
    hasError: boolean
  ): FormControlOverrides => ({
    ControlContainer: {
      style: {
        marginBottom: 0,

        [DESKTOP]: {
          flexGrow: 1,
          maxHeight: '100%',
          padding: '24px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: hasError ? theme.colors.red500 : theme.colors.gray200,
          borderRadius: '8px',
          overflow: 'auto',
          transition: 'border-color 0.2s cubic-bezier(0, 0, 1, 1)',
          ...HIDE_SCROLLBAR,
        },
        [MOBILE]: {
          minHeight: '48px',
        },
      },
    },
    Caption: {
      style: () => ({
        position: 'absolute',
        top: '100%',
        left: 0,
        marginTop: '8px',
        marginBottom: 0,
        ...TEXT.sm_book,
        color: theme.colors.red500,
      }),
    },
  });

  const formControlStyle: StyleObject = {
    maxHeight: '100%',
    overflow: 'hidden',
  };

  const spinnerWrapperClass = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  });

  const timeRowClass = css({
    display: 'flex',
    gap: '16px',
  });

  return {
    rootClass,
    headerClass,
    titleClass,
    meetNowButtonStyle,
    getBodyClass,
    dateContainerClass,
    timeContainerClass,
    timeColumnClass,
    getFormControlOverrides,
    formControlStyle,
    spinnerWrapperClass,
    timeRowClass,
  };
};
