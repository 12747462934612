import { FC, useMemo } from 'react';
import {
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  Avatar,
  Desktop,
  Mobile,
} from 'shared/ui';
import { useModal, MODAL_TYPE } from 'shared/lib';

import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { User, Role } from 'shared/api';
import { useTranslation } from 'react-i18next';
import { SessionHostList } from './ui';
import { useStyles } from './styles';

type SessionHostProps = {
  hosts: User[];
  value?: string;
  onChange?: (id?: string) => void;
};

export const SessionHost: FC<SessionHostProps> = ({
  value,
  onChange,
  hosts = [],
}) => {
  const { t } = useTranslation(['common', 'appointments']);
  const {
    rowClass,
    nameClass,
    roleClass,
    buttonsClass,
    popoverOverrides,
    popoverHeaderClass,
    popoverTitleClass,
    changeButtonStyle,
  } = useStyles();

  const { open: openModal, close: closeModal } = useModal();

  const selectedUser = useMemo(
    () => hosts.find(({ id }) => id === value),
    [hosts, value]
  );

  return (
    <div className={rowClass}>
      <Avatar name={selectedUser?.name} src={selectedUser?.avatar_url} />
      <div>
        <p className={nameClass}>{selectedUser?.name}</p>
        <p className={roleClass}>
          {(selectedUser?.roles as Role[])?.map(({ name }) => name).join(', ')}
        </p>
      </div>
      {!!onChange && (
        <div className={buttonsClass}>
          <Desktop>
            <StatefulPopover
              popperOptions={{ placement: 'top-end' }}
              overrides={popoverOverrides}
              popoverMargin={8}
              ignoreBoundary
              content={({ close }) => (
                <>
                  <div className={popoverHeaderClass}>
                    <h5 className={popoverTitleClass}>
                      {t('appointments:CREATE.CHANGE_SESSION')}
                    </h5>
                  </div>
                  <SessionHostList
                    users={hosts}
                    selectedId={value}
                    setCurrentId={onChange}
                    setSelectedId={(id?: string) => {
                      onChange?.(id);
                      close();
                    }}
                  />
                </>
              )}
              accessibilityType="menu"
              triggerType={TRIGGER_TYPE.click}
            >
              <Button
                kind={BUTTON_KIND.SECONDARY}
                size={BUTTON_SIZE.SMALL}
                style={changeButtonStyle}
              >
                {t('appointments:CREATE.CHANGE_HOST')}
              </Button>
            </StatefulPopover>
          </Desktop>

          <Mobile>
            <Button
              kind={BUTTON_KIND.SECONDARY}
              size={BUTTON_SIZE.SMALL}
              style={changeButtonStyle}
              onClick={() => {
                openModal(MODAL_TYPE.BASE, {
                  title: t('appointments:CREATE.SESSION_HOST'),
                  children: (
                    <SessionHostList
                      users={hosts}
                      selectedId={value}
                      setCurrentId={(id) => {
                        onChange(id);
                        closeModal();
                      }}
                    />
                  ),
                });
              }}
            >
              {t('appointments:CREATE.CHANGE_HOST')}
            </Button>
          </Mobile>
        </div>
      )}
    </div>
  );
};
