import { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionRoomState } from 'pages/session-room/context';
import { Message } from '../message';
import { BodyScroller, BodyEmpty, useStyles } from './styles';

export const ChatBody: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { getBodyStyles } = useStyles();
  const isFirstRender = useRef(true);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const { chatMessages, chatOptions } = useSessionRoomState();
  const scrollToBottom = useCallback((isAnimate?: boolean) => {
    if (scrollerRef.current) {
      scrollerRef.current.scroll({
        top: scrollerRef.current.scrollHeight,
        behavior: isAnimate ? 'smooth' : undefined,
      });
    }
  }, []);

  useEffect(() => {
    scrollToBottom(!isFirstRender.current);
    isFirstRender.current = false;
  }, [chatMessages, scrollToBottom]);

  return (
    <main className={getBodyStyles(chatOptions.isDarkTheme ?? false)}>
      <BodyScroller ref={scrollerRef}>
        {chatMessages.length === 0 ? (
          <BodyEmpty>{t('session-room:CHAT.EMPTY_MESSAGES')}</BodyEmpty>
        ) : (
          chatMessages.map(({ id, avatarUrl, who, text }) => (
            <Message
              key={id}
              who={who}
              avatarUrl={avatarUrl}
              isDark={chatOptions.isDarkTheme}
            >
              {text}
            </Message>
          ))
        )}
      </BodyScroller>
    </main>
  );
};
