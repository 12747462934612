import { TEXT, TrustvideoTheme } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { INPUT_SIZE, INPUT_KIND } from './model';

export const INPUT_SIZE_STYLES = ({ size }: { size: INPUT_SIZE }) => {
  const map = new Map<INPUT_SIZE, StyleObject>([
    [
      INPUT_SIZE.SMALL,
      {
        height: '40px',
        paddingRight: '16px',
        paddingLeft: '16px',
        ...TEXT.sm_book,
      },
    ],
    [
      INPUT_SIZE.MEDIUM,
      {
        height: '48px',
        paddingRight: '16px',
        paddingLeft: '16px',
        ...TEXT.base_book,
      },
    ],
    [
      INPUT_SIZE.LARGE,
      {
        height: '56px',
        paddingRight: '16px',
        paddingLeft: '16px',
        ...TEXT.lg_book,
      },
    ],
    [
      INPUT_SIZE.XL,
      {
        height: '76px',
        paddingRight: '24px',
        paddingLeft: '24px',
        ...TEXT.xl_book,
      },
    ],
  ]);

  return map.get(size);
};

export const INPUT_KIND_STYLES = ({
  kind,
  theme,
}: {
  kind: INPUT_KIND;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<INPUT_KIND, StyleObject>([
    [
      INPUT_KIND.PRIMARY,
      {
        borderLeftColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderTopColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        backgroundColor: theme.colors.white900,

        ':hover': {
          borderTopColor: theme.colors.gray300,
          borderRightColor: theme.colors.gray300,
          borderBottomColor: theme.colors.gray300,
          borderLeftColor: theme.colors.gray300,
        },
      },
    ],
    [
      INPUT_KIND.SECONDARY,
      {
        borderLeftColor: theme.colors.gray100,
        borderRightColor: theme.colors.gray100,
        borderTopColor: theme.colors.gray100,
        borderBottomColor: theme.colors.gray100,
        backgroundColor: theme.colors.gray100,

        ':hover': {
          borderTopColor: theme.colors.gray300,
          borderRightColor: theme.colors.gray300,
          borderBottomColor: theme.colors.gray300,
          borderLeftColor: theme.colors.gray300,
        },
      },
    ],
  ]);

  return map.get(kind);
};
