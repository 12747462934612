import { StyleObject } from 'styletron-standard';
import {
  CheckIcon,
  CloseIcon,
  COLORS,
  PatientsIcon,
  styled,
  TEXT,
} from 'shared/ui';
import { InfoModalProps, INFO_MODAL_TYPE } from './model';

const flexColumnStyles = (gap: number): StyleObject => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${gap}px`,
});

export const Root = styled('div', flexColumnStyles(24));

export const InfoBlock = styled('div', flexColumnStyles(16));

export const Content = styled('div', {
  ...flexColumnStyles(8),

  textAlign: 'center',
});

export const Title = styled('h3', ({ $theme }) => ({
  ...TEXT.xl_medium,

  color: $theme.colors.gray700,
}));

export const Text = styled('p', ({ $theme }) => ({
  ...TEXT.base_book,

  color: $theme.colors.gray400,
}));

export const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  fontSize: '64px',
  width: '1em',
  height: '1em',
  borderRadius: '50%',
  backgroundColor: 'transparent',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,
  pointerEvents: 'none',

  '::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    backgroundColor: 'currentColor',
    opacity: 0.1,
  },
});

const iconStyles = {
  display: 'block',
  width: '0.5em',
  height: '0.5em',
  color: 'currentColor',
};

export const StyledCheckIcon = styled(CheckIcon, iconStyles);
export const StyledCloseIcon = styled(CloseIcon, iconStyles);
export const StyledUserIcon = styled(PatientsIcon, iconStyles);

export const iconWrapperStyles: (
  type: InfoModalProps['type']
) => StyleObject = (type) => {
  switch (type) {
    case INFO_MODAL_TYPE.ERROR:
    case INFO_MODAL_TYPE.USER_ERROR:
      return { color: COLORS.red500 };
    default:
      return { color: COLORS.green500 };
  }
};
