import { TEXT, useStyletron, MOBILE } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const listClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  });

  const itemClass = css({
    padding: '16px 24px',
    border: `1px solid ${theme.colors.gray200}`,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',

    [MOBILE]: {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  });

  const headerClass = css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  });

  const iconStyle = {
    width: '24px',
    color: theme.colors.gray400,
  };

  const titleStyle = {
    ...TEXT.base_medium,
  };

  const descriptionClass = css({
    marginTop: '4px',
    ...TEXT.sm_book,
  });

  const dateClass = css({
    marginTop: '8px',
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  return {
    listClass,
    itemClass,
    headerClass,
    iconStyle,
    titleStyle,
    descriptionClass,
    dateClass,
  };
};
