import { useCallback, useMemo, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import {
  Appointment,
  AppointmentStatusEnum,
  DocumentSubmissionStatusEnum,
  RequirementType,
} from 'shared/api';
import { handleStartSessionLink, useSharedUser } from 'shared/lib';
import { useMobileMediaQuery } from 'shared/ui';
import { useMedcartModal } from 'widgets/medcart-modal';

const openUrlFn: (url: string) => void = (url) => {
  window.open(url, url.startsWith('zoom') ? '_self' : '_blank');
};

export const useLaunchSession = ({
  url,
  appointment,
  appUrl,
}: {
  appointment?: Appointment;
  url?: string | null;
  appUrl?: string | null;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSharedUser();
  const canLaunch = useMemo(() => {
    if (!appointment?.participants?.[0]) {
      return false;
    }
    const hasUncompletedDocuments =
      !!appointment.participants?.[0].document_submissions?.find(
        (doc) =>
          doc?.document.requirement === RequirementType.Before &&
          doc?.status !== DocumentSubmissionStatusEnum.Submitted
      );

    const isBookedStatus = appointment.status === AppointmentStatusEnum.Booked;
    return !hasUncompletedDocuments && isBookedStatus;
  }, [appointment]);

  const isMobile = useMobileMediaQuery();
  const openUrl = useMemo(
    () => handleStartSessionLink(appUrl, isMobile) ?? url,
    [url, appUrl, isMobile]
  );
  const openMedcartModal = useMedcartModal({
    isMedcart: true,
    onJoin: () => {
      if (!openUrl) {
        return;
      }
      openUrlFn(openUrl);
    },
  });

  const launch = useCallback(() => {
    let timeout = 0;
    if (isMacOs || isMobile || !user?.has_medcart) {
      if (!openUrl) {
        return;
      }
      openUrlFn(openUrl);
      return;
    }
    setIsLoading(true);
    const listener = () => {
      window.clearTimeout(timeout);
    };
    window.addEventListener('blur', listener);
    timeout = window.setTimeout(() => {
      openMedcartModal();
    }, 10000);

    window.setTimeout(() => {
      window.removeEventListener('blur', listener);
      setIsLoading(false);
    }, 10100);

    window.location.href = `medcart://authenticate?sessionid=${appointment?.id}`;
  }, [isMobile, user, appointment, openUrl, openMedcartModal]);

  return { canLaunch, launch, isLoading };
};
