/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloLink,
  Observable,
  Observer,
  Operation,
  NextLink,
  FetchResult,
} from '@apollo/client/core';
import Pusher from 'pusher-js';
import { getIdToken } from 'shared/lib';

type RequestResult = FetchResult<
  { [key: string]: any },
  Record<string, any>,
  Record<string, any>
>;

type Subscription = {
  closed: boolean;
  unsubscribe(): void;
};

// Turn `subscribe` arguments into an observer-like thing, see getObserver
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L347-L361
function getObserver<T>(
  // eslint-disable-next-line @typescript-eslint/ban-types
  observerOrNext: Function | Observer<T>,
  onError?: (e: Error) => void,
  onComplete?: () => void
) {
  if (typeof observerOrNext === 'function') {
    // Duck-type an observer
    return {
      next: (v: T) => observerOrNext(v),
      error: (e: Error) => onError?.(e),
      complete: () => onComplete?.(),
    };
  }
  // Make an object that calls to the given object, with safety checks
  return {
    next: (v: T) => observerOrNext?.next?.(v),
    error: (e: Error) => observerOrNext.error?.(e),
    complete: () => observerOrNext.complete?.(),
  };
}

export class PusherLink extends ApolloLink {
  private _pusher?: Pusher;

  decompress: (result: string) => any;

  constructor() {
    super();
    this.decompress = () => {
      throw new Error(
        "Received compressed_result but PusherLink wasn't configured with `decompress: (result: string) => any`. Add this configuration."
      );
    };
  }

  private _createConnection(): void {
    const token = getIdToken();
    const subdomain =
      process.env.NODE_ENV === 'production'
        ? window.location.hostname.split('.').slice(0, -2).join('.')
        : process.env.REACT_APP_SUBDOMAIN ??
          window.location.hostname.split('.').slice(0, -2).join('.') ??
          'test';

    console.log('creating pusher link - subdomain:', subdomain);

    this._pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY ?? '', {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          authorization: token ? `Bearer ${token}` : null,
          'X-SUBDOMAIN': subdomain,
        },
      },
    });
  }

  request(operation: Operation, forward: NextLink): Observable<RequestResult> {
    const subscribeObservable = new Observable<RequestResult>(
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
    // Capture the super method
    const prevSubscribe =
      subscribeObservable.subscribe.bind(subscribeObservable);
    // Override subscribe to return an `unsubscribe` object, see
    // https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L182-L212
    subscribeObservable.subscribe = (
      observerOrNext:
        | Observer<RequestResult>
        | ((value: RequestResult) => void),
      onError?: (error: any) => void,
      onComplete?: () => void
    ): Subscription => {
      // Call super
      if (typeof observerOrNext === 'function') {
        prevSubscribe(observerOrNext, onError, onComplete);
      } else {
        prevSubscribe(observerOrNext);
      }
      const observer = getObserver(observerOrNext, onError, onComplete);
      let subscriptionChannel: string;
      // Check the result of the operation
      const resultObservable = forward(operation);
      // When the operation is done, try to get the subscription ID from the server
      resultObservable.subscribe({
        next: (data: any) => {
          // If the operation has the subscription channel, it's a subscription
          subscriptionChannel =
            data?.extensions?.lighthouse_subscriptions.channel ?? null;
          if (subscriptionChannel) {
            if (!this._pusher) {
              this._createConnection();
            }
            // Set up the pusher subscription for updates from the server
            const pusherChannel = this._pusher?.subscribe(subscriptionChannel);
            // Subscribe for more update
            pusherChannel?.bind('lighthouse-subscription', (payload: any) => {
              this._onUpdate(subscriptionChannel, observer, payload);
            });
          } else {
            // This isn't a subscription,
            // So pass the data along and close the observer.
            observer.next(data);
            observer.complete();
          }
        },
        error: observer.error,
        // complete: observer.complete Don't pass this because Apollo unsubscribes if you do
      });

      // Return an object that will unsubscribe _if_ the query was a subscription.
      return {
        closed: false,
        unsubscribe: () => {
          if (subscriptionChannel)
            this._pusher?.unsubscribe(subscriptionChannel);
        },
      };
    };
    return subscribeObservable;
  }

  private _onUpdate(
    subscriptionChannel: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    observer: { next: Function; complete: Function },
    payload: { more: boolean; compressed_result?: string; result?: object }
  ): void {
    let result: any;
    if (payload.compressed_result) {
      result = this.decompress(payload.compressed_result);
    } else {
      result = payload.result;
    }
    if (result) {
      // Send the new response to listeners
      observer.next(result);
    }
    if (!payload.more) {
      // This is the end, the server says to unsubscribe
      this._pusher?.unsubscribe(subscriptionChannel);
      observer.complete();
    }
  }
}
