import { styled, TEXT } from 'shared/ui';

export const MessageContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 16px',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: $theme.colors.gray200,
}));

export const MessageTitle = styled('span', ({ $theme }) => ({
  ...TEXT.base_book,
  color: $theme.colors.gray400,
}));

export const MessageText = styled('div', {
  ...TEXT.base_book,
  overflowWrap: 'anywhere',
});
