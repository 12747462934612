import { FC, useCallback, useState } from 'react';
import {
  isGraphQLError,
  useDeleteNoteMutation,
  useNotesExportLazyQuery,
} from 'shared/api';
import { getTabUUID } from 'shared/lib';
import { Folder, notifications } from 'shared/ui';
import { NoteBlockInterface } from './model';
import { Item } from './styles';
import { NoteBlockHeader, NoteBlockContent } from './ui';

export const NoteBlock: FC<NoteBlockInterface> = ({
  note,
  isOpen = false,
  onEditClick,
  onDeleteComplete,
}) => {
  const [isShowContent, setIsShowContent] = useState(isOpen);
  const handleToggleView = useCallback(
    () => setIsShowContent(!isShowContent),
    [isShowContent]
  );
  const [deleteNoteMutation] = useDeleteNoteMutation({
    variables: {
      id: note.id,
    },
    onCompleted: () => onDeleteComplete?.(),
  });

  // Download specific note
  const [notesExport] = useNotesExportLazyQuery({
    fetchPolicy: 'network-only',
    onError: (data) => {
      if (isGraphQLError(data.graphQLErrors)) {
        notifications.error({
          title: data.message,
        });
      }
    },
  });
  const handleNoteExport = useCallback(() => {
    notesExport({
      variables: {
        input: {
          note_id: note?.id,
          extension: 'csv',
        },
        browser_identifier: getTabUUID(),
      },
    });
  }, [notesExport, note?.id]);

  const handleEdit = useCallback(() => onEditClick?.(), [onEditClick]);
  const handleNoteRemove = useCallback(() => {
    deleteNoteMutation();
  }, [deleteNoteMutation]);

  return (
    <li>
      {!isShowContent ? (
        <Item>
          <NoteBlockHeader
            note={note}
            isOpen={isShowContent}
            onToggle={handleToggleView}
            onEdit={handleEdit}
            onRemove={handleNoteRemove}
            onNoteExport={handleNoteExport}
          />
        </Item>
      ) : (
        <Folder
          header={
            <NoteBlockHeader
              note={note}
              isOpen={isShowContent}
              onToggle={handleToggleView}
              onEdit={handleEdit}
              onRemove={handleNoteRemove}
              onNoteExport={handleNoteExport}
            />
          }
          content={
            <NoteBlockContent
              note={note}
              onToggle={handleToggleView}
              onEdit={handleEdit}
              onRemove={handleNoteRemove}
              onNoteExport={handleNoteExport}
            />
          }
        />
      )}
    </li>
  );
};
