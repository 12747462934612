import { useRsvp } from 'features/appointment';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { notifications, RsvpNoIcon, RsvpYesIcon } from 'shared/ui';
import { RsvpTypesEnum, useParticipantRsvpMutation } from 'shared/api';
import { useStyles } from './styles';

export const RSVPStatus: FC<{
  appointmentId: string;
  rsvp?: RsvpTypesEnum | null;
  participantId?: string;
}> = ({ appointmentId, rsvp, participantId = '' }) => {
  const { rootClass, buttonsWrapperClass, baseButtonClass, baseIconClass } =
    useStyles();
  const { t } = useTranslation(['participant-dashboard']);
  const { setNoStatus } = useRsvp();
  const [acceptRsvp, { loading }] = useParticipantRsvpMutation({
    variables: {
      appointment_id: appointmentId,
      rsvp: RsvpTypesEnum.RsvpStatusAccepted,
    },
    onError: (error) => {
      notifications.error({ title: error.message });
    },
  });
  const handleYesClick = useCallback(() => {
    if (loading || rsvp === RsvpTypesEnum.RsvpStatusAccepted) {
      return;
    }

    acceptRsvp({
      optimisticResponse: {
        participantRsvp: {
          __typename: 'Participant',
          id: participantId,
          rsvp: RsvpTypesEnum.RsvpStatusAccepted,
        },
      },
    });
  }, [acceptRsvp, loading, participantId, rsvp]);
  const handleNoClick = useCallback(() => {
    if (loading) {
      return;
    }

    setNoStatus({ appointmentId });
  }, [appointmentId, loading, setNoStatus]);

  return (
    <div className={rootClass}>
      <span>{t('participant-dashboard:APPOINTMENTS_CARD.RSVP.WILL_MAKE')}</span>
      <div className={buttonsWrapperClass}>
        <button
          className={baseButtonClass(
            loading,
            rsvp === RsvpTypesEnum.RsvpStatusAccepted,
            false
          )}
          type="button"
          onClick={handleYesClick}
        >
          <RsvpYesIcon
            className={baseIconClass(rsvp === RsvpTypesEnum.RsvpStatusAccepted)}
          />
          <span>{t('participant-dashboard:APPOINTMENTS_CARD.RSVP.YES')}</span>
        </button>
        <button
          className={baseButtonClass(
            false,
            false,
            rsvp === RsvpTypesEnum.RsvpStatusDeclined
          )}
          type="button"
          onClick={handleNoClick}
        >
          <RsvpNoIcon
            className={baseIconClass(rsvp === RsvpTypesEnum.RsvpStatusDeclined)}
          />
          <span>{t('participant-dashboard:APPOINTMENTS_CARD.RSVP.NO')}</span>
        </button>
      </div>
    </div>
  );
};
