import { useCancelAppointmentMutation } from 'shared/api';

export const useCancelAppointment = (id: string) => {
  const [cancelAppointment, { loading }] = useCancelAppointmentMutation({
    variables: { id },
    optimisticResponse: {
      cancelAppointment: {
        __typename: 'Appointment',
        id,
      },
    },
    update: (cache, { data }) => {
      if (data?.cancelAppointment) {
        const normalizedId = cache.identify({
          id: data.cancelAppointment.id,
          __typename: 'Appointment',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  return { cancelAppointment, loading };
};
