import { styled } from 'shared/ui';
import { SESSION_ROOM_COLORS } from '../../../theme/colors';

export const Header = styled('header', {
  padding: '16px 32px',
  backgroundColor: 'rgba(17, 17, 17, 0.3)',
  display: 'flex',
  alignItems: 'center',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
});

export const Title = styled('h3', {
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '32px',
  color: SESSION_ROOM_COLORS.white,
});
