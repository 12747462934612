import { ButtonProps as BaseButtonProps } from 'baseui/button';
import { StyleObject } from 'styletron-standard';

export enum ICON_BUTTON_KIND {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export type IconButtonProps = Pick<
  BaseButtonProps,
  'onClick' | 'isLoading' | 'type' | 'disabled'
> & {
  kind?: ICON_BUTTON_KIND;
  isDeleteButton?: boolean;
  style?: StyleObject;
};
