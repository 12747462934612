import { DESKTOP, styled, useStyletron } from 'shared/ui';
import { theme } from '../theme';

export const ActionsContainer = styled('div', {
  display: 'flex',
  gap: '24px',

  [DESKTOP]: {
    gap: '56px',
    flex: 1,
  },
});

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 16px 16px',

    [DESKTOP]: {
      padding: '16px 60px 8px',
      backgroundColor: theme.colors.transparentBlue,
      borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    },
  });

  return {
    rootClass,
  };
};
