import { useStyletron, TEXT, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [MOBILE]: {
      gap: '24px',
    },
  });

  const searchIconClass = css({
    color: theme.colors.gray400,
  });

  const listClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [MOBILE]: {
      gap: '16px',
    },
  });

  const itemClass = css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  });

  const buttonStyle: StyleObject = {
    position: 'relative',
    flexGrow: 1,
    justifyContent: 'flex-start',
    ...TEXT.base_book,

    ':hover': {
      borderLeftColor: theme.colors.gray300,
      borderRightColor: theme.colors.gray300,
      borderTopColor: theme.colors.gray300,
      borderBottomColor: theme.colors.gray300,
      color: 'inherit',
    },
  };

  const colorLineStyle: StyleObject = {
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    bottom: '-1px',
    width: '4px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  };

  const timeClass = css({
    marginLeft: 'auto',
    color: theme.colors.gray400,
  });

  const checkIconClass = css({
    flexShrink: 0,
    width: '24px',
    color: theme.colors.accent500,
  });

  return {
    rootClass,
    searchIconClass,
    listClass,
    itemClass,
    buttonStyle,
    colorLineStyle,
    timeClass,
    checkIconClass,
  };
};
