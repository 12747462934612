import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, useStyletron } from 'shared/ui';
import { Display2, Display4 } from 'baseui/typography';
import { Card } from 'baseui/card';
import { Spinner } from 'baseui/spinner';
import usePusher from '../../hooks/use-pusher';
import { ConnectionStatus } from '../connection-status';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';
import { ConnectionStatusEnum } from '../connection-status/model';
import {
  BiometricsBlock,
  Header,
  HeaderLeft,
  HeaderRight,
  HeaderIcon,
  HeaderTitle,
  Body,
  SensorBlocks,
} from './styles';
import {
  SingleMeasurement
} from './ui';
import { SingleMeasurementType } from './model';

export const BiometricsWrapper: FC = () => {
  const [css, theme] = useStyletron();
  const { t } = useTranslation(['session-room']);
  const { appointmentId, biometricsData } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatusEnum>(Array.isArray(biometricsData?.measurement) && biometricsData.measurement.length > 0 ? ConnectionStatusEnum.CONNECTED : ConnectionStatusEnum.DISCONNECTED);

  type PusherData = {
    measurement: SingleMeasurementType[];
  };

  const pusherData = usePusher(appointmentId as string, appointmentId as string) as PusherData | null;

  useEffect(() => {
    if (pusherData && pusherData.measurement) {

      // console.info('pusherData received:', pusherData);
      const formattedMeasurement = Object.entries(pusherData.measurement)
        .filter(([_, value]) => typeof value === 'number')
        .map(([key, value]) => ({
          Key: key,
          Value: value as unknown as number,
        }));

      dispatch({
        type: 'SET_BIOMETRICS_DATA',
        payload: {
          status: 'connected',
          measurement: formattedMeasurement,
        },
      });

      setConnectionStatus(ConnectionStatusEnum.CONNECTED);
    }
  }, [pusherData]);

  const handleClose = useCallback(() => {
    dispatch({
      type: 'TOGGLE_SHOW_BIOMETRICS',
      payload: {
        isShow: false,
      },
    });
  }, [dispatch]);

  return (
    <BiometricsBlock>
      <Header>
        <HeaderLeft>
          <HeaderIcon />
          <HeaderTitle>{t('session-room:BIOMETRICS')}</HeaderTitle>
        </HeaderLeft>

        <HeaderRight>
          <ConnectionStatus status={connectionStatus} />
          <CloseButton onClick={handleClose} />
        </HeaderRight>
      </Header>

      <Body>

        {/* {pusherData && <div>New Data from Pusher: {JSON.stringify(pusherData)}</div>} */}
        {/* {biometricsData && <div>New Biometrics Data: {JSON.stringify(biometricsData)}</div>} */}

        {connectionStatus === ConnectionStatusEnum.PENDING &&
          <Card overrides={{
            Root: {
              style: {
                width: '90%',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: theme.borders.radius300,
                backgroundColor: '#1d202b',
                borderStyle: 'none',
                padding: '0',
                margin: '32px',
              },
            },
          }}>
            <Display4 color={theme.colors.accent500}>
              Retrieving results...
            </Display4>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.sizing.scale800,
              })}
            >
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Spinner
                color={theme.colors.accent}
                size={theme.sizing.scale1200}
              />
            </div>
          </Card>
        }

        {connectionStatus === ConnectionStatusEnum.DISCONNECTED &&
          <Card overrides={{
            Root: {
              style: {
                width: '90%',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: theme.borders.radius300,
                backgroundColor: '#1d202b',
                borderStyle: 'none',
                padding: '32px',
                margin: '32px',
                textAlign: 'center',
              },
            },
          }}>
            <Display4 color={theme.colors.accent500}>
              Please complete digital biometrics assesment and return here when done.
              <br /><br />
            </Display4>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.sizing.scale800,
              })}
            >
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Display2 color={theme.colors.primary}>
                <a href={`https://codeaffex.ltidev.net?id=${appointmentId}`} target="_blank" rel="noopener noreferrer">
                  Digital Biometrics Assessment
                </a>
              </Display2>
            </div>
          </Card>
        }

        {connectionStatus === ConnectionStatusEnum.CONNECTED &&
          <SensorBlocks>
            <SingleMeasurement />
          </SensorBlocks>
        }
      </Body>
    </BiometricsBlock>
  );
};
