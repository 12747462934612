import { useEffect, useMemo } from 'react';
import { useBookingLocationMessageLazyQuery } from 'shared/api';
import { useCurrentOrganization } from 'entities/organization';

export const useBookingInfo = (location?: string | null) => {
  const [getMessage, { loading, data }] = useBookingLocationMessageLazyQuery();
  const { organization } = useCurrentOrganization();

  useEffect(() => {
    if (location) {
      getMessage({ variables: { location } });
    }
  }, [getMessage, location]);

  const info = useMemo(
    () => data?.organization?.booking_message ?? organization.support_info,
    [data, organization.support_info]
  );

  return { isLoading: loading, info };
};
