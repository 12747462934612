import { styled, useStyletron } from 'shared/ui';
import { theme } from 'pages/session-room/ui/theme';
import { StyleObject } from 'styletron-standard';

export const BodyScroller = styled('div', {
  // Height 80% of viewport minus header and footer
  height: 'calc(80vh - 144px)',
  maxHeight: '494px',
  padding: '0 24px 24px',
  overflow: 'auto',
});

export const BodyEmpty = styled('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '24px',
  lineHeight: 1.5,
  color: theme.colors.gray,
  opacity: 0.5,
  pointerEvents: 'none',
  userSelect: 'none',
});

export const useStyles = () => {
  const [css] = useStyletron();

  const pseudoStyles = (isTop: boolean): StyleObject => ({
    content: '""',
    display: 'block',
    position: 'absolute',
    right: 0,
    left: 0,
    height: '24px',
    pointerEvents: 'none',
    zIndex: 1,
    ...(isTop
      ? {
          top: 0,
          backgroundImage:
            'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
        }
      : {
          bottom: 0,
          backgroundImage:
            'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
        }),
  });

  const bodyDarkStyles = {
    backgroundColor: theme.colors.blue,
    borderRight: `1px solid rgba(255, 255, 255, 0.1)`,
    borderLeft: `1px solid rgba(255, 255, 255, 0.1)`,
    colorScheme: 'dark',

    '::before': {
      ...pseudoStyles(true),
      backgroundImage:
        'linear-gradient(0deg, rgba(35, 39, 54, 0) 0%, rgba(35, 39, 54, 1) 100%)',
    },

    '::after': {
      ...pseudoStyles(false),
      backgroundImage:
        'linear-gradient(180deg, rgba(35, 39, 54, 0) 0%, rgba(35, 39, 54, 1) 100%)',
    },
  };

  const getBodyStyles = (isDarkTheme: boolean) =>
    css({
      position: 'relative',
      backgroundColor: theme.colors.white,
      colorScheme: 'light',

      '::before': pseudoStyles(true),
      '::after': pseudoStyles(false),

      ...(isDarkTheme && bodyDarkStyles),
    });

  return {
    getBodyStyles,
  };
};
