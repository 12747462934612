import { FC, Children } from 'react';
import { Tabs as UiTabs, TabsProps, TabOverrides } from 'baseui/tabs-motion';
import { useStyletron, TEXT, TrustvideoTheme, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { mergeDeepRight } from 'ramda';

export const Tabs: FC<
  TabsProps & {
    wideTabs?: boolean;
    listStyles?: StyleObject;
    rootStyles?: StyleObject;
  }
> = ({ wideTabs, listStyles, rootStyles, ...props }) => {
  const [, theme] = useStyletron();
  const childrenCount = Children.count(props.children);

  return (
    <UiTabs
      {...props}
      overrides={{
        TabBorder: {
          style: {
            display: 'none',
          },
        },
        TabHighlight: {
          style: {
            display: 'none',
          },
        },
        TabList: {
          style: {
            display: wideTabs ? 'grid' : 'flex',
            gridTemplateColumns: `repeat(${childrenCount}, 1fr)`,
            gap: '8px',
            marginBottom: 0,
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderTopWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '1px',
            borderBottomWidth: '1px',
            borderTopStyle: 'solid',
            borderRightStyle: 'solid',
            borderBottomStyle: 'solid',
            borderLeftStyle: 'solid',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            borderLeftColor: theme.colors.gray200,
            borderRightColor: theme.colors.gray200,
            borderTopColor: theme.colors.gray200,
            borderBottomColor: theme.colors.gray200,
            ...listStyles,
          },
        },
        Root: {
          style: {
            ...rootStyles,
          },
        },
      }}
    />
  );
};

export const TabOverrideObject: TabOverrides = {
  Tab: {
    style: ({ $isActive, $theme }) => ({
      flexShrink: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '8px',
      paddingRight: '8px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
      ...TEXT.base_book,
      color: $isActive
        ? ($theme as TrustvideoTheme).colors.accent900
        : 'inherit',
      background: $isActive ? $theme.colors.accent50 : 'inherit',
      ':hover': {
        background: $isActive
          ? $theme.colors.accent50
          : ($theme as TrustvideoTheme).colors.gray100,
      },
    }),
  },
  TabPanel: {
    style: {
      paddingTop: '16px',
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
};

export const AccountTabOverride = mergeDeepRight(TabOverrideObject, {
  TabPanel: {
    style: {
      paddingTop: '40px',

      [MOBILE]: {
        paddingTop: '24px',
      },
    },
  },
}) as TabOverrides;
