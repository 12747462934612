import { StyleObject } from 'styletron-standard';
import { DESKTOP, MOBILE, useStyletron } from '../theme';

export const useStepperStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    border: `1px solid ${theme.colors.gray200}`,
    borderRadius: '8px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    display: 'inline-flex',

    [DESKTOP]: {
      display: 'block',
      width: '100%',
    },
  });

  const listStyles: StyleObject = {
    display: 'grid',
    width: '100%',
    height: '74px',
    backgroundColor: theme.colors.white,
    borderRadius: '8px',

    [DESKTOP]: {
      height: '72px',
    },
  };

  const listItemStyleClass = css({
    [MOBILE]: {
      minWidth: 'calc(100vw - 90px)',
    },
  });

  return { rootClass, listStyles, listItemStyleClass };
};
