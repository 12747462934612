import { StyledSpinnerNext } from 'baseui/spinner';
import { styled, withStyle } from 'shared/ui';
import { theme } from '../theme';

export const Root = styled('div', {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.black,
});

export const Spinner = withStyle(StyledSpinnerNext, {
  borderTopColor: theme.colors.blue,
});
