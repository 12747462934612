import { FC, useCallback } from 'react';
import {
  DownloadIcon,
  BrowserIcon,
  ZoomIcon,
  Button,
  BUTTON_SIZE,
  BUTTON_KIND,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPE, useModal } from 'shared/lib';
import { useCurrentOrganization } from 'entities/organization';
import { useStyles } from './styles';

const Content: FC<{
  isMedcart: boolean;
  onJoin: () => void;
}> = ({ isMedcart, onJoin }) => {
  const { t } = useTranslation(['medcart']);
  const {
    titleClass,
    gridClass,
    itemClass,
    iconClass,
    zoomIconClass,
    subtitleClass,
    captionClass,
    buttonStyle,
    footerClass,
    footerMediumTextClass,
    footerNormalTextClass,
  } = useStyles();

  const { organization } = useCurrentOrganization();

  return (
    <>
      <h2 className={titleClass}>{t('medcart:TITLE')}</h2>

      <div className={gridClass}>
        {isMedcart ? (
          <div className={itemClass}>
            <DownloadIcon className={iconClass} />
            <h3 className={subtitleClass}>{t('medcart:DOWNLOAD_MEDCART')}</h3>
            <p className={captionClass}>{t('medcart:INSTALL_MEDCART')}</p>
            <Button
              type="button"
              size={BUTTON_SIZE.SMALL}
              kind={BUTTON_KIND.SECONDARY}
              style={buttonStyle}
              onClick={() => {
                window.open('mailto:matt@letstalkinteractive.com', '_blank');
              }}
            >
              {t('medcart:DOWNLOAD_MEDCART')}
            </Button>
          </div>
        ) : (
          <div className={itemClass}>
            <ZoomIcon className={zoomIconClass} />
            <h3 className={subtitleClass}>{t('medcart:DOWNLOAD_ZOOM')}</h3>
            <p className={captionClass}>{t('medcart:INSTALL_ZOOM')}</p>
            <Button
              type="button"
              size={BUTTON_SIZE.SMALL}
              kind={BUTTON_KIND.SECONDARY}
              style={buttonStyle}
              onClick={() => {
                window.open('https://zoom.us/support/download', '_blank');
              }}
            >
              {t('medcart:DOWNLOAD_ZOOM')}
            </Button>
          </div>
        )}

        <div className={itemClass}>
          <BrowserIcon className={iconClass} />
          <h3 className={subtitleClass}>{t('medcart:JOIN_IN_BROWSER')}</h3>
          <p className={captionClass}>{t('medcart:JOIN_SESSION')}</p>
          <Button
            type="button"
            size={BUTTON_SIZE.SMALL}
            kind={BUTTON_KIND.SECONDARY}
            style={buttonStyle}
            onClick={onJoin}
          >
            {t('medcart:JOIN_FROM_BROWSER')}
          </Button>
        </div>
      </div>

      {organization.support_phone_number && (
        <div className={footerClass}>
          <p className={footerMediumTextClass}>
            {t('medcart:NEED_ASSISTANCE')}
          </p>
          <p className={footerNormalTextClass}>
            {t('medcart:CALL_SUPPORT', {
              phone: organization.support_phone_number,
            })}
          </p>
        </div>
      )}
    </>
  );
};

export const useMedcartModal = ({
  isMedcart,
  onJoin,
}: {
  isMedcart: boolean;
  onJoin: () => void;
}) => {
  const { open } = useModal();
  const { modalStyle, closeStyle } = useStyles();

  return useCallback(() => {
    open(MODAL_TYPE.BASE, {
      children: <Content isMedcart={isMedcart} onJoin={onJoin} />,
      style: modalStyle,
      closeStyle,
    });
  }, [open, modalStyle, closeStyle, isMedcart, onJoin]);
};
