import { StyleObject } from 'styletron-standard';

import { InMeetingIcon, styled, TEXT, COLORS } from 'shared/ui';
import { PARTICIPANT_MEETING_STATUS } from './model';

export const Root = styled('div', {
  padding: '3px 15px',
  borderWidth: '1px',
  borderStyle: 'solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  borderRadius: '8px',
  minHeight: '32px',
});

export const Text = styled('div', ({ $theme }) => ({
  ...TEXT.sm_book,

  color: $theme.colors.gray700,
}));

const iconStyles = {
  fontSize: '24px',
  display: 'block',
  width: '1em',
  height: '1em',
};

export const StyledMeetingIcon = styled(InMeetingIcon, ({ $theme }) => ({
  ...iconStyles,

  color: $theme.colors.green500,
}));

export const getRootStyles: (
  status?: PARTICIPANT_MEETING_STATUS,
  isInline?: boolean
) => StyleObject | undefined = (status, isInline = false) => {
  const baseStyles = isInline ? { display: 'inline-flex' } : {};

  switch (status) {
    case PARTICIPANT_MEETING_STATUS.VIEWING:
      return {
        ...baseStyles,

        backgroundColor: COLORS.yellow50,
        borderColor: COLORS.yellow200,
      };

    case PARTICIPANT_MEETING_STATUS.IN_MEETING:
      return {
        ...baseStyles,

        backgroundColor: COLORS.green50,
        borderColor: COLORS.green200,
      };

    default:
      return undefined;
  }
};
