import { useStyletron } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  });

  const iconClass = css({
    width: '20px',
    color: theme.colors.gray400,
  });

  return {
    rootClass,
    iconClass,
  };
};
