import { useState, useEffect, useCallback } from 'react';
import { Publisher, Stream } from '@opentok/client';
import { useSessionRoomState } from '../context';

export const useHasTrack = () => {
  const { session, publisher } = useSessionRoomState();
  const publisherContext = publisher?.context as Publisher;
  const publisherStream = publisherContext.stream as Stream;
  const [hasAudio, setHasAudio] = useState(publisherStream?.hasAudio ?? false);
  const [hasVideo, setHasVideo] = useState(publisherStream?.hasVideo ?? false);
  const toggleAudio = useCallback(() => {
    publisherContext.publishAudio(!hasAudio);
  }, [hasAudio, publisherContext]);
  const toggleVideo = useCallback(() => {
    publisherContext.publishVideo(!hasVideo);
  }, [hasVideo, publisherContext]);
  const catchPropertyChange = useCallback(
    (event) => {
      if (publisherStream?.streamId === event.stream.streamId) {
        switch (event.changedProperty) {
          case 'hasAudio':
            setHasAudio(event.newValue);
            break;

          case 'hasVideo':
            setHasVideo(event.newValue);
            break;

          default:
            break;
        }
      }
    },
    [publisherStream.streamId]
  );

  useEffect(() => {
    session?.on('streamPropertyChanged', catchPropertyChange);

    return () => session?.off('streamPropertyChanged', catchPropertyChange);
  }, [catchPropertyChange, session]);

  return {
    hasAudio,
    hasVideo,
    toggleAudio,
    toggleVideo,
  };
};
