/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */

import { useEffect } from 'react';
import TherapistListing from './ui/therapist-listing/TherapistListing';
import { useTherapistFilterLogic } from './hooks/use-therapist-filter-logic';
// import { useGetTherapists } from './hooks/use-get-therapists';
// import { useTherapistFilter } from './hooks/use-therapist-filter-logic';

const TherapistPage = () => {
  // const { filters, handleFilterChange } = useTherapistFilter();
  // const {
  //   data: therapists,
  //   error,
  //   loading,
  //   getTherapists,
  // } = useGetTherapists(filters);

  // useEffect(() => {
  //   getTherapists();
  // }, [filters, getTherapists]);

  // const { handleSubmit } = useTherapistFilterLogic();

  // useEffect(() => handleSubmit(), []);

  return (
    <div>
      <TherapistListing />
    </div>
  );
};

export default TherapistPage;
