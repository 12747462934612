import { ReactElement, useEffect, useMemo, useRef } from 'react';

export function Suspender(): ReactElement {
  const resolve = useRef<() => void>();
  const promise = useMemo(
    () =>
      new Promise<void>((res) => {
        resolve.current = res;
      }),
    []
  );

  useEffect(() => () => {
    resolve.current?.();
  });

  throw promise;
}
