import { FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Preloader, SessionEnded, SessionView } from '../index';
import { useStyles } from './styles';
import { useSessionRoomState } from '../../context';
import { useLoadSessionData } from '../../hooks/use-load-session-data';

export const SessionRoomWrapper: FC = () => {
  const { t } = useTranslation(['session-room']);
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('id') ?? '0';
  const loadSessionData = useLoadSessionData();
  const {
    isShowVitals,
    dataLoadingState,
    error: stateError,
  } = useSessionRoomState();
  const content = useMemo(() => {
    switch (dataLoadingState) {
      case 'error':
        return <SessionEnded>{stateError}</SessionEnded>;

      case 'disconnected':
        return (
          <SessionEnded isShowBackButton>{t('session-room:END')}</SessionEnded>
        );

      case 'ready':
        return <SessionView />;

      default:
        return <Preloader />;
    }
  }, [dataLoadingState, stateError, t]);
  const { getRootClass } = useStyles();

  useEffect(() => {
    loadSessionData(appointmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <main className={getRootClass(isShowVitals)}>{content}</main>;
};
