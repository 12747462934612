import { TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { RADIO_SIZE } from './model';

export const RADIO_SIZE_STYLES = new Map<RADIO_SIZE, StyleObject>([
  [
    RADIO_SIZE.SMALL,
    {
      ...TEXT.sm_book,
    },
  ],
  [
    RADIO_SIZE.MEDIUM,
    {
      ...TEXT.base_book,
    },
  ],
]);
