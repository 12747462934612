import { FC } from 'react';
import { useStyletron, TEXT } from 'shared/ui';
import {
  FormControl as UiFormControl,
  FormControlProps,
} from 'baseui/form-control';
import { StyleObject } from 'styletron-standard';

export const FormControl: FC<
  FormControlProps & {
    required?: boolean;
    style?: StyleObject;
  }
> = ({ label, required, error, style = {}, ...props }) => {
  const [css, theme] = useStyletron();

  const computedLabel = required ? (
    <>
      {label} <span className={css({ color: theme.colors.red500 })}>*</span>
    </>
  ) : (
    label
  );

  return (
    <div className={css(style)}>
      <UiFormControl
        overrides={{
          ControlContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          Caption: {
            style: {
              marginTop: '8px',
              marginBottom: 0,
              ...TEXT.sm_book,
              color: error ? theme.colors.red500 : theme.colors.gray400,
            },
          },
          Label: {
            style: {
              marginTop: '-8px',
              marginBottom: 0,
              ...TEXT.base_medium,
            },
          },
        }}
        label={computedLabel}
        error={error || undefined}
        {...props}
      />
    </div>
  );
};
