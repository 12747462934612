import { useStyletron, TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const modalStyle: StyleObject = {
    maxWidth: '647px',
    padding: '24px',
  };

  const closeStyle: StyleObject = {
    top: '24px',
  };

  const titleClass = css({
    marginBottom: '16px',
    ...TEXT.xl_medium,
  });

  const rowClass = css({
    position: 'relative',
  });

  const inputStyle: StyleObject = {
    paddingLeft: '73px',
    paddingRight: '120px',
    ...TEXT.base_book,
  };

  const iconContainerClass = css({
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '16px',
    borderRight: `1px solid ${theme.colors.gray200}`,
  });

  const iconClass = css({
    width: '24px',
    color: theme.colors.gray400,
  });

  const buttonStyle: StyleObject = {
    position: 'absolute',
    top: '8px',
    right: '8px',
  };

  return {
    modalStyle,
    closeStyle,
    rowClass,
    inputStyle,
    titleClass,
    iconContainerClass,
    iconClass,
    buttonStyle,
  };
};
