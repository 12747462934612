import { useContext } from 'react';
import { OrganizationSharedData, SharedContext } from './shared-provider';

export const useSharedOrganizationList: () => {
  organizations: OrganizationSharedData[];
} = () => {
  const context = useContext(SharedContext);
  if (context === undefined) {
    throw new Error(
      'useSharedOrganizationList must be used within a SharedContext'
    );
  }
  const [state] = context;
  return { organizations: state.organizations };
};
