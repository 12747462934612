import { styled, TEXT } from 'shared/ui';

export const Root = styled('div', ({ $theme }) => ({
  padding: '24px',
  borderRadius: '8px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
  backgroundColor: $theme.colors.white900,
}));

export const Title = styled('h2', {
  ...TEXT.xl2_medium,
});

export const Appointments = styled('p', ({ $theme }) => ({
  color: $theme.colors.gray400,
}));

export const Count = styled('span', ({ $theme }) => ({
  ...TEXT.base_medium,
  color: $theme.colors.accent500,
}));
