import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionRoomState } from 'pages/session-room/context';
import { FooterMessageInput, useStyles } from './styles';
import { ChatSignal } from '../../model';

export const ChatFooter: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { getFooterStyles } = useStyles();
  const { session, chatOptions } = useSessionRoomState();
  const [currentMessage, setCurrentMessage] = useState('');
  const handleChange = useCallback(
    ({ target: { value } }) => setCurrentMessage(value),
    []
  );
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (currentMessage.trim().length > 0) {
        session?.signal(
          {
            type: ChatSignal.Message,
            data: currentMessage,
          },
          (error) => {
            if (error) {
              // eslint-disable-next-line no-console
              console.error(error);
              return;
            }

            setCurrentMessage('');
          }
        );
      }
    },
    [currentMessage, session]
  );

  return (
    <footer className={getFooterStyles(chatOptions.isDarkTheme ?? false)}>
      <form onSubmit={handleSubmit}>
        <FooterMessageInput
          autoFocus
          type="text"
          placeholder={t('session-room:CHAT.MESSAGE_PLACEHOLDER')}
          value={currentMessage}
          onChange={handleChange}
        />
      </form>
    </footer>
  );
};
