import { Input } from 'shared/ui';
import { InputMethodProps } from './model';

export const FieldDefault = (props: InputMethodProps) => (
  <Input
    placeholder={props.placeholder}
    value={props.value}
    onBlur={props.onBlur}
    onChange={({ currentTarget }) => props.onChange(currentTarget.value)}
    hasError={props.hasError}
  />
);
