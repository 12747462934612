import { FC, useEffect, useRef } from 'react';
import {
  ChatHeader as Header,
  ChatBody as Body,
  ChatFooter as Footer,
} from './ui';
import { ChatWrapper } from './styles';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';

export const Chat: FC = () => {
  const chatDOM = useRef(null);
  const {
    chatOptions: { x, y },
  } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();

  useEffect(() => {
    dispatch({
      type: 'REFRESH_CHAT_OPTIONS',
      payload: {
        DOMObject: chatDOM.current ?? undefined,
      },
    });
  }, [dispatch]);

  return (
    <ChatWrapper
      ref={chatDOM}
      style={{
        left: x,
        top: y,
      }}
    >
      <Header />
      <Body />
      <Footer />
    </ChatWrapper>
  );
};
