import { DESKTOP, styled, TEXT, useStyletron } from 'shared/ui';

export const Root = styled('div', {
  padding: ' 0 16px',

  [DESKTOP]: {
    padding: ' 0 24px',
  },
});

export const InnerBlock = styled('div', ({ $theme }) => ({
  paddingTop: '16px',
  paddingBottom: '16px',

  [DESKTOP]: {
    paddingTop: '24px',
    paddingBottom: '24px',
    borderBottom: `1px solid ${$theme.colors.gray200}`,
  },
}));

export const MobileDivider = styled('div', ({ $theme }) => ({
  marginTop: '8px',
  borderTop: `1px solid ${$theme.colors.gray200}`,
  paddingTop: '8px',
}));

export const List = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const ListItem = styled('div', {
  ...TEXT.base_book,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  [DESKTOP]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '24px',
  },
});

export const ItemLabel = styled('div', ({ $theme }) => ({
  color: $theme.colors.gray400,

  [DESKTOP]: {
    flex: '0 0 224px',
  },
}));

export const ItemValue = styled('div', {
  flex: '1 1 auto',
});

export const LessButton = styled('button', ({ $theme }) => ({
  ...TEXT.sm_book,

  color: $theme.colors.accent500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  width: '100%',
  transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',

  ':hover': {
    color: $theme.colors.accent600,
  },
}));

export const Actions = styled('div', ({ $theme }) => ({
  margin: '0 -16px',
  padding: '16px',
  borderTop: `1px solid ${$theme.colors.gray200}`,
}));

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const userListClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  });

  const hostClass = css({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
  });

  const hostAvatarClass = css({
    flex: '0 0 48px',
  });

  const hostContentClass = css({
    flex: '1 1 auto',
  });

  const hostNameClass = css({
    ...TEXT.base_medium,
  });

  const hostEmailClass = css({
    ...TEXT.sm_book,
    color: theme.colors.gray400,
    marginTop: '4px',
  });

  const textClass = css({
    ...TEXT.base_book,
  });

  return {
    userListClass,
    hostClass,
    hostAvatarClass,
    hostContentClass,
    hostNameClass,
    hostEmailClass,
    textClass,
  };
};
