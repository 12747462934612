import { useContext } from 'react';
import { SidebarCtx } from './provider';

export function useSidebarContext() {
  const ctx = useContext(SidebarCtx);

  if (!ctx) {
    throw new Error('useSidebarContext must be used within the SidebarContext');
  }

  return ctx;
}
