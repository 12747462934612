import { useEffect, useState } from 'react';
import { Publisher } from '@opentok/client';
import { useEvent } from 'shared/lib';

import { useSessionRoomState } from '../context';
import { ActionButtonItemInterface } from '../ui/action-button/model';

export const useMediaInfo = () => {
  const { publisher } = useSessionRoomState();
  const publisherContext = publisher?.context as Publisher;
  const [currentAudioDeviceId, setCurrentAudioDeviceId] = useState<
    string | null
  >('default');
  const [currentVideoDeviceId, setCurrentVideoDeviceId] = useState<
    string | null
  >((publisher?.context as Publisher).getVideoSource().deviceId ?? null);
  const [devicesList, setDevicesList] = useState<{
    audio: ActionButtonItemInterface[];
    video: ActionButtonItemInterface[];
  }>({
    audio: [],
    video: [],
  });
  const handleSetAudioSource = useEvent((deviceId) => () => {
    if (!deviceId) {
      return;
    }

    publisherContext
      .setAudioSource(deviceId)
      .then(() => setCurrentAudioDeviceId(deviceId));
  });
  const getSessionDevices = useEvent(() => {
    OT.getDevices((error, devices) => {
      /**
       * Default Audio Device Issue
       * https://github.com/opentok/opentok-react/issues/187
       */
      const defaultAudioDeviceLabel =
        devices?.find(
          (device) =>
            device.kind === 'audioInput' && device.deviceId === 'default'
        )?.label ?? null;
      const audioDevices =
        devices?.filter(
          (device) =>
            device.kind === 'audioInput' && device.deviceId !== 'default'
        ) ?? [];
      const videoDevices =
        devices?.filter(
          (device) =>
            device.kind === 'videoInput' && device.deviceId !== 'default'
        ) ?? [];

      const audio =
        audioDevices.length > 1
          ? audioDevices.map(({ label, deviceId }) => ({
              label,
              deviceId,
              onClick: handleSetAudioSource(deviceId),
            }))
          : [];
      const video =
        videoDevices.length > 1
          ? videoDevices.map(({ label, deviceId }) => ({
              label,
              deviceId,
              onClick: () => {
                publisherContext
                  .setVideoSource(deviceId)
                  .then(() => setCurrentVideoDeviceId(deviceId))
                  .catch((deviceChangeError) =>
                    // eslint-disable-next-line no-console
                    console.error(deviceChangeError.name)
                  );
              },
            }))
          : [];

      setDevicesList({
        audio,
        video,
      });

      if (defaultAudioDeviceLabel) {
        handleSetAudioSource(
          audioDevices.find(
            (device) =>
              device.label === defaultAudioDeviceLabel.replace('Default - ', '')
          )?.deviceId ?? null
        )();
      }
    });
  });

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', getSessionDevices);
    getSessionDevices();

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        getSessionDevices
      );
    };
  }, [getSessionDevices]);

  return {
    currentAudioDeviceId,
    currentVideoDeviceId,
    devicesList,
  };
};
