import { FC } from 'react';
import { DragIcon, DeleteIcon } from 'shared/ui';
import { Content, DeleteButton, Item, useStyles } from './styles';

type Props = {
  onRemove?: () => void;
};

export const DndItem: FC<Props> = ({ children, onRemove }) => {
  const { dragIconClass } = useStyles();

  return (
    <Item>
      <div className="handle">
        <DragIcon className={dragIconClass} />
      </div>

      <Content>{children}</Content>

      {!!onRemove && (
        <DeleteButton type="button" onClick={onRemove}>
          <DeleteIcon />
        </DeleteButton>
      )}
    </Item>
  );
};
