import { i18n } from '../i18n';
import { PHONE_COUNTRY, ValidationProps } from './model';

export const validationPhone = ({ schema }: ValidationProps) =>
  schema
    .min(12, i18n.t('common:VALIDATION.MIN_CHARS', { count: 10 }))
    .test('country', i18n.t('common:VALIDATION.ONLY_US_CA'), (value) => {
      if (value) {
        const isCountryUSA = value.slice(0, 2) === PHONE_COUNTRY;
        return !!value && isCountryUSA;
      }
      return true;
    });

export const registerPhoneValidation = ({ schema }: ValidationProps) =>
  schema.required().matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
    message: i18n.t('common:VALIDATION.ONLY_US_CA'),
    excludeEmptyString: false,
  });
