import { Publisher } from '@opentok/client';
import { SessionRoomState, SessionRoomAction } from '../model';
import { generateMessage, matchDarkTheme } from '../utils';

export const initialSessionRoomState: SessionRoomState = {
  dataLoadingState: 'idle',
  session: null,
  isHost: false,
  publisher: null,
  users: [],
  error: null,
  appointmentId: null,
  talkingUser: null,
  pinnedStreamId: null,

  // Vitals
  isShowVitals: false,
  isShowBiometrics: false,
  sessionPubNub: undefined,
  vitalsData: {
    status: 'pending',
  },
  biometricsData: {
    status: 'disconnected',
    measurement: []
    //   { Key: 'ppm', Value: 68.7779 },
    //   { Key: 'bmi', Value: 32.0073 },
    //   { Key: 'snr', Value: 6.9043 },
    //   { Key: 'msi', Value: 3.3918 },
    //   { Key: 'systolic', Value: 137.8946 },
    //   { Key: 'diastolic', Value: 90.9885 },
    //   { Key: 'age', Value: -1 },
    //   { Key: 'breathing', Value: 8 },
    //   { Key: 'healthScore', Value: 57.7619 },
    //   { Key: 'waistToHeight', Value: 62.0555 },
    //   { Key: 'heartRateVariability', Value: 26.7916 },
    //   { Key: 'cardiacWorkload', Value: 3.9769 },
    //   { Key: 'absi', Value: 7.8349 },
    //   { Key: 'cvdRisk', Value: 4.7651 },
    //   { Key: 'strokeRisk', Value: 3.7479 },
    //   { Key: 'heartAttackRisk', Value: 0.7642 },
    //   { Key: 'HypertensionRisk', Value: 59.1449 },
    //   { Key: 'HypertriglyceridemiaRisk', Value: 23.9831 },
    //   { Key: 'HypercholesterolemiaRisk', Value: 73.7684 },
    //   { Key: 'DiabetesRisk', Value: 82.2744 },
    //   { Key: 'irregularHeartBeats', Value: 0 },
    //   { Key: 'avgStarRating', Value: 1.9140022050717 },
    //   { Key: 'SNR', Value: 6.9043 },
    //   { Key: 'BP_STROKE', Value: 3.7479 },
    //   { Key: 'PHYSIO_SCORE', Value: 3 },
    //   { Key: 'RISKS_SCORE', Value: 2.8571 },
    //   { Key: 'BP_DIASTOLIC', Value: 90.9885 },
    //   { Key: 'MFBG_RISK_PROB', Value: 57.1538 },
    //   { Key: 'HRV_SDNN', Value: 26.7916 },
    //   { Key: 'HPT_RISK_PROB', Value: 59.1449 },
    //   { Key: 'BR_BPM', Value: 8 },
    //   { Key: 'TG_RISK_PROB', Value: 73.7684 },
    //   { Key: 'BP_TAU', Value: 1.9379 },
    //   { Key: 'WAIST_TO_HEIGHT', Value: 62.0555 },
    //   { Key: 'OVERALL_METABOLIC_RISK_PROB', Value: 65.1669 },
    //   { Key: 'ABSI', Value: 7.8349 },
    //   { Key: 'VITAL_SCORE', Value: 3.25 },
    //   { Key: 'BP_RPP', Value: 3.9769 },
    //   { Key: 'MSI', Value: 3.3918 },
    //   { Key: 'HDLTC_RISK_PROB', Value: 82.2744 },
    //   { Key: 'BP_HEART_ATTACK', Value: 0.7642 },
    //   { Key: 'HEALTH_SCORE', Value: 57.7619 },
    //   { Key: 'WAIST_CIRCUM', Value: 100.53 },
    //   { Key: 'BMI_CALC', Value: 32.0073 },
    //   { Key: 'PHYSICAL_SCORE', Value: 2.3333 },
    //   { Key: 'DBT_RISK_PROB', Value: 23.9831 },
    //   { Key: 'HBA1C_RISK_PROB', Value: 50.9611 },
    //   { Key: 'BP_CVD', Value: 4.7651 },
    //   { Key: 'FLD_RISK_PROB', Value: 86.6638 },
    //   { Key: 'BP_SYSTOLIC', Value: 137.8946 },
    //   { Key: 'IHB_COUNT', Value: 0 },
    //   { Key: 'HR_BPM', Value: 68.7779 },
    //   { Key: 'MENTAL_SCORE', Value: 3 }
    // ]    
  },

  // Chat
  isShowChat: false,
  chatOptions: {
    DOMObject: undefined,
    x: window.innerWidth / 2 - 336,
    y: window.innerHeight > 620 ? window.innerHeight / 2 - 320 : 0,
    isDragging: false,
    isShowNotification: false,
    isDarkTheme: matchDarkTheme(),
  },
  chatMessages: [],
};

export const sessionRoomReducer = (
  state: SessionRoomState,
  action: SessionRoomAction
): SessionRoomState => {
  switch (action.type) {
    case 'LOAD_SESSION_ROOM_DATA':
      return { ...state, dataLoadingState: 'loading' };

    case 'LOAD_SESSION_ROOM_DATA_SUCCESS':
      return {
        ...state,
        session: action.payload.session,
        isHost: action.payload.session.capabilities.forceDisconnect === 1,
        error: null,
        dataLoadingState: 'ready',
        appointmentId: action.payload.appointmentId,
      };

    case 'SET_SESSION_ROOM_ERROR':
      return {
        ...state,
        dataLoadingState: 'error',
        error: action.payload,
      };

    case 'FORCE_DISCONNECT':
      return {
        ...state,
        dataLoadingState: 'disconnected',
      };

    case 'RESET_SESSION_ROOM_STATE':
      return initialSessionRoomState;

    case 'ADD_PUBLISHER':
      return {
        ...state,
        publisher: {
          context: action.payload.context as Publisher,
          videoEl: action.payload.videoEl as HTMLVideoElement,
          hasVideo: action.payload.context?.stream?.hasVideo ?? true,
          hasAudio: action.payload.context?.stream?.hasAudio ?? true,
        },
      };

    case 'ADD_USER': {
      const users = [...state.users, action.payload.user];
      let { talkingUser } = state;

      if (talkingUser === null && users.length > 0) {
        const [firstUser] = users;
        const id = firstUser.context.stream?.streamId;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const userData = JSON.parse(firstUser.context.stream!.connection.data);

        talkingUser = {
          id,
          name: userData.name,
        };
      }

      return {
        ...state,
        users,
        talkingUser,
      };
    }

    case 'REMOVE_USER': {
      const users = state.users.filter(
        (user) => user.context.stream?.streamId !== action.payload.streamId
      );
      const talkingUser = (() => {
        if (state.talkingUser?.id === action.payload.streamId) {
          return users.length === 0
            ? null
            : () => {
                const [firstUser] = users;
                const id = firstUser.context.stream?.streamId;
                const userData = JSON.parse(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  firstUser.context.stream!.connection.data
                );

                return {
                  id,
                  name: userData.name,
                };
              };
        }

        return state.talkingUser;
      })();
      const pinnedStreamId =
        state.pinnedStreamId === action.payload.streamId
          ? null
          : state.pinnedStreamId;

      return <SessionRoomState>{
        ...state,
        users,
        talkingUser,
        pinnedStreamId,
      };
    }

    case 'SET_TALKING_USER':
      if (
        action.payload.talkingUser?.id === state.talkingUser?.id ||
        action.payload.talkingUser?.id ===
          state.publisher?.context.stream?.streamId
      ) {
        return state;
      }

      return {
        ...state,
        talkingUser: action.payload.talkingUser,
      };

    case 'SET_PINNED_STREAM_ID':
      return {
        ...state,
        pinnedStreamId: action.payload.pinnedStreamId,
      };

    case 'STREAM_PROPERTY_CHANGED':
      return {
        ...state,
        publisher:
          state.publisher?.context.stream?.streamId === action.payload.streamId
            ? {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...state.publisher!,
                [action.payload.changedProperty]: action.payload.value,
              }
            : state.publisher,
        // eslint-disable-next-line
        // @ts-ignore
        users: state.users.reduce((acc, current) => {
          if (current.context.stream?.streamId === action.payload.streamId) {
            return [
              ...acc,
              {
                ...current,
                [action.payload.changedProperty]: action.payload.value,
              },
            ];
          }

          return [...acc, current];
        }, []),
      };

    case 'TOGGLE_SHOW_VITALS':
      return {
        ...state,
        isShowVitals: action.payload.isShow,
      };

    case 'TOGGLE_SHOW_BIOMETRICS':
      return {
        ...state,
        isShowBiometrics: action.payload.isShow,
      };

    case 'SET_PUBNUB_INSTANCE':
      return {
        ...state,
        sessionPubNub: {
          instance: action.payload.instance,
          channels: action.payload.channels,
        },
      };

    case 'SET_VITALS_DATA':
      return {
        ...state,
        vitalsData: {
          ...state.vitalsData,
          ...action.payload,
        },
      };

      case 'SET_BIOMETRICS_DATA':
        return {
          ...state,
          biometricsData: {
            status: action.payload.status,
            measurement: action.payload.measurement,
          },
        };
    case 'TOGGLE_SHOW_CHAT':
      return {
        ...state,
        isShowChat: action.payload.isShow,
      };

    case 'REFRESH_CHAT_OPTIONS':
      return {
        ...state,
        chatOptions: {
          ...state.chatOptions,
          ...action.payload,
        },
      };

    case 'REFRESH_MESSAGE_LIST':
      return {
        ...state,
        chatMessages: [
          ...state.chatMessages,
          ...generateMessage(action.payload.signal),
        ],
        chatOptions: {
          ...state.chatOptions,
          isShowNotification: !state.isShowChat,
        },
      };

    default:
      return state;
  }
};
