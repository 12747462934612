import { DESKTOP, styled, TEXT, useStyletron } from 'shared/ui';

export const Root = styled('header', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '24px',
  padding: '16px',

  [DESKTOP]: {
    padding: '16px 24px',
  },
});

export const HeaderInfo = styled('div', {
  flex: '1 1 auto',
  marginRight: '16px',
});

export const HeaderMeta = styled('div', {
  marginTop: '4px',
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '8px',
});

export const HeaderButtons = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const Title = styled('h3', {
  ...TEXT.base_medium,
});

export const NoteId = styled('div', ({ $theme }) => ({
  ...TEXT.sm_book,
  color: $theme.colors.accent500,
}));

export const Date = styled('time', ({ $theme }) => ({
  ...TEXT.sm_book,
  color: $theme.colors.gray400,
}));

export const useStyles = (isFull: boolean) => {
  const [css, theme] = useStyletron();

  const chevronSmallIconStyle = css({
    width: '20px',
    color: theme.colors.gray400,
  });

  const chevronIconStyle = css({
    color: theme.colors.gray400,
    transform: isFull ? 'rotate(180deg)' : undefined,
  });

  return {
    chevronSmallIconStyle,
    chevronIconStyle,
  };
};
