import { ApolloClient, StoreObject } from '@apollo/client/core';

export const removeFromApolloCache = <T extends StoreObject>({
  data,
  client,
}: {
  data?: T | null;
  client: ApolloClient<object>;
}) => {
  const normalizedId = client.cache.identify(data ?? {});

  if (normalizedId) {
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  }
};
