import { createContext, FC, useCallback, useMemo, useState } from 'react';
import { ConfirmFnResult, ConfirmFnProps } from './model';
import { ModalCloseEvent } from '../../model';
import { Confirm } from '../confirm';
import { ConfirmProps } from '../model';

type Context = {
  current?: ConfirmProps;
  confirm: (props: ConfirmFnProps) => ConfirmFnResult;
  close: () => void;
  setLoading: (value: boolean) => void;
};

export const ConfirmContext = createContext<Context | null>({
  confirm: () => {
    throw new Error('confirm not implemented');
  },
  close: () => {
    throw new Error('close not implemented');
  },
  setLoading: () => {
    throw new Error('setLoading not implemented');
  },
});

export const ConfirmContainer: FC = ({ children }) => {
  const [currentConfirm, setCurrentConfirm] = useState<
    ConfirmProps | undefined
  >(undefined);

  const confirm = useCallback(
    (props: ConfirmFnProps) =>
      new Promise<{ result: boolean; type: ModalCloseEvent | 'submit' }>(
        (resolve) => {
          setCurrentConfirm({
            onCancel: (type) => {
              resolve({ result: false, type });
              setCurrentConfirm(undefined);
            },
            onSubmit: () => {
              resolve({ result: true, type: 'submit' });
              if (!props.noCloseAfterSubmit) {
                setCurrentConfirm(undefined);
              }
            },
            ...props,
          });
        }
      ),
    [setCurrentConfirm]
  );

  const close = useCallback(() => {
    setCurrentConfirm(undefined);
  }, [setCurrentConfirm]);

  const setLoading = useCallback(
    (isLoading: boolean) => {
      setCurrentConfirm((current) =>
        current ? { ...current, isLoading } : undefined
      );
    },
    [setCurrentConfirm]
  );

  const state: Context = useMemo(
    () => ({
      confirm,
      setLoading,
      close,
      current: currentConfirm,
    }),
    [currentConfirm, confirm, close, setLoading]
  );

  return (
    <ConfirmContext.Provider value={state}>
      <>
        {children}
        {!!currentConfirm && <Confirm {...currentConfirm} />}
      </>
    </ConfirmContext.Provider>
  );
};
