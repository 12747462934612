import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectionStatusEnum, ConnectionStatusInterface } from './model';
import { Wrapper, StatusDot, StatusText } from './style';

export const ConnectionStatus: FC<ConnectionStatusInterface> = ({
  status = ConnectionStatusEnum.PENDING,
}) => {
  const { t } = useTranslation(['session-room']);
  const [dotColor, text] = useMemo(() => {
    switch (status) {
      case ConnectionStatusEnum.CONNECTED:
        return ['#26d859', t('session-room:CONNECTION_STATUS.CONNECTED')];

      case ConnectionStatusEnum.DISCONNECTED:
        return ['#de3c3d', t('session-room:CONNECTION_STATUS.DISCONNECTED')];

      default:
        return ['#f86623', t('session-room:CONNECTION_STATUS.PENDING')];
    }
  }, [status, t]);

  return (
    <Wrapper>
      <StatusDot style={{ backgroundColor: dotColor }} />
      <StatusText>{text}</StatusText>
    </Wrapper>
  );
};
