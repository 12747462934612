import { styled } from 'shared/ui';
import { theme } from '../theme';

export const Root = styled('header', {
  padding: '24px 32px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const UserInfo = styled('div', {
  marginRight: '16px',
});

export const HeaderActions = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  gap: '56px',
});

export const UserName = styled('h1', {
  fontSize: '32px',
  fontWeight: 400,
  lineHeight: 1.25,
  color: theme.colors.white,
});

export const AppointmentId = styled('h2', {
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: 1.33,
  color: theme.colors.gray,
});
