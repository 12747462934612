import { FC, forwardRef } from 'react';
import { Button as BaseButton } from 'baseui/button';
import { mergeStyles, useStyletron } from 'shared/ui';
import { ButtonProps, BUTTON_SIZE, BUTTON_KIND, BUTTON_COLOR } from './model';
import {
  BUTTON_KIND_STYLES,
  BUTTON_SIZE_STYLES,
  BUTTON_COLOR_STYLES,
  DELETE_BUTTON_STYLES,
} from './util';

export const Button: FC<ButtonProps> = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      children,
      startEnhancer,
      endEnhancer,
      size = BUTTON_SIZE.MEDIUM,
      kind = BUTTON_KIND.PRIMARY,
      color = BUTTON_COLOR.ACCENT,
      style = {},
      loadingStyle,
      isDeleteButton = false,
      disabled = false,
      ...props
    },
    ref
  ) => {
    const [, theme] = useStyletron();

    return (
      <BaseButton
        {...props}
        ref={ref}
        overrides={{
          BaseButton: {
            style: mergeStyles([
              {
                alignItems: 'center',
                columnGap: '8px',
                flexShrink: 0,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: '16px',
                paddingLeft: '16px',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                borderBottomLeftRadius: '4px',
                transitionDuration: '0.2s',
                transitionProperty: 'all',
              },
              BUTTON_SIZE_STYLES({ size }) ?? {},
              BUTTON_KIND_STYLES({ kind, disabled, theme }) ?? {},
              BUTTON_COLOR_STYLES({
                kind,
                color,
                disabled,
                theme,
              }) ?? {},
              DELETE_BUTTON_STYLES({ isDeleteButton, disabled, theme }),
              style ?? {},
            ]),
          },
          LoadingSpinner: {
            style: loadingStyle,
          },
        }}
        kind={kind}
        disabled={disabled}
      >
        {startEnhancer}
        {children}
        {endEnhancer}
      </BaseButton>
    );
  }
);
