import { FC } from 'react';
import { NotificationPill } from '..';
import { TitleTabNavigationProps } from './model';
import { Wrapper } from './styles';
import { TitleTab } from './ui';

export const TitleTabNavigation: FC<TitleTabNavigationProps> = ({ items }) => (
  <Wrapper>
    {items.map(({ to, title, count }) => (
      <TitleTab key={to} to={to}>
        {title}
        {!!count && count > 0 && <NotificationPill count={count} />}
      </TitleTab>
    ))}
  </Wrapper>
);
