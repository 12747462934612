import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { InfoWindowInterface } from './model';
import { InfoWindowTable } from './table';

export const InfoWindow: FC<InfoWindowInterface> = ({ onClose }) => {
  const { t } = useTranslation(['session-room']);
  const {
    rootClass,
    windowClass,
    closeBgClass,
    headerClass,
    sideClass,
    titleClass,
    backButton,
    bodyClass,
  } = useStyles();

  return (
    <div className={rootClass}>
      <button
        type="button"
        className={closeBgClass}
        onClick={onClose}
        aria-label={t('session-room:CLOSE')}
      />

      <div className={windowClass}>
        <div className={headerClass}>
          <div className={sideClass}>
            <button type="button" className={backButton} onClick={onClose}>
              {t('session-room:CLOSE')}
            </button>
          </div>
          <div className={titleClass}>{t('session-room:SESSION_DETAILS')}</div>
          <div className={sideClass} />
        </div>

        <div className={bodyClass}>
          <InfoWindowTable />
        </div>
      </div>
    </div>
  );
};
