import { FC, useCallback, useMemo, useState } from 'react';
import { TagsProps } from './model';
import { List } from './styles';
import { Tag } from './tag';
import { splitTags } from './utils';

export const Tags: FC<TagsProps> = ({ tags, showCount: showInputCount }) => {
  const [canShowAll, setCanShowAll] = useState(false);

  const [displayTags, hiddenTags] = useMemo(
    () => splitTags(tags, canShowAll ? undefined : showInputCount),
    [tags, showInputCount, canShowAll]
  );

  const showAll = useCallback(() => {
    setCanShowAll(true);
  }, []);
  return (
    <List>
      {displayTags.map((title, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tag key={index}>{title}</Tag>
      ))}
      {!!hiddenTags.length && (
        <button type="button" onClick={showAll}>
          <Tag>+ {tags.length - displayTags.length}</Tag>
        </button>
      )}
    </List>
  );
};
