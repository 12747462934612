import { Appointment } from 'shared/api';
import { useMemo } from 'react';

export const useAgenda = (appointment?: Appointment | null) => {
  const agendaTitle = useMemo(
    () => appointment?.agenda_title ?? appointment?.service?.name,
    [appointment?.agenda_title, appointment?.service?.name]
  );
  const agendaDescription = useMemo(
    () =>
      appointment?.agenda_description &&
      appointment.agenda_description.trim() !== ''
        ? appointment.agenda_description
        : appointment?.service?.description,
    [appointment?.agenda_description, appointment?.service?.description]
  );

  return {
    agendaTitle,
    agendaDescription,
  };
};
