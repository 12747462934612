export const SESSION_ROOM_COLORS = {
  primary: '#3e8afe',
  black: '#111',
  blue: '#232736',
  transparentBlue: 'rgba(16, 21, 27, 0.92)',
  white: '#fff',
  transparentWhite: 'rgba(255, 255, 255, 0.1)',
  gray: '#a9a9a8',
  gray700: '#374151',
  red: '#de3c3d',
};
