import { styled } from 'shared/ui';
import { SESSION_ROOM_COLORS } from '../theme/colors';

export const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgb(17, 17, 17, 0.2)',
  borderRadius: '12px',
  padding: '4px 8px',
  pointerEvents: 'none',
});

export const StatusDot = styled('div', {
  flexShrink: 0,
  fontSize: '8px',
  width: '1em',
  height: '1em',
  borderRadius: '50%',
  marginRight: '8px',
});

export const StatusText = styled('div', {
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16px',
  color: SESSION_ROOM_COLORS.gray,
});
