import { useStyletron, TEXT, MOBILE, DESKTOP } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { PopoverOverrides } from 'baseui/popover';
import { useCallback } from 'react';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const getPopoverOverrides = useCallback(
    (isSelected: boolean, isPaymentEnabled: boolean): PopoverOverrides => ({
      Body: {
        style: {
          width: isPaymentEnabled ? (isSelected ? '472px' : '720px') : '720px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          boxShadow: 'none',
          backgroundColor: 'initial',
        },
      },
      Inner: {
        style: {
          paddingTop: '16px',
          paddingBottom: '16px',
          paddingLeft: '24px',
          paddingRight: '24px',
          borderTopWidth: '1px',
          borderRightWidth: '1px',
          borderBottomWidth: '1px',
          borderLeftWidth: '1px',
          borderTopStyle: 'solid',
          borderRightStyle: 'solid',
          borderBottomStyle: 'solid',
          borderLeftStyle: 'solid',
          borderTopColor: theme.colors.gray200,
          borderRightColor: theme.colors.gray200,
          borderBottomColor: theme.colors.gray200,
          borderLeftColor: theme.colors.gray200,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          backgroundColor: theme.colors.white900,

          [DESKTOP]: {
            overflow: 'auto',
            maxHeight: '250px',
          },
        },
      },
    }),
    [theme.colors]
  );

  const serviceRowClass = css({
    display: 'flex',
    gap: '24px',

    [MOBILE]: {
      flexDirection: 'column',
    },
  });

  const serviceBoxClass = css({
    width: '100%',
    flexGrow: 1,
  });

  const priceBoxClass = css({
    width: '100%',
  });

  const priceInputStyle: StyleObject = {
    ...TEXT.base_book,
  };

  const priceIconClass = css({
    width: '24px',
    color: theme.colors.gray400,
  });

  const subtitleStyle: StyleObject = {
    marginBottom: '16px',
    ...TEXT.xl_medium,
  };

  return {
    getPopoverOverrides,
    serviceRowClass,
    serviceBoxClass,
    priceBoxClass,
    priceInputStyle,
    priceIconClass,
    subtitleStyle,
  };
};
