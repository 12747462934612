import { styled, FingerprintIcon } from 'shared/ui';
import { SESSION_ROOM_COLORS } from '../theme/colors';

export const BIOMETRICS_MODAL_WIDTH = 960;

export const BiometricsBlock = styled('div', {
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  width: `${BIOMETRICS_MODAL_WIDTH}px`,
  backgroundColor: SESSION_ROOM_COLORS.blue,
  borderTopLeftRadius: '24px',
  borderBottomLeftRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
});

export const Header = styled('header', {
  padding: '20px 32px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
});

export const HeaderLeft = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
});

export const HeaderRight = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginLeft: '16px',
  gap: '32px',
});

export const HeaderIcon = styled(FingerprintIcon, {
  width: '24px',
  color: SESSION_ROOM_COLORS.white,
  pointerEvents: 'none',
  marginRight: '16px',
});

export const HeaderTitle = styled('h2', {
  fontSize: '24px',
  fontWeight: '400',
  lineHeight: 1.67,
  color: SESSION_ROOM_COLORS.white,
});

export const Body = styled('div', {
  flex: '1 1 auto',
  overflow: 'auto',
});

export const SensorBlocks = styled('div', {
  padding: '16px',
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: '1fr 1fr',
});
