export const getConditionalItem = <T>(
  item: T,
  condition: boolean | boolean[],
  elseItem?: T
) => {
  const isExist = Array.isArray(condition)
    ? condition.every((c) => !!c)
    : condition;

  return isExist ? [item] : elseItem ? [elseItem] : ([] as T[]);
};

export const getConditionalItemObject = <T>(
  item: T,
  condition: boolean,
  elseItem?: T
) => (condition ? item : elseItem ?? ({} as T));
