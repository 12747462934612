import { FC } from 'react';
import { TEXT, styled } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const Divider: FC<{
  styles?: StyleObject;
  label?: string;
  labelStyles?: StyleObject;
}> = ({ styles, label, labelStyles }) => {
  const DividerEl = styled('div', ({ $theme }) => ({
    alignSelf: 'stretch',
    borderTopWidth: `1px`,
    borderRightWidth: `1px`,
    borderStyle: 'solid',
    borderColor: $theme.colors.gray200,
    marginTop: label ? '10px' : undefined,
    marginBottom: label ? '10px' : undefined,
    position: 'relative',
    '::after': {
      content: label ? 'attr(data-label)' : 'none',
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '-11px',
      backgroundColor: $theme.colors.white,
      paddingRight: '16px',
      paddingLeft: '16px',
      ...TEXT.sm_book,
      color: $theme.colors.gray400,
      transform: 'translateX(-50%)',
      ...labelStyles,
    },
    ...styles,
  }));

  return <DividerEl data-label={label} />;
};
