import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VitalPlate, VitalPlateData } from '../plate';
import { useSessionRoomState } from '../../../../context';

export const VitalGlucose: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { vitalsData } = useSessionRoomState();
  const value = useMemo(
    () => vitalsData.glucose?.Value ?? '–',
    [vitalsData.glucose]
  );

  return (
    <VitalPlate>
      <VitalPlateData
        title={t('session-room:SENSORS.GLUCOSE')}
        units="mg/dL"
        color="#fdff04"
        value={value}
      />
    </VitalPlate>
  );
};
