import { FC } from 'react';
import { AppointmentMeetingTypeEnum } from 'shared/api';
import {
  styled,
  InMeetingIcon,
  NurseIcon,
  ChatIcon,
  Desktop,
  Mobile,
  Select,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

type SessionTypeProps = {
  value?: AppointmentMeetingTypeEnum;
  onChange: (value?: AppointmentMeetingTypeEnum) => void;
  hasError?: boolean;
};

export const SessionType: FC<SessionTypeProps> = ({
  value,
  onChange,
  hasError = false,
}) => {
  const { t } = useTranslation(['appointments', 'participants']);
  const { getListClass, itemClass, getButtonClass, getIconStyle } = useStyles();

  const options = [
    {
      id: AppointmentMeetingTypeEnum.Video,
      icon: InMeetingIcon,
      label: t('appointments:CREATE.TELEMEDICINE'),
    },
    {
      id: AppointmentMeetingTypeEnum.Inperson,
      icon: NurseIcon,
      label: t('appointments:CREATE.IN_PERSON'),
      disabled: true,
    },
    {
      id: AppointmentMeetingTypeEnum.Chat,
      icon: ChatIcon,
      label: t('appointments:CREATE.CHAT'),
      disabled: true,
    },
  ];

  const currentOption = options.find((option) => option.id === value);

  return (
    <>
      <Desktop>
        <ul className={getListClass(hasError)}>
          {options.map(({ id, icon, label, disabled }) => {
            const isActive = id === value;
            const Icon = styled(icon, getIconStyle(isActive));

            return (
              <li key={id} className={itemClass}>
                <button
                  className={getButtonClass(isActive, disabled)}
                  type="button"
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    onChange(id);
                  }}
                >
                  <Icon />
                  <span>{label}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </Desktop>

      <Mobile>
        <Select
          placeholder={t('appointments:CREATE.SESSION_TYPE')}
          options={options}
          value={currentOption && [currentOption]}
          onChange={({ option }) => {
            onChange(option?.id as AppointmentMeetingTypeEnum);
          }}
        />
      </Mobile>
    </>
  );
};
