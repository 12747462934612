import { ChartOptions, Tooltip } from 'chart.js';
import { TooltipState } from './model';
import { update } from './store';
import { WIDTH } from './styles';

export const tooltipOptions: ChartOptions<'line'> = {
  plugins: {
    tooltip: {
      enabled: false,
      position: 'custom',
      external(context) {
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          update({ opacity: '0' });
          return;
        }

        const position = context.chart.canvas.getBoundingClientRect();

        update({
          opacity: '1',
          left: `${position.left + window.pageXOffset + tooltipModel.caretX}px`,
          top: `${position.top + window.pageYOffset + tooltipModel.caretY}px`,
          /** @todo (@webatom) make value list with labels */
          value: tooltipModel.body?.[0]?.lines?.[0] ?? '',
        } as TooltipState);
      },
    },
  },
};

// eslint-disable-next-line func-names
Tooltip.positioners.custom = function (elements, position) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const chartEl = this.chart;

  if (!elements.length) {
    return false;
  }
  let offset = 0;
  if (chartEl.width / 2 > position.x) {
    offset = 16;
  } else {
    offset = -16 - WIDTH;
  }

  return {
    x: position.x + offset,
    y: position.y - 16,
  };
};
