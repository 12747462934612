import { FC, useCallback } from 'react';
import { Select as BaseSelect, Option } from 'baseui/select';
import {
  ChevronDownIcon,
  mergeStyles,
  useStyletron,
  useMobileMediaQuery,
} from 'shared/ui';
import { useModal, MODAL_TYPE } from 'shared/lib';
import { SELECT_SIZE_STYLES, SELECT_KIND_STYLES } from './util';
import { SELECT_SIZE, SELECT_KIND, SelectProps } from './model';
import { useStyles } from './styles';

export const Select: FC<SelectProps> = ({
  size = SELECT_SIZE.MEDIUM,
  kind = SELECT_KIND.PRIMARY,
  style,
  startEnhancer,
  searchable = false,
  creatable = false,
  hasError = false,
  isLoading = false,
  dropdownOverrideStyles,
  dropdownItemStyle,
  placeholder,
  value,
  options,
  onChange,
  valueKey = 'id',
  labelKey = 'label',
  isAvailableChooseDisabled = false,
  ...props
}) => {
  const [css, theme] = useStyletron();
  const {
    dropdownStyle,
    iconsContainerClass,
    getChevronIconClass,
    popoverOverrides,
    getControlContainerStyle,
    singleValueStyle,
    valueContainerStyle,
    getDropdownItemStyle,
    optionContentStyle,
    inputContainerStyle,
    searchIconSvgStyle,
    searchIconContainerStyle,
    placeholderStyle,
    focusStyle,
    errorStyle,
    modalListClass,
  } = useStyles();

  const isMobile = useMobileMediaQuery();
  const { open: openModal, close: closeModal } = useModal();

  const getFocusStyle = (isFocused: boolean) => {
    if (!isFocused || kind === SELECT_KIND.TERTIARY) {
      return {};
    }
    return focusStyle;
  };

  const getErrorStyle = (isError: boolean) => {
    if (!isError) {
      return {};
    }
    return errorStyle;
  };

  const Modal = useCallback(
    () => (
      <ul className={modalListClass}>
        {(options as Option[])?.map((option) => (
          <li key={String(option[valueKey])}>
            <button
              className={css(
                getDropdownItemStyle(
                  option[valueKey] === value?.[0].id,
                  option.disabled
                )
              )}
              style={dropdownItemStyle}
              type="button"
              onClick={() => {
                if (option.disabled && !isAvailableChooseDisabled) {
                  return;
                }
                onChange?.({ value: [option], option });
                closeModal();
              }}
            >
              {option[labelKey]}
            </button>
          </li>
        ))}
      </ul>
    ),
    [
      modalListClass,
      options,
      valueKey,
      css,
      getDropdownItemStyle,
      value,
      dropdownItemStyle,
      labelKey,
      isAvailableChooseDisabled,
      onChange,
      closeModal,
    ]
  );

  return (
    <BaseSelect
      placeholder={placeholder}
      value={value}
      options={options}
      onChange={onChange}
      clearable={false}
      searchable={searchable}
      creatable={creatable}
      isLoading={isLoading}
      valueKey={valueKey}
      labelKey={labelKey}
      onFocus={() => {
        if (!isMobile) {
          return;
        }
        openModal(MODAL_TYPE.BASE, {
          title: placeholder,
          children: <Modal />,
        });
      }}
      overrides={{
        Dropdown: {
          style: mergeStyles([dropdownStyle, dropdownOverrideStyles ?? {}]),
        },
        IconsContainer: {
          component: () => (
            <div className={iconsContainerClass}>
              <ChevronDownIcon className={getChevronIconClass(size)} />
            </div>
          ),
        },
        Popover: {
          props: {
            overrides: popoverOverrides,
          },
        },
        ControlContainer: {
          style: ({ $isFocused }) =>
            mergeStyles([
              getControlContainerStyle,
              SELECT_SIZE_STYLES({ size }) ?? {},
              SELECT_KIND_STYLES({ kind, theme }) ?? {},
              getFocusStyle($isFocused),
              getErrorStyle(hasError),
              style ?? {},
            ]),
        },
        SingleValue: {
          style: singleValueStyle,
        },
        ValueContainer: {
          style: valueContainerStyle,
        },
        DropdownListItem: {
          style: ({ $isHighlighted, $disabled }) =>
            mergeStyles([
              getDropdownItemStyle($isHighlighted, $disabled),
              dropdownItemStyle ?? {},
            ]),
        },
        OptionContent: {
          style: optionContentStyle,
        },
        InputContainer: {
          style: inputContainerStyle,
        },
        SearchIcon: {
          props: {
            overrides: {
              Svg: {
                component: () => startEnhancer,
                style: searchIconSvgStyle,
              },
            },
          },
        },
        SearchIconContainer: {
          style: searchIconContainerStyle,
        },
        Placeholder: {
          style: placeholderStyle,
        },
      }}
      {...props}
    />
  );
};
