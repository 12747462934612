import { FC, useState, useEffect } from 'react';
import { Service } from 'shared/api';
import {
  useStyletron,
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  CheckIcon,
  Input,
  INPUT_KIND,
  SearchIcon,
  Desktop,
  Mobile,
} from 'shared/ui';
import { formatDistanceStrict, addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const ServiceList: FC<{
  value: Service[];
  options: Service[];
  onClick: (value: Service[]) => void;
}> = ({ value, options, onClick }) => {
  const { t } = useTranslation(['common', 'appointments']);
  const [css, theme] = useStyletron();
  const {
    rootClass,
    searchIconClass,
    listClass,
    itemClass,
    buttonStyle,
    colorLineStyle,
    timeClass,
    checkIconClass,
  } = useStyles();

  const [filteredOptions, setFilteredOptions] = useState<Service[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setFilteredOptions(
      options
        .filter(
          ({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [search, options]);

  const now = new Date();

  return (
    <div className={rootClass}>
      <Input
        placeholder={t('common:SEARCH.SEARCH')}
        value={search}
        onChange={(e) => {
          setSearch((e.target as HTMLInputElement).value);
        }}
        kind={INPUT_KIND.SECONDARY}
        startEnhancer={<SearchIcon className={searchIconClass} />}
      />

      <Desktop>
        {filteredOptions.length === 0 ? (
          <p>{t('common:SEARCH.NO_RECORD_FOUND')}</p>
        ) : (
          <p>{t('appointments:CREATE.SELECT_A_SERVICE')}</p>
        )}
      </Desktop>
      <Mobile>
        {filteredOptions.length === 0 && (
          <p>{t('common:SEARCH.NO_RECORD_FOUND')}</p>
        )}
      </Mobile>

      <ul className={listClass}>
        {filteredOptions.map((option) => {
          const { id, name, duration, booking_color: color } = option;
          const dur = formatDistanceStrict(now, addMinutes(now, duration));

          return (
            <li key={id} className={itemClass}>
              <Button
                kind={BUTTON_KIND.SECONDARY}
                size={BUTTON_SIZE.LARGE}
                type="button"
                onClick={() => onClick([option])}
                style={buttonStyle}
              >
                <span
                  className={css({
                    ...colorLineStyle,
                    backgroundColor: color
                      ? theme.colors[`${color as 'accent'}500`]
                      : theme.colors.gray500,
                  })}
                />
                <span>{name}</span>
                <span className={timeClass}>{dur}</span>
              </Button>
              {option.id === value[0]?.id && (
                <CheckIcon className={checkIconClass} />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
