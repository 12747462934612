import { createStore, createEvent, sample } from 'effector';
import { Therapist, TherapistFilters } from '../Model';
import { fetchTherapistsFx, getTherapistsEvent } from './use-get-therapists';

/* ******* Events ******* */
export const setFilters = createEvent<TherapistFilters>();
export const setLocalFilters = createEvent<TherapistFilters>();
export const setTherapists = createEvent<Therapist[]>();

/* ******* Stores ******* */
// Store for filters
export const $filters = createStore<TherapistFilters>({
  // set initial value to be different than localFilters to trigger fetch
  gender: ['M', 'F', 'U', 'O'],
  service: [],
  licensed_states: [],
}).on(setFilters, (_, filters) => filters);

// Store for local filters
export const $localFilters = createStore<TherapistFilters>({
  gender: [],
  service: [],
  licensed_states: [],
}).on(setLocalFilters, (_, filters) => filters);

// Store for therapists
export const $therapists = createStore<Therapist[]>([])
  .on(setTherapists, (_, therapists) => {
    console.log('updating the store via setTherapists');
    return therapists;
  })
  .on(fetchTherapistsFx.doneData, (_, therapists) => {
    console.log('updating $therapists via fetchTherapistFx.doneData');
    return therapists;
  });

/* ******* Utilities ******* */
// Utility function to compare filters (shallow comparison)
const filtersChanged = (
  prev: TherapistFilters,
  current: TherapistFilters
): boolean =>
  JSON.stringify(prev.gender) !== JSON.stringify(current.gender) ||
  JSON.stringify(prev.service) !== JSON.stringify(current.service) ||
  JSON.stringify(prev.licensed_states) !==
    JSON.stringify(current.licensed_states);

/* ******* Actions ******* */
// Sample to fetch therapists only when filters have changed
sample({
  // when getTherapistEvent is triggered
  clock: getTherapistsEvent,
  // read state of $localFilters and $filters stores
  source: { filters: $localFilters, previous: $filters },
  // and if previous does not match filters
  filter: ({ filters, previous }) => filtersChanged(previous, filters),
  // then pass filters to fetchTherapistFx
  fn: ({ filters }) => filters,
  // and execute the request (effect)
  target: [setFilters, fetchTherapistsFx],
});
