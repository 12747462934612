import { FC, ReactElement, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSharedUser } from '.';
import { SharedContext } from './shared-provider';

function usePermissions(): { permissions: string[]; roles: string[] } {
  const context = useContext(SharedContext);
  if (context === undefined) {
    throw new Error('usePermissions must be used within a SharedContext');
  }
  const [state] = context;
  return { permissions: state.permissions, roles: state.roles };
}

export const useHasPermissions = (permissions: string[]) => {
  const { permissions: userPermissions } = usePermissions();
  const { user } = useSharedUser();
  if (!permissions.length || user?.is_admin) {
    return true;
  }

  return permissions.every((permission) =>
    userPermissions.includes(permission)
  );
};

export const useHasAnyPermissions = (permissions: string[]) => {
  const { permissions: userPermissions } = usePermissions();
  const { user } = useSharedUser();
  if (!permissions.length || user?.is_admin) {
    return true;
  }

  return permissions.some((permission) => userPermissions.includes(permission));
};

export const useHasRoles = (roles: string[]) => {
  let { roles: userRoles } = usePermissions();
  const { user } = useSharedUser();
  if (user?.is_admin) {
    userRoles = ['Admin'];
  }
  if (!roles.length) {
    return true;
  }

  return roles.every((role) => userRoles.includes(role));
};

export const useHasAnyRoles = (roles: string[]) => {
  let { roles: userRoles } = usePermissions();
  const { user } = useSharedUser();

  if (user?.is_admin) {
    userRoles = [...userRoles, 'Admin'];
  }
  if (!roles.length) {
    return true;
  }
  return roles.some((role) => userRoles.includes(role));
};

export const PermissionsGate: FC<{
  permissions?: string[];
  roles?: string[];
  anyRolesOf?: string[];
  redirectLink?: string;
}> = ({
  children,
  roles = [],
  permissions = [],
  anyRolesOf = [],
  redirectLink = '/',
}) => {
  const { user } = useSharedUser();

  const permissionGranted = useHasPermissions(permissions);
  const rolesGranted = useHasRoles(roles);
  const anyRolesGranted = useHasAnyRoles(anyRolesOf);

  if (!user) {
    return null;
  }

  if (!permissionGranted || !rolesGranted || !anyRolesGranted) {
    return <Navigate to={redirectLink} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export const PermissionRender: FC<{
  permissions?: string[];
  roles?: string[];
  anyRolesOf?: string[];
  fallbackRender?: ReactElement;
}> = ({
  children,
  roles = [],
  permissions = [],
  anyRolesOf = [],
  fallbackRender = null,
}) => {
  const permissionGranted = useHasPermissions(permissions);
  const rolesGranted = useHasRoles(roles);
  const anyRolesGranted = useHasAnyRoles(anyRolesOf);

  if (!permissionGranted || !rolesGranted || !anyRolesGranted) {
    return fallbackRender;
  }

  return <>{children}</>;
};
