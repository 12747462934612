import { StyledSpinnerNext } from 'baseui/spinner';
import { styled, useStyletron } from 'shared/ui';

export const SpinnerWrapper = styled('div', {
  position: 'absolute',
  top: '-2px',
  left: '12px',
});

export const Spinner = styled(StyledSpinnerNext, {
  width: '20px',
  height: '20px',
});

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const getContainerClass = (
    isActive: boolean,
    isDisabled: boolean,
    isLoading: boolean,
    displayLoading: boolean
  ) =>
    css({
      position: 'relative',
      flexShrink: 0,
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '44px',
      height: '16px',
      borderRadius: '100px',
      backgroundColor: 'currentColor',
      color:
        isDisabled || (isLoading && displayLoading)
          ? theme.colors.gray200
          : isActive
          ? theme.colors.green500
          : theme.colors.red500,
      transition: 'color 0.1s ease-out',
      cursor: 'pointer',
      pointerEvents: isLoading ? 'none' : 'auto',

      ':hover > div': {
        boxShadow: '0 0 0 3px rgba(55, 65, 81, 0.32)',
      },
    });

  const inputClass = css({
    visibility: 'hidden',
    opacity: 0,
  });

  const getThumbClass = (isActive: boolean) =>
    css({
      position: 'absolute',
      top: '50%',
      left: isActive ? 'calc(100% - 24px)' : 0,
      transform: 'translateY(-50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      boxShadow: '0 0 0 3px rgba(55, 65, 81, 0.16)',
      backgroundColor: theme.colors.white900,
      transitionDuration: '0.1s',
      transitionTimingFunction: 'ease-out',
      transitionProperty: 'left, box-shadow',
    });

  const getHoleClass = (isDisabled: boolean) =>
    css({
      width: '2px',
      height: '8px',
      borderRadius: '100px',
      backgroundColor: isDisabled ? theme.colors.gray700 : 'currentColor',
      transition: 'background-color 0.1s ease-out',
    });

  return {
    getContainerClass,
    inputClass,
    getThumbClass,
    getHoleClass,
  };
};
