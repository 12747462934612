import { LayersManager } from 'baseui/layer';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { Desktop } from 'shared/ui';
import { SidebarPanelEvent, SidebarPanelProps } from './model';
import { useStyles } from './styles';

export const SidebarPanel: FC<SidebarPanelProps> = ({
  children,
  onClose,
  isCatchCloseEvent = true,
}) => {
  const { pathname } = useLocation();
  const openedLocation = useRef<string | null>(null);
  const { rootClass, panelBg, panelContent } = useStyles();
  const [panelRoot] = useState(document.createElement('div'));
  const handleBackgroundClick = useCallback(() => {
    if (onClose && isCatchCloseEvent) {
      onClose(SidebarPanelEvent.BackgroundClickEvent);
    }
  }, [onClose, isCatchCloseEvent]);
  const sidebarPanelMarkup = useMemo(
    () => (
      <LayersManager zIndex={101}>
        <Desktop>
          <div
            className={panelBg}
            onClick={handleBackgroundClick}
            aria-hidden="true"
          />
        </Desktop>
        <div className={panelContent}>{children}</div>
      </LayersManager>
    ),
    [children, handleBackgroundClick, panelBg, panelContent]
  );

  const catchESC = useCallback(
    (e) => {
      if (e.code === 'Escape' && onClose && isCatchCloseEvent) {
        onClose(SidebarPanelEvent.KeyboardEvent);
      }
    },
    [onClose, isCatchCloseEvent]
  );

  useEffect(() => {
    openedLocation.current = pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname !== openedLocation.current) {
      onClose?.(SidebarPanelEvent.LocationChange);
    }
  }, [pathname, openedLocation, onClose]);

  useEffect(() => {
    panelRoot.className = rootClass;
    document.body.appendChild(panelRoot);
    return () => {
      document.body.removeChild(panelRoot);
    };
  }, [rootClass, panelRoot]);

  useEffect(() => {
    document.documentElement.classList.add('is-fixed_sidebar');

    return () => {
      document.removeEventListener('keyup', catchESC);
    };
  }, [catchESC]);

  return createPortal(sidebarPanelMarkup, panelRoot);
};
