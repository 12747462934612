import {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  DatePicker,
  Desktop,
  FormControl,
  Mobile,
  Select,
  TimePicker,
} from 'shared/ui';
import { useUserAvailabilityLazyQuery } from 'shared/api';
import {
  addDays,
  addMinutes,
  format,
  isAfter,
  isEqual,
  isToday,
  nextMonday,
  startOfDay,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { StyledSpinnerNext } from 'baseui/spinner';
import { getUserZonedDateNow } from 'shared/lib';
import { DateTimeProps } from './model';
import { useStyles } from './styles';

export const DateTime: FC<DateTimeProps> = forwardRef<
  HTMLDivElement,
  DateTimeProps
>(
  (
    {
      value,
      onChange,
      service,
      hostId = null,
      isShowHeader = true,
      canSelectDisabled = true,
      width = 'auto',
      hasError = false,
      isAnyHost = false,
      showAlerts = true,
      isClosestTime = false,
      meetNow = false,
      onMeetNow,
      bookingLocation = null,
    },
    ref
  ) => {
    const { t } = useTranslation(['appointments']);
    const {
      rootClass,
      headerClass,
      titleClass,
      meetNowButtonStyle,
      getBodyClass,
      dateContainerClass,
      timeContainerClass,
      timeColumnClass,
      getFormControlOverrides,
      formControlStyle,
      spinnerWrapperClass,
      timeRowClass,
    } = useStyles();

    const didMountRef = useRef(false);
    const [date, setDate] = useState<Date>(value ?? getUserZonedDateNow());
    const [time, setTime] = useState<Date | null>(value);
    const [isDoubleBooked, setIsDoubleBooked] = useState(false);
    const [isAllUnavailable, setIsAllUnavailable] = useState(false);
    const [isOutsideWorkingHours, setIsOutsideWorkingHours] = useState(false);
    const [laterTime, setLaterTime] = useState<Date>();

    const [
      userAvailability,
      { data: userAvailabilityResponse, loading: isUserAvailabilityLoading },
    ] = useUserAvailabilityLazyQuery();

    useEffect(() => {
      userAvailability({
        variables: {
          date,
          service: service.id,
          host: hostId,
          location: bookingLocation ?? null,
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service, userAvailability, hostId, bookingLocation]);

    useEffect(() => {
      userAvailability({
        variables: {
          date,
          service: service.id,
          host: hostId,
          location: bookingLocation ?? null,
        },
      });

      if (didMountRef.current) {
        onChange(null);
        setTime(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, hostId, service, userAvailability, bookingLocation]);

    useEffect(() => {
      onChange(time);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

    useEffect(() => {
      didMountRef.current = true;
    }, []);

    const closestTime = useMemo(() => {
      if (!isClosestTime) {
        return undefined;
      }
      return userAvailabilityResponse?.userAvailability?.find(
        ({ time: slotTime, available }) =>
          isAfter(slotTime.date, getUserZonedDateNow()) &&
          canSelectDisabled &&
          available
      )?.time;
    }, [
      canSelectDisabled,
      isClosestTime,
      userAvailabilityResponse?.userAvailability,
    ]);

    useEffect(() => {
      if (closestTime && isToday(closestTime.date) && !meetNow && !time) {
        setTime(closestTime.date);
      }
    }, [closestTime, meetNow, time]);

    useEffect(() => {
      const laterValue = userAvailabilityResponse?.userAvailability?.find(
        ({ time: slotTime }) =>
          value && isEqual(slotTime.date, addMinutes(value, 60))
      )?.time;

      const activeSlot = userAvailabilityResponse?.userAvailability?.find(
        ({ time: slotTime }) => value && isEqual(slotTime.date, value)
      );

      const isEveryUnavailable =
        userAvailabilityResponse?.userAvailability?.every(
          ({ available, time: slotTime }) =>
            !isAfter(slotTime.date, getUserZonedDateNow()) || !available
        ) || false;

      setLaterTime(laterValue?.date);

      if (activeSlot) {
        setIsDoubleBooked(!activeSlot.available && activeSlot.is_appointment);
        setIsOutsideWorkingHours(
          !activeSlot.available && activeSlot.outside_working_hours
        );
      }

      setIsAllUnavailable(isEveryUnavailable);
    }, [userAvailabilityResponse?.userAvailability, value, date]);

    useEffect(() => {
      if (meetNow) {
        setTime(getUserZonedDateNow());
      }
    }, [date, meetNow]);

    const timeOptions = useMemo(
      () =>
        userAvailabilityResponse?.userAvailability?.map(
          ({ time: { date: slotDate }, available }) => ({
            id: slotDate.toString(),
            label: `${format(slotDate, 'h:mm aaa')} - ${format(
              addMinutes(slotDate, service.duration || 0),
              'h:mm aaa'
            )}`,
            disabled: !available || slotDate < getUserZonedDateNow(),
          })
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [userAvailabilityResponse?.userAvailability]
    );

    const currentOption = useMemo(
      () => timeOptions?.find((option) => option.id === time?.toString()),
      [timeOptions, time]
    );

    const MeetNowButton = useCallback(
      () => (
        <Button
          size={BUTTON_SIZE.MEDIUM}
          kind={meetNow ? BUTTON_KIND.PRIMARY : BUTTON_KIND.SECONDARY}
          type="button"
          style={meetNowButtonStyle}
          onClick={() => {
            if (onMeetNow) {
              onMeetNow(true);
            }
            setDate(getUserZonedDateNow());
          }}
          isLoading={isUserAvailabilityLoading}
        >
          {t('appointments:CREATE.MEET_NOW')}
        </Button>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [closestTime, date, isUserAvailabilityLoading, meetNowButtonStyle, t]
    );

    return (
      <div className={rootClass} ref={ref}>
        {isShowHeader && (
          <header className={headerClass}>
            <h4 className={titleClass}>
              {t('appointments:CREATE.DATE_AND_TIME')}
            </h4>
          </header>
        )}
        <div className={getBodyClass(width)}>
          <div className={dateContainerClass}>
            <DatePicker
              hostId={hostId}
              value={date}
              isAllDayAvailable={isAnyHost}
              onChange={(dateValue) => {
                if (onMeetNow) {
                  onMeetNow(false);
                }
                setDate(dateValue as Date);
              }}
              minDate={getUserZonedDateNow()}
              canSelectNotAvailable={canSelectDisabled}
            />
          </div>
          <div className={timeContainerClass}>
            <div className={timeColumnClass}>
              {showAlerts && isAllUnavailable && (
                <Alert
                  type="error"
                  title={t('appointments:CREATE.NO_TIMESLOTS')}
                  text={t('appointments:CREATE.NO_AVAILABLE')}
                >
                  <button
                    onClick={() => setDate(startOfDay(addDays(date, 1)))}
                    type="button"
                  >
                    {t('appointments:CREATE.NEXT_DAY')}
                  </button>
                  <button
                    onClick={() => setDate(nextMonday(date))}
                    type="button"
                  >
                    {t('appointments:CREATE.NEXT_WEEK')}
                  </button>
                </Alert>
              )}

              {!isUserAvailabilityLoading ? (
                <FormControl
                  error={
                    hasError
                      ? t('appointments:ERRORS.SELECT_TIMESLOT')
                      : undefined
                  }
                  overrides={getFormControlOverrides(hasError)}
                  style={formControlStyle}
                >
                  <>
                    <Desktop>
                      {isClosestTime && <MeetNowButton />}
                      <TimePicker
                        value={time}
                        duration={service.duration}
                        options={userAvailabilityResponse?.userAvailability}
                        onChange={(timeValue) => {
                          if (onMeetNow) {
                            onMeetNow(false);
                          }
                          setTime(timeValue);
                        }}
                        canSelectNotAvailable={canSelectDisabled}
                      />
                    </Desktop>

                    <Mobile>
                      <div className={timeRowClass}>
                        <Select
                          placeholder={t('appointments:CREATE.SELECT_TIME')}
                          options={timeOptions}
                          value={currentOption && [currentOption]}
                          onChange={({ option }) => {
                            if (!option?.id) {
                              return;
                            }
                            setTime(new Date(option.id));
                          }}
                          hasError={hasError}
                          isAvailableChooseDisabled={canSelectDisabled}
                        />
                        {isClosestTime && <MeetNowButton />}
                      </div>
                    </Mobile>
                  </>
                </FormControl>
              ) : (
                <div className={spinnerWrapperClass}>
                  <StyledSpinnerNext />
                </div>
              )}
            </div>
          </div>
        </div>

        <Desktop>
          <p>
            {format(date, 'EEEE, do')} of {format(date, 'MMMM')}
            {!!time &&
              !!service &&
              ` from ${format(time, 'h:mm aaa')} — ${format(
                addMinutes(time, service.duration || 0),
                'h:mm aaa'
              )}`}
          </p>
        </Desktop>

        {showAlerts ? (
          isDoubleBooked ? (
            <Alert
              type="warning"
              title={t('appointments:CREATE.ATTENTION_NEEDED')}
              text={t('appointments:CREATE.DOUBLE_BOOKED')}
            >
              {laterTime && (
                <button onClick={() => setTime(laterTime)} type="button">
                  {t('appointments:CREATE.HOUR_LATER')}
                </button>
              )}
              <button onClick={() => setIsDoubleBooked(false)} type="button">
                {t('appointments:CREATE.DISMISS')}
              </button>
            </Alert>
          ) : isOutsideWorkingHours ? (
            <Alert
              type="warning"
              title={t('appointments:CREATE.ATTENTION_NEEDED')}
              text={t('appointments:CREATE.OUTSIDE_WORKING_HOURS')}
            >
              <button onClick={() => setIsDoubleBooked(false)} type="button">
                {t('appointments:CREATE.DISMISS')}
              </button>
            </Alert>
          ) : null
        ) : null}
      </div>
    );
  }
);
