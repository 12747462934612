import { ReactElement } from 'react';

export enum INFO_MODAL_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  USER_ERROR = 'user-error',
}

export interface InfoModalProps {
  type?: INFO_MODAL_TYPE;
  title?: string;
  text?: string;
  buttons?: ReactElement;
  noClose?: boolean;
  isHideCloseButton?: boolean;
}
