import { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { format, isEqual, isAfter, addMinutes } from 'date-fns';
import { Button, BUTTON_KIND, BUTTON_SIZE } from 'shared/ui';
import { AvailabilitySlot } from 'shared/api';
import { useTranslation } from 'react-i18next';
import { getUserZonedDateNow } from 'shared/lib';
import { SlotListInterface, TimePickerProps } from './model';
import { TimeSlot } from './time-slot';
import { useSlots } from './use-slots';
import { Root, Title, List, ListWide, useStyles } from './styles';

export const TimePicker: FC<TimePickerProps> = ({
  value,
  options,
  onChange,
  canSelectNotAvailable = true,
  duration = 0,
}) => {
  const { toggleButtonStyles } = useStyles();
  const { t } = useTranslation(['appointments']);
  const [isEarlier, setIsEarlier] = useState(false);
  const [isLater, setIsLater] = useState(false);
  const FORMAT = 'h:mm aaa';
  const { afternoon, earlier, evening, later, morning } = useSlots(options);

  const isSlotWide = useMemo(() => duration >= 60, [duration]);
  const ListComponent = useMemo(
    () => (isSlotWide ? ListWide : List),
    [isSlotWide]
  );

  const renderList = useCallback(
    (data: AvailabilitySlot[]) => (
      <ListComponent>
        {data.map(({ time: { date }, available }) => {
          const timeRange = `${format(date, FORMAT)} - ${format(
            addMinutes(date, duration),
            FORMAT
          )}`;

          return (
            <li key={date.toString()}>
              <TimeSlot
                onClick={() => onChange(date)}
                isAvailable={isAfter(date, getUserZonedDateNow()) && available}
                isActive={!!value && isEqual(date, value)}
                canSelectNotAvailable={
                  isAfter(date, getUserZonedDateNow()) && canSelectNotAvailable
                }
              >
                {isSlotWide ? timeRange : format(date, FORMAT)}
              </TimeSlot>
            </li>
          );
        })}
      </ListComponent>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canSelectNotAvailable, onChange, value]
  );

  const slotBlocks = useMemo(() => {
    const slotsList: SlotListInterface[] = [
      {
        title: t('appointments:CREATE.MORNING'),
        displayCondition: earlier.length > 0 || morning.length > 0,
        data: isEarlier ? [...earlier, ...morning] : morning,
        before:
          earlier.length > 0 && !isEarlier ? (
            <Button
              size={BUTTON_SIZE.SMALL}
              kind={BUTTON_KIND.SECONDARY}
              type="button"
              style={toggleButtonStyles()}
              onClick={() => setIsEarlier(true)}
            >
              {t('appointments:CREATE.EARLIER')}
            </Button>
          ) : null,
      },
      {
        title: t('appointments:CREATE.AFTERNOON'),
        data: afternoon,
      },
      {
        title: t('appointments:CREATE.EVENING'),
        data: isLater ? [...evening, ...later] : evening,
        after:
          later.length > 0 && !isLater ? (
            <Button
              size={BUTTON_SIZE.SMALL}
              kind={BUTTON_KIND.SECONDARY}
              type="button"
              style={toggleButtonStyles(true)}
              onClick={() => setIsLater(true)}
            >
              {t('appointments:CREATE.LATER')}
            </Button>
          ) : null,
      },
    ];

    return slotsList.map(
      ({ data, displayCondition, title, before, after }, slotBlockIndex) => {
        // By default, check data array length
        if (
          typeof displayCondition !== 'undefined'
            ? !displayCondition
            : data.length === 0
        ) {
          return null;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`slot-block-${slotBlockIndex}`}>
            {title ? <Title>{title}</Title> : null}
            {before}
            {renderList(data)}
            {after}
          </div>
        );
      }
    );
  }, [
    afternoon,
    earlier,
    evening,
    isEarlier,
    isLater,
    later,
    morning,
    renderList,
    t,
    toggleButtonStyles,
  ]);

  useEffect(() => {
    if (!morning.length) {
      setIsEarlier(true);
    }
  }, [morning]);

  useEffect(() => {
    if (!evening.length) {
      setIsLater(true);
    }
  }, [evening]);

  return <Root>{slotBlocks}</Root>;
};
