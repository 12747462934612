import { useContext } from 'react';
import { ConfirmContext } from './provider';

export const useConfirm = () => {
  const context = useContext(ConfirmContext);

  if (!context) {
    throw new Error('useConfirmManager must be used within a ConfirmContext');
  }
  return {
    confirm: context.confirm,
    close: context.close,
    setLoading: context.setLoading,
  };
};
