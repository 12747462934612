import { FC } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { mergeDeepRight, reduce } from 'ramda';
import { StyleObject } from 'styletron-standard';

export const BREAKPOINTS = {
  md: 1024,
};

export const IS_NO_TOUCH = '@media (hover: hover) and (pointer: fine)' as const;

export const DESKTOP =
  `@media screen and (min-width: ${BREAKPOINTS.md}px)` as const;
export const MOBILE = `@media screen and (max-width: ${
  BREAKPOINTS.md - 1
}px)` as const;

export const Desktop: FC = ({ children }) => (
  <MediaQuery minWidth={BREAKPOINTS.md}>{children}</MediaQuery>
);

export const Mobile: FC = ({ children }) => (
  <MediaQuery maxWidth={BREAKPOINTS.md - 1}>{children}</MediaQuery>
);

export const useDesktopMediaQuery = () =>
  useMediaQuery({ minWidth: BREAKPOINTS.md });

export const useMobileMediaQuery = () =>
  useMediaQuery({ maxWidth: BREAKPOINTS.md - 1 });

export const mergeStyles = reduce<StyleObject, StyleObject>(mergeDeepRight, {});
