import { FC, useState, useRef } from 'react';
import {
  useStyletron,
  TEXT,
  Checkbox,
  MOBILE,
  ChevronDownIcon,
} from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { Transition, TransitionStatus } from 'react-transition-group';
import { difference } from 'ramda';

export type CheckgroupOptions = {
  id: string;
  name: string;
  notice: string;
}[];

interface CheckgroupProps {
  title: string;
  options?: CheckgroupOptions;
  value?: string[];
  onChange: (value?: string[]) => void;
}

export const Checkgroup: FC<CheckgroupProps> = ({
  title,
  options = [],
  value = [],
  onChange,
}) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const allChecked = options?.every(({ id }) => value?.includes(id));
  const isIndeterminate =
    options?.some(({ id }) => value?.includes(id)) && !allChecked;

  const duration = 200;
  const dropdownHeight = `${dropdownRef.current?.offsetHeight || 0}px`;

  const transitionStyles = {
    entering: { maxHeight: dropdownHeight },
    entered: { maxHeight: dropdownHeight },
    exiting: { maxHeight: 0 },
    exited: { maxHeight: 0 },
  } as { [key in TransitionStatus]: StyleObject };

  return (
    <div>
      <div
        className={css({
          position: 'relative',
          padding: '16px',
          border: `1px solid ${theme.colors.gray200}`,
          backgroundColor: theme.colors.gray50,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottomLeftRadius: isOpen ? 0 : '8px',
          borderBottomRightRadius: isOpen ? 0 : '8px',
          cursor: 'pointer',
        })}
      >
        <button
          className={css({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            paddingLeft: '44px',
            ...TEXT.base_medium,
          })}
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          {title}
        </button>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <div
            className={css({
              position: 'relative',
            })}
          >
            <Checkbox
              onChange={() => {
                const currentIds = options.map(({ id }) => id);
                const ids = allChecked
                  ? difference(value, currentIds)
                  : [...value, ...currentIds];
                onChange(ids);
              }}
              isIndeterminate={isIndeterminate}
              checked={allChecked}
            />
          </div>
          <ChevronDownIcon
            className={css({
              width: '24px',
              color: theme.colors.gray400,
              transform: isOpen ? 'rotate(-180deg)' : 'none',
              transition: `transform ${duration}ms ease-in-out`,
              pointerEvents: 'none',
            })}
          />
        </div>
      </div>
      <Transition in={isOpen} timeout={duration}>
        {(state) => (
          <div
            style={{
              maxHeight: 0,
              overflow: 'hidden',
              transition: `max-height ${duration}ms ease-in-out`,
              ...transitionStyles[state],
            }}
          >
            <ul
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                padding: '16px',
                border: `1px solid ${theme.colors.gray200}`,
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              })}
              ref={dropdownRef}
            >
              {options?.map(({ id, name, notice }) => (
                <li
                  key={id}
                  className={css({
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '16px',

                    [MOBILE]: {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '4px',
                    },
                  })}
                >
                  <Checkbox
                    onChange={() => {
                      if (!value) {
                        onChange();
                        return;
                      }
                      const ids = value.includes(id)
                        ? value.filter((valueId) => valueId !== id)
                        : [...value, id];
                      onChange(ids);
                    }}
                    checked={value?.includes(id)}
                  >
                    {name}
                  </Checkbox>
                  {notice && (
                    <span
                      className={css({
                        ...TEXT.sm_book,
                        color: theme.colors.red500,
                      })}
                    >
                      {notice}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Transition>
    </div>
  );
};
