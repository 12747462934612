import { useMemo } from 'react';
import { useStyletron, DESKTOP, MOBILE } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();
  const rootClass = useMemo(
    () =>
      css({
        position: 'relative',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        backgroundColor: theme.colors.tableBackground,
        transform: 'scale(1)',

        [DESKTOP]: {
          borderWidth: '0.5px',
          borderStyle: 'solid',
          borderColor: theme.colors.gray200,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        },
        [MOBILE]: {
          marginLeft: '-16px',
          marginRight: '-16px',
        },
      }),
    [theme, css]
  );

  return { rootClass };
};
