import { styled } from 'shared/ui';

export const Container = styled('div', () => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  ':hover > div': {
    opacity: 1,
  },
}));

export const Field = styled('div', () => ({
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '95%',
}));

export const Placeholder = styled('div', ({ $theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  color: $theme.colors.gray300,
  zIndex: 1,
  whiteSpace: 'nowrap',
}));

export const Input = styled('input', () => ({
  backgroundColor: 'transparent',
  border: 0,
  outline: 0,
  position: 'relative',
  zIndex: 2,
}));

export const Icon = styled('div', () => ({
  opacity: 0.5,
  transition: 'all 0.2s',
  marginLeft: '5px',
}));
