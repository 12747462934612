export const FONT = {
  size_xs: 'var(--tru-fs-xs)',
  size_sm: 'var(--tru-fs-sm)',
  size_base: 'var(--tru-fs-base)',
  size_lg: 'var(--tru-fs-lg)',
  size_xl: 'var(--tru-fs-xl)',
  size_2xl: 'var(--tru-fs-2xl)',
  size_3xl: 'var(--tru-fs-3xl)',
  size_4xl: 'var(--tru-fs-4xl)',
  size_5xl: 'var(--tru-fs-5xl)',
  size_6xl: 'var(--tru-fs-6xl)',

  height_xs: 'var(--tru-lh-xs)',
  height_sm: 'var(--tru-lh-sm)',
  height_base: 'var(--tru-lh-base)',
  height_lg: 'var(--tru-lh-lg)',
  height_xl: 'var(--tru-lh-xl)',
  height_2xl: 'var(--tru-lh-2xl)',
  height_3xl: 'var(--tru-lh-3xl)',
  height_4xl: 'var(--tru-lh-4xl)',
  height_5xl: 'var(--tru-lh-5xl)',
  height_6xl: 'var(--tru-lh-6xl)',

  weight_normal: 'var(--tru-fw-normal)',
  weight_medium: 'var(--tru-fw-medium)',
  weight_bold: 'var(--tru-fw-bold)',
};

export const TEXT = {
  xs_book: {
    fontSize: FONT.size_xs,
    lineHeight: FONT.height_xs,
    fontWeight: FONT.weight_normal,
  },
  xs_medium: {
    fontSize: FONT.size_xs,
    lineHeight: FONT.height_xs,
    fontWeight: FONT.weight_medium,
  },
  xs_bold: {
    fontSize: FONT.size_xs,
    lineHeight: FONT.height_xs,
    fontWeight: FONT.weight_bold,
  },

  sm_book: {
    fontSize: FONT.size_sm,
    lineHeight: FONT.height_sm,
    fontWeight: FONT.weight_normal,
  },
  sm_medium: {
    fontSize: FONT.size_sm,
    lineHeight: FONT.height_sm,
    fontWeight: FONT.weight_medium,
  },
  sm_bold: {
    fontSize: FONT.size_sm,
    lineHeight: FONT.height_sm,
    fontWeight: FONT.weight_bold,
  },

  base_book: {
    fontSize: FONT.size_base,
    lineHeight: FONT.height_base,
    fontWeight: FONT.weight_normal,
  },
  base_medium: {
    fontSize: FONT.size_base,
    lineHeight: FONT.height_base,
    fontWeight: FONT.weight_medium,
  },
  base_bold: {
    fontSize: FONT.size_base,
    lineHeight: FONT.height_base,
    fontWeight: FONT.weight_bold,
  },

  lg_book: {
    fontSize: FONT.size_lg,
    lineHeight: FONT.height_lg,
    fontWeight: FONT.weight_normal,
  },
  lg_medium: {
    fontSize: FONT.size_lg,
    lineHeight: FONT.height_lg,
    fontWeight: FONT.weight_medium,
  },
  lg_bold: {
    fontSize: FONT.size_lg,
    lineHeight: FONT.height_lg,
    fontWeight: FONT.weight_bold,
  },

  xl_book: {
    fontSize: FONT.size_xl,
    lineHeight: FONT.height_xl,
    fontWeight: FONT.weight_normal,
  },
  xl_medium: {
    fontSize: FONT.size_xl,
    lineHeight: FONT.height_xl,
    fontWeight: FONT.weight_medium,
  },
  xl_bold: {
    fontSize: FONT.size_xl,
    lineHeight: FONT.height_xl,
    fontWeight: FONT.weight_bold,
  },

  xl2_book: {
    fontSize: FONT.size_2xl,
    lineHeight: FONT.height_2xl,
    fontWeight: FONT.weight_normal,
  },
  xl2_medium: {
    fontSize: FONT.size_2xl,
    lineHeight: FONT.height_2xl,
    fontWeight: FONT.weight_medium,
  },
  xl2_bold: {
    fontSize: FONT.size_2xl,
    lineHeight: FONT.height_2xl,
    fontWeight: FONT.weight_bold,
  },

  xl3_book: {
    fontSize: FONT.size_3xl,
    lineHeight: FONT.height_3xl,
    fontWeight: FONT.weight_normal,
  },
  xl3_medium: {
    fontSize: FONT.size_3xl,
    lineHeight: FONT.height_3xl,
    fontWeight: FONT.weight_medium,
  },
  xl3_bold: {
    fontSize: FONT.size_3xl,
    lineHeight: FONT.height_3xl,
    fontWeight: FONT.weight_bold,
  },

  xl4_book: {
    fontSize: FONT.size_4xl,
    lineHeight: FONT.height_4xl,
    fontWeight: FONT.weight_normal,
  },
  xl4_medium: {
    fontSize: FONT.size_4xl,
    lineHeight: FONT.height_4xl,
    fontWeight: FONT.weight_medium,
  },
  xl4_bold: {
    fontSize: FONT.size_4xl,
    lineHeight: FONT.height_4xl,
    fontWeight: FONT.weight_bold,
  },

  xl5_book: {
    fontSize: FONT.size_5xl,
    lineHeight: FONT.height_5xl,
    fontWeight: FONT.weight_normal,
  },
  xl5_medium: {
    fontSize: FONT.size_5xl,
    lineHeight: FONT.height_5xl,
    fontWeight: FONT.weight_medium,
  },
  xl5_bold: {
    fontSize: FONT.size_5xl,
    lineHeight: FONT.height_5xl,
    fontWeight: FONT.weight_bold,
  },

  xl6_book: {
    fontSize: FONT.size_6xl,
    lineHeight: FONT.height_6xl,
    fontWeight: FONT.weight_normal,
  },
  xl6_medium: {
    fontSize: FONT.size_6xl,
    lineHeight: FONT.height_6xl,
    fontWeight: FONT.weight_medium,
  },
  xl6_bold: {
    fontSize: FONT.size_6xl,
    lineHeight: FONT.height_6xl,
    fontWeight: FONT.weight_bold,
  },
};
