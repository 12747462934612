import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteIcon,
  EditIcon,
  ExportIcon,
  MenuList,
  useStyletron,
  ContextMenu,
} from 'shared/ui';
import {
  useHasPermissions,
  getConditionalItem,
  useSharedUser,
} from 'shared/lib';
import { Note } from 'shared/api';

export type NoteMenuProps = {
  note: Note;
  onEdit?: () => void;
  onNoteExport?: () => void;
  onRemove?: () => void;
};

export const NoteMenu: FC<NoteMenuProps> = ({
  note,
  children,
  onEdit,
  onNoteExport,
  onRemove,
}) => {
  const { t } = useTranslation(['common', 'appointments']);
  const [css, theme] = useStyletron();
  const canDeleteOwn = useHasPermissions(['note-delete-own']);
  const canDeleteAny = useHasPermissions(['note-delete-any']);
  const canEditOwn = useHasPermissions(['note-edit-own']);
  const { user } = useSharedUser();

  const list: MenuList[] = [
    {
      items: [
        ...getConditionalItem(
          {
            label: t('common:BUTTONS.EDIT'),
            icon: <EditIcon />,
            onClick: onEdit,
          },
          [!!onEdit, canEditOwn]
        ),
        ...getConditionalItem(
          {
            label: t('common:BUTTONS.DOWNLOAD'),
            icon: <ExportIcon />,
            onClick: onNoteExport,
          },
          !!onNoteExport
        ),
      ],
    },
    {
      items: [
        ...getConditionalItem(
          {
            label: t('appointments:VIEW.BUTTONS.REMOVE_NOTE'),
            icon: (
              <DeleteIcon className={css({ color: theme.colors.red500 })} />
            ),
            isDelete: true,
            onClick: onRemove,
          },
          [
            !!onRemove,
            canDeleteAny || (canDeleteOwn && note.owner_id === user?.id),
          ]
        ),
      ],
    },
  ];

  return <ContextMenu list={list}>{children}</ContextMenu>;
};
