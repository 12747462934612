import { FC } from 'react';
import { useStyletron, DocumentIcon } from 'shared/ui';

export enum DOCUMENT_STATUS {
  SUCCESS,
  FAIL,
  NONE,
}

interface DocumentStatusIconProps {
  status?: DOCUMENT_STATUS;
}

export const DocumentStatusIcon: FC<DocumentStatusIconProps> = ({ status }) => {
  const [css, theme] = useStyletron();

  const getStatusColor = () => {
    switch (status) {
      case DOCUMENT_STATUS.SUCCESS:
        return theme.colors.green500;
      case DOCUMENT_STATUS.FAIL:
        return theme.colors.red500;
      default:
        return 'initial';
    }
  };

  return (
    <span
      className={css({
        position: 'relative',
        display: 'block',
        width: '24px',

        '::before': {
          content: '""',
          position: 'absolute',
          top: '-2px',
          left: 0,
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          border: `2px solid ${theme.colors.white900}`,
          backgroundColor: getStatusColor(),
        },
      })}
    >
      <DocumentIcon
        className={css({
          width: '100%',
          color: theme.colors.gray400,
        })}
      />
    </span>
  );
};
