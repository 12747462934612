import { VitalMessage, VitalSensors } from './model';

const VitalSensorsCamelCase = {
  [VitalSensors.BLOOD_PRESSURE]: 'bloodPressure',
  [VitalSensors.BLOOD_OXY]: 'bloodOxy',
  [VitalSensors.TEMPERATURE]: 'temperature',
  [VitalSensors.GLUCOSE]: 'glucose',
  [VitalSensors.ECG]: 'ecg',
};

export const generateVitalObject = (message: VitalMessage) => {
  if (Object.values(VitalSensors).includes(message.typeString)) {
    return { [VitalSensorsCamelCase[message.typeString]]: message.data };
  }

  // eslint-disable-next-line no-console
  console.error('PubNub: Unknown sensor type');
  return {};
};
