import { FC, useRef, useEffect, useState, ReactElement } from 'react';
import { setCaret } from 'shared/lib';
import { Placeholder, Input, Icon, Field, Container } from './styles';
import { EditIcon } from '../icons';

type Props = {
  value?: string;
  onChange: (value: string) => void;
  inputClass?: string;
  placeholder?: string;
  afterField?: ReactElement | boolean;
};

export const EditableText: FC<Props> = ({
  value = '',
  onChange,
  inputClass,
  placeholder,
  afterField,
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const ghostRef = useRef<HTMLDivElement | null>(null);
  const refPlaceholder = useRef<HTMLDivElement | null>(null);
  const [placeholderWidth, setPlaceholderWidth] = useState<number>(0);

  useEffect(() => {
    setPlaceholderWidth(
      (refPlaceholder.current?.getBoundingClientRect()?.width || 0) + 5
    );
  }, [placeholder]);

  const moveCaretLast = () => {
    if (!ref.current) {
      return;
    }

    setCaret(ref.current);
  };

  return (
    <Container>
      <Field style={{ minWidth: `${placeholderWidth}px` }}>
        <Input
          ref={ref}
          className={inputClass}
          style={{
            width: `${ghostRef?.current?.offsetWidth}px`,
            minWidth: `${placeholderWidth}px`,
          }}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
        <div
          ref={ghostRef}
          style={{
            position: 'absolute',
            height: 0,
            overflow: 'hidden',
            whiteSpace: 'pre',
          }}
        >
          {value}
        </div>
        <Placeholder style={{ opacity: !value ? 1 : 0 }} ref={refPlaceholder}>
          {placeholder}
        </Placeholder>
      </Field>
      {afterField}
      <Icon aria-hidden="true" onClick={() => moveCaretLast()}>
        <EditIcon />
      </Icon>
    </Container>
  );
};
