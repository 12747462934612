import { loadOrganization } from 'entities/organization';
import ErrorPage from 'pages/error';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useCurrentOrganizationQuery, GRAPHQL_ERROR } from 'shared/api';
import { logout } from 'shared/lib';
import { Button, Suspender } from 'shared/ui';

import 'entities/organization/init';

const participantPages = [
  '/book',
  '/participant-chats',
  '/account-details',
  '/participant-dashboard',
];

function getFaviconEl() {
  return document.querySelector('#favicon') as HTMLLinkElement;
}

export const CurrentOrganizationProvider: FC = ({ children }) => {
  const { t } = useTranslation(['error', 'common']);
  const [isLoading, setIsLoading] = useState(true);
  const { data, error } = useCurrentOrganizationQuery({
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      loadOrganization(res?.organization ?? null);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const location = useLocation();
  const trackingCodeElements = useRef<Node[]>([]);

  const [isNotFound, isAccessDenied] = useMemo(() => {
    if (!error?.graphQLErrors?.length) {
      return [false, false];
    }
    const code = error.graphQLErrors[0].extensions?.code;
    return [
      code === GRAPHQL_ERROR.NOT_FOUND,
      code === GRAPHQL_ERROR.ACCESS_DENIED,
    ];
  }, [error]);

  useEffect(() => {
    if (data?.organization?.favicon_url || data?.organization?.logo_url) {
      const favicon = getFaviconEl();
      const icon = data.organization.favicon_url ?? data.organization.logo_url;
      if (favicon && icon) {
        const isIcoFormat = icon.getFormat() === 'ico';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const link = isIcoFormat ? icon.get()! : icon.get('16x16')!;
        favicon.href = link;
        favicon.type = isIcoFormat ? 'image/x-icon' : 'image/png';
      }
    }
  }, [data?.organization?.favicon_url, data?.organization?.logo_url]);

  useEffect(() => {
    if (data?.organization?.name) {
      document.title = data.organization?.name;
    }
  }, [data?.organization?.name]);

  const insertTrackingCode = useCallback((code: string) => {
    if (!trackingCodeElements.current.length) {
      const inputEl = new DOMParser().parseFromString(code, 'text/html');
      const scripts = inputEl.querySelectorAll('script');
      scripts.forEach((item) => {
        const script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        const scr = item.src;
        if (scr) {
          script.src = scr;
        }
        script.textContent = item.textContent;
        document.body.appendChild(script);
        trackingCodeElements.current.push(script);
      });
    }
  }, []);

  const removeTrackingCode = useCallback(() => {
    trackingCodeElements.current.forEach((el) => {
      document.body.removeChild(el);
    });
    trackingCodeElements.current = [];
  }, []);

  useEffect(() => {
    const code = data?.organization?.tracking_code;
    if (!code) {
      return;
    }
    if (participantPages.some((path) => location.pathname.startsWith(path))) {
      insertTrackingCode(code);
    } else {
      removeTrackingCode();
    }
  }, [
    insertTrackingCode,
    location.pathname,
    removeTrackingCode,
    data?.organization?.tracking_code,
  ]);

  const LogoutButton = useCallback(
    () => (
      <Button onClick={() => logout()}>{t('common:USER_MENU.LOGOUT')}</Button>
    ),
    [t]
  );

  if (isLoading) {
    return <Suspender />;
  }

  return (
    <>
      {isNotFound ? (
        <ErrorPage
          status={404}
          title={t('error:ORGANIZATION_NOT_FOUND.TITLE')}
          hideOrgInfo
          hideReturnButton
        />
      ) : isAccessDenied ? (
        <ErrorPage
          status={401}
          title={t('error:401.TITLE')}
          text={t('error:401.TEXT')}
          hideOrgInfo
          hideReturnButton
          customButton={<LogoutButton />}
        />
      ) : (
        children
      )}
    </>
  );
};
