export enum PageActions {
  NEXT_PAGE = 'NEXT_PAGE',
  PREVIOUS_PAGE = 'PREVIOUS_PAGE',
  SET_PAGE = 'SET_PAGE',
  SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS',
  SET_PAGE_SIZE = 'SET_PAGE_SIZE',
}

export type CurrentPageActions =
  | { type: PageActions.NEXT_PAGE }
  | { type: PageActions.PREVIOUS_PAGE }
  | { type: PageActions.SET_PAGE; page: number };

export type TotalItemsActions = {
  type: PageActions.SET_TOTAL_ITEMS;
  totalItems: number;
};

export type PageSizeActions = {
  type: PageActions.SET_PAGE_SIZE;
  pageSize: number;
};

export type PaginationStateReducerActions =
  | CurrentPageActions
  | TotalItemsActions
  | PageSizeActions;

export type PaginationState = {
  totalItems: number;
  pageSize: number;
  currentPage: number;
};

export type PaginationMeta = {
  totalPages: number;
  startIndex: number;
  endIndex: number;
  previousEnabled: boolean;
  nextEnabled: boolean;
};

export type PaginationActions = {
  setPage: (page: number) => void;
  setNextPage: () => void;
  setPreviousPage: () => void;
  setPageSize: (pageSize: number, nextPage?: number) => void;
};
