import { FC, forwardRef } from 'react';
import { Checkbox as BaseCheckbox } from 'baseui/checkbox';
import { useStyletron, CheckIcon } from 'shared/ui';
import { Overrides } from 'baseui/overrides';
import { CheckboxProps, CHECKBOX_SIZE } from './model';
import { CHECKBOX_SIZE_STYLES } from './util';

export const Checkbox: FC<CheckboxProps> = forwardRef<
  BaseCheckbox,
  CheckboxProps
>(({ children, size = CHECKBOX_SIZE.MEDIUM, ...props }, ref) => {
  const [css, theme] = useStyletron();
  return (
    <BaseCheckbox
      ref={ref}
      {...props}
      overrides={{
        Checkmark: {
          component: ({
            $isHovered,
            $checked,
            $isIndeterminate,
          }: Overrides<boolean>) => (
            <span
              className={css({
                display: 'block',
                flexShrink: 0,
                borderColor: $isHovered
                  ? theme.colors.gray400
                  : theme.colors.gray300,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '4px',
                backgroundColor: theme.colors.white900,
                color: theme.colors.accent,
                transition: 'border-color 0.2s cubic-bezier(0, 0, 1, 1)',
              })}
            >
              <span
                className={css({
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '20px',
                  height: '20px',
                  margin: '-1px',
                })}
              >
                {$checked && <CheckIcon />}
                {$isIndeterminate && (
                  <span
                    className={css({
                      display: 'block',
                      width: '12px',
                      height: '2px',
                      backgroundColor: theme.colors.accent,
                    })}
                  />
                )}
              </span>
            </span>
          ),
        },
        Label: {
          style: {
            width: '100%',
            paddingLeft: '8px',
            ...CHECKBOX_SIZE_STYLES.get(size),
          },
        },
        Root: {
          style: {
            alignItems: 'center',
          },
        },
      }}
    >
      {children}
    </BaseCheckbox>
  );
});
