import { FC } from 'react';
import { useStyletron } from 'shared/ui';
import { ModalBase } from '../base';
import { ModalDefaultProps } from './model';
import { useStyles } from './styles';
import { ICONS_COLOR } from './utils';

export const ModalDefault: FC<ModalDefaultProps> = ({
  title,
  content,
  footer,
  onClose,
  icon,
}) => {
  const [, theme] = useStyletron();
  const {
    RootStyles,
    TitleStyles,
    ContentStyles,
    FooterStyles,
    iconWrapperClass,
    iconSVGClass,
  } = useStyles();

  return (
    <ModalBase onClose={onClose}>
      <div className={RootStyles}>
        {!!icon && (
          <div
            className={iconWrapperClass(
              ICONS_COLOR({ type: icon.type, theme }) ?? {}
            )}
          >
            {icon.svg(iconSVGClass)}
          </div>
        )}

        <div className={TitleStyles}>{title}</div>
        {content ? <div className={ContentStyles}>{content}</div> : null}
      </div>

      {footer ? <div className={FooterStyles}>{footer}</div> : null}
    </ModalBase>
  );
};
