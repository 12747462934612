import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SexEnum } from 'shared/api';
import { dateFormat } from 'shared/lib';
import {
  FormControl,
  Input,
  INPUT_KIND,
  Select,
  SELECT_KIND,
  Value,
} from 'shared/ui';
import { RedoxFieldsInterface } from './model';
import { useStyles } from './styles';

export const RedoxFormFields: FC<RedoxFieldsInterface> = ({
  errors,
  touched,
  onBlur,
  setFieldValue,
  values,
  isNoMargins = false,
  isFlat = false,
}) => {
  const { t } = useTranslation(['auth', 'common']);
  const { formRow } = useStyles();
  const sexOptions: Value = useMemo(
    () => [
      {
        label: t('auth:SEX.MALE'),
        id: SexEnum.Male,
      },
      {
        label: t('auth:SEX.FEMALE'),
        id: SexEnum.Female,
      },
    ],
    [t]
  );
  const currentSex = sexOptions.find(({ id }) => id === values.sex);

  return (
    <>
      <div className={formRow(isNoMargins)}>
        <FormControl
          error={touched.DOB && errors.DOB}
          label={t('auth:MODAL.CREATE_ACCOUNT.FORM.DOB.TITLE')}
          required
        >
          <Input
            type="text"
            hasError={Boolean(errors.DOB && touched.DOB)}
            kind={isFlat ? undefined : INPUT_KIND.SECONDARY}
            onBlur={onBlur ? onBlur('DOB') : undefined}
            onChange={({ currentTarget: { value } }) => {
              setFieldValue('DOB', dateFormat(value), true);
            }}
            placeholder={t('auth:MODAL.CREATE_ACCOUNT.FORM.DOB.PLACEHOLDER')}
            value={values.DOB}
          />
        </FormControl>
      </div>

      <div className={formRow(isNoMargins)}>
        <FormControl
          error={touched.sex && errors.sex}
          label={t('auth:MODAL.CREATE_ACCOUNT.FORM.SEX.TITLE')}
          required
        >
          <Select
            options={sexOptions}
            hasError={Boolean(errors.sex && touched.sex)}
            value={currentSex ? [currentSex] : undefined}
            onBlur={onBlur ? onBlur('sex') : undefined}
            onChange={({ option }) => {
              setFieldValue('sex', option?.id, true);
            }}
            kind={isFlat ? undefined : SELECT_KIND.SECONDARY}
          />
        </FormControl>
      </div>
    </>
  );
};
