import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './styles.css';
import { min, max } from 'ramda';
import { useStyles } from './styles';
import { canvasPreview } from './canvas-preview';
import { useDebounceEffect } from './use-debounce-effect';

export interface ExtendedReactCrop extends ReactCrop {
  handleSave: () => void;
  handleCancel: () => void;
}

export interface ImageCropProps {
  imageFile: string;
  onSave: (file: File, blob: Blob) => void;
  onCancel: () => void;
  scale?: number;
  rotate?: number;
}

export const ImageCrop = forwardRef<ExtendedReactCrop, ImageCropProps>(
  ({ imageFile, scale = 1, rotate = 0, onSave, onCancel }, ref) => {
    const { reactCropClass, imageClass, canvasClass } = useStyles();

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
      imgRef.current?.addEventListener('load', () => {
        if (!imgRef.current) {
          return;
        }
        const smallerSize = min(imgRef.current.width, imgRef.current.height);
        const lagerSize = max(imgRef.current.width, imgRef.current.height);
        const centerSize = lagerSize / 2 - smallerSize / 2;
        const isLandscape = imgRef.current.width > imgRef.current.height;

        const fullCrop: PixelCrop = {
          x: isLandscape ? centerSize : 0,
          y: !isLandscape ? centerSize : 0,
          width: smallerSize,
          height: smallerSize,
          unit: 'px',
        };
        setCrop(fullCrop);
        setCompletedCrop(fullCrop);
      });
    }, []);

    const handleSave = () => {
      try {
        previewCanvasRef?.current?.toBlob((blob) => {
          if (blob) {
            const newImage = new File([blob], '', { type: blob.type });
            onSave(newImage, blob);
          }
        });
      } catch (error) {
        onCancel();
      }
    };

    const handleCancel = () => {
      onCancel();
    };

    useImperativeHandle(
      ref,
      () =>
        ({
          handleSave() {
            handleSave();
          },
          handleCancel() {
            handleCancel();
          },
        } as ExtendedReactCrop)
    );

    useDebounceEffect(
      async () => {
        if (completedCrop && imgRef.current && previewCanvasRef.current) {
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate
          );
        }
      },
      100,
      [completedCrop, scale, rotate]
    );

    return (
      <div>
        <ReactCrop
          className={reactCropClass}
          ref={ref}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={1}
          keepSelection
        >
          <img className={imageClass} ref={imgRef} alt="" src={imageFile} />
        </ReactCrop>

        <canvas ref={previewCanvasRef} className={canvasClass} />
      </div>
    );
  }
);
