import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRange } from 'shared/lib';
import { useMobileMediaQuery } from 'shared/ui';
import { TablePaginationProps } from './model';
import { NavigationButton } from './navigation-button';
import { PagePill } from './page-pill';
import { useStyles } from './styles';

export const TablePagination: FC<TablePaginationProps> = ({
  totalPages,
  totalCount,
  endIndex,
  startIndex,
  currentPage,
  nextEnabled,
  previousEnabled,
  setNextPage,
  setPreviousPage,
  onChange,
}) => {
  const { rootClass, textClass, paginationListWrapperClass } = useStyles({
    totalPages,
  });
  const { t } = useTranslation(['common']);
  const isMobile = useMobileMediaQuery();

  const paginationRange = useRange({
    totalPages,
    currentPage,
    siblingCount: isMobile ? 0 : 1,
  });

  if (totalCount < 2) {
    return null;
  }
  return (
    <div className={rootClass}>
      <span className={textClass}>
        {t('common:LISTS.RESULTS_COUNT', {
          start: startIndex + 1,
          end: endIndex + 1,
          total: totalCount,
        })}
      </span>
      {totalPages > 1 && (
        <ul className={paginationListWrapperClass}>
          <li>
            <NavigationButton
              type="LEFT"
              disabled={!previousEnabled}
              onClick={setPreviousPage}
            />
          </li>
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === 'DOTS') {
              // eslint-disable-next-line react/no-array-index-key
              return <PagePill key={`${index}-dots`} isDots />;
            }
            return (
              <li key={pageNumber}>
                <PagePill
                  onClick={() => onChange(pageNumber)}
                  isActive={currentPage === pageNumber}
                >
                  {pageNumber}
                </PagePill>
              </li>
            );
          })}
          <li>
            <NavigationButton
              type="RIGHT"
              disabled={!nextEnabled}
              onClick={setNextPage}
            />
          </li>
        </ul>
      )}
    </div>
  );
};
