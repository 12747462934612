import { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { SidebarPanelBase, SidebarPanelEvent, useConfirm } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import {
  MODAL_WIDTH_CSS_VAR,
  SidebarContext,
  ConfirmTextProps,
  SidebarContextProps,
} from './model';

const setWidth = (value: string | null) =>
  document.documentElement.style.setProperty(MODAL_WIDTH_CSS_VAR, value);

export const SidebarCtx = createContext<SidebarContext>({
  close: () => {
    throw new Error('not implemented');
  },
  setHasUnsavedChanges: () => {
    throw new Error('not implemented');
  },
  setWidth: () => {
    throw new Error('not implemented');
  },
  setConfirmText: () => {
    throw new Error('not implemented');
  },
});

export function SidebarProvider({
  props: { width: customOpenWidth, children, ...props },
  id,
}: SidebarContextProps) {
  const { t } = useTranslation(['common']);
  const hasUnsavedChanges = useRef<boolean>(false);
  const confirmTextRef = useRef<ConfirmTextProps | null>(null);
  const defaultConfirmText = useMemo(
    () => ({
      title: t('common:UNSAVED_DATA_CONFIRM.TITLE'),
      caption: t('common:UNSAVED_DATA_CONFIRM.CAPTION'),
      submitText: t('common:UNSAVED_DATA_CONFIRM.LEAVE'),
      noText: t('common:UNSAVED_DATA_CONFIRM.STAY'),
    }),
    [t]
  );
  const { confirm } = useConfirm();

  const closeSidebar = useCallback(
    (eventType: SidebarPanelEvent) => {
      props?.onClose?.(eventType);
    },
    [props]
  );

  const setHasUnsavedChanges = useCallback((val: boolean) => {
    hasUnsavedChanges.current = val;
  }, []);

  const setConfirmText = useCallback((text: ConfirmTextProps) => {
    confirmTextRef.current = text;
  }, []);

  const close = useCallback(
    (eventType: SidebarPanelEvent) => {
      if (
        !hasUnsavedChanges.current ||
        eventType === SidebarPanelEvent.LocationChange
      ) {
        closeSidebar(eventType);
        return;
      }
      confirm(confirmTextRef.current ?? defaultConfirmText).then(
        ({ result }) => {
          if (result) {
            closeSidebar(eventType);
          }
        }
      );
    },
    [closeSidebar, confirm, defaultConfirmText]
  );

  const width = useMemo(
    () => `var(${MODAL_WIDTH_CSS_VAR},${customOpenWidth ?? '800px'})`,
    [customOpenWidth]
  );

  const value = useMemo(
    () => ({
      close,
      setHasUnsavedChanges,
      setWidth,
      setConfirmText,
    }),
    [close, setConfirmText, setHasUnsavedChanges]
  );

  useEffect(
    () => () => {
      hasUnsavedChanges.current = false;
      confirmTextRef.current = null;
      setWidth(null);
    },
    [id]
  );

  return (
    <SidebarCtx.Provider value={value}>
      <SidebarPanelBase width={width} {...props} onClose={close}>
        {children}
      </SidebarPanelBase>
    </SidebarCtx.Provider>
  );
}
