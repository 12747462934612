import { ApolloLink } from '@apollo/client';

/**
 * It's a crutch for participants request with redox extension data
 * @see TRU-1880 - https://issues.yournextagency.com/issue/TRU-1880
 */
export const redoxLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    if (
      response.extensions?.isRedox &&
      response.data?.participants?.edges?.[0]?.node
    ) {
      const TVname = response.data.participants.edges[0].node.name;
      const redoxName = `${response.extensions.firstName} ${response.extensions.lastName} `;
      response.data.participants.edges[0].node.name = `${redoxName} - (TrustVideo user: ${TVname})`;
    }
    return response;
  })
);
