import { AlertIcon, RemindIcon, TrustvideoTheme } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { CONFIRM_KIND, CONFIRM_SIZE } from './model';

export const CONFIRM_SIZE_STYLES = ({ size }: { size: CONFIRM_SIZE }) => {
  const map = new Map<CONFIRM_SIZE, StyleObject>([
    [
      CONFIRM_SIZE.SMALL,
      {
        maxWidth: '424px',
      },
    ],
    [
      CONFIRM_SIZE.LARGE,
      {
        maxWidth: '536px',
      },
    ],
  ]);

  return map.get(size);
};

export const CONFIRM_FIGURE_STYLES = ({
  kind,
  theme,
}: {
  kind: CONFIRM_KIND;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<CONFIRM_KIND, StyleObject>([
    [
      CONFIRM_KIND.ALERT,
      {
        backgroundColor: theme.colors.red100,
      },
    ],
    [
      CONFIRM_KIND.REMINDER,
      {
        backgroundColor: theme.colors.yellow100,
      },
    ],
  ]);

  return map.get(kind);
};

export const CONFIRM_ICONS = {
  [CONFIRM_KIND.ALERT]: AlertIcon,
  [CONFIRM_KIND.REMINDER]: RemindIcon,
};

export const CONFIRM_ICON_STYLES = ({
  kind,
  theme,
}: {
  kind: CONFIRM_KIND;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<CONFIRM_KIND, StyleObject>([
    [
      CONFIRM_KIND.ALERT,
      {
        color: theme.colors.red500,
      },
    ],
    [
      CONFIRM_KIND.REMINDER,
      {
        color: theme.colors.yellow500,
      },
    ],
  ]);

  return map.get(kind);
};

export const CONFIRM_BUTTON_STYLES = ({
  kind,
  theme,
}: {
  kind: CONFIRM_KIND;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<CONFIRM_KIND, StyleObject>([
    [
      CONFIRM_KIND.ALERT,
      {
        backgroundColor: theme.colors.red500,

        ':hover': {
          backgroundColor: theme.colors.red600,
        },
      },
    ],
    [CONFIRM_KIND.REMINDER, {}],
  ]);

  return map.get(kind);
};

export const CONFIRM_LOADING_STYLES = ({
  kind,
  theme,
}: {
  kind: CONFIRM_KIND;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<CONFIRM_KIND, StyleObject>([
    [
      CONFIRM_KIND.ALERT,
      {
        borderTopColor: theme.colors.red500,
      },
    ],
    [CONFIRM_KIND.REMINDER, {}],
  ]);

  return map.get(kind);
};
