import { FC, useCallback, useMemo, FormEvent } from 'react';
import { withMask } from 'use-mask-input';
import { PHONE_COUNTRY } from 'shared/lib';
import { Input, InputProps } from '../input';

type Props = InputProps & {
  value: string;
  onChangePhone: (value: string) => void;
};

const clear = (source: string): string => source.replace(/[^0123456789+]/g, '');

export const InputPhone: FC<Props> = (props) => {
  const phoneValue = useMemo(() => {
    if (props.value.slice(0, 2) === PHONE_COUNTRY) {
      return props.value.slice(2, 100);
    }
    return props.value;
  }, [props.value]);

  const onChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const value = clear(e.currentTarget.value);
      props.onChangePhone(value ? `${PHONE_COUNTRY}${value}` : '');
    },
    [props]
  );

  return (
    <Input
      {...props}
      inputRef={withMask('(999) 999-9999')}
      onChange={onChange}
      value={phoneValue}
      placeholder="(___) ___-____"
    />
  );
};
