import { StyledLink, LinkProps } from 'baseui/link';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from '../theme';

export const Link: FC<LinkProps> = ({ children, ...props }) => {
  const navigate = useNavigate();
  const [, theme] = useStyletron();

  return (
    <StyledLink
      {...props}
      $style={{ color: theme.colors.accent, fontWeight: 'inherit' }}
      onClick={(event) => {
        event.preventDefault();
        navigate(props.href ?? '');
      }}
    >
      {children}
    </StyledLink>
  );
};
