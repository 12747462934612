import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { DATE_SELECTOR_TYPE } from './model';

export const DATE_SELECTOR_FORMAT_MAP = new Map<DATE_SELECTOR_TYPE, string>([
  [DATE_SELECTOR_TYPE.DAY, 'd MMMM'],
  [DATE_SELECTOR_TYPE.WEEK, 'MMMM'],
  [DATE_SELECTOR_TYPE.MONTH, 'MMMM'],
  [DATE_SELECTOR_TYPE.YEAR, 'yyyy'],
]);

export const getDates: (
  date: Date,
  type: DATE_SELECTOR_TYPE
) => { dateFrom: Date; dateTo: Date } = (date, type) => {
  switch (type) {
    case DATE_SELECTOR_TYPE.YEAR:
      return { dateFrom: startOfYear(date), dateTo: endOfYear(date) };
    case DATE_SELECTOR_TYPE.MONTH:
      return { dateFrom: startOfMonth(date), dateTo: endOfMonth(date) };
    case DATE_SELECTOR_TYPE.WEEK:
      return { dateFrom: startOfWeek(date), dateTo: endOfWeek(date) };
    case DATE_SELECTOR_TYPE.DAY:
    default:
      return { dateFrom: startOfDay(date), dateTo: endOfDay(date) };
  }
};

export const getFormattedDate: (
  dateFrom: Date,
  dateTo: Date,
  type: DATE_SELECTOR_TYPE
) => string = (dateFrom, dateTo, type) => {
  switch (type) {
    case DATE_SELECTOR_TYPE.YEAR:
      return format(dateFrom, 'y');
    case DATE_SELECTOR_TYPE.MONTH:
      return format(dateFrom, 'MMMM y');
    case DATE_SELECTOR_TYPE.WEEK:
      return isSameMonth(dateFrom, dateTo)
        ? `${format(dateFrom, 'MMM')} ${format(dateFrom, 'd')} - ${format(
            dateTo,
            'd'
          )}`
        : `${format(dateFrom, 'MMM d')} — ${format(dateTo, 'MMM d')}`;
    case DATE_SELECTOR_TYPE.DAY:
    default:
      return format(dateFrom, 'd MMMM');
  }
};
