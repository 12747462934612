import { createEvent, createEffect, sample, createStore } from 'effector';
import { $currentOrganization } from 'entities/organization';
import saveAs from 'file-saver';
import { condition, interval } from 'patronum';
import {
  client,
  PullDocument,
  PullingType,
  PullQueryVariables,
  PullQuery,
  Pulling,
} from 'shared/api';
import { getTabUUID, i18n } from 'shared/lib';
import { notifications } from 'shared/ui';
import { PULL_INTERVAL } from './consts';

const getFilename = (url: string) => url.split(/(\\|\/)/g).pop();

const showNotify = (link: string) => {
  const { close } = notifications.success(
    {
      title: i18n.t('common:EXPORT_SUCCESS_MODAL.TITLE'),
      buttons: [
        {
          message: i18n.t('common:BUTTONS.DOWNLOAD'),
          onClick: () => {
            saveAs(link, getFilename(link));
            close();
          },
        },
      ],
    },
    { duration: 'none' }
  );
};

const pullExportFx = createEffect(({ orgId }: { orgId: string }) =>
  client.query<PullQuery, PullQueryVariables>({
    query: PullDocument,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        browser_identifier: getTabUUID(),
        type: PullingType.Export,
        organization_id: orgId,
      },
    },
  })
);

const showDownloadNotifyFx = createEffect(
  ({ pullResults }: { pullResults: Pulling[] }) => {
    pullResults.forEach(({ data }) => {
      const json = JSON.parse(data);
      const link = json.url;
      if (link) {
        showNotify(link);
      }
    });
  }
);

export const queueExport = createEvent<string>();

const startExportPulling = createEvent();
const stopExportPulling = createEvent();

const $queue = createStore<string[]>([]).on(queueExport, (queue, name) => [
  ...queue,
  name,
]);

const { tick } = interval({
  timeout: PULL_INTERVAL,
  start: startExportPulling,
  stop: stopExportPulling,
});

condition({
  source: $queue,
  if: (queue) => queue.length > 0,
  then: startExportPulling,
  else: stopExportPulling,
});

sample({
  clock: tick,
  source: $currentOrganization,
  filter: (org) => !!org,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  fn: (org) => ({ orgId: org!.id }),
  target: pullExportFx,
});

sample({
  clock: pullExportFx.doneData,
  filter: ({ data }) => !!data.pull?.length,
  fn: ({ data }) => ({ pullResults: data.pull as Pulling[] }),
  target: showDownloadNotifyFx,
});

sample({
  clock: pullExportFx.doneData,
  source: $queue,
  filter: (_, { data }) => !!data.pull?.length,
  fn: (queue, { data }) => {
    const names = data.pull?.map((result) => {
      const json = JSON.parse(result?.data);
      return (json.fileName as string) ?? null;
    });

    return queue.filter((name) => !names?.includes(name));
  },
  target: $queue,
});
