import { Vital } from 'shared/api';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  COLORS,
  extractColorFromCSSVariable,
  LineChartInterface,
} from 'shared/ui';
import { ChartData, ChartOptions } from 'chart.js';
import { getUserZonedDate } from 'shared/lib';

const normalizedChartData: (
  labelsArray: Date[],
  valuesArray: number[]
) => ChartData<'line'> = (labelsArray, valuesArray) => ({
  datasets: [
    {
      data: valuesArray,
    },
  ],
  labels: labelsArray,
});

export const getPulseChartOptions: () => ChartOptions<'line'> = () => ({
  elements: {
    line: {
      borderColor: extractColorFromCSSVariable(COLORS.accent500),
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'second',
        displayFormats: {
          second: 'hh:mm:ss',
        },
      },
      ticks: {
        autoSkip: false,
        maxTicksLimit: 6,
        maxRotation: 0,
        minRotation: 0,
      },
    },
  },
});

// interval in milliseconds (5 minutes)
const GROUP_INTERVAL = 60000;

/** @todo (@webatom) refactor this grouping */
export const groupPulseData = (SpO2Records: Vital[]) =>
  (SpO2Records ?? [])
    .sort(
      (a, b) =>
        new Date(JSON.parse(a.fdata)?.['Last update']).getTime() -
        new Date(JSON.parse(b.fdata)?.['Last update']).getTime()
    )
    .reduce((acc, item) => {
      if (!acc.length) {
        acc.push([item]);
        return acc;
      }
      const lastArray = acc[acc.length - 1];
      if (
        new Date(JSON.parse(item.fdata)?.['Last update']).getTime() -
          new Date(
            JSON.parse(lastArray[lastArray.length - 1].fdata)?.['Last update']
          ).getTime() >=
        GROUP_INTERVAL
      ) {
        return [...acc, [item]];
      }
      return [...acc.slice(0, -1), [...lastArray, item]];
    }, [] as Vital[][]);

export const usePulseOxiChartData = (
  SpO2Records?: Pick<Vital, 'fdata'>[]
): [
  { label: Date; value: number; SpO2: number }[],
  LineChartInterface['data'] | null,
  LineChartInterface['data'] | null,
  LineChartInterface['meta'] | undefined
] => {
  const { t } = useTranslation(['medcart']);

  const values = useMemo(
    () =>
      (SpO2Records ?? [])
        .reduce((res, vital) => {
          const parsedData = JSON.parse(vital?.fdata);

          if (parsedData) {
            res.push({
              label: getUserZonedDate(new Date(parsedData['Last update'])),
              value: Number(parsedData.PR) || 0,
              SpO2: Number(parsedData.SpO2) || 0,
            });
          }
          return res;
        }, [] as { label: Date; value: number; SpO2: number }[])
        .sort((a, b) => a.label.getTime() - b.label.getTime()),
    [SpO2Records]
  );

  const [labels, SpO2Values, PRValues] = useMemo(
    () => [
      values.map((item) => item.label),
      values.map((item) => item.SpO2),
      values.map((item) => item.value),
    ],
    [values]
  );

  return useMemo(
    () => [
      values,
      normalizedChartData(labels, SpO2Values),
      normalizedChartData(labels, PRValues),
      [
        {
          label: t('medcart:SENSORS.SpO2.AVERAGE'),
          value: `${(
            SpO2Values.reduce((a, b) => a + b, 0) / SpO2Values.length
          ).toFixed(2)}%`,
        },
        {
          label: t('medcart:SENSORS.SpO2.HIGHEST'),
          value: `${Math.max(...SpO2Values)}%` ?? '–',
        },
        {
          label: t('medcart:SENSORS.SpO2.LOWEST'),
          value: `${Math.min(...SpO2Values)}%` ?? '–',
        },
      ],
    ],
    [values, PRValues, SpO2Values, labels, t]
  );
};
