import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import {
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  CheckIcon,
  DateInput,
  Desktop,
  FormControl,
  Input,
  INPUT_KIND,
  PatientsIcon,
  SearchIcon,
  SELECT_KIND,
  TimezonePicker,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_PAGE_SIZE,
  hideGeneratedEmail,
  MODAL_TYPE,
  useDebounceValue,
  useModal,
} from 'shared/lib';
import {
  NotificationChannelsEnum,
  OrganizationMessageTypeEnum,
  useOrganizationMessagesQuery,
  useParticipantsConnectionLazyQuery,
  User,
} from 'shared/api';
import { useIntersectionObserver } from 'usehooks-ts';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Formik } from 'formik';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { ParticipantButton, ParticipantInvite } from 'entities/participant';
import { useCurrentOrganization } from 'entities/organization';
import { useStyles } from './styles';
import { useForm } from './form';
import { ParticipantModalProps } from './model';
import { RedoxFormFields } from '../redox-form-fields';

const Content: FC<
  ParticipantModalProps & {
    onCreate?: (value: User) => void;
    onClose: () => void;
  }
> = ({
  searchable = true,
  participants = [],
  onCreate,
  appointmentId,
  isAddingToChat,
  onClose,
}) => {
  const { t } = useTranslation([
    'common',
    'appointments',
    'participants',
    'chat',
  ]);
  const {
    getPopoverOverrides,
    headerClass,
    headerIconClass,
    headerTitleClass,
    headerCaptionClass,
    bodyClass,
    searchClass,
    popoverContainer,
    inputWrapperClass,
    searchIconClass,
    textClass,
    listWrapperClass,
    fetchMoreSpinnerClass,
    getItemClass,
    nameClass,
    captionClass,
    checkIconClass,
    footerClass,
    buttonStyle,
    spinnerStyle,
    messageClass,
    fieldsClass,
  } = useStyles();

  const { organization } = useCurrentOrganization();
  const isFormExtended = organization.is_redox_enable;

  const [activeStepId, setActiveStepId] = useState<1 | 2>(searchable ? 1 : 2);
  const [searchTerm, debounceSearchTerm, setSearchTerm] = useDebounceValue(
    '',
    500
  );
  const [dob, setDob] = useState<Date | undefined>();

  const [getParticipants, { data, fetchMore, loading }] =
    useParticipantsConnectionLazyQuery();

  const { data: messages } = useOrganizationMessagesQuery();

  const notFoundContent = useMemo(() => {
    const custom =
      messages?.organization_messages?.find(
        (message) =>
          message?.type === OrganizationMessageTypeEnum.RedoxPatientNotFound
      )?.content || null;

    if (custom && isFormExtended) {
      return custom;
    }

    return t('common:SEARCH.NO_RECORD_FOUND');
  }, [isFormExtended, messages?.organization_messages, t]);

  const onSearch = useCallback(() => {
    getParticipants({
      variables: {
        search: searchTerm,
        first: DEFAULT_PAGE_SIZE,
        exclude: participants.map(({ user }) => user.id),
        dob,
      },
      fetchPolicy: 'network-only',
    });
  }, [getParticipants, searchTerm, participants, dob]);

  useEffect(() => {
    if (!isFormExtended) {
      onSearch();
    }
  }, [debounceSearchTerm]);

  useEffect(() => {
    onSearch();
  }, []);

  const { validationSchema, initialValues, onSubmit, isLoading } = useForm(
    onClose,
    activeStepId,
    participants,
    appointmentId,
    onCreate,
    isAddingToChat
  );

  const [listWrapperElement, setListWrapperElement] =
    useState<HTMLDivElement | null>(null);
  const endOfListRef = useRef<HTMLDivElement | null>(null);
  const popoverBoxRef = useRef<HTMLDivElement | null>(null);

  const observerEntry = useIntersectionObserver(endOfListRef, {
    root: listWrapperElement,
  });

  const onSearchEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSearch();
      }
    },
    [onSearch]
  );

  useEffect(() => {
    if (
      observerEntry?.isIntersecting &&
      data?.participants?.pageInfo.hasNextPage &&
      !loading
    ) {
      fetchMore({ variables: { after: data.participants.pageInfo.endCursor } });
    }
  }, [observerEntry?.isIntersecting, data?.participants?.pageInfo, fetchMore]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        isRedoxEnabled: organization.is_redox_enable as boolean,
      }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onSubmit={onSubmit!}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
        isValid,
        setValues,
        resetForm,
        validateForm,
        handleBlur,
      }) => (
        <>
          <Desktop>
            <header className={headerClass}>
              <PatientsIcon className={headerIconClass} />
              <h3 className={headerTitleClass}>
                {t('appointments:VIEW.ADD_PARTICIPANT')}
              </h3>
              <p className={headerCaptionClass}>
                {isAddingToChat
                  ? t('chat:VIEW.INVITE_OR_ADD')
                  : t('appointments:VIEW.INVITE_OR_ADD')}
              </p>
            </header>
          </Desktop>
          <div className={bodyClass}>
            {activeStepId === 1 && (
              <fieldset>
                <FormControl
                  error={touched.participant && errors.participant}
                  label={
                    values.participant
                      ? t('appointments:CREATE.SELECT_PARTICIPANT.TITLE')
                      : t('appointments:CREATE.SELECT_PARTICIPANT.LIST_TITLE')
                  }
                >
                  <div className={searchClass}>
                    <StatefulPopover
                      placement={PLACEMENT.bottomLeft}
                      overrides={getPopoverOverrides(
                        popoverBoxRef.current?.clientWidth || 445
                      )}
                      popoverMargin={8}
                      content={({ close }) => (
                        <>
                          {loading ? (
                            <StyledSpinnerNext $style={spinnerStyle} />
                          ) : data?.participants?.edges.length ? (
                            <div
                              className={listWrapperClass}
                              ref={setListWrapperElement}
                            >
                              <ul>
                                {data?.participants?.edges.map(
                                  ({ node: participant }) => {
                                    const isSelected =
                                      values.participant?.id === participant.id;
                                    const caption =
                                      participant.default_notification_channel ===
                                      NotificationChannelsEnum.Mail
                                        ? hideGeneratedEmail(participant.email)
                                        : participant.sms ?? undefined;
                                    return (
                                      <li
                                        className={getItemClass(isSelected)}
                                        key={participant.id}
                                        onClick={() => {
                                          if (!participant) {
                                            setValues({
                                              ...values,
                                              participant: null,
                                              invite: null,
                                            });
                                            return;
                                          }
                                          const invite = {
                                            channel:
                                              participant.default_notification_channel,
                                            value: caption ?? undefined,
                                          };
                                          setValues({
                                            ...values,
                                            participant: participant as User,
                                            invite,
                                          });
                                          close();
                                        }}
                                        aria-hidden="true"
                                      >
                                        <div>
                                          <p className={nameClass}>
                                            {participant.name}
                                          </p>
                                          {caption && (
                                            <p className={captionClass}>
                                              {caption}
                                            </p>
                                          )}
                                        </div>
                                        {isSelected && (
                                          <CheckIcon
                                            className={checkIconClass}
                                          />
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                              <div ref={endOfListRef} />
                              {data.participants.pageInfo.hasNextPage && (
                                <div className={fetchMoreSpinnerClass}>
                                  <StyledSpinnerNext />
                                </div>
                              )}
                            </div>
                          ) : (
                            <p className={messageClass}>{notFoundContent}</p>
                          )}
                        </>
                      )}
                      accessibilityType="menu"
                      triggerType={TRIGGER_TYPE.click}
                    >
                      <div className={popoverContainer}>
                        <div ref={popoverBoxRef} className={fieldsClass}>
                          {!values.participant ? (
                            <div className={inputWrapperClass}>
                              <Input
                                placeholder={t(
                                  'appointments:VIEW.SEARCH_PARTICIPANT'
                                )}
                                value={searchTerm}
                                onChange={({ currentTarget }) =>
                                  setSearchTerm(currentTarget.value)
                                }
                                onKeyDown={onSearchEnter}
                                startEnhancer={
                                  <SearchIcon className={searchIconClass} />
                                }
                                kind={INPUT_KIND.SECONDARY}
                                hasError={Boolean(
                                  touched.participant && errors.participant
                                )}
                              />
                            </div>
                          ) : (
                            <ParticipantButton
                              value={values.participant}
                              onClose={() => {
                                setValues({
                                  ...values,
                                  participant: null,
                                  invite: null,
                                });
                                validateForm();
                              }}
                            />
                          )}

                          {isFormExtended && !values.participant && (
                            <>
                              <DateInput
                                value={dob}
                                onChange={setDob}
                                popover={false}
                                onKeyDown={onSearchEnter}
                                placeholder={t(
                                  'appointments:CREATE.SELECT_PARTICIPANT.BIRTHDATE'
                                )}
                              />
                              <Button type="submit" onClick={onSearch}>
                                <SearchIcon />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </StatefulPopover>

                    {!values.participant && (
                      <>
                        <p className={textClass}>{t('common:SEARCH.OR')}</p>
                        {isFormExtended ? (
                          <Button
                            kind={BUTTON_KIND.SECONDARY}
                            onClick={() => {
                              setActiveStepId(2);
                              resetForm();
                            }}
                          >
                            +
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setActiveStepId(2);
                              resetForm();
                            }}
                          >
                            {t('appointments:VIEW.BUTTONS.ADD_NEW_PARTICIPANT')}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </FormControl>
              </fieldset>
            )}

            {activeStepId === 2 && (
              <>
                <fieldset>
                  <FormControl
                    error={touched.name && errors.name}
                    label={t('participants:ADD_MODAL.FORM.NAME.TITLE')}
                    required
                  >
                    <div className={searchClass}>
                      <Input
                        placeholder={t(
                          'participants:ADD_MODAL.FORM.NAME.TITLE'
                        )}
                        onChange={(e) => {
                          setFieldValue('name', e.currentTarget.value, true);
                        }}
                        onBlur={handleBlur('name')}
                        value={values.name}
                        kind={INPUT_KIND.SECONDARY}
                        hasError={Boolean(touched.name && errors.name)}
                      />
                      {searchable && (
                        <Button
                          kind={BUTTON_KIND.SECONDARY}
                          onClick={() => {
                            setActiveStepId(1);
                            resetForm();
                          }}
                        >
                          {t('participants:ADD_MODAL.FORM.SEARCH')}
                        </Button>
                      )}
                    </div>
                  </FormControl>
                </fieldset>

                {organization.is_redox_enable ? (
                  <RedoxFormFields
                    errors={errors}
                    touched={touched}
                    values={values}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    isNoMargins
                  />
                ) : null}

                <fieldset>
                  <FormControl
                    error={touched.timezone && errors.timezone}
                    label={t('participants:ADD_MODAL.FORM.TIMEZONE.TITLE')}
                    required
                  >
                    <TimezonePicker
                      kind={SELECT_KIND.SECONDARY}
                      onChange={(timezone) => {
                        setFieldValue('timezone', timezone.id);
                      }}
                      onBlur={handleBlur('timezone')}
                      value={values.timezone}
                      hasError={Boolean(touched.timezone && errors.timezone)}
                    />
                  </FormControl>
                </fieldset>
              </>
            )}

            <fieldset>
              <FormControl
                error={
                  touched.invite &&
                  (typeof errors.invite === 'object'
                    ? (errors.invite as unknown as { value: string }).value
                    : errors.invite)
                }
                label={t('appointments:CREATE.INVITE_AND_REMINDERS.TITLE')}
              >
                <ParticipantInvite
                  participant={values.participant}
                  value={values.invite}
                  onChange={(value) => {
                    setFieldValue('invite', value, true);
                  }}
                  onBlur={handleBlur('invite')}
                  disabled={!values.participant && activeStepId === 1}
                  hasError={Boolean(touched.invite && errors.invite)}
                />
              </FormControl>
            </fieldset>
          </div>

          <div className={footerClass}>
            <Button
              size={BUTTON_SIZE.SMALL}
              kind={BUTTON_KIND.SECONDARY}
              onClick={onClose}
              style={buttonStyle}
            >
              {t('common:BUTTONS.CANCEL')}
            </Button>
            <Button
              size={BUTTON_SIZE.SMALL}
              onClick={() => {
                handleSubmit();
              }}
              isLoading={isLoading}
              disabled={!isValid}
              style={buttonStyle}
            >
              {isAddingToChat
                ? t('chat:VIEW.ADD_TO_CHAT')
                : t('appointments:VIEW.ADD_TO_SESSION')}
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

export const useParticipantModal = (props: ParticipantModalProps) => {
  const { open, close } = useModal();
  const { t } = useTranslation(['appointments']);
  const { modalStyle } = useStyles();

  return useCallback(
    (cbProps?: { onCreate?: (value: User) => void }) => {
      open(MODAL_TYPE.BASE, {
        title: t('appointments:VIEW.ADD_PARTICIPANT'),
        children: (
          <Content onClose={close} onCreate={cbProps?.onCreate} {...props} />
        ),
        style: modalStyle,
      });
    },
    [open, close, props, modalStyle, t]
  );
};
