import * as React from 'react';
import { useStyletron } from 'baseui';
import { Card } from 'baseui/card';
import { Spinner } from 'baseui/spinner';
import { H5, Display4, Paragraph2 } from 'baseui/typography';
import { useEffect, useRef, useState } from 'react';
import { VitalPlate } from '../plate';

export default function Biometrics() {
  const [css, theme] = useStyletron();

  return (
    <VitalPlate>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: theme.sizing.scale800,
        })}
      >
        <H5 color="#03fcff">Biometrics</H5>
      </div>

      <Card
        overrides={{
          Root: {
            style: {
              width: '100%',
              minWidth: '605px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: theme.borders.radius300,
              backgroundColor: '#1d202b',
              borderStyle: 'none',
              // position: 'relative',
              padding: '0',
              margin: '0',
              // border: '2px solid red',
            },
          },
        }}
      >
   
      <iframe src="https://codeaffex.ltidev.net" title="TrustVideo" style={{ width: '580px', height: '500px', border: 'none' }} />
      </Card>
    </VitalPlate>
  );
}
