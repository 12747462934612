import { FC } from 'react';
import {
  useStyletron,
  IconButton,
  EditIcon,
  ExportIcon,
  DeleteIcon,
} from 'shared/ui';

export const NoteActions: FC<{
  onEdit?: () => void;
  onDownload?: () => void;
  onRemove?: () => void;
}> = ({ onEdit, onDownload, onRemove }) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
      })}
    >
      {!!onEdit && (
        <IconButton type="button" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      )}

      {!!onDownload && (
        <IconButton type="button" onClick={onDownload}>
          <ExportIcon />
        </IconButton>
      )}

      {!!onRemove && (
        <IconButton type="button" onClick={onRemove} isDeleteButton>
          <DeleteIcon
            className={css({
              color: theme.colors.red500,
            })}
          />
        </IconButton>
      )}
    </div>
  );
};
