import { useContext, useEffect } from 'react';
import { SharedContext } from './shared-provider';

export const useSharedUser = () => {
  const context = useContext(SharedContext);
  if (context === undefined) {
    throw new Error('useSharedUser must be used within a SharedContext');
  }

  const [state] = context;

  useEffect(() => {
    if (!state.user) {
      state.getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user: state?.user || null };
};
