export const IANA_TIMEZONES = [
  { key: 'America/New_York', label: 'Eastern Time (America/New York)' },
  { key: 'America/Chicago', label: 'Central Time (America/Chicago)' },
  { key: 'America/Los_Angeles', label: 'Pacific Time (America/Los Angeles)' },
  { key: 'America/Denver', label: 'Mountain Time (America/Denver)' },
  { key: 'Pacific/Honolulu', label: 'Hawaiian Time (Pacific/Honolulu)' },
  { key: 'America/Anchorage', label: 'Alaskan Time (America/Anchorage)' },
  { key: 'America/Halifax', label: 'Atlantic Time (America/Halifax)' },
  { key: 'America/Phoenix', label: 'Arizona Time (America/Phoenix)' },
  { key: 'America/Regina', label: 'Saskatchewan Time (America/Regina)' },
  { key: 'America/St_Johns', label: 'Newfoundland Time (America/St Johns)' },
  { key: 'America/Tijuana', label: 'Pacific Time (Mexico) (America/Tijuana)' },
  {
    key: 'America/Chihuahua',
    label: 'Mountain Time (Mexico) (America/Chihuahua)',
  },
  {
    key: 'America/Costa_Rica',
    label: 'Central America Time (America/Costa_Rica)',
  },
  {
    key: 'America/Mexico_City',
    label: 'Central Time (Mexico) (America/Mexico City)',
  },
  { key: 'America/Bogota', label: 'SA Pacific Time (America/Bogota)' },
  {
    key: 'America/Port-au-Prince',
    label: 'Haiti Time (America/Port-au-Prince)',
  },
  { key: 'America/Havana', label: 'Cuba Time (America/Havana)' },
  { key: 'America/Caracas', label: 'Venezuela Time (America/Caracas)' },
  { key: 'America/Asuncion', label: 'Paraguay Time (America/Asuncion)' },
  { key: 'America/Cuiaba', label: 'Central Brazilian Time (America/Cuiaba)' },
  { key: 'America/La_Paz', label: 'SA Western Time (America/La Paz)' },
  { key: 'America/Santiago', label: 'Pacific SA Time (America/Santiago)' },
  {
    key: 'America/Sao_Paulo',
    label: 'E. South America Time (America/Sao Paulo)',
  },
  {
    key: 'America/Argentina/Buenos_Aires',
    label: 'Argentina Time (America/Argentina/Buenos Aires)',
  },
  { key: 'America/Cayenne', label: 'SA Eastern Time (America/Cayenne)' },
  { key: 'America/Godthab', label: 'Greenland Time (America/Godthab)' },
  { key: 'America/Montevideo', label: 'Montevideo Time (America/Montevideo)' },
  { key: 'America/Bahia', label: 'Bahia Time (America/Bahia)' },
  { key: 'America/Noronha', label: 'UTC-02 (America/Noronha)' },
];
