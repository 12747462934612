import { addDays, addMonths, addWeeks, addYears } from 'date-fns';
import { FC, useEffect, useMemo } from 'react';
import { Button, BUTTON_KIND, BUTTON_SIZE } from 'shared/ui';
import { ChevronLeftIcon, ChevronRightIcon } from '../icons';
import { DATE_SELECTOR_TYPE } from './model';
import { getDates, getFormattedDate } from './utils';
import { useStyles } from './styles';

export type DateSelectorProps = {
  date: Date;
  updateDate: (date: Date) => void;
  update?: (dateFrom: Date, dateTo: Date) => void;
  type: DATE_SELECTOR_TYPE;
};

export const DateSelector: FC<DateSelectorProps> = ({
  date,
  updateDate,
  type,
  update,
}) => {
  const { buttonStyle, chevronClass, textClass } = useStyles();

  const dates = useMemo(() => getDates(date, type), [date, type]);
  const formattedDate = useMemo(
    () => getFormattedDate(dates.dateFrom, dates.dateTo, type),
    [dates, type]
  );

  const handleNextDate = () => {
    switch (type) {
      case DATE_SELECTOR_TYPE.YEAR:
        updateDate(addYears(date, 1));
        break;
      case DATE_SELECTOR_TYPE.MONTH:
        updateDate(addMonths(date, 1));
        break;
      case DATE_SELECTOR_TYPE.WEEK:
        updateDate(addWeeks(date, 1));
        break;
      case DATE_SELECTOR_TYPE.DAY:
      default:
        updateDate(addDays(date, 1));
        break;
    }
  };

  const handlePrevDate = () => {
    switch (type) {
      case DATE_SELECTOR_TYPE.YEAR:
        updateDate(addYears(date, -1));
        break;
      case DATE_SELECTOR_TYPE.MONTH:
        updateDate(addMonths(date, -1));
        break;
      case DATE_SELECTOR_TYPE.WEEK:
        updateDate(addWeeks(date, -1));
        break;
      case DATE_SELECTOR_TYPE.DAY:
      default:
        updateDate(addDays(date, -1));
        break;
    }
  };

  useEffect(() => update?.(dates.dateFrom, dates.dateTo), [dates, update]);

  return (
    <Button
      kind={BUTTON_KIND.SECONDARY}
      size={BUTTON_SIZE.SMALL}
      startEnhancer={
        <ChevronLeftIcon className={chevronClass} onClick={handlePrevDate} />
      }
      endEnhancer={
        <ChevronRightIcon className={chevronClass} onClick={handleNextDate} />
      }
      style={buttonStyle}
    >
      <span className={textClass}>{formattedDate}</span>
    </Button>
  );
};
