import { createEvent, createStore } from 'effector';
import { ModalPanelProps, MODAL_PANELS } from 'modals/model';
import { mergeDeepRight } from 'ramda';
import { ModalPanel } from '../../model';

export const openPanel = createEvent<ModalPanel>();
export const closePanel = createEvent();
export const closeAllPanels = createEvent();
export const updateProps = createEvent<
  ModalPanelProps[MODAL_PANELS] | undefined
>();

export const $panel = createStore<ModalPanel | null>(null)
  .on(openPanel, (_, panel) => panel)
  .on(closePanel, () => null)
  .on(updateProps, (panel, updatedProps) => {
    if (!panel || !updatedProps) {
      return panel;
    }
    return {
      ...panel,
      props: mergeDeepRight(panel?.props, updatedProps),
    } as ModalPanel;
  });
