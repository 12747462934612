import { memo } from 'react';
import { Card, StyledBody, StyledAction } from 'baseui/card';
import { ChevronRight } from 'baseui/icon';
import { Button } from 'baseui/button';
import { Tag } from 'baseui/tag';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Block } from 'baseui/block';
import { styled } from 'styletron-react';
import { TherapistCardProps } from '../../Model';
import { ClickableHref } from '../shared/styles';

export function TherapistCard({ therapist }: TherapistCardProps) {
  const ProfileImage = styled('img', {
    width: '160px',
    height: '220px',
    objectFit: 'cover',
    borderRadius: '8px',
  });

  const MemoizedProfileImage = memo(
    ({ imageUrl, name }: { imageUrl: string; name: string }) => (
      <ProfileImage src={imageUrl} alt={name} />
    )
  );

  return (
    <>
      <Card overrides={{ Root: { style: { width: '100%' } } }}>
        <StyledBody>
          <FlexGrid
            flexGridColumnCount={[1, 1, 3]} // 1 column on small screens, 3 columns on larger
            flexGridColumnGap="scale800"
          >
            {/* First column (Profile Image) - 15% width */}
            <FlexGridItem
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    flexBasis: '15%', // Fixed width for this column
                    flexGrow: 0,
                    [$theme.mediaQuery.small]: {
                      flexBasis: '100%', // This column takes up 65%
                      justifyContent: 'center',
                    },
                    [$theme.mediaQuery.medium]: {
                      flexBasis: '100%', // This column takes up 65%
                      justifyContent: 'center',
                    },
                    [$theme.mediaQuery.large]: {
                      flexBasis: '15%', // This column takes up 65%
                      flexGrow: 0, // Prevent from expanding
                      justifyContent: 'center',
                    },
                  }),
                },
              }}
            >
              <Block
                display="flex"
                justifyContent="center"
                // alignItems="center" // Center vertically
                height="100%" // Ensure it takes the full height of the FlexGridItem
              >
                <MemoizedProfileImage
                  imageUrl={therapist.imageUrl}
                  name={therapist.name}
                />
              </Block>
            </FlexGridItem>

            {/* Second column (Therapist Info) - 65% width */}
            <FlexGridItem
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    flexBasis: '62%', // This column takes up 65%
                    flexGrow: 0, // Prevent this from growing beyond 65%
                    [$theme.mediaQuery.small]: {
                      flexBasis: '100%',
                      marginTop: '20px',
                      justifyContent: 'center',
                    },
                    [$theme.mediaQuery.medium]: {
                      flexBasis: '100%',
                      marginTop: '20px',
                    },
                    [$theme.mediaQuery.large]: {
                      flexBasis: '62%', // This column takes up 65%
                      flexGrow: 0, // Prevent this from growing beyond 65%
                      marginTop: '0px',
                    },
                  }),
                },
              }}
            >
              <Block // name, title, location and tags
                overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      display: 'flex',
                      flexWrap: 'wrap', // Wrap elements to handle layout better
                      [$theme.mediaQuery.small]: {
                        flexBasis: '80%',
                        flexGrow: 0,
                        justifyContent: 'center',
                      },
                      [$theme.mediaQuery.medium]: {
                        flexBasis: '80%',
                        flexGrow: 0,
                        justifyContent: 'center',
                      },
                      [$theme.mediaQuery.large]: {
                        flexBasis: '100%',
                        justifyContent: 'space-between', // Space items evenly
                      },
                    }),
                  },
                }}
              >
                {/* Name Block (centered on its own line) */}
                <Block
                  overrides={{
                    Block: {
                      style: ({ $theme }) => ({
                        display: 'flex',
                        justifyContent: 'center', // Center the name
                        alignItems: 'center',
                        width: '100%', // Take full width
                        marginBottom: 'scale300', // Adjust margin if needed
                        [$theme.mediaQuery.large]: {
                          justifyContent: 'flex-start', // Align to the left on large screens
                        },
                      }),
                    },
                  }}
                >
                  <Block
                    font="font600"
                    color="#2a89b0"
                    overrides={{
                      Block: {
                        style: {
                          fontSize: '26px',
                        },
                      },
                    }}
                  >
                    {therapist.name}
                  </Block>
                </Block>

                <Block // title, location and tags
                  overrides={{
                    Block: {
                      style: ({ $theme }) => ({
                        display: 'flex',
                        flexWrap: 'wrap', // Wrap elements to handle layout better
                        [$theme.mediaQuery.small]: {
                          flexBasis: '100%',
                          // flexGrow: 0,
                          justifyContent: 'center',
                          marginTop: '15px',
                        },
                        [$theme.mediaQuery.medium]: {
                          flexBasis: '100%',
                          // flexGrow: 0,
                          justifyContent: 'center',
                          marginTop: '15px',
                        },
                        [$theme.mediaQuery.large]: {
                          flexBasis: '100%',
                          justifyContent: 'space-between', // Space items evenly
                        },
                      }),
                    },
                  }}
                >
                  {/* Title and Location */}
                  <Block
                    overrides={{
                      Block: {
                        style: ({ $theme }) => ({
                          [$theme.mediaQuery.small]: {
                            justifyContent: 'center', // Space items evenly
                            marginTop: '15px',
                          },
                          [$theme.mediaQuery.medium]: {
                            flexBasis: '50%',
                            justifyContent: 'flex-start', // Space items evenly
                            marginTop: '15px',
                          },
                          [$theme.mediaQuery.large]: {
                            justifyContent: 'space-between', // Space items evenly
                          },
                        }),
                      },
                    }}
                    // overrides={{
                    //   Block: {
                    //     style: () => ({
                    //       flexGrow: '1', // Ensure it takes up remaining space
                    //       marginBottom: 'scale300',
                    //     }),
                    //   },
                    // }}
                  >
                    {therapist.title} <br /> {therapist.location}
                  </Block>

                  {/* Tags Block */}
                  <Block
                    overrides={{
                      Block: {
                        style: ({ $theme }) => ({
                          flexWrap: 'wrap', // Allow tags to wrap on smaller screens
                          [$theme.mediaQuery.small]: {
                            justifyContent: 'center',
                            marginTop: '15px',
                          },
                          [$theme.mediaQuery.medium]: {
                            // flexBasis: '50%',
                            justifyContent: 'flex-end',
                            marginTop: '15px',
                          },
                          [$theme.mediaQuery.large]: {},
                        }),
                      },
                    }}
                  >
                    {therapist.verified && (
                      <Tag closeable={false} kind="positive">
                        Verified
                      </Tag>
                    )}
                    {(therapist.endorsements ?? 0) > 0 && (
                      <Tag closeable={false} kind="accent">
                        {therapist.endorsements} Endorsed
                      </Tag>
                    )}
                    {therapist.onlineCounseling && (
                      <Block
                        overrides={{
                          Block: {
                            style: {
                              width: '195px', // Set desired max width
                              overflow: 'visible', // Ensure text doesn't clip
                              whiteSpace: 'nowrap', // Keep text on one line
                            },
                          },
                        }}
                      >
                        <Tag
                          closeable={false}
                          kind="positive"
                          overrides={{
                            Root: {
                              style: {
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'visible',
                                display: 'inline-block',
                              },
                            },
                          }}
                        >
                          Online counseling available
                        </Tag>
                      </Block>
                    )}
                  </Block>
                </Block>
              </Block>

              {/* Bio Block */}
              <Block
                font="font200"
                color="contentPrimary"
                overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 5, // Change this to the number of lines you want to display
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginTop: '10px',
                      [$theme.mediaQuery.small]: {
                        WebkitLineClamp: 10, // Change this to the number of lines you want to display
                      },
                      [$theme.mediaQuery.medium]: {
                        WebkitLineClamp: 10, // Change this to the number of lines you want to display
                      },
                      [$theme.mediaQuery.large]: {
                        WebkitLineClamp: 5, // Change this to the number of lines you want to display
                      },
                    }),
                  },
                }}
              >
                {therapist.bio}
              </Block>
            </FlexGridItem>

            {/* Third column (Contact Info) - 20% width */}
            <FlexGridItem
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    flexDirection: 'column',
                    flexBasis: '17%', // Fixed 20% width for this column
                    flexGrow: 0, // Prevent from expanding
                    [$theme.mediaQuery.small]: {
                      flexBasis: '100%', // This column takes up 65%
                      marginTop: '38px',
                    },
                    [$theme.mediaQuery.medium]: {
                      flexBasis: '100%', // This column takes up 65%
                      marginTop: '38px',
                    },
                    [$theme.mediaQuery.large]: {
                      flexBasis: '17%', // This column takes up 65%
                      flexGrow: 0, // Prevent from expanding
                      marginTop: '38px',
                    },
                  }),
                },
              }}
            >
              {/* <Block
              // display="flex"
              // flexDirection="column"
              // justifyContent="space-between"
              // height="100%"
              > */}
              <Block
                font="font600" // You can adjust this to a larger font style if needed
                color="contentPrimary"
                overrides={{
                  Block: {
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center', // Center the text
                      fontSize: '24px', // Set a larger font size (adjust as needed)
                      // marginBottom: 'scale600', // Maintain bottom margin if needed
                    },
                  },
                }}
              >
                {/* {therapist.phone} */}
              </Block>

              <Block>
                <StyledAction>
                  <Button
                    overrides={{
                      Root: {
                        style: {
                          width: '100%',
                          marginTop: '32px',
                          borderRadius: '8px',
                        },
                      },
                    }}
                  >
                    <ClickableHref href={therapist.bookingUrl}>
                      Book
                    </ClickableHref>
                  </Button>
                  {/* <Button
                    kind="secondary"
                    endEnhancer={() => <ChevronRight size={24} />}
                    overrides={{
                      Root: { style: { width: '100%', marginTop: '24px' } },
                    }}
                  >
                    View
                  </Button> */}
                </StyledAction>
              </Block>
              {/* </Block> */}
            </FlexGridItem>
          </FlexGrid>
        </StyledBody>
      </Card>
    </>
  );
}
