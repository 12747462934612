import { useStore } from 'effector-react';
import { $currentOrganization } from './store';
import { CurrentOrganization } from './types';

export const useCurrentOrganization: () => {
  organization: CurrentOrganization;
} = () => {
  const organization = useStore($currentOrganization);
  if (!organization) {
    throw new Error('Organization not init');
  }

  return { organization };
};
