import { ReactNode } from 'react';
import { SelectProps as BaseSelectProps, Option } from 'baseui/select';
import { StyleObject } from 'styletron-standard';

export type { Option, Value, OnChangeParams } from 'baseui/select';

export enum SELECT_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum SELECT_KIND {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export type SelectProps = Pick<
  BaseSelectProps,
  | 'onChange'
  | 'onInputChange'
  | 'onBlur'
  | 'options'
  | 'value'
  | 'placeholder'
  | 'type'
  | 'searchable'
  | 'creatable'
  | 'labelKey'
  | 'valueKey'
  | 'isLoading'
  | 'multi'
> & {
  size?: SELECT_SIZE;
  kind?: SELECT_KIND;
  style?: StyleObject;
  startEnhancer?: ReactNode;
  hasError?: boolean;
  dropdownOverrideStyles?: StyleObject;
  isAvailableChooseDisabled?: boolean;
  dropdownItemStyle?: StyleObject;
};

export type TimezonePickerProps = Omit<SelectProps, 'value' | 'onChange'> & {
  value?: string;
  onChange?: (value: Option) => void;
};
