import { StyleObject } from 'styletron-standard';
import { IS_NO_TOUCH, styled } from 'shared/ui';
import { theme } from 'pages/session-room/ui/theme';
import { SESSION_ROOM_COLORS } from '../../../theme/colors';

export const Header = styled('header', {
  padding: '16px 32px',
  backgroundColor: 'rgba(17, 17, 17, 0.3)',
  display: 'flex',
  alignItems: 'center',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
});

export const HeaderLeft = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  flex: '1 1 auto',
});

export const HeaderRight = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  flexShrink: 0,
});

export const Title = styled('h3', {
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '32px',
  color: SESSION_ROOM_COLORS.white,
});

const timeStyles: StyleObject = {
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '24px',
};

export const StartTime = styled('time', {
  ...timeStyles,
  color: SESSION_ROOM_COLORS.white,
});

export const Duration = styled('time', {
  ...timeStyles,
  color: SESSION_ROOM_COLORS.gray,
});

export const ResetButton = styled('button', {
  color: theme.colors.white,
  padding: '8px',
  margin: '-8px',
  backgroundColor: theme.colors.blue,
  borderRadius: '12px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.transparentWhite,

  [IS_NO_TOUCH]: {
    transition: 'color 0.2s, background-color 0.2s',

    ':hover': {
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
    },
  },
});
