import { FC, useMemo } from 'react';
import { useStyletron, MOBILE } from 'shared/ui';
import { ChevronLeftIcon, ChevronRightIcon } from 'shared/ui/icons';

type NavigationButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  type: 'LEFT' | 'RIGHT';
};

export const NavigationButton: FC<NavigationButtonProps> = ({
  onClick,
  disabled = false,
  type,
}) => {
  const [css, theme] = useStyletron();

  const iconClass = useMemo(
    () => css({ height: '24px', width: '24px', color: theme.colors.gray400 }),
    [css, theme]
  );

  const isLeft = type === 'LEFT';

  const buttonClass = useMemo(
    () =>
      css({
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.colors.gray200,
        backgroundColor: theme.colors.white,
        borderTopLeftRadius: isLeft ? '8px' : 0,
        borderTopRightRadius: isLeft ? 0 : '8px',
        borderBottomRightRadius: isLeft ? 0 : '8px',
        borderBottomLeftRadius: isLeft ? '8px' : 0,

        [MOBILE]: {
          width: '49px',
        },
        ':hover': {
          backgroundColor: theme.colors.gray50,
        },
        ':disabled': {
          pointerEvents: 'none',
        },
      }),
    [css, theme, isLeft]
  );

  return (
    <button
      disabled={disabled}
      className={buttonClass}
      onClick={onClick}
      type="button"
    >
      {isLeft ? (
        <ChevronLeftIcon className={iconClass} />
      ) : (
        <ChevronRightIcon className={iconClass} />
      )}
    </button>
  );
};
