import { useEffect } from 'react';

export const useWhiteBgColor = () => {
  useEffect(() => {
    document.body.style.setProperty('background-color', 'white');
    return () => {
      document.body.style.removeProperty('background-color');
    };
  }, []);
};
