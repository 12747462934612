import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSessionRoomDispatch,
  useSessionRoomState,
} from 'pages/session-room/context';
import {
  HeaderCloseButton,
  HeaderCloseIcon,
  HeaderTitle,
  DragArea,
  useStyles,
  HeaderLeft,
} from './styles';
import { ThemeSwitcher } from '../theme-switcher';

export const ChatHeader: FC = () => {
  const temporaryPosition = useRef({
    x: 0,
    y: 0,
    width: 672,
    height: 640,
  });
  const { t } = useTranslation(['session-room']);
  const { getHeaderStyles, getDragStyles } = useStyles();
  const { chatOptions } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();
  const handleClose = useCallback(() => {
    dispatch({
      type: 'TOGGLE_SHOW_CHAT',
      payload: {
        isShow: false,
      },
    });
  }, [dispatch]);
  const handleMove = useCallback(
    (e) => {
      const [x, y] = [
        e.pageX - temporaryPosition.current.x < 0
          ? 0
          : e.pageX - temporaryPosition.current.x,
        e.pageY - temporaryPosition.current.y < 0
          ? 0
          : e.pageY - temporaryPosition.current.y,
      ];
      e.stopPropagation();
      e.preventDefault();

      dispatch({
        type: 'REFRESH_CHAT_OPTIONS',
        payload: {
          x,
          y,
        },
      });
    },
    [dispatch]
  );
  const handleMouseDown = useCallback(
    (e) => {
      const { x, y, width, height } = (
        chatOptions.DOMObject as HTMLDivElement
      ).getBoundingClientRect();

      e.stopPropagation();
      e.preventDefault();

      temporaryPosition.current = {
        x: e.pageX - x,
        y: e.pageY - y,
        width,
        height,
      };

      document.addEventListener('mousemove', handleMove);

      dispatch({
        type: 'REFRESH_CHAT_OPTIONS',
        payload: {
          isDragging: true,
        },
      });
    },
    [chatOptions.DOMObject, dispatch, handleMove]
  );
  const handleMouseUp = useCallback(() => {
    dispatch({
      type: 'REFRESH_CHAT_OPTIONS',
      payload: {
        isDragging: false,
      },
    });

    document.removeEventListener('mousemove', handleMove);
  }, [dispatch, handleMove]);

  return (
    <header className={getHeaderStyles(chatOptions.isDarkTheme ?? false)}>
      <DragArea
        style={getDragStyles(chatOptions.isDragging ?? false)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
      <HeaderLeft>
        <HeaderTitle>{t('session-room:CHAT.TITLE')}</HeaderTitle>
        <ThemeSwitcher />
      </HeaderLeft>
      <HeaderCloseButton type="button" onClick={handleClose}>
        <HeaderCloseIcon />
      </HeaderCloseButton>
    </header>
  );
};
