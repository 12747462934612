import {
  FC,
  useMemo,
  cloneElement,
  Children,
  isValidElement,
  ReactNode,
} from 'react';
import {
  StatefulPopover,
  BasePopoverProps,
  PopoverOverrides,
} from 'baseui/popover';
import {
  Menu,
  MenuList,
  useStyletron,
  useMobileMediaQuery,
  Desktop,
  Mobile,
} from 'shared/ui';
import { useModal, MODAL_TYPE } from 'shared/lib';

type Props = {
  list: MenuList[];
  title?: string;
  header?: ReactNode;
  setIsOpen?: (isOpen: boolean) => void;
  popperOptions?: BasePopoverProps['popperOptions'];
  overrides?: PopoverOverrides;
};

export const ContextMenu: FC<Props> = ({
  children,
  list,
  title,
  header,
  setIsOpen,
  popperOptions = { placement: 'bottom-end' },
  overrides,
}) => {
  const [, theme] = useStyletron();
  const isMobile = useMobileMediaQuery();
  const { open: openModal, close: closeModal } = useModal();

  const filteredList = useMemo(
    () =>
      list?.reduce((acc, current) => {
        if (current.items.length === 0) {
          return acc;
        }
        return [...acc, current];
      }, [] as MenuList[]),
    [list]
  );

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isMobile) {
      openModal(MODAL_TYPE.BASE, {
        title,
        children: <Menu list={filteredList} onClose={closeModal} />,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props: any = { onClick };

  if (filteredList.length === 0) {
    return null;
  }

  return (
    <>
      <Desktop>
        <StatefulPopover
          onOpen={() => setIsOpen?.(true)}
          onClose={() => setIsOpen?.(false)}
          overrides={{
            Body: {
              style: {
                zIndex: 90,
                boxShadow: 'none',
              },
            },
            Inner: {
              style: {
                width: 'auto',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                border: `1px solid ${theme.colors.gray200}`,
                backgroundColor: theme.colors.white900,
                boxShadow:
                  '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                overflow: 'hidden',
              },
            },
            ...overrides,
          }}
          autoFocus={false}
          popperOptions={popperOptions}
          popoverMargin={8}
          ignoreBoundary
          content={({ close }) => (
            <>
              {header}
              <Menu list={filteredList} onClose={close} />
            </>
          )}
          accessibilityType="menu"
          {...props}
        >
          {children}
        </StatefulPopover>
      </Desktop>

      <Mobile>
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, { onClick: props.onClick });
          }
          return child;
        })}
      </Mobile>
    </>
  );
};
