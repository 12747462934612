import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounceValue } from 'shared/lib';
import { Input, INPUT_SIZE, useMobileMediaQuery } from 'shared/ui';

import { SearchInputInterface } from './model';
import { StyledSearchIcon, useStyles } from './styles';

export const SearchInput: FC<SearchInputInterface> = ({
  placeholder,
  initialValue = '',
  onChange,
  debounceMs = 500,
  ...props
}) => {
  const isMobile = useMobileMediaQuery();
  const { t } = useTranslation(['common']);
  const { searchInputStyled } = useStyles();
  const placeholderText = useMemo(
    () => placeholder ?? t('common:SEARCH.SEARCH'),
    [placeholder, t]
  );
  const inputTextSize = useMemo(
    () => (isMobile ? INPUT_SIZE.MEDIUM : INPUT_SIZE.SMALL),
    [isMobile]
  );
  const [inputValue, debounceInputValue, setInputValue] = useDebounceValue(
    initialValue,
    debounceMs
  );
  const handleChange = useCallback(
    (e) => setInputValue(e.currentTarget.value),
    [setInputValue]
  );
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        setInputValue('');
      }
    },
    [setInputValue]
  );

  useEffect(() => onChange(debounceInputValue), [debounceInputValue, onChange]);

  return (
    <Input
      size={inputTextSize}
      startEnhancer={<StyledSearchIcon />}
      placeholder={placeholderText}
      style={searchInputStyled}
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
};
