import { useCallback, useMemo } from 'react';
import { StyleObject } from 'styletron-standard';
import {
  useStyletron,
  styled,
  UnmuteIcon,
  FullscreenIcon,
  UnfullscreenIcon,
  useMobileMediaQuery,
  MOBILE,
  IS_NO_TOUCH,
} from 'shared/ui';
import { theme } from '../theme';

export const UserName = styled('div', {
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: 1.6,
  color: theme.colors.white,
});

export const MutedIconContainer = styled('div', {
  position: 'absolute',
  bottom: 0,
  left: 0,
  padding: '0.6666666667em',
  backgroundColor: 'rgba(16, 21, 27, 0.92)',
  borderTopRightRadius: '24px',
});

export const MutedIcon = styled(UnmuteIcon, {
  width: '1em',
  height: '1em',
});

export const PinBar = styled('button', {
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(16, 21, 27, 0.92)',
  padding: '4px 8px 4px 12px',
  borderBottomRightRadius: '8px',
});

export const FullscreenButton = styled('button', {
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: '0.6666666667em',
  backgroundColor: 'rgba(16, 21, 27, 0.92)',
  borderTopLeftRadius: '24px',
  color: '#374151',
  [IS_NO_TOUCH]: {
    transition: 'color 0.2s',
    ':hover': {
      color: theme.colors.white,
    },
  },
});

export const UnfullscreenStyledIcon = styled(UnfullscreenIcon, {
  width: '1em',
  height: '1em',
});

export const FullscreenStyledIcon = styled(FullscreenIcon, {
  width: '1em',
  height: '1em',
});

export const useStyles = () => {
  const [css] = useStyletron();
  const isMobile = useMobileMediaQuery();

  const baseRootStyles: StyleObject = useMemo(
    () => ({
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      width: '260px',
      height: '146px',
      backgroundColor: theme.colors.blue,
      borderRadius: '24px',
      boxShadow: '0 32px 48px -2px rgba(14, 31, 53, 0.2)',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      fontSize: '16px',

      [MOBILE]: {
        width: '136px',
        height: '168px',
      },
    }),
    []
  );

  const baseRootMainStyles: StyleObject = useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      right: '284px',
      bottom: 0,
      left: 0,
      width: 'auto',
      height: 'auto',
      fontSize: '24px',

      [MOBILE]: {
        top: 'auto',
        height: '88%',
        border: 'none',
        borderRadius: 0,
        left: '-16px',
        right: '-16px',
        zIndex: 0,
      },
    }),
    []
  );

  const rootClass = useCallback(
    (isMain, isFull, isPinned) => {
      const rightOffset = isMobile ? 0 : isFull ? 0 : '284px';

      return css(
        isMain || isFull
          ? {
              ...baseRootStyles,
              ...{
                ...baseRootMainStyles,
                right: rightOffset,
              },
              borderColor: isPinned ? theme.colors.gray : undefined,
              ':fullscreen': {
                border: 'none',
                borderRadius: 0,
              },
            }
          : baseRootStyles
      );
    },
    [baseRootMainStyles, baseRootStyles, css, isMobile]
  );

  const videoKeeperClass = useCallback(
    (hasVideo) =>
      css({
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        opacity: hasVideo ? '1' : '0',
      }),
    [css]
  );

  const videoClass = useMemo(
    () =>
      css({
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }),
    [css]
  );

  return {
    rootClass,
    videoKeeperClass,
    videoClass,
  };
};
