import { FC, useCallback } from 'react';
import { useModal, MODAL_TYPE } from 'shared/lib';
import { useStyles, Image } from './styles';

const Content: FC<{
  imageUrl: string | null;
}> = ({ imageUrl }) => (imageUrl ? <Image src={imageUrl} alt="" /> : null);

export const useExpandImage = () => {
  const { open } = useModal();
  const { modalStyle } = useStyles();

  const expand = useCallback(
    (imageUrl: string | null) => {
      open(MODAL_TYPE.BASE, {
        children: <Content imageUrl={imageUrl} />,
        style: modalStyle,
      });
    },
    [open, modalStyle]
  );

  return expand;
};
