import { FC } from 'react';

import { TableSortIconProps } from './model';
import { useStyles } from './styles';

export const TableSortIcon: FC<TableSortIconProps> = ({ type }) => {
  const { rootClass, upArrowColor, downArrowColor } = useStyles(type);

  return (
    <svg
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={rootClass}
    >
      <path
        fill={upArrowColor}
        d="M9.367 10.286h5.908c.55 0 .824-.665.437-1.052l-2.953-3.053a.615.615 0 0 0-.87 0L8.93 9.235a.616.616 0 0 0 .436 1.051Z"
      />
      <path
        fill={downArrowColor}
        d="M15.274 13.715H9.367a.616.616 0 0 0-.436 1.051l2.958 3.054c.118.12.256.18.432.18.158 0 .315-.06.436-.18l2.953-3.054a.616.616 0 0 0-.436-1.051Z"
      />
    </svg>
  );
};
