import { FC } from 'react';
import { useStyletron, TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

interface TimeSlotProps {
  onClick: () => void;
  isAvailable: boolean;
  isActive: boolean;
  canSelectNotAvailable: boolean;
}

export const TimeSlot: FC<TimeSlotProps> = ({
  children,
  onClick,
  isAvailable,
  isActive,
  canSelectNotAvailable,
}) => {
  const [css, theme] = useStyletron();

  enum KIND {
    AVAILABLE = 'normal',
    AVAILABLE_ACTIVE = 'active',
    UNAVAILABLE = 'unavailable',
    UNAVAILABLE_ACTIVE = 'active-unavailable',
  }

  const STYLES = new Map<KIND, StyleObject>([
    [
      KIND.AVAILABLE,
      {
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderTopColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        ':hover': {
          borderLeftColor: theme.colors.gray300,
          borderRightColor: theme.colors.gray300,
          borderTopColor: theme.colors.gray300,
          borderBottomColor: theme.colors.gray300,
          color: theme.colors.accent500,
        },
      },
    ],
    [
      KIND.AVAILABLE_ACTIVE,
      {
        backgroundColor: theme.colors.accent500,
        color: theme.colors.white900,
      },
    ],
    [
      KIND.UNAVAILABLE,
      {
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderTopColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        background: `repeating-linear-gradient(-45deg, ${theme.colors.white900}, ${theme.colors.white900} 6px, ${theme.colors.gray100} 6px, ${theme.colors.gray100} 12px)`,
        color: theme.colors.gray400,
        opacity: 0.5,
        ':hover': {
          opacity: 1,
        },
      },
    ],
    [
      KIND.UNAVAILABLE_ACTIVE,
      {
        background: `repeating-linear-gradient(-45deg, ${theme.colors.accent500}, ${theme.colors.accent500} 6px, ${theme.colors.accent400} 6px, ${theme.colors.accent400} 12px)`,
        color: theme.colors.white900,
      },
    ],
  ]);

  let timeSlotKind = KIND.UNAVAILABLE;

  if (isAvailable && isActive) {
    timeSlotKind = KIND.AVAILABLE_ACTIVE;
  } else if (isAvailable && !isActive) {
    timeSlotKind = KIND.AVAILABLE;
  } else if (!isAvailable && isActive) {
    timeSlotKind = KIND.UNAVAILABLE_ACTIVE;
  }

  const isDisabled = !canSelectNotAvailable && !isAvailable;

  return (
    <button
      disabled={isDisabled}
      className={css({
        width: '100%',
        height: '40px',
        textAlign: 'center',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        transitionDuration: '0.2s',
        transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
        transitionProperty: 'border-color background, color',
        ...TEXT.sm_book,
        ...STYLES.get(timeSlotKind),
        pointerEvents: isDisabled ? 'none' : 'auto',
      })}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
};
