import { forwardRef, FC, useMemo } from 'react';
import { useStyletron } from 'shared/ui';
import { SharedStylePropsT } from 'baseui/datepicker';

type DayCellProps = Pick<
  SharedStylePropsT,
  '$pseudoSelected' | '$startDate' | '$selected' | '$range'
> & {
  isOutsideMonth: boolean;
  isToday: boolean;
  isOutdate: boolean;
  isAvailable: boolean;
  canSelectNotAvailable?: boolean;
  onClick: () => void;
  onMouseOver: () => void;
  onFocus: () => void;
  onMouseLeave: () => void;
  onBlur: () => void;
  isAppointmentDots: boolean;
  clickable: boolean;
  appointmentColors: string[];
  $endDate: boolean;
};

export const DayCell: FC<DayCellProps> = forwardRef<
  HTMLButtonElement,
  DayCellProps
>((props, ref) => {
  const {
    onClick,
    isOutsideMonth,
    isToday,
    isOutdate,
    isAvailable,
    canSelectNotAvailable,
    onMouseOver,
    onFocus,
    onMouseLeave,
    onBlur,
    isAppointmentDots,
    clickable,
    appointmentColors,
    $pseudoSelected,
    $startDate,
    $endDate,
    $selected,
    $range,
  } = props;
  const [css, theme] = useStyletron();

  const dayBg = useMemo(() => {
    if (isOutsideMonth && isAvailable) {
      return 'var(--tru-gray100)';
    }
    if (isOutsideMonth && !isAvailable) {
      return 'repeating-linear-gradient(-45deg, var(--tru-gray100), var(--tru-gray100) 8px, var(--tru-gray50) 8px, var(--tru-gray50) 16px)';
    }
    if (!isOutsideMonth && !isAvailable) {
      return 'repeating-linear-gradient(-45deg, var(--tru-gray100), var(--tru-gray100) 8px, var(--tru-white900) 8px, var(--tru-white900) 16px)';
    }
    return 'initial';
  }, [isOutsideMonth, isAvailable]);

  const dayColor = useMemo(() => {
    if (isAppointmentDots && isToday) {
      return theme.colors.accent;
    }
    if ($startDate || $endDate || $selected || $pseudoSelected) {
      return theme.colors.white;
    }
    if (isToday) {
      return theme.colors.accent;
    }
    return 'inherit';
  }, [
    isAppointmentDots,
    isToday,
    theme,
    $startDate,
    $endDate,
    $pseudoSelected,
    $selected,
  ]);

  const selectionBg = useMemo(() => {
    if (isAppointmentDots) {
      return 'initial';
    }
    if (!$range && $selected) {
      return theme.colors.gray700;
    }
    return 'initial';
  }, [$range, $selected, isAppointmentDots, theme]);

  const isDisabled = canSelectNotAvailable ? false : !isAvailable;

  const renderRangeBg = () => {
    if ($pseudoSelected) {
      return (
        <span
          className={css({
            position: 'absolute',
            top: '4px',
            bottom: '4px',
            left: '-1px',
            right: 0,
            backgroundColor: theme.colors.accent,
          })}
        />
      );
    }
    if ($endDate && !$startDate) {
      return (
        <span
          className={css({
            position: 'absolute',
            top: '4px',
            bottom: '4px',
            left: '-1px',
            right: '8px',
            borderTopRightRadius: '999px',
            borderBottomRightRadius: '999px',
            backgroundColor: theme.colors.accent,
          })}
        />
      );
    }
    if ($startDate || $selected) {
      return (
        <span
          className={css({
            position: 'absolute',
            top: '4px',
            bottom: '4px',
            left: '8px',
            right: 0,
            borderTopLeftRadius: '999px',
            borderBottomLeftRadius: '999px',
            backgroundColor: theme.colors.accent,
          })}
        />
      );
    }
    return null;
  };

  return (
    <button
      disabled={isDisabled}
      ref={ref}
      className={css({
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: isToday ? '700' : '400',
        background: dayBg,
        color: dayColor,
        ':not(:first-child)': {
          borderLeft: '1px solid var(--tru-gray200)',
        },
        pointerEvents: isOutdate || isDisabled || !clickable ? 'none' : 'auto',

        '::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          backgroundColor: selectionBg,
          transition: 'background-color 0.2s cubic-bezier(0, 0, 1, 1)',
        },

        ':hover::before': {
          backgroundColor:
            !$range && $selected ? theme.colors.gray700 : theme.colors.gray200,
        },
      })}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      onBlur={onBlur}
      type="button"
    >
      {$range && renderRangeBg()}
      <span
        className={css({
          position: 'relative',
        })}
      >
        {props.children}
      </span>
      {isAppointmentDots && (
        <span
          className={css({
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: '6px',
            display: 'flex',
            justifyContent: 'center',
            gap: '4px',
            overflow: 'hidden',
          })}
        >
          {appointmentColors.map((color, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={css({
                flexShrink: 0,
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                backgroundColor: color,
              })}
            />
          ))}
        </span>
      )}
    </button>
  );
});
