import { LoginResponse, NewPasswordRequired } from 'shared/api';

export interface InputTokens {
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  expires?: number;
}

export function isNewPasswordRequiredResponse(
  res: LoginResponse
): res is NewPasswordRequired {
  return (res as NewPasswordRequired).Session !== undefined;
}
