import { Block } from 'baseui/block';
import { Link } from 'shared/ui';
import { styled } from 'styletron-react';

export const Header = styled(Block, {
  height: '113px', // Fixed height for the header
  minWidth: '516px',
  marginBottom: 'scale800', // Separation from the body
  backgroundColor: 'rgb(66, 168, 209)', // Background color
  color: 'white', // White font color
  padding: '0 16px', // Add padding to the header
  display: 'flex', // Flexbox to align items
  alignItems: 'center', // Vertically center the content
  justifyContent: 'space-between', // Ensure space between logo and text if needed
  boxSizing: 'border-box', // Ensure padding is included in width
});

export const Wrapper = styled('div', {
  width: '90%', // Limit width to 80%
  maxWidth: '1200px', // Optional: Set a maximum width for larger screens
  margin: '0 auto', // Center the component
  padding: '16px', // Optional: Add some padding
  boxSizing: 'border-box', // Include padding in the total width
});

export const ClickableLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  height: '100%', // Ensure the logo fills the height of the header
  cursor: 'pointer', // Change cursor to pointer for better UX
  color: 'white !important', // Inherit color from the parent header
  textDecoration: 'none !important', // Remove underline
  ':hover': {
    textDecoration: 'none',
  },
  ':visited': {
    textDecoration: 'none',
  },
});

export const ClickableHref = styled('a', {
  display: 'flex',
  alignItems: 'center',
  height: '100%', // Ensure the logo fills the height of the header
  cursor: 'pointer', // Change cursor to pointer for better UX
  color: 'white !important', // Inherit color from the parent header
  textDecoration: 'none !important', // Remove underline
  ':hover': {
    textDecoration: 'none',
  },
  ':visited': {
    textDecoration: 'none',
  },
});

export const Logo = styled('img', {
  display: 'flex',
  maxHeight: '80%', // Constrain the logo to the height of the header
  height: 'auto', // Maintain aspect ratio based on the height
  maxWidth: 'calc(100vw - 32px)', // Ensure logo doesn't exceed the viewport width minus padding
  width: 'auto', // Maintain aspect ratio based on the smaller dimension
  marginRight: '16px', // Space between logo and text
  objectFit: 'contain', // Prevent distortion, ensuring the image fits its container
});
