import { createEffect, createStore } from 'effector';
import { ModalPanel } from 'modals/_manager/model';
import { SidebarPanelEvent } from 'shared/ui';

export const hideScrollBarFx = createEffect(() => {
  document.documentElement.style.paddingRight = `${
    window.innerWidth - document.documentElement.offsetWidth
  }px`;
  document.documentElement.classList.add('is-fixed_sidebar');
});

export const unsetScrollBarFx = createEffect(() => {
  document.documentElement.style.paddingRight = '';
  document.documentElement.classList.remove('is-fixed_sidebar');
});

export const onAllModalCloseFx = createEffect(
  ({ panels }: { panels: ModalPanel[] }) => {
    panels.forEach(({ sidebarProps: { onClose } }) => {
      onClose?.(SidebarPanelEvent.LocationChange);
    });
  }
);

export const $panelStack = createStore<ModalPanel[]>([]);
