import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatMessageInterface } from './model';
import {
  MessageWrapper,
  Who,
  MessageBody,
  Avatar,
  AvatarInitials,
  AvatarImage,
  MessageBalloon,
  useStyles,
} from './styles';

export const Message: FC<ChatMessageInterface> = memo(
  ({ who, avatarUrl, isDark = false, children }) => {
    const { t } = useTranslation(['session-room']);
    const { getMessageColor, getTextColor } = useStyles();
    const isYou = !who;
    const username = isYou ? t('session-room:CHAT.YOU') : who;
    const normalizedUsername = useMemo(
      () =>
        username
          .split(' ')
          .map((word) => word[0])
          .join(''),
      [username]
    );

    return (
      <MessageWrapper style={getMessageColor(isYou, isDark)}>
        <Who>{username}</Who>

        <MessageBody>
          <Avatar>
            <AvatarInitials>{normalizedUsername}</AvatarInitials>
            {avatarUrl ? (
              <AvatarImage src={avatarUrl} alt={username} loading="lazy" />
            ) : null}
          </Avatar>

          <MessageBalloon>
            <p className={getTextColor(isDark)}>{children}</p>
          </MessageBalloon>
        </MessageBody>
      </MessageWrapper>
    );
  }
);
