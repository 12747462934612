import { NotificationChannelsEnum } from 'shared/api';

export type Invite = {
  channel: NotificationChannelsEnum;
  value?: string;
};

export const getInviteParams = (invite: Invite) => {
  const params = new Map<
    NotificationChannelsEnum,
    { email?: string; sms?: string }
  >([
    [NotificationChannelsEnum.Mail, { email: invite.value }],
    [NotificationChannelsEnum.Sms, { sms: invite.value }],
    [NotificationChannelsEnum.None, {}],
  ]);

  return {
    default_notification_channel: invite.channel,
    ...params.get(invite.channel),
  };
};
