import { FC, useMemo } from 'react';
import { useHasTrack } from '../../hooks/use-has-track';
import { useMediaInfo } from '../../hooks/use-media-info';
import { VideoButton } from './video-button';
import { AudioButton } from './audio-button';

export const MediaActions: FC = () => {
  const { hasVideo, toggleVideo, hasAudio, toggleAudio } = useHasTrack();
  const { currentVideoDeviceId, currentAudioDeviceId, devicesList } =
    useMediaInfo();
  const audioContextMenu = useMemo(
    () =>
      devicesList.audio.map((item) => ({
        ...item,
        isSelected: item.deviceId === currentAudioDeviceId,
      })),
    [currentAudioDeviceId, devicesList.audio]
  );
  const videoContextMenu = useMemo(
    () =>
      devicesList.video.map((item) => ({
        ...item,
        isSelected: item.deviceId === currentVideoDeviceId,
      })),
    [currentVideoDeviceId, devicesList.video]
  );

  return (
    <>
      <AudioButton
        isOn={hasAudio}
        onToggleState={toggleAudio}
        contextMenu={audioContextMenu}
      />
      <VideoButton
        isOn={hasVideo}
        onToggleState={toggleVideo}
        contextMenu={videoContextMenu}
      />
    </>
  );
};
