import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useStyles } from './styles';

export const TitleTab: FC<{ to: string }> = ({ to, children }) => {
  const { tabClass } = useStyles();

  return (
    <NavLink end to={to} className={({ isActive }) => tabClass(isActive)}>
      {children}
    </NavLink>
  );
};
