import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useSessionRoomState } from '../../context';

export const EndCallButton: FC = () => {
  const { isHost, session } = useSessionRoomState();
  const { t } = useTranslation(['session-room']);
  const navigate = useNavigate();
  const endButtonText = useMemo(
    () =>
      isHost ? t('session-room:END_MEETING') : t('session-room:LEAVE_MEETING'),
    [isHost, t]
  );
  const handleEndMeeting = useCallback(() => {
    if (isHost) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (session as any).connections.forEach((connection: any) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (session as any).forceDisconnect(connection.id)
      );
    }
    window.close();

    if (!window.closed) {
      navigate(-1);
    }
  }, [isHost, navigate, session]);
  const { rootClass } = useStyles();

  return (
    <button type="button" className={rootClass} onClick={handleEndMeeting}>
      {endButtonText}
    </button>
  );
};
