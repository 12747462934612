import { DEFAULT_TIMEZONE } from './default-timezone';
import { IANA_TIMEZONES } from './timezones';

const availableTimezones = IANA_TIMEZONES.map(({ key }) => key);

export const getCurrentTimeZone = () => {
  let res = DEFAULT_TIMEZONE;
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (availableTimezones.includes(timezone)) {
      res = DEFAULT_TIMEZONE;
    }
  } catch {
    res = DEFAULT_TIMEZONE;
  }

  return res;
};
