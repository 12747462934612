import { limitPageBounds } from './get-meta';
import {
  CurrentPageActions,
  PageActions,
  PageSizeActions,
  PaginationState,
  PaginationStateReducerActions,
  TotalItemsActions,
} from './types';

const getCurrentPageReducer = (rootState: PaginationState) =>
  function currentPageReducer(
    state: PaginationState['currentPage'],
    action: PaginationStateReducerActions
  ) {
    switch (action.type) {
      case PageActions.SET_PAGE:
        return limitPageBounds(
          rootState.totalItems,
          rootState.pageSize
        )(action.page);
      case PageActions.NEXT_PAGE:
        return limitPageBounds(
          rootState.totalItems,
          rootState.pageSize
        )(state + 1);
      case PageActions.PREVIOUS_PAGE:
        return limitPageBounds(
          rootState.totalItems,
          rootState.pageSize
        )(state - 1);
      case PageActions.SET_PAGE_SIZE:
        return limitPageBounds(rootState.totalItems, action.pageSize)(state);
      case PageActions.SET_TOTAL_ITEMS:
        return limitPageBounds(action.totalItems, rootState.pageSize)(state);
      default:
        return state;
    }
  };

const totalItemsReducer: (
  state: PaginationState['totalItems'],
  action: TotalItemsActions
) => PaginationState['totalItems'] = (state, action) => {
  switch (action.type) {
    case PageActions.SET_TOTAL_ITEMS:
      return action.totalItems;
    default:
      return state;
  }
};

const pageSizeReducer: (
  state: PaginationState['pageSize'],
  action: PageSizeActions
) => PaginationState['pageSize'] = (state, action) => {
  switch (action.type) {
    case PageActions.SET_PAGE_SIZE:
      return action.pageSize;
    default:
      return state;
  }
};

export const paginationStateReducer: (
  state: PaginationState,
  action: PaginationStateReducerActions
) => PaginationState = (state, action) => ({
  currentPage: getCurrentPageReducer(state)(
    state.currentPage,
    action as CurrentPageActions
  ),
  totalItems: totalItemsReducer(state.totalItems, action as TotalItemsActions),
  pageSize: pageSizeReducer(state.pageSize, action as PageSizeActions),
});
