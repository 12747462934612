export interface IResizableImage {
  get: (size?: string | { w?: number; h?: number }) => string | null;
  srcset: ({ w, h }: { w?: number; h?: number }) => string | null;
  getFormat: () => string | null;
}

export class ResizableImage implements IResizableImage {
  constructor(private _url: string | null) {}

  private _getUrl(): URL | null {
    if (!this._url) {
      return null;
    }
    try {
      const url = new URL(this._url);
      return url;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[ResizableImage] invalid url', this._url, e);
      return null;
    }
  }

  get(size?: string | { w?: number; h?: number }): string | null {
    const url = this._getUrl();
    if (!url) {
      return null;
    }
    if (!size) {
      return this._url;
    }

    if (typeof size === 'string') {
      return `${url.origin}/${size}${url.pathname}`;
    }

    switch (true) {
      case size.h && !size.w: {
        return `${url.origin}/h${size.h}${url.pathname}`;
      }
      case !size.h && size.w: {
        return `${url.origin}/w${size.w}${url.pathname}`;
      }
      default:
        return `${url.origin}/${size.w}x${size.h}${url.pathname}`;
    }
  }

  srcset({ w, h }: { w?: number; h?: number }) {
    const url = this._getUrl();
    if (!url || (w === undefined && h === undefined)) {
      return null;
    }

    let oneX: string;
    let twoX: string;
    if (w && !h) {
      oneX = `${url.origin}/w${w}${url.pathname}`;
      twoX = `${url.origin}/w${w * 2}${url.pathname}`;
    } else if (!w && h) {
      oneX = `${url.origin}/h${h}${url.pathname}`;
      twoX = `${url.origin}/h${h * 2}${url.pathname}`;
    } else {
      oneX = `${url.origin}/${w}x${h}${url.pathname}`;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      twoX = `${url.origin}/${w! * 2}x${h! * 2}${url.pathname}`;
    }

    return `${oneX} 1x, ${twoX} 2x`;
  }

  getFormat(): string | null {
    return this._getUrl()?.pathname?.split?.('.')?.slice?.(-1)[0] ?? null;
  }
}
