import { intervalToDuration } from 'date-fns';

export const humanReadableSeconds = (durationSeconds: number): string => {
  if (!durationSeconds) {
    return '–';
  }

  const { hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: new Date(0).setSeconds(durationSeconds),
  });

  return [hours, minutes || '00', seconds]
    .filter(Boolean)
    .map((value) => `0${value}`.slice(-2))
    .join(':');
};
