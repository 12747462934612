import { FC, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StreamDataInterface, StreamWrapInterface } from './model';
import {
  useStyles,
  UserName,
  MutedIconContainer,
  MutedIcon,
  PinBar,
  FullscreenButton,
  FullscreenStyledIcon,
  UnfullscreenStyledIcon,
} from './styles';
import { useSessionRoomDispatch } from '../../context';
import { toggleFullScreenForElement } from '../../utils';

export const StreamWrap: FC<StreamWrapInterface> = ({
  isMain = false,
  isPinned = false,
  isFull = false,
  videoEl,
  context,
  onTalking,
  hasAudio = true,
  hasVideo = true,
}) => {
  const { t } = useTranslation(['session-room']);
  const [isShowActions, setIsShowActions] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const streamRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { stream } = context;
  const dispatch = useSessionRoomDispatch();
  const connectionInfo: StreamDataInterface = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => JSON.parse(stream!.connection.data),
    [stream]
  );
  const { rootClass, videoKeeperClass, videoClass } = useStyles();

  const isTalkingCallback = useCallback(
    ({ audioLevel }) => {
      if (audioLevel > 0.05) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onTalking(stream!.streamId, connectionInfo.name);
      }
    },
    [connectionInfo.name, onTalking, stream]
  );

  const handleFullscreen = useCallback(() => {
    const crossBrowserDocument = document as Document & {
      webkitFullscreenElement: HTMLElement;
      msFullscreenElement: boolean;
    };
    const existFullScreenElement =
      crossBrowserDocument.fullscreenElement ||
      crossBrowserDocument.webkitFullscreenElement ||
      crossBrowserDocument.msFullscreenElement;

    setIsFullscreen(wrapperRef.current === existFullScreenElement);
  }, []);

  const toggleFullscreen = useCallback(() => {
    if (isFullscreen) {
      return toggleFullScreenForElement();
    }

    return toggleFullScreenForElement(wrapperRef.current as HTMLElement, true);
  }, [isFullscreen]);

  useEffect(() => {
    const appendedVideo = videoEl;

    appendedVideo.className = videoClass;
    streamRef.current?.appendChild(appendedVideo);

    context.on('audioLevelUpdated', isTalkingCallback);

    return () => {
      context.off('audioLevelUpdated', isTalkingCallback);
    };
  }, [context, isTalkingCallback, videoClass, videoEl]);

  useEffect(() => {
    if (isMain || isFull) {
      document.addEventListener('fullscreenchange', handleFullscreen);
      document.addEventListener('webkitfullscreenchange', handleFullscreen);
      document.addEventListener('msfullscreenchange', handleFullscreen);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullscreen);
        document.removeEventListener(
          'webkitfullscreenchange',
          handleFullscreen
        );
        document.removeEventListener('msfullscreenchange', handleFullscreen);
      };
    }

    return undefined;
  }, [isMain, isFull, handleFullscreen]);

  const handleShowActions = useCallback(
    (isShow = false) =>
      () =>
        setIsShowActions(isShow),
    []
  );
  const pinText = useMemo(
    () => (isPinned ? t('session-room:UNPIN') : t('session-room:PIN')),
    [isPinned, t]
  );
  const handleTriggerUserPin = useCallback(() => {
    dispatch({
      type: 'SET_PINNED_STREAM_ID',
      payload: {
        pinnedStreamId: isPinned ? null : (stream?.streamId as string),
      },
    });
  }, [dispatch, isPinned, stream?.streamId]);

  return (
    <div
      className={rootClass(isMain, isFull, isPinned)}
      onMouseEnter={handleShowActions(true)}
      onMouseLeave={handleShowActions()}
      ref={wrapperRef}
    >
      <UserName>{connectionInfo.name}</UserName>
      <div className={videoKeeperClass(hasVideo)} ref={streamRef} />
      {isShowActions ? (
        <>
          {!isFullscreen ? (
            <PinBar type="button" onClick={handleTriggerUserPin}>
              {pinText}
            </PinBar>
          ) : null}

          <FullscreenButton type="button" onClick={toggleFullscreen}>
            {isFullscreen ? (
              <UnfullscreenStyledIcon />
            ) : (
              <FullscreenStyledIcon />
            )}
          </FullscreenButton>
        </>
      ) : null}
      {!hasAudio ? (
        <MutedIconContainer>
          <MutedIcon />
        </MutedIconContainer>
      ) : null}
    </div>
  );
};
