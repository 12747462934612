import { SidebarPanelEvent, ConfirmProps, SidebarPanelProps } from 'shared/ui';

export type ConfirmTextProps = Pick<
  ConfirmProps,
  'title' | 'caption' | 'submitText' | 'noText'
>;

export interface SidebarContext {
  close: (eventType: SidebarPanelEvent) => void;
  setHasUnsavedChanges: (value: boolean) => void;
  setWidth: (width: string | null) => void;
  setConfirmText: (text: ConfirmTextProps) => void;
}

export const MODAL_WIDTH_CSS_VAR = '--tv-modal-width';

export type SidebarContextProps = {
  props: SidebarPanelProps;
  id: string;
};
