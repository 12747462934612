import { ReactNode } from 'react';
import { ModalCloseEvent } from '../model';

export type ConfirmProps = {
  title: string;
  caption: string | ReactNode;
  submitText: string;
  onCancel?: (eventType: ModalCloseEvent) => void;
  onSubmit?: () => void;
  isLoading?: boolean;
  kind?: CONFIRM_KIND;
  size?: CONFIRM_SIZE;
  noText?: string;
};

export enum CONFIRM_KIND {
  ALERT,
  REMINDER,
}

export enum CONFIRM_SIZE {
  SMALL,
  LARGE,
}
