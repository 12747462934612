import { FC } from 'react';
import { useMobileMediaQuery } from 'shared/ui';
import { useSessionRoomState } from '../../context';
import { Header } from '../header';
import { MobileHeader } from '../mobile-header';
import { Participants } from '../participants';
import { Vitals } from '../vitals';
import { Footer } from '../footer';
import { Chat } from '../chat';
import { Biometrics } from '../biometrics';

export const SessionView: FC = () => {
  const isMobile = useMobileMediaQuery();
  const { isShowVitals, isShowBiometrics, isShowChat } = useSessionRoomState();

  return (
    <>
      {isMobile ? <MobileHeader /> : <Header />}

      <Participants />

      {isShowVitals ? <Vitals /> : null}
      
      {isShowBiometrics ? <Biometrics /> : null}

      <Footer />

      {isShowChat ? <Chat /> : null}
    </>
  );
};
