import { styled, TEXT } from 'shared/ui';

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const Progress = styled('progress', ({ $theme }) => {
  const progressBarStyles = {
    backgroundColor: $theme.colors.accent100,
    borderRadius: '8px',
  };
  const progressBarValueStyles = {
    backgroundColor: $theme.colors.accent,
    borderRadius: '8px',
  };

  return {
    width: '100%',
    height: '8px',
    border: 'none',
    outline: 'none',

    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    appearance: 'none',

    // For Firefox
    ...progressBarStyles,
    // For other browsers
    '::-webkit-progress-bar': progressBarStyles,
    '::progress-bar': progressBarStyles,

    '::-moz-progress-bar': progressBarValueStyles,
    '::-webkit-progress-value': progressBarValueStyles,
    '::progress-value': progressBarValueStyles,
  };
});

export const Info = styled('div', ({ $theme }) => ({
  ...TEXT.sm_book,

  color: $theme.colors.gray700,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
}));

export const CenterCell = styled('div', { textAlign: 'center' });

export const LastCell = styled('div', { textAlign: 'right' });
