import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { StyleObject } from 'styletron-standard';
import { ChartTooltipProps } from './model';
import { $tooltip } from './store';
import { Label, Root, Row } from './styles';

const tooltipRoot = document.createElement('div');

const Tooltip: FC<ChartTooltipProps> = ({ icon, label }) => {
  const tooltip = useStore($tooltip);

  if (!tooltip || !tooltip.value) {
    return null;
  }

  const { left, opacity, top, value } = tooltip;
  const computedStyles: StyleObject = {
    top,
    opacity,
    left,
  };

  return (
    <Root style={computedStyles}>
      {icon ?? null}
      <Row>
        <Label>{label}</Label>
        <div>{value}</div>
      </Row>
    </Root>
  );
};

export const TooltipPortal: FC<ChartTooltipProps> = ({ icon, label }) => {
  useEffect(() => {
    const el = document.body.appendChild(tooltipRoot);
    return () => {
      document.body.removeChild(el);
    };
  }, []);

  return createPortal(<Tooltip icon={icon} label={label} />, tooltipRoot);
};
