import { FC, useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BOOKING_LOCATION } from '../conts';
import { BookingLocationCtx } from './context';

export const BookingLocationProvider: FC = ({ children }) => {
  const { location: routerBookingLocation } =
    useParams<{ location?: string }>();
  const savedLocation = localStorage.getItem(BOOKING_LOCATION);

  useLayoutEffect(() => {
    if (savedLocation !== routerBookingLocation) {
      if (!routerBookingLocation) {
        localStorage.removeItem(BOOKING_LOCATION);
      } else {
        localStorage.setItem(BOOKING_LOCATION, routerBookingLocation);
      }
    }
  }, [routerBookingLocation, savedLocation]);

  const value = useMemo(
    () => ({ location: routerBookingLocation ?? null }),
    [routerBookingLocation]
  );

  return (
    <BookingLocationCtx.Provider value={value}>
      {children}
    </BookingLocationCtx.Provider>
  );
};
