import { useCallback } from 'react';
import { Participant, useUpdateAppointmentMutation } from 'shared/api';
import { differenceWith } from 'ramda';
import { useStyletron, FONT, useConfirm } from 'shared/ui';
import { useTranslation, Trans } from 'react-i18next';

export const useRemoveParticipants = ({
  appointmentId,
  participants,
}: {
  appointmentId: string;
  participants: Participant[];
}) => {
  const { t } = useTranslation(['appointments']);
  const [css] = useStyletron();
  const { confirm } = useConfirm();
  const [updateAppointment] = useUpdateAppointmentMutation();

  const remove = useCallback(
    (removedParticipants: Participant[]) => {
      const computedParticipants = differenceWith(
        (p1, p2) => p1.id === p2.id,
        participants,
        removedParticipants
      ).map(({ user }) => {
        const { id, email, sms, default_notification_channel: channel } = user;
        return {
          user_id: id,
          email,
          sms,
          default_notification_channel: channel,
        };
      });

      confirm({
        title: t('appointments:REMOVE_PARTICIPANT_MODAL.TITLE'),
        caption: (
          <Trans
            ns="appointments"
            i18nKey="REMOVE_PARTICIPANT_MODAL.SUMMARY"
            values={{
              name: removedParticipants.map(({ user }) => user.name).join(', '),
            }}
            components={{
              medium: (
                <span className={css({ fontWeight: FONT.weight_medium })} />
              ),
            }}
          />
        ),
        submitText: t('appointments:REMOVE_PARTICIPANT_MODAL.SUBMIT'),
      }).then((res) => {
        if (res.result) {
          updateAppointment({
            variables: {
              id: appointmentId,
              input: {
                participants: computedParticipants,
              },
            },
          });
        }
      });
    },
    [updateAppointment, appointmentId, participants, confirm, t, css]
  );

  return remove;
};
