import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

if (process.env.REACT_APP_SENTRY_DNS)
  Sentry.init({
    release: `trustvideo-react@${process.env.REACT_APP_VERSION}`,
    environment: process.env.REACT_APP_ENV || 'staging',
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],
    beforeBreadcrumb: excludeGraphQLFetch,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
