import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Appointment } from 'shared/api';
import { styled, COLORS } from 'shared/ui';
import { fullTimeFormat } from 'shared/lib';
import { getLogData, getCreatorName, makeDescription } from './util';
import { useStyles } from './styles';

export const AppointmentHistoryList: FC<{
  appointment?: Appointment | null;
}> = ({ appointment }) => {
  const { t } = useTranslation(['appointments']);
  const {
    listClass,
    itemClass,
    headerClass,
    iconStyle,
    titleStyle,
    descriptionClass,
    dateClass,
  } = useStyles();

  if (!appointment) {
    return null;
  }

  return (
    <ul className={listClass}>
      {(appointment.history ?? [])?.map((activity) => {
        if (!activity) {
          return null;
        }
        const {
          id,
          log_name: logName,
          created_at: createdAt,
          causer,
        } = activity;
        const {
          title,
          titleColor,
          icon,
          iconColor = COLORS.gray400,
        } = getLogData({ name: logName }) ?? {};

        const Title = styled('h3', {
          ...titleStyle,
          color: titleColor,
        });

        const creatorName = getCreatorName(causer);

        const description = makeDescription(activity);

        const Icon =
          icon &&
          styled(icon, {
            ...iconStyle,
            color: iconColor,
          });

        return (
          <li key={id} className={itemClass}>
            <div className={headerClass}>
              {Icon && <Icon />}
              {title && <Title>{t(title)}</Title>}
            </div>
            {description && (
              <p className={descriptionClass}>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {t(description.message as any, description.props)}
              </p>
            )}
            {createdAt && (
              <p className={dateClass}>
                {fullTimeFormat(createdAt.rawDate)}
                {creatorName && (
                  <span>
                    {' '}
                    {t('appointments:VIEW.HISTORY.BY', { name: creatorName })}
                  </span>
                )}
              </p>
            )}
          </li>
        );
      })}
    </ul>
  );
};
