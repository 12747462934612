import { t } from 'i18next';
import { MessageBodyInterface, MessageSignal } from './ui/chat/ui/body/model';

export const getOTErrorMessage = (errorName: string): string =>
  ({
    OT_USER_MEDIA_ACCESS_DENIED: t(
      'session-room:ERRORS.OT_USER_MEDIA_ACCESS_DENIED'
    ),
    OT_HARDWARE_UNAVAILABLE: t('session-room:ERRORS.OT_HARDWARE_UNAVAILABLE'),
  }[errorName] || t('session-room:ERRORS.DEFAULT'));

export const generateMessage = ({
  data,
  from,
  session,
}: MessageSignal): MessageBodyInterface[] => {
  if (data && from) {
    const { name, avatarUrl } = JSON.parse(from?.data);
    const isMe = from.connectionId === session?.connection?.connectionId;

    return [
      {
        id: new Date().getTime(),
        who: isMe ? undefined : name,
        avatarUrl,
        text: data,
      },
    ];
  }

  return [];
};

export const matchDarkTheme = (): boolean =>
  window.matchMedia('(prefers-color-scheme: dark)').matches;

export const toggleFullScreenForElement = async (
  element?: HTMLElement,
  isOpen?: boolean
) => {
  const currentElement = element as HTMLElement & {
    webkitRequestFullscreen: () => Promise<void>;
    msRequestFullscreen: () => Promise<void>;
  };
  const browserDocument = document as Document & {
    webkitExitFullscreen: () => Promise<void>;
    msExitFullscreen: () => Promise<void>;
  };
  const requestFunction = isOpen
    ? currentElement.requestFullscreen ||
      currentElement.webkitRequestFullscreen ||
      currentElement.msRequestFullscreen
    : browserDocument.exitFullscreen ||
      browserDocument.webkitExitFullscreen ||
      browserDocument.msExitFullscreen;

  if (requestFunction) {
    await requestFunction.call(isOpen ? currentElement : browserDocument);
  }
};
