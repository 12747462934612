/* eslint-disable jsx-a11y/heading-has-content */
import { convertToHTML as convertToHTMLBase } from 'draft-convert';
import { FONT } from '../theme';

export const convertToHTML = convertToHTMLBase({
  styleToHTML: (style) => {
    switch (style) {
      case 'BOLD': {
        return <span style={{ fontWeight: FONT.weight_bold }} />;
      }
      default: {
        return undefined;
      }
    }
  },
  blockToHTML: (block) => {
    const fontWeight = block.inlineStyleRanges.find(
      ({ style }) => style === 'BOLD'
    )
      ? FONT.weight_bold
      : FONT.weight_medium;
    switch (block.type) {
      case 'header-one':
        return (
          <h1
            style={{
              lineHeight: FONT.height_4xl,
              fontSize: FONT.size_4xl,
              fontWeight,
            }}
          />
        );
      case 'header-two':
        return (
          <h2
            style={{
              lineHeight: FONT.height_3xl,
              fontSize: FONT.size_3xl,
              fontWeight,
            }}
          />
        );
      case 'header-three':
        return (
          <h3
            style={{
              lineHeight: FONT.height_2xl,
              fontSize: FONT.size_2xl,
              fontWeight,
            }}
          />
        );
      default:
        return undefined;
    }
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'LINK') {
      return (
        <a
          href={entity.data.url}
          target={entity.data?.targetOption ?? '_self'}
          rel="noopener noreferrer"
          style={{
            textDecoration: 'underline',
            textUnderlineOffset: '4px',
            color: 'var(--tru-accent500)',
          }}
        >
          {originalText}
        </a>
      );
    }
    return originalText;
  },
});
