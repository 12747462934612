import { FC } from 'react';
import {
  // DevicesIcon,
  Desktop,
  Mobile,
} from 'shared/ui';
import { useStyles, ActionsContainer } from './styles';
import { EndCallButton } from '../end-call-button';
import { MoreButton } from '../more-button';
import { ToggleVitalsButton } from '../toggle-vitals-button';
import { ToggleChatButton } from '../toggle-chat-button';
import { MediaActions } from '../media-actions';

export const Footer: FC = () => {
  const { rootClass } = useStyles();

  return (
    <div className={rootClass}>
      <ActionsContainer>
        <MediaActions />

        <Mobile>
          <MoreButton />
        </Mobile>
      </ActionsContainer>

      <Desktop>
        <EndCallButton />

        <ActionsContainer style={{ justifyContent: 'flex-end' }}>
          {/* <ActionButton icon={<DevicesIcon />}> */}
          {/*   {t('session-room:DEVICES')} */}
          {/* </ActionButton> */}

          <ToggleChatButton />

          <ToggleVitalsButton />
        </ActionsContainer>
      </Desktop>
    </div>
  );
};
