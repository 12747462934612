// TODO Remove node.js global Buffer after it fixed in PubNub SDK
import { Buffer } from 'buffer';
import { FC, Suspense, useEffect } from 'react';
import { Preloader, SessionRoomWrapper } from './ui';
import { SessionRoomProvider } from './context';
import { theme } from './ui/theme';

const SessionRoom: FC = () => {
  useEffect(() => {
    // Set black background on mount
    document.body.style.paddingTop = '100px';
    document.body.style.backgroundColor = theme.colors.black;

    /**
     * @todo Remove node.js global Buffer after it fixed in PubNub SDK
     * @see https://github.com/pubnub/javascript/issues/288
     * @see https://github.com/webpack/changelog-v5#automatic-nodejs-polyfills-removed
     */
    // eslint-disable-next-line
    // @ts-ignore
    window.Buffer = Buffer;
  }, []);

  return (
    <Suspense fallback={<Preloader />}>
      <SessionRoomProvider>
        <SessionRoomWrapper />
      </SessionRoomProvider>
    </Suspense>
  );
};

export default SessionRoom;
