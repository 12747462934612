import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VitalPlate, VitalPlateData } from '../plate';
import { SysDiaWrap } from './styles';
import { useSessionRoomState } from '../../../../context';

export const VitalSysDia: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { vitalsData } = useSessionRoomState();
  const [sysDiaValue, PRValue] = useMemo(
    () => [
      `${vitalsData.bloodPressure?.Sys ?? '–'}/${
        vitalsData.bloodPressure?.Dia ?? '–'
      }`,
      vitalsData.bloodPressure?.Plus ?? '–',
    ],
    [vitalsData.bloodPressure]
  );

  return (
    <VitalPlate>
      <SysDiaWrap>
        <VitalPlateData
          title={t('session-room:SENSORS.SYS_DIA')}
          units="mmHg"
          value={sysDiaValue}
        />
        <VitalPlateData
          title={t('session-room:SENSORS.PR')}
          units="bpm"
          value={PRValue}
          style={{ width: '254px', maxWidth: '254px' }}
          extraData={[`L: ${50}`, `H: ${120}`]}
        />
      </SysDiaWrap>
    </VitalPlate>
  );
};
