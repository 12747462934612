import { Block } from 'baseui/block';
import { Spinner } from 'baseui/spinner';
import { FC, ReactElement } from 'react';
import { useStyletron } from 'shared/ui';

export interface DrawerContentProps {
  isLoading: boolean;
  children: ReactElement;
}

export const DrawerContent: FC<DrawerContentProps> = ({
  children,
  isLoading,
}) => {
  const [, theme] = useStyletron();
  if (isLoading) {
    return (
      <Block
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100%"
      >
        <Spinner size={46} color={theme.colors.accent} />
      </Block>
    );
  }

  return children;
};
