import { FC, useEffect, useRef } from 'react';
import PubNub from 'pubnub';
import { VitalsWrapper } from './vitals';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';
import { useGeneratePubnubKeysMutation } from '../../../../shared/api';
import { useSharedUser } from '../../../../shared/lib';

export const Vitals: FC = () => {
  const pubnub = useRef<PubNub | null>(null);
  const { appointmentId } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();
  const { user } = useSharedUser();
  const [fetchPubnubData] = useGeneratePubnubKeysMutation();

  useEffect(() => {
    if (appointmentId) {
      fetchPubnubData({
        variables: {
          appointment_id: appointmentId,
        },

        onCompleted: ({ generateOpentokToken }) => {
          dispatch({
            type: 'SET_PUBNUB_INSTANCE',
            payload: {
              instance: (pubnub.current = new PubNub({
                subscribeKey: generateOpentokToken?.subscribe_key as string,
                cipherKey: generateOpentokToken?.cipher_key as string,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                userId: user!.id,
              })),
              channels: [...(generateOpentokToken?.channels ?? [])],
            },
          });
        },
      });
    }
  }, [appointmentId, dispatch, fetchPubnubData, user]);

  return <VitalsWrapper />;
};
