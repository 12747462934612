import { FC, useCallback } from 'react';
import {
  MODAL_ICON_TYPE,
  Button,
  BUTTON_SIZE,
  BUTTON_KIND,
  CalendarIcon,
  CheckIcon,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPE, useModal } from 'shared/lib';
import { useStyles } from './styles';

const Footer: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { t } = useTranslation(['common', 'appointments']);
  const { buttonRowClass, iconClass, buttonStyle } = useStyles();

  return (
    <>
      <div className={buttonRowClass}>
        <Button
          size={BUTTON_SIZE.SMALL}
          kind={BUTTON_KIND.SECONDARY}
          type="button"
          startEnhancer={<CalendarIcon className={iconClass} />}
          style={buttonStyle}
          disabled
        >
          <span>{t('appointments:UPDATE.SUCCESS_MODAL.ADD_TO_CALENDAR')}</span>
        </Button>
      </div>
      <div className={buttonRowClass}>
        <Button
          size={BUTTON_SIZE.SMALL}
          onClick={onClose}
          type="button"
          style={buttonStyle}
        >
          {t('common:BUTTONS.CLOSE')}
        </Button>
      </div>
    </>
  );
};

export const useUpdateSuccess = () => {
  const { t } = useTranslation(['appointments']);
  const { open, close } = useModal();

  const openSuccess = useCallback(() => {
    open(MODAL_TYPE.DEFAULT, {
      title: t('appointments:UPDATE.SUCCESS_MODAL.TITLE'),
      content: 'The session has been updated',
      icon: {
        type: MODAL_ICON_TYPE.SUCCESS,
        svg: (className) => <CheckIcon className={className} />,
      },
      footer: <Footer onClose={close} />,
    });
  }, [open, close, t]);

  return openSuccess;
};
