import { ButtonProps as BaseButtonProps } from 'baseui/button';
import { StyleObject } from 'styletron-standard';

export enum BUTTON_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum BUTTON_KIND {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum BUTTON_COLOR {
  ACCENT = 'accent',
  GREEN = 'green',
  RED = 'red',
  WHITE = 'white',
}

export type ButtonProps = Pick<
  BaseButtonProps,
  | 'onClick'
  | 'isLoading'
  | 'type'
  | 'startEnhancer'
  | 'endEnhancer'
  | 'disabled'
> & {
  size?: BUTTON_SIZE;
  kind?: BUTTON_KIND;
  color?: BUTTON_COLOR;
  style?: StyleObject;
  loadingStyle?: StyleObject;
  isDeleteButton?: boolean;
};
