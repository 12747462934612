import { useStyletron, TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const getListClass = (hasError: boolean) =>
    css({
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderTopColor: hasError ? theme.colors.red500 : theme.colors.gray200,
      borderRightColor: hasError ? theme.colors.red500 : theme.colors.gray200,
      borderBottomColor: hasError ? theme.colors.red500 : theme.colors.gray200,
      borderLeftColor: hasError ? theme.colors.red500 : theme.colors.gray200,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
      borderRadius: '8px',
      transition: 'border-color 0.2s cubic-bezier(0, 0, 1, 1)',
    });

  const itemClass = css({
    position: 'relative',
    padding: '8px',

    ':not(:first-child)::after': {
      content: '""',
      position: 'absolute',
      top: '18px',
      bottom: '18px',
      left: 0,
      width: '1px',
      backgroundColor: theme.colors.gray200,
    },
  });

  const getButtonClass = (isActive: boolean, isDisabled?: boolean) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      width: '100%',
      padding: '8px',
      borderRadius: '8px',
      ...TEXT.sm_book,
      backgroundColor: isActive ? theme.colors.accent50 : 'initial',
      color: isActive ? theme.colors.accent900 : 'inherit',
      opacity: isDisabled ? 0.3 : 1,
      pointerEvents: isDisabled ? 'none' : 'auto',
      transition: 'background-color 0.2s cubic-bezier(0, 0, 1, 1)',

      ':hover': {
        backgroundColor: isActive ? theme.colors.accent50 : theme.colors.gray50,
      },
    });

  const getIconStyle = (isActive: boolean): StyleObject => ({
    width: '40px',
    color: isActive ? theme.colors.accent500 : theme.colors.gray400,
  });

  return {
    getListClass,
    itemClass,
    getButtonClass,
    getIconStyle,
  };
};
