import { forwardRef, useMemo } from 'react';
import { CloseIcon } from 'shared/ui';
import { User, NotificationChannelsEnum } from 'shared/api';
import { hideGeneratedEmail } from 'shared/lib';
import { useStyles } from './styles';

type ParticipantButtonProps = {
  value: User;
  onClick?: () => void;
  onClose?: () => void;
};

export const ParticipantButton = forwardRef<
  HTMLButtonElement,
  ParticipantButtonProps
>(({ onClick, onClose, value }, ref) => {
  const { buttonClass, textBlockClass, titleClass, captionClass, iconClass } =
    useStyles();

  const caption = useMemo(() => {
    const isEmail =
      value.default_notification_channel === NotificationChannelsEnum.Mail;
    return isEmail ? hideGeneratedEmail(value.email) : value.sms ?? '';
  }, [value]);

  return (
    <button type="button" className={buttonClass} onClick={onClick} ref={ref}>
      <span className={textBlockClass}>
        <span className={titleClass}>{value.name}</span>
        {caption && <span className={captionClass}>{caption}</span>}
      </span>
      <CloseIcon
        className={iconClass}
        onClick={(e) => {
          e.stopPropagation();
          onClose?.();
        }}
      />
    </button>
  );
});
