import {
  styled,
  useStyletron,
  MOBILE,
  ChevronRightIcon,
  RsvpYesIcon,
} from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const Root = styled('div', ({ $theme }) => ({
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
  borderRadius: '8px',
  backgroundColor: $theme.colors.white900,
}));

export const Container = styled('div', {
  padding: '16px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [MOBILE]: {
    padding: '16px',
    position: 'relative',
  },
});

export const ActionsWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  flexShrink: 0,
});

export const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const TitleWrapper = styled('div', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

export const ButtonIcon = styled(ChevronRightIcon, ({ $theme }) => ({
  width: '24px',
  height: '24px',
  pointerEvents: 'none',
  color: $theme.colors.gray400,
}));

export const CheckStyled = styled(RsvpYesIcon, ({ $theme }) => ({
  width: '24px',
  height: '24px',
  pointerEvents: 'none',
  color: $theme.colors.green500,
}));

export const useStyles = (
  isSigned?: boolean,
  isFlat?: boolean,
  isSubmitted?: boolean
) => {
  const [css] = useStyletron();

  const signIconClass = css({
    width: '20px',
  });

  const buttonStyles: StyleObject = {
    [MOBILE]: {
      width: '48px',
      padding: 0,
    },
  };

  const mobileSignButtonStyle: StyleObject = {
    [MOBILE]: {
      position: 'absolute',
      right: isFlat ? 0 : '16px',
      bottom: isFlat ? 0 : '16px',
      left: isFlat ? 0 : '16px',
    },
  };

  const containerNotSignedStyle: StyleObject = {
    padding: isFlat ? 0 : '16px 24px',

    [MOBILE]: {
      padding: isFlat ? 0 : '16px',
      // Button height + container paddings
      paddingBottom: !isSubmitted
        ? isFlat
          ? 0
          : '16px'
        : !isSigned
        ? isFlat
          ? '56px'
          : '72px'
        : isFlat
        ? 0
        : '16px',
    },
  };

  return {
    signIconClass,
    buttonStyles,
    mobileSignButtonStyle,
    containerNotSignedStyle,
  };
};
