import { DateTime } from 'features/appointment';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPE, useModal } from 'shared/lib';
import { Button, BUTTON_KIND, BUTTON_SIZE, styled } from 'shared/ui';
import {
  AppointmentCalendarModalProps,
  OpenAppointmentCalendarModalProps,
} from './model';
import { useModalStyles, useStyles } from './styles';

const Content: FC<
  AppointmentCalendarModalProps &
    OpenAppointmentCalendarModalProps & { onClose: () => void }
> = ({ icon, title, onClose, appointment, successButton, onSuccess }) => {
  const { headerClass, headerTitleClass, bodyClass, buttonsClass, iconStyles } =
    useStyles();
  const { t } = useTranslation(['appointments', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [newDate, setNewDate] = useState<Date | null>(
    appointment.start_time.date
  );

  const Icon = useMemo(
    () => styled(icon, { ...iconStyles }),
    [icon, iconStyles]
  );

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await onSuccess?.(newDate);
      setIsLoading(false);
      onClose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDate, onSuccess]);

  return (
    <>
      <div className={headerClass}>
        <Icon />
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <h3 className={headerTitleClass}>{t(title as any)}</h3>
      </div>
      <div className={bodyClass}>
        <DateTime
          value={newDate}
          onChange={(value) => {
            setNewDate(value);
          }}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          service={appointment.service!}
          hostId={appointment.host?.id}
        />
      </div>
      <div className={buttonsClass}>
        <Button
          size={BUTTON_SIZE.SMALL}
          kind={BUTTON_KIND.SECONDARY}
          onClick={onClose}
          isLoading={isLoading}
        >
          {t('common:BUTTONS.CANCEL')}
        </Button>
        <Button
          size={BUTTON_SIZE.SMALL}
          disabled={!newDate || newDate === appointment.start_time.date}
          onClick={handleClick}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {t(successButton as any)}
        </Button>
      </div>
    </>
  );
};

export const useAppointmentCalendarModal = (
  props: AppointmentCalendarModalProps
) => {
  const { open: openModal, close } = useModal();
  const { modalStyle } = useModalStyles();

  const open = useCallback(
    (openProps: OpenAppointmentCalendarModalProps) => {
      openModal(MODAL_TYPE.BASE, {
        children: <Content onClose={close} {...openProps} {...props} />,
        style: modalStyle,
      });
    },
    [openModal, close, props, modalStyle]
  );

  return { open, close };
};
