import { FC, useMemo } from 'react';
import { styled } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

const Cell = styled('div', {
  margin: '-16px',
  padding: '16px',
  cursor: 'pointer',
});

export const ClickableCell: FC<{
  position?: 'first' | 'last';
  onClick?: () => void;
}> = ({ children, onClick, position }) => {
  const styles: StyleObject | undefined = useMemo(
    () =>
      position === 'last'
        ? { marginRight: '-24px', paddingRight: '24px' }
        : position === 'first'
        ? { marginLeft: '-24px', paddingLeft: '24px' }
        : undefined,
    [position]
  );

  return (
    <Cell style={styles} onClick={onClick} aria-hidden="true">
      {children}
    </Cell>
  );
};
