export const dateFormat = (value: string): string => {
  if (!value) {
    return '';
  }

  const currentValue = value.replace(/[^\d]/g, '').slice(-8);

  return [
    currentValue.slice(0, 2),
    currentValue.slice(2, 4),
    currentValue.slice(4, 8),
  ]
    .filter(Boolean)
    .join('/');
};
