import { useStyletron } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const searchRowClass = css({
    display: 'flex',
    gap: '16px',
    flexGrow: 1,
    marginLeft: '-16px',
    marginRight: '-16px',
    padding: '16px',
    backgroundColor: theme.colors.gray100,
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.gray200,
  });

  const searchIconClass = css({
    width: '24px',
    color: theme.colors.gray400,
  });

  return {
    searchRowClass,
    searchIconClass,
  };
};
