import { styled } from 'baseui';
import { COLORS, TEXT } from '../theme';

export const List = styled('ul', {
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
});

export const TagEl = styled('li', {
  borderRadius: '4px',
  padding: '2px 4px',
  userSelect: 'none',
  backgroundColor: COLORS.accent100,
  ...TEXT.sm_book,
  fontWeight: 450,
  color: COLORS.accent500,
  transition: 'background-color 0.2s',
  ':hover': {
    backgroundColor: COLORS.accent200,
  },
});
