import { formatInTimeZone } from 'date-fns-tz';
import { getUserTimezone } from '.';

type Options = {
  seconds?: boolean;
};

export const fullTimeFormat = (time: Date, options?: Options) => {
  const sec = options?.seconds ? ':ss' : '';

  return formatInTimeZone(
    time,
    getUserTimezone(),
    `MMMM d, y @ hh:mm${sec} a zzz`
  );
};
