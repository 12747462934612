const KEY = 'SESSION_SEARCH_PARAMS';

export const setSessionSearchParams = () => {
  sessionStorage.setItem(KEY, window.location.search);
};

const getNormalizedSearchParams = () =>
  new URLSearchParams(sessionStorage.getItem(KEY) ?? undefined);

export const getSessionSearchParams = (searchParam: string): string[] =>
  getNormalizedSearchParams().getAll(searchParam);

export const hasSessionSearchParam = (searchParam: string): boolean =>
  getNormalizedSearchParams().has(searchParam);

export const deleteSessionSearchParam = (searchParam: string) => {
  const urlSearchParams = getNormalizedSearchParams();

  urlSearchParams.delete(searchParam);

  sessionStorage.setItem(KEY, urlSearchParams.toString());
};

export const clearSessionSearchParams = () => sessionStorage.removeItem(KEY);
