import { DESKTOP, useStyletron } from 'shared/ui';

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    position: 'absolute',
    top: '88px',
    right: '16px',
    bottom: '124px',
    left: '16px',
    display: 'flex',
    flexDirection: 'column',

    [DESKTOP]: {
      top: '120px',
      right: '32px',
      bottom: '120px',
      left: '32px',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });

  const innerClass = css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',

    [DESKTOP]: {
      flex: '0 0 260px',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  });

  const videoElementStyles = css({
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  });

  return {
    rootClass,
    innerClass,
    videoElementStyles,
  };
};
