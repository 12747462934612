import { FC, ReactNode } from 'react';
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import { TOOLTIP_DIRECTION } from "./model";
import { useTooltipStyles } from "./styles";

type TooltipProps = {
  content: ReactNode,
  direction?: TOOLTIP_DIRECTION,
};

export const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  direction = TOOLTIP_DIRECTION.DOWN,
}) => {
  const {bodyStyles, innerStyles, arrowStyles} = useTooltipStyles();

  return (
    <StatefulTooltip
      placement={PLACEMENT[direction]}
      accessibilityType="tooltip"
      content={content}
      showArrow
      overrides={{
        Body: {
          style: {...bodyStyles}
        },
        Inner: {
          style: {...innerStyles}
        },
        Arrow: {
          style: {...arrowStyles}
        }
      }}
    >
      {children}
    </StatefulTooltip>
  );
}