import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContainer, MessageTitle, MessageText } from './styles';

export const AppointmentMessage: FC = ({ children }) => {
  const { t } = useTranslation(['participant-dashboard']);

  return (
    <MessageContainer>
      <MessageTitle>
        {t('participant-dashboard:APPOINTMENT_REQUEST.MESSAGE')}
      </MessageTitle>
      <MessageText>{children}</MessageText>
    </MessageContainer>
  );
};
