import { FC, useCallback } from 'react';
import {
  useStyletron,
  CloseIcon,
  Desktop,
  Mobile,
  mergeStyles,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal';
import { ModalProps, ModalCloseEvent } from '../model';
import { useStyles } from './styles';

export const ModalBase: FC<ModalProps> = ({
  children,
  onClose,
  style,
  closeStyle,
  title,
  isCatchCloseEvent = true,
}) => {
  const { t } = useTranslation(['common']);
  const [css] = useStyletron();
  const {
    rootStyle,
    closeButtonStyle,
    closeIconClass,
    headerClass,
    titleClass,
  } = useStyles();
  const handleClick = useCallback(() => {
    if (onClose) {
      onClose(ModalCloseEvent.CloseButtonClickEvent);
    }
  }, [onClose]);

  return (
    <Modal isCatchCloseEvent={isCatchCloseEvent} onClose={onClose}>
      <div className={css(mergeStyles([rootStyle, style ?? {}]))}>
        {title && (
          <Mobile>
            <header className={headerClass}>
              <h3 className={titleClass}>{title}</h3>
              {isCatchCloseEvent && (
                <button
                  className={css(
                    mergeStyles([closeButtonStyle, closeStyle ?? {}])
                  )}
                  onClick={handleClick}
                  type="button"
                >
                  {t('common:BUTTONS.CLOSE')}
                </button>
              )}
            </header>
          </Mobile>
        )}
        <Desktop>
          {isCatchCloseEvent ? (
            <button
              className={css({
                ...closeButtonStyle,
                ...closeStyle,
              })}
              onClick={handleClick}
              type="button"
            >
              <CloseIcon className={closeIconClass} />
            </button>
          ) : null}
        </Desktop>

        {children}
      </div>
    </Modal>
  );
};
