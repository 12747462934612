import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VitalPlate, VitalPlateData } from '../plate';
import { useSessionRoomState } from '../../../../context';
import { BiometricsInfoMap, SingleMeasurementType } from '../../model';

export const SingleMeasurement: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { biometricsData } = useSessionRoomState();

  // Ensure biometricsData.measurement is defined before sorting
  const sortedMeasurements = (biometricsData.measurement ?? []).sort((a, b) => {
    const aIndex = Object.keys(BiometricsInfoMap).indexOf(a.Key);
    const bIndex = Object.keys(BiometricsInfoMap).indexOf(b.Key);
    return aIndex - bIndex;
  });

  return (
    <>
      {sortedMeasurements.map(({ Key, Value }: SingleMeasurementType) => {
        const biometricsInfo = BiometricsInfoMap[Key];
        return (
          biometricsInfo && (
            <VitalPlate key={Key}>
              <VitalPlateData
                title={biometricsInfo.name}
                units={biometricsInfo.unit === 'unitless' ? '' : biometricsInfo.unit}
                color="#f86623"
                value={typeof Value === 'number' ? Value.toFixed(2) : Value}
                range={biometricsInfo.range}
                extraData={[]}
              />
            </VitalPlate>
          )
        );
      })}
    </>
  );
};
