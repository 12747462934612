import {
  RadioProps as BaseRadioProps,
  RadioGroupProps as BaseRadioGroupProps,
} from 'baseui/radio';

export enum RADIO_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export type RadioProps = BaseRadioProps & {
  size?: RADIO_SIZE;
};

export type RadioGroupProps = BaseRadioGroupProps & {
  gap?: number;
};
