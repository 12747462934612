import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IResizableImage } from 'shared/api';
import { Avatar, Desktop, Mobile } from 'shared/ui';
import { NoteActions } from 'entities/note';
import { NoteBlockHeaderInterface } from '../header/model';
import {
  useStyles,
  LessButton,
  Root,
  InnerBlock,
  MobileDivider,
  List,
  ListItem,
  ItemLabel,
  ItemValue,
  Actions,
} from './styles';

type NoteBlockContentType = Pick<
  NoteBlockHeaderInterface,
  'note' | 'onToggle' | 'onEdit' | 'onRemove' | 'onNoteExport'
>;

export const NoteBlockContent: FC<NoteBlockContentType> = ({
  note,
  onToggle,
  onEdit,
  onRemove,
  onNoteExport,
}) => {
  const { t } = useTranslation(['appointments']);
  const {
    userListClass,
    hostClass,
    hostAvatarClass,
    hostContentClass,
    hostNameClass,
    hostEmailClass,
    textClass,
  } = useStyles();

  return (
    <Root>
      <InnerBlock>
        <List>
          <ListItem>
            <ItemLabel>
              {t('appointments:VIEW.NOTES.NOTE_WRITTEN_FOR')}
            </ItemLabel>

            <ItemValue>
              <ul className={userListClass}>
                {note.users.map((user) => (
                  <li key={user?.id}>{user?.name}</li>
                ))}
              </ul>
            </ItemValue>
          </ListItem>

          {note.appointment?.host ? (
            <ListItem>
              <ItemLabel>{t('appointments:VIEW.SESSION_HOST')}</ItemLabel>

              <ItemValue>
                <div className={hostClass}>
                  <div className={hostAvatarClass}>
                    <Avatar
                      name={note.appointment?.host.name}
                      src={note.appointment?.host.avatar_url as IResizableImage}
                    />
                  </div>
                  <div className={hostContentClass}>
                    <div className={hostNameClass}>
                      {note.appointment?.host.name}
                    </div>
                    <div className={hostEmailClass}>
                      {note.appointment?.host.email}
                    </div>
                  </div>
                </div>
              </ItemValue>
            </ListItem>
          ) : null}
        </List>
      </InnerBlock>

      <Mobile>
        <MobileDivider />
      </Mobile>

      <InnerBlock>
        <div
          className={textClass}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: note.content }}
        />
      </InnerBlock>

      <Mobile>
        <Actions>
          <NoteActions
            onEdit={onEdit}
            onDownload={onNoteExport}
            onRemove={onRemove}
          />
        </Actions>
      </Mobile>

      <Desktop>
        <LessButton type="button" onClick={onToggle}>
          {t('appointments:VIEW.NOTES.SHOW_LESS')}
        </LessButton>
      </Desktop>
    </Root>
  );
};
