import { createContext, FC, useCallback, useMemo } from 'react';
import { SidebarPanelEvent } from 'shared/ui';
import { useSidebarContext } from 'process/sidebar-panel';
import { ModalPanelProps, MODAL_PANELS } from '../../model';
import { ModalContext } from './model';
import { updateProps } from '../store';
import { MODALS } from '../../modals';

export const ModalCtx = createContext<ModalContext<MODAL_PANELS>>({
  close: () => {
    throw new Error('not implemented');
  },
  updatePropsState: () => {
    throw new Error('not implemented');
  },
  setHasUnsavedChanges: () => {
    throw new Error('not implemented');
  },
  setWidth: () => {
    throw new Error('not implemented');
  },
  setConfirmText: () => {
    throw new Error('not implemented');
  },
});

export function ModalProvider<T extends MODAL_PANELS>({
  name,
  props,
}: {
  name: MODAL_PANELS;
  props: ModalPanelProps[T];
}) {
  const ModalContent = MODALS[name] as FC<ModalPanelProps[T]>;
  const {
    close: closeSidebar,
    setHasUnsavedChanges,
    setWidth,
    setConfirmText,
  } = useSidebarContext();

  const close = useCallback(() => {
    closeSidebar(SidebarPanelEvent.CustomEvent);
  }, [closeSidebar]);

  const updatePropsState = useCallback((updatedProps) => {
    updateProps(updatedProps);
  }, []);

  const value = useMemo(
    () => ({
      close,
      updatePropsState,
      setWidth,
      setHasUnsavedChanges,
      setConfirmText,
    }),
    [close, updatePropsState, setWidth, setHasUnsavedChanges, setConfirmText]
  );

  return (
    <ModalCtx.Provider value={value}>
      <ModalContent {...props} />
    </ModalCtx.Provider>
  );
}
