import { FC } from 'react';
import { VitalPlateDataInterface } from './model';
import {
  VitalPlateWrapper,
  VitalDataWrap,
  VitalDataHeader,
  VitalDataTitle,
  VitalDataUnits,
  VitalDataBody,
  VitalDataValue,
  VitalExtraDataList,
  VitalExtraDataItem,
} from './style';

export const VitalPlateData: FC<VitalPlateDataInterface> = ({
  title,
  units,
  value,
  extraData,
  color,
  style,
}) => (
  <VitalDataWrap style={style}>
    <VitalDataHeader>
      <VitalDataTitle style={{ color }}>{title}</VitalDataTitle>
      <VitalDataUnits>{units}</VitalDataUnits>
    </VitalDataHeader>

    <VitalDataBody>
      {extraData ? (
        <VitalExtraDataList>
          {extraData.map((dataItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <VitalExtraDataItem key={index}>{dataItem}</VitalExtraDataItem>
          ))}
        </VitalExtraDataList>
      ) : null}

      <VitalDataValue>{value}</VitalDataValue>
    </VitalDataBody>
  </VitalDataWrap>
);

export const VitalPlate: FC<{ onClick?: () => void }> = ({
  onClick,
  children,
}) => <VitalPlateWrapper onClick={onClick}>{children}</VitalPlateWrapper>;
