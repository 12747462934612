import { createContext, Dispatch, FC, useContext, useReducer } from 'react';
import { SessionRoomAction } from '../model';
import { initialSessionRoomState, sessionRoomReducer } from './reducer';

const SessionRoomContext = createContext(initialSessionRoomState);
const SessionRoomDispatchContext =
  createContext<Dispatch<SessionRoomAction> | null>(null);

export const SessionRoomProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    sessionRoomReducer,
    initialSessionRoomState
  );

  return (
    <SessionRoomContext.Provider value={state}>
      <SessionRoomDispatchContext.Provider value={dispatch}>
        {children}
      </SessionRoomDispatchContext.Provider>
    </SessionRoomContext.Provider>
  );
};

export const useSessionRoomState = () => {
  const sessionRoomContext = useContext(SessionRoomContext);

  if (sessionRoomContext == null)
    throw Error(
      'useSessionRoomState must be used within a SessionRoomProvider'
    );

  return sessionRoomContext;
};

export const useSessionRoomDispatch = () => {
  const dispatch = useContext(SessionRoomDispatchContext);

  if (dispatch == null)
    throw Error(
      'useSessionRoomDispatch must be used within a SessionRoomProvider'
    );

  return dispatch;
};
