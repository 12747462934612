import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHasRoles } from 'shared/lib';
import { SessionEndedInterface } from './model';
import { Root, ReturnLink, Title } from './styles';
import { useSessionRoomState } from '../../context';

export const SessionEnded: FC<SessionEndedInterface> = ({
  isShowBackButton = false,
  children,
}) => {
  const { t } = useTranslation(['session-room']);
  const { appointmentId } = useSessionRoomState();
  const isPatient = useHasRoles(['Patient']);
  const navigateLink = isPatient
    ? `/participant-dashboard/appointments/${appointmentId}`
    : `/appointments?appointment=${appointmentId}`;

  return (
    <Root>
      <Title dangerouslySetInnerHTML={{ __html: `${children}` }} />
      {isShowBackButton ? (
        <ReturnLink href={navigateLink}>
          {t('session-room:BACK_TO_DETAILS')}
        </ReturnLink>
      ) : null}
    </Root>
  );
};
