import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, BUTTON_SIZE } from 'shared/ui';
import { ErrorPageOrganizationInfo } from './organization-info';
import { ErrorPageInterface } from './model';
import {
  ErrorFooter,
  ErrorHeader,
  ErrorIcon,
  ErrorIconSVG,
  ErrorInfoText,
  ErrorInfoTitle,
  ErrorTitle,
  MainInfo,
  Root,
  Wrapper,
} from './styles';

const ErrorPage: FC<ErrorPageInterface> = ({
  status,
  title,
  text,
  hideOrgInfo,
  hideReturnButton,
  customButton,
}) => {
  const { t } = useTranslation(['error']);
  const navigate = useNavigate();

  const [errorStatus, errorTitle, errorText] = [
    status ?? 404,
    title ?? t('error:404.TITLE'),
    text ?? t('error:404.TEXT'),
  ];

  const handleReturnToHome = useCallback(
    () => navigate('/', { replace: true }),
    [navigate]
  );

  return (
    <Root>
      <Wrapper>
        <MainInfo>
          <ErrorHeader>
            <ErrorIcon>
              <ErrorIconSVG />
            </ErrorIcon>
            <ErrorTitle>{t('error:ERROR', { status: errorStatus })}</ErrorTitle>
          </ErrorHeader>

          <ErrorInfoTitle>{errorTitle}</ErrorInfoTitle>
          <ErrorInfoText>{errorText}</ErrorInfoText>

          {(!hideReturnButton || !!customButton) && (
            <ErrorFooter>
              <>
                {customButton ?? null}
                {!hideReturnButton && (
                  <Button
                    type="button"
                    onClick={handleReturnToHome}
                    size={BUTTON_SIZE.SMALL}
                  >
                    {t('error:RETURN_TO_HOME')}
                  </Button>
                )}
              </>
            </ErrorFooter>
          )}
        </MainInfo>

        {!hideOrgInfo && <ErrorPageOrganizationInfo />}
      </Wrapper>
    </Root>
  );
};

export default ErrorPage;
