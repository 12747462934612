import { FC, forwardRef } from 'react';
import { useStyletron, TEXT } from 'shared/ui';
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from 'baseui/button';
import { StyleObject } from 'styletron-standard';

export type DashedButtonProps = Pick<
  BaseButtonProps,
  | 'onClick'
  | 'isLoading'
  | 'type'
  | 'startEnhancer'
  | 'endEnhancer'
  | 'disabled'
> & {
  style?: StyleObject;
};

export const DashedButton: FC<DashedButtonProps> = forwardRef<
  HTMLButtonElement,
  DashedButtonProps
>(
  (
    {
      children,
      onClick,
      type,
      startEnhancer,
      endEnhancer,
      disabled,
      style,
      isLoading,
    },
    ref
  ) => {
    const [css, theme] = useStyletron();

    const iconClass = css({
      width: '20px',
    });

    return (
      <BaseButton
        ref={ref}
        overrides={{
          BaseButton: {
            style: {
              alignItems: 'center',
              gap: '8px',
              flexShrink: 0,
              width: '100%',
              height: '56px',
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: '16px',
              paddingLeft: '16px',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: '8px',
              borderTopWidth: '2px',
              borderRightWidth: '2px',
              borderBottomWidth: '2px',
              borderLeftWidth: '2px',
              borderTopStyle: 'dashed',
              borderRightStyle: 'dashed',
              borderBottomStyle: 'dashed',
              borderLeftStyle: 'dashed',
              borderTopColor: theme.colors.gray200,
              borderRightColor: theme.colors.gray200,
              borderBottomColor: theme.colors.gray200,
              borderLeftColor: theme.colors.gray200,
              ...TEXT.base_book,
              color: theme.colors.gray400,
              transitionProperty: 'background-color, color',
              ':hover': !disabled
                ? {
                    backgroundColor: theme.colors.gray50,
                    color: theme.colors.accent,
                  }
                : {},
              ...style,
            },
          },
        }}
        kind="secondary"
        onClick={onClick}
        type={type}
        disabled={disabled}
        isLoading={isLoading}
      >
        {startEnhancer && <span className={iconClass}>{startEnhancer}</span>}
        {children}
        {endEnhancer && <span className={iconClass}>{endEnhancer}</span>}
      </BaseButton>
    );
  }
);
