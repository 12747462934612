export class Timer {
  private timerId: NodeJS.Timeout = setTimeout(this.callback, this.remaining);

  private start: number = Date.now();

  constructor(
    private readonly callback: () => void,
    private remaining: number
  ) {}

  public pause() {
    clearTimeout(this.timerId);
    this.remaining -= Date.now() - (this.start ?? 0);
  }

  resume() {
    this.start = Date.now();
    clearTimeout(this.timerId);
    this.timerId = setTimeout(this.callback, this.remaining);
  }

  clear() {
    clearTimeout(this.timerId);
  }
}
