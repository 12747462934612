import { ECGPackageType } from './model';

export const transformECGToChartData: ({
  meta,
  startIndex,
  width,
  maxPointCount,
}: {
  meta: ECGPackageType;
  width: number;
  startIndex?: number;
  maxPointCount?: number;
}) => {
  x: number;
  y: number;
}[] = ({
  meta: { ECGmax, AmpN, ECGdata },
  startIndex = 0,
  maxPointCount = 1750,
  width,
}) =>
  ECGdata.map((value, index) => ({
    x: startIndex + ((index + 1) * width) / maxPointCount,
    y: (value - ECGmax / 2) * AmpN + ECGmax / 2,
  }));
