import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CountProgressInterface } from './model';
import { Root, Progress, Info, CenterCell, LastCell } from './styles';
import { formatNumberString } from '../../lib';

export const CountProgress: FC<CountProgressInterface> = ({
  value,
  max,
  text,
}) => {
  const { t } = useTranslation(['common']);
  const [formattedValue, formattedTotal] = [
    formatNumberString(value),
    formatNumberString(max),
  ];

  return (
    <Root>
      <Progress value={value} max={max} />
      <Info>
        <div>{max === 0 ? '' : 0}</div>

        <CenterCell>
          {text ??
            t('common:LISTS.OF', {
              from: formattedValue,
              to: formattedTotal,
            })}
        </CenterCell>

        <LastCell>{max === 0 ? '' : formattedTotal}</LastCell>
      </Info>
    </Root>
  );
};
