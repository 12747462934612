import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';

const usePusher = (channelName: string, eventName: string) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Enable Pusher logging (for development, remove in production)
    // Pusher.logToConsole = true;

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY!, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    
    pusher.connection.bind('connected', () => {
      console.log('Connected to Pusher');
    });
    
    pusher.connection.bind('disconnected', () => {
      console.log('Disconnected from Pusher');
    });
    
    pusher.connection.bind('error', (err: any) => {
      console.error('Pusher connection error:', err);
    });
    
    const channel = pusher.subscribe(channelName);

    channel.bind(eventName, (eventData: any) => {
      setData(eventData);  // Set the event data to state
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [channelName, eventName]);

  return data;
};



export default usePusher;
