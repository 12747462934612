import { useCallback, useRef } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { MODAL_TYPE, OpenModalProps, StoredModal } from '.';
import { open as openModal, close as closeModal } from './store';

export const useModal = () => {
  const id = useRef<string | null>(null);

  const open = useCallback(
    <T extends MODAL_TYPE = MODAL_TYPE>(type: T, props: OpenModalProps[T]) => {
      id.current = uuidV4();

      openModal({
        type,
        props,
        id: id.current,
      } as StoredModal);
    },

    []
  );

  const close = useCallback(() => {
    if (id.current) {
      closeModal(id.current);
    }
  }, []);

  return {
    open,
    close,
  };
};
