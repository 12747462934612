import { useCallback } from 'react';
import { DESKTOP, TEXT, useStyletron } from 'shared/ui';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootClass = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    ...TEXT.base_book,

    [DESKTOP]: {
      justifyContent: 'flex-start',
    },
  });

  const buttonsWrapperClass = css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    ...TEXT.sm_book,
  });

  const yesButtonStyles = {
    borderColor: theme.colors.green500,
    backgroundColor: theme.colors.green50,
    color: theme.colors.green700,
    cursor: 'default',

    ':hover': {
      borderColor: theme.colors.green500,
    },
  };

  const noButtonStyles = {
    borderColor: theme.colors.red500,
    backgroundColor: theme.colors.red50,
    color: theme.colors.red700,

    ':hover': {
      borderColor: theme.colors.red500,
    },
  };

  const baseButtonClass = (loading: boolean, isYes: boolean, isNo: boolean) =>
    css({
      display: 'inline-flex',
      padding: '8px',
      alignItems: 'center',
      gap: '10px',
      backgroundColor: theme.colors.white,
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.gray200,
      transitionProperty: 'border-color, color, background-color',
      transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
      transitionDuration: '200ms',
      pointerEvents: loading ? 'none' : 'auto',

      ':hover': {
        borderColor: theme.colors.gray300,
      },

      // Colorize button
      ...(isYes ? yesButtonStyles : {}),
      ...(isNo ? noButtonStyles : {}),
    });

  const baseIconClass: (isActive: boolean) => string = useCallback(
    (isActive) =>
      css({
        color: isActive ? 'inherit' : theme.colors.gray400,
      }),
    [css, theme]
  );

  return {
    rootClass,
    buttonsWrapperClass,
    baseButtonClass,
    baseIconClass,
  };
};
