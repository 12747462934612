import { useStyletron, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const buttonStyle: StyleObject = {
    minWidth: '208px',

    [MOBILE]: {
      flexGrow: 1,
    },
  };

  const chevronClass = css({
    color: theme.colors.gray400,
    ':hover': {
      color: 'inherit',
    },
  });

  const textClass = css({
    flexGrow: 1,
    textAlign: 'center',
  });

  return {
    buttonStyle,
    chevronClass,
    textClass,
  };
};
