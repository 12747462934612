import { ChartOptions } from 'chart.js';
import { mergeDeepRight } from 'ramda';
import { COLORS, extractColorFromCSSVariable } from 'shared/ui';
import { tooltipOptions } from './tooltip/plugin';

const gridScaleOptions = (): ChartOptions => {
  const defaultStylesForAxis = {
    offset: true,

    border: {
      dash: [4, 2],
      color: 'transparent',
    },

    ticks: {
      font: {
        family: 'Circular Std, sans-serif',
        size: 12,
        weight: '500',
        lineHeight: 16 / 12,
      },
    },

    grid: {
      color: extractColorFromCSSVariable(COLORS.gray200),
    },
  };

  return {
    scales: {
      x: defaultStylesForAxis,
      y: defaultStylesForAxis,
    },
  };
};

const pointOptions: () => ChartOptions<'line'> = () => ({
  elements: {
    point: {
      borderColor: extractColorFromCSSVariable(COLORS.white900),
      pointBackgroundColor: extractColorFromCSSVariable(COLORS.accent500),
      pointBackgroundOpacity: 1,
      pointBorderWidth: 0,
      pointHoverRadius: 8,
      pointHoverBackgroundColor: extractColorFromCSSVariable(COLORS.accent500),
      pointHoverBorderColor: extractColorFromCSSVariable(COLORS.white900),
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
    },
  },
});

const intersectOptions = (): ChartOptions => ({
  interaction: {
    intersect: false,
    mode: 'index',
  },
});

export const getDefaultOptions: () => ChartOptions = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      corsair: {
        dash: [4, 4],
        color: extractColorFromCSSVariable(COLORS.red500),
        width: 1,
      },
    },
    ...gridScaleOptions(),
    ...pointOptions(),
    ...intersectOptions(),
  };
  return mergeDeepRight(options, tooltipOptions) as ChartOptions;
};
