import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { VitailsIcon } from 'shared/ui';
import { ActionButton } from '../action-button';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';

export const ToggleVitalsButton: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { isShowVitals } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();
  const toggleVitals = useCallback(() => {
    dispatch({
      type: 'TOGGLE_SHOW_VITALS',
      payload: {
        isShow: !isShowVitals,
      },
    });
  }, [dispatch, isShowVitals]);

  return (
    <ActionButton icon={<VitailsIcon />} onClick={toggleVitals}>
      {t('session-room:VITALS')}
    </ActionButton>
  );
};
