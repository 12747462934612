import { createEvent, createStore } from 'effector';
import { ECGPackageType } from 'features/vital';

export const resetECG = createEvent();
export const addECGPackage = createEvent<ECGPackageType>();

export const $ECGData = createStore<{ x: number; y: number }[]>([]).reset(
  resetECG
);

export const $lastEcgDate = createStore<null | Date>(null).reset(resetECG);
