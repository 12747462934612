import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  INPUT_KIND,
  SearchIcon,
  Avatar,
  CheckIcon,
  useMobileMediaQuery,
} from 'shared/ui';
import { User } from 'shared/api';
import { useStyles } from './styles';

export const SessionHostList: FC<{
  users?: User[];
  selectedId?: string;
  setCurrentId?: (id?: string) => void;
  setSelectedId?: (id?: string) => void;
}> = ({ users, selectedId, setCurrentId, setSelectedId }) => {
  const { t } = useTranslation(['common']);
  const {
    rootClass,
    searchIconClass,
    listClass,
    getItemClass,
    itemNameClass,
    itemEmailClass,
    checkIconClass,
    messageClass,
  } = useStyles();

  const isMobile = useMobileMediaQuery();
  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!users) {
      return;
    }

    setFilteredUsers(
      users.filter(
        ({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ) as User[]
    );
  }, [search, users]);

  return (
    <div className={rootClass}>
      <Input
        placeholder={t('common:SEARCH.SEARCH')}
        value={search}
        onChange={({ currentTarget }) => setSearch(currentTarget.value)}
        kind={INPUT_KIND.SECONDARY}
        startEnhancer={<SearchIcon className={searchIconClass} />}
      />
      {filteredUsers.length !== 0 ? (
        <ul className={listClass}>
          {filteredUsers.map(({ id, name, email, avatar_url: avatar }) => (
            <li key={id}>
              <div
                className={getItemClass(id === selectedId)}
                onClick={() => {
                  if (isMobile) {
                    setCurrentId?.(id);
                  } else {
                    setSelectedId?.(id);
                  }
                }}
                aria-hidden="true"
              >
                <Avatar name={name} src={avatar} />
                <div>
                  <p className={itemNameClass}>{name}</p>
                  <p className={itemEmailClass}>{email}</p>
                </div>
                {id === selectedId && <CheckIcon className={checkIconClass} />}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className={messageClass}>{t('common:SEARCH.NO_RECORD_FOUND')}</p>
      )}
    </div>
  );
};
