import { add } from 'date-fns';
import { Cookies } from 'react-cookie';
import { InputTokens } from './types';

const cookies = new Cookies();

export const setToken = (key: string, value?: string) => {
  const parsedHostName = window.location.hostname.split('.');
  const domain =
    parsedHostName.length > 2
      ? `.${parsedHostName.slice(1).join('.')}`
      : parsedHostName.join('.');
  cookies.set(key, value ?? '', {
    expires: add(new Date(), { days: 30 }),
    sameSite: 'none',
    domain,
    secure: true,
    path: '/',
  });
};

export const clearToken: (key: string) => void = (key) => {
  const parsedHostName = window.location.hostname.split('.');
  const domain =
    parsedHostName.length > 2
      ? `.${parsedHostName.slice(1).join('.')}`
      : parsedHostName.join('.');
  cookies.remove(key, { domain, path: '/' });
};

export const saveAuth = (data: InputTokens) => {
  setToken('accessToken', data.accessToken);
  setToken('refreshToken', data.refreshToken);
  setToken('idToken', data.idToken);
};

export const clearAuth = () => {
  clearToken('accessToken');
  clearToken('refreshToken');
  clearToken('idToken');
};

export const getAccessToken = () => cookies.get<string>('accessToken');

export const getRefreshToken = () => cookies.get<string>('refreshToken');

export const getIdToken = () => cookies.get<string>('idToken');

export const hasAuth = () => !!getIdToken();
