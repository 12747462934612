import { FC } from 'react';
import { useStyletron, TEXT, ChevronRightIcon, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover';
import { useCurrentOrganization } from 'entities/organization';
import { MenuItemObject } from './model';
import { Menu } from '../menu';

interface MenuItemProps {
  item: MenuItemObject;
  onClose?: () => void;
  onClick?: () => void;
}

export const MenuItem: FC<MenuItemProps> = ({ item, onClose, onClick }) => {
  const [css, theme] = useStyletron();
  const { t } = useTranslation();
  const { organization } = useCurrentOrganization();

  const style: ({
    isDeleteItem,
  }: {
    isDeleteItem?: boolean;
    isDisabled?: boolean;
  }) => StyleObject = ({ isDeleteItem = false, isDisabled = false }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    padding: '8px 24px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    color: !isDisabled ? theme.colors.gray700 : theme.colors.gray400,
    ':hover > *': {
      color: isDisabled
        ? theme.colors.gray400
        : isDeleteItem
        ? theme.colors.red500
        : theme.colors.accent500,
    },

    [MOBILE]: {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  });

  const renderContent = () => (
    <>
      {item.icon && (
        <span
          className={css({
            width: '24px',
            color: theme.colors.gray400,
            transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
          })}
        >
          {item.icon}
        </span>
      )}

      <span
        className={css({
          ...TEXT.base_book,
          transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
        })}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {t(item.label as any, {
          name: organization.name,
        })}
      </span>

      {item.iconRight && (
        <span
          className={css({
            width: '24px',
            color: theme.colors.gray400,
            transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
          })}
        >
          {item.iconRight}
        </span>
      )}

      {item.children && (
        <ChevronRightIcon
          className={css({
            width: '24px',
            marginLeft: 'auto',
            color: theme.colors.gray400,
            transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
          })}
        />
      )}
    </>
  );

  if (item.children?.length) {
    return (
      <StatefulPopover
        placement={PLACEMENT.rightTop}
        overrides={{
          Body: {
            style: {
              top: '-9px',
              boxShadow: 'none',
            },
          },
          Inner: {
            style: {
              width: '240px',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              border: `1px solid ${theme.colors.gray200}`,
              backgroundColor: theme.colors.white900,
              boxShadow:
                '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            },
          },
        }}
        popoverMargin={0}
        ignoreBoundary
        content={({ close }) => (
          <Menu list={item.children} onClick={onClick} onClose={close} />
        )}
        accessibilityType="menu"
        triggerType={TRIGGER_TYPE.hover}
      >
        <div
          className={css(
            style({ isDeleteItem: item.isDelete, isDisabled: item.disabled })
          )}
        >
          {renderContent()}
        </div>
      </StatefulPopover>
    );
  }

  if (item.disabled) {
    return (
      <div className={css(style({ isDisabled: true }))}>{renderContent()}</div>
    );
  }

  if (item.link) {
    return (
      <NavLink
        to={item.link}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
          onClose?.();
        }}
        className={css(style({ isDeleteItem: item.isDelete }))}
      >
        {renderContent()}
      </NavLink>
    );
  }

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        item.onClick?.();
        onClick?.();
        onClose?.();
      }}
      className={css(style({ isDeleteItem: item.isDelete }))}
      type="button"
    >
      {renderContent()}
    </button>
  );
};
