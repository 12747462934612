import { setContext } from '@apollo/client/link/context';

export const subdomainLink = setContext((_, { headers }) => {
  const subdomain =
    process.env.NODE_ENV === 'production'
      ? window.location.hostname.split('.').slice(0, -2).join('.')
      : process.env.REACT_APP_SUBDOMAIN ??
        window.location.hostname.split('.').slice(0, -2).join('.') ??
        'test';
  return {
    headers: {
      ...headers,
      'X-SUBDOMAIN': subdomain,
    },
  };
});
