import { FC } from 'react';
// import { RecordIcon, SettingsIcon } from 'shared/ui';
// import { useTranslation } from 'react-i18next';
import { useSharedUser } from 'shared/lib';
import {
  Root,
  UserInfo,
  UserName,
  AppointmentId,
  HeaderActions,
} from './styles';
// import { ActionButton } from '../action-button';
import { useSessionRoomState } from '../../context';

export const Header: FC = () => {
  const userData = useSharedUser();
  const { appointmentId, talkingUser } = useSessionRoomState();
  // const { t } = useTranslation(['session-room']);

  return (
    <Root>
      <UserInfo>
        <UserName>{talkingUser?.name ?? userData.user?.name}</UserName>
        <AppointmentId>{`ID ${appointmentId}`}</AppointmentId>
      </UserInfo>

      <HeaderActions>
        {/* <ActionButton icon={<RecordIcon />}> */}
        {/*   {t('session-room:RECORD')} */}
        {/* </ActionButton> */}
        {/* <ActionButton icon={<SettingsIcon />}> */}
        {/*   {t('session-room:SETTINGS')} */}
        {/* </ActionButton> */}
      </HeaderActions>
    </Root>
  );
};
