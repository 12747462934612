import { TEXT, MOBILE, TrustvideoTheme } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { BUTTON_SIZE, BUTTON_KIND, BUTTON_COLOR } from './model';

export const BUTTON_SIZE_STYLES = ({ size }: { size: BUTTON_SIZE }) => {
  const map = new Map<BUTTON_SIZE, StyleObject>([
    [
      BUTTON_SIZE.SMALL,
      {
        height: '40px',
        ...TEXT.sm_medium,

        [MOBILE]: {
          height: '48px',
          ...TEXT.base_medium,
        },
      },
    ],
    [
      BUTTON_SIZE.MEDIUM,
      {
        height: '48px',
        ...TEXT.base_medium,
      },
    ],
    [
      BUTTON_SIZE.LARGE,
      {
        height: '56px',
        ...TEXT.lg_medium,
      },
    ],
  ]);

  return map.get(size);
};

export const BUTTON_KIND_STYLES = ({
  kind,
  disabled,
  theme,
}: {
  kind: BUTTON_KIND;
  disabled: boolean;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<BUTTON_KIND, StyleObject>([
    [
      BUTTON_KIND.PRIMARY,
      {
        boxShadow:
          '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
        ':disabled': {
          backgroundColor: theme.colors.accent200,
          color: theme.colors.white900,
        },
      },
    ],
    [
      BUTTON_KIND.SECONDARY,
      {
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderTopColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        ':hover': !disabled
          ? {
              borderLeftColor: theme.colors.gray300,
              borderRightColor: theme.colors.gray300,
              borderTopColor: theme.colors.gray300,
              borderBottomColor: theme.colors.gray300,
              color: theme.colors.accent500,
            }
          : {},
      },
    ],
    [
      BUTTON_KIND.TERTIARY,
      {
        boxShadow:
          '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
      },
    ],
  ]);

  return map.get(kind);
};

export const BUTTON_COLOR_STYLES = ({
  kind,
  color,
  disabled,
  theme,
}: {
  kind: BUTTON_KIND;
  color: BUTTON_COLOR;
  disabled: boolean;
  theme: TrustvideoTheme;
}) => {
  if (kind !== BUTTON_KIND.PRIMARY) {
    return {};
  }
  const map = new Map<BUTTON_COLOR, StyleObject>([
    [
      BUTTON_COLOR.ACCENT,
      disabled
        ? {
            backgroundColor: theme.colors.accent500,
          }
        : {
            backgroundColor: theme.colors.accent500,
            ':hover': {
              backgroundColor: theme.colors.accent600,
            },
            ':active': {
              backgroundColor: theme.colors.accent500,
            },
          },
    ],
    [
      BUTTON_COLOR.GREEN,
      disabled
        ? {
            backgroundColor: theme.colors.green500,
          }
        : {
            backgroundColor: theme.colors.green500,
            ':hover': {
              backgroundColor: theme.colors.green600,
            },
            ':active': {
              backgroundColor: theme.colors.green500,
            },
          },
    ],
    [
      BUTTON_COLOR.WHITE,
      disabled
        ? {
            backgroundColor: theme.colors.gray400,
            color: theme.colors.gray700,
          }
        : {
            backgroundColor: theme.colors.white900,
            color: theme.colors.gray700,
            ':hover': {
              backgroundColor: theme.colors.gray100,
            },
            ':active': {
              backgroundColor: theme.colors.gray100,
            },
          },
    ],
    [
      BUTTON_COLOR.RED,
      disabled
        ? {
            backgroundColor: theme.colors.red500,
          }
        : {
            backgroundColor: theme.colors.red500,
            ':hover': {
              backgroundColor: theme.colors.red600,
            },
            ':active': {
              backgroundColor: theme.colors.red500,
            },
          },
    ],
  ]);

  return map.get(color);
};

export const DELETE_BUTTON_STYLES: (props: {
  isDeleteButton: boolean;
  disabled?: boolean;
  theme: TrustvideoTheme;
}) => StyleObject = ({ isDeleteButton, disabled, theme }) =>
  isDeleteButton && !disabled
    ? {
        ':hover': {
          color: theme.colors.red500,
        },
      }
    : {};
