import { useCallback } from 'react';
import { useStyletron } from 'shared/ui';

export const useStyles = (size: number) => {
  const [css, theme] = useStyletron();

  const wrapper = css({
    fontSize: `${size}px`,
    overflow: 'hidden',
    position: 'relative',
  });

  const imageStyles = useCallback(
    (height = 64) =>
      css({
        display: 'block',
        maxWidth: '100%',
        maxHeight: `${height}px`,
        '::after': {
          content: "''",
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: theme.colors.white900,
        },
      }),
    [css, theme.colors.white900]
  );

  const organizationAbbrStyles = css({
    height: '1em',
    display: 'inline-flex',
    verticalAlign: 'top',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '1em',
    paddingRight: '0.1875em',
    paddingLeft: '0.1875em',
    backgroundColor: theme.colors.accent500,
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
  });

  const organizationAbbrText = css({
    color: theme.colors.white900,
    fontSize: '0.25em',
    fontWeight: 500,
  });

  return {
    wrapper,
    imageStyles,
    organizationAbbrStyles,
    organizationAbbrText,
  };
};
