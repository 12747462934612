import { FC, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ModalCloseEvent, ModalProps } from './model';
import { useStyles } from './styles';

export const Modal: FC<ModalProps> = ({
  children,
  onClose,
  isCatchCloseEvent = true,
}) => {
  const { rootClass, modalBg } = useStyles();
  const [modalRoot] = useState(document.createElement('div'));
  const handleBackgroundClick = useCallback(() => {
    if (onClose && isCatchCloseEvent) {
      onClose(ModalCloseEvent.BackgroundClickEvent);
    }
  }, [onClose, isCatchCloseEvent]);

  const modalMarkup = (
    <>
      <div
        className={modalBg}
        onClick={handleBackgroundClick}
        aria-hidden="true"
      />
      {children}
    </>
  );

  const catchESC = useCallback(
    (e) => {
      if (e.code === 'Escape' && onClose && isCatchCloseEvent) {
        onClose(ModalCloseEvent.KeyboardEvent);
      }
    },
    [onClose, isCatchCloseEvent]
  );

  useEffect(() => {
    modalRoot.className = rootClass;
    document.body.appendChild(modalRoot);
    return () => {
      document.body.removeChild(modalRoot);
    };
  }, [rootClass, modalRoot]);

  useEffect(() => {
    document.addEventListener('keyup', catchESC);
    document.documentElement.style.paddingRight = `${
      window.innerWidth - document.documentElement.offsetWidth
    }px`;
    document.documentElement.classList.add('is-fixed_modal');

    return () => {
      document.removeEventListener('keyup', catchESC);
      document.documentElement.style.paddingRight = '';
      document.documentElement.classList.remove('is-fixed_modal');
    };
  }, [catchESC]);

  return createPortal(modalMarkup, modalRoot);
};
