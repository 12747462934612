import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { StyledSpinnerNext } from 'baseui/spinner';
import {
  NotificationChannelsEnum,
  useParticipantsConnectionLazyQuery,
} from 'shared/api';
import { DEFAULT_PAGE_SIZE, useDebounceValue } from 'shared/lib';
import { SearchIcon, Input, INPUT_KIND } from 'shared/ui';
import { useStyles } from './styles';
import { CreateNoteNewParticipant } from './model';

export const CreateNoteAddParticipant: FC<CreateNoteNewParticipant> = ({
  onChoose,
  existsParticipants = [],
}) => {
  const { t } = useTranslation([
    'common',
    'appointments',
    'participants',
    'chat',
  ]);
  const {
    templateIconClass,
    popoverOverrides,
    popoverContainer,
    listWrapperClass,
    itemClass,
    nameClass,
    captionClass,
    spinnerStyle,
    messageClass,
  } = useStyles();

  const [searchTerm, debounceSearchTerm, setSearchTerm] = useDebounceValue(
    '',
    500
  );
  const [getParticipants, { data, loading }] =
    useParticipantsConnectionLazyQuery();

  useEffect(() => {
    getParticipants({
      variables: {
        search: debounceSearchTerm,
        first: DEFAULT_PAGE_SIZE,
        exclude: existsParticipants?.map(({ id }) => id),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchTerm, getParticipants]);

  const handleChoose = useCallback(
    ({ id, name }) => {
      onChoose?.({
        id,
        name,
      });
    },
    [onChoose]
  );

  return (
    <StatefulPopover
      placement={PLACEMENT.bottomLeft}
      overrides={popoverOverrides}
      popoverMargin={8}
      content={() => (
        <>
          {loading ? (
            <StyledSpinnerNext $style={spinnerStyle} />
          ) : data?.participants?.edges.length ? (
            <div className={listWrapperClass}>
              <ul>
                {data?.participants?.edges.map(({ node: participant }) => {
                  const caption =
                    participant.default_notification_channel ===
                    NotificationChannelsEnum.Mail
                      ? participant.email
                      : participant.sms ?? undefined;
                  return (
                    <li
                      className={itemClass}
                      key={participant.id}
                      onClick={() => handleChoose(participant)}
                      aria-hidden="true"
                    >
                      <div>
                        <p className={nameClass}>{participant.name}</p>
                        {caption && <p className={captionClass}>{caption}</p>}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <p className={messageClass}>{t('common:SEARCH.NO_RECORD_FOUND')}</p>
          )}
        </>
      )}
      accessibilityType="menu"
      triggerType={TRIGGER_TYPE.click}
    >
      <div className={popoverContainer}>
        <Input
          placeholder={t('appointments:VIEW.SEARCH_PARTICIPANT')}
          value={searchTerm}
          onChange={({ currentTarget }) => setSearchTerm(currentTarget.value)}
          startEnhancer={<SearchIcon className={templateIconClass} />}
          kind={INPUT_KIND.SECONDARY}
        />
      </div>
    </StatefulPopover>
  );
};
