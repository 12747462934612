import { AppointmentVideoTypeEnum } from 'shared/api';
import { useHasRoles } from 'shared/lib';

export const useCanAddParticipant = (
  videoType: AppointmentVideoTypeEnum,
  participantsLength: number
) => {
  const isExternalAdministrator = useHasRoles(['External Administrator']);

  if (isExternalAdministrator) {
    return false;
  }

  if (
    videoType === AppointmentVideoTypeEnum.Opentok &&
    participantsLength >= 4
  ) {
    return false;
  }

  return true;
};
