import React, {
  createContext,
  Dispatch,
  FC,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import {
  Organization,
  SharedDataQuery,
  Permission,
  Role,
  User,
  useSharedDataLazyQuery,
} from 'shared/api';
import { setUserTimezone } from 'shared/lib';
import { Suspender } from 'shared/ui';

export type OrganizationSharedData = Pick<
  Organization,
  'id' | 'subdomain' | 'name'
> | null;

export type UserSharedData = Pick<
  User,
  | 'id'
  | 'timezone'
  | 'name'
  | 'username'
  | 'is_admin'
  | 'email'
  | 'phone'
  | 'sms'
  | 'avatar_url'
  | 'has_medcart'
  | 'settings'
  | 'address'
  | 'zip'
  | 'birth_date'
  | 'sex'
  | 'device_user'
  | 'external_calendar_connected'
> | null;
type State = {
  user: UserSharedData;
  permissions: string[];
  organizations: OrganizationSharedData[];
  roles: string[];
  getUserData: () => void;
};

enum ACTIONS {
  LOAD_SHARED_DATA = 'LOAD_SHARED_DATA',
  CLEAR_SHARED_DATA = 'CLEAR_SHARED_DATA',
}

type Action = Partial<State> &
  (
    | {
        type: ACTIONS.CLEAR_SHARED_DATA;
      }
    | { type: ACTIONS.LOAD_SHARED_DATA; payload: SharedDataQuery }
  );

const initialState: State = {
  user: null,
  permissions: [],
  roles: [],
  organizations: [],
  getUserData: () => {
    throw new Error('getUserData not implemented');
  },
};

export const SharedContext = createContext<[State, Dispatch<Action>]>([
  initialState,
  () => undefined,
]);

const SharedContextReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_SHARED_DATA:
      return { ...state, roles: [], permissions: [] };
    case ACTIONS.LOAD_SHARED_DATA: {
      const roles = (action.payload.user?.roles as Role[]) ?? [];
      const permissions = (
        (action.payload.user?.permissions as Permission[])?.map(
          ({ name }) => name
        ) ?? []
      ).concat(
        ...roles.map(({ permissions: rolesPermissions }) =>
          (rolesPermissions as Permission[])?.map(({ name }) => name)
        )
      );
      const user: UserSharedData = action.payload.user
        ? {
            id: action.payload.user.id,
            timezone: action.payload.user.timezone,
            name: action.payload.user.name,
            username: action.payload.user.username,
            is_admin: action.payload.user.is_admin,
            email: action.payload.user.email,
            phone: action.payload.user.phone,
            sms: action.payload.user.sms,
            avatar_url: action.payload.user.avatar_url,
            has_medcart: action.payload.user.has_medcart,
            settings: action.payload.user.settings,
            address: action.payload.user.address,
            zip: action.payload.user.zip,
            birth_date: action.payload.user.birth_date,
            sex: action.payload.user.sex,
            device_user: action.payload.user.device_user,
            external_calendar_connected:
              action.payload.user.external_calendar_connected,
          }
        : null;

      const organizations: OrganizationSharedData[] =
        action.organizations ?? [];
      return {
        ...state,
        user,
        organizations,
        roles: roles.map(({ name }) => name),
        permissions: [...new Set(permissions)],
      };
    }
    default: {
      return state;
    }
  }
};

export const SharedContextProvider: FC = ({ children }) => {
  const reducer = useCallback(SharedContextReducer, []);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [getUser] = useSharedDataLazyQuery({
    onCompleted: (result) => {
      dispatch({ type: ACTIONS.LOAD_SHARED_DATA, payload: result });
      if (result.user?.timezone) {
        setUserTimezone(result.user.timezone);
      }

      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const getUserData = useCallback(() => {
    setIsLoading(true);
    getUser();
  }, [getUser]);

  const value: [State, React.Dispatch<Action>] = useMemo(
    () => [{ ...state, getUserData }, dispatch],
    [state, getUserData]
  );

  if (isLoading) {
    return <Suspender />;
  }

  return (
    <SharedContext.Provider value={value}>
      {children || <Outlet />}
    </SharedContext.Provider>
  );
};
