import { fromPromise, NextLink, Operation, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  RefreshTokenDocument,
} from 'shared/api';
import { getIdToken, getRefreshToken, logout, setToken } from 'shared/lib';

import { client } from '../client';
import { isGraphQLError } from '../error';

const refreshTokenFn: () => Promise<string> = async () => {
  const refreshToken = getRefreshToken();
  const idToken = getIdToken();
  if (!refreshToken || !idToken) {
    throw new Error('Refresh or access token does not exist');
  }

  const { data, errors } = await client.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: RefreshTokenDocument,
    variables: {
      input: {
        id_token: idToken,
        refresh_token: refreshToken,
      },
    },
    context: {
      publicRequest: true,
    },
  });

  if (errors || !data?.refresh?.IdToken) {
    throw errors ?? new Error('Id token does not exist');
  }
  setToken('idToken', data.refresh.IdToken);
  return data.refresh.IdToken;
};

const updateToken = (forward: NextLink, operation: Operation) =>
  fromPromise(
    refreshTokenFn().catch((e) => {
      // eslint-disable-next-line no-console
      console.error(`[Refresh token error]: ${e}`);
      logout();
      return null;
    })
  )
    .filter((newToken) => newToken !== null)
    .flatMap((newToken) => {
      const oldHeaders = operation.getContext().headers;
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${newToken}`,
        },
      });
      return forward(operation);
    });

export const errorLink = onError(
  // eslint-disable-next-line consistent-return
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (isGraphQLError(graphQLErrors)) {
      // eslint-disable-next-line consistent-return
      graphQLErrors?.forEach(({ message, locations, path }) => {
        // if (message === 'Unauthenticated.') {
        //   return updateToken(forward, operation);
        // }
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      if ((networkError as ServerError).statusCode === 401) {
        return updateToken(forward, operation);
      }
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`);
    }
  }
);
