import { useModalPanel } from 'modals';
import { ModalPanelProps, MODAL_PANELS } from 'modals/model';

export const useAppointmentModal = (
  props?: Partial<ModalPanelProps[MODAL_PANELS.VIEW_APPOINTMENT]>
) =>
  useModalPanel({
    name: MODAL_PANELS.VIEW_APPOINTMENT,
    props,
    panelProps: {
      rootStyles: {
        flexDirection: 'row',
      },
    },
  });
