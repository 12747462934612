import { FONT, MOBILE, TrustvideoTheme } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { SELECT_SIZE, SELECT_KIND } from './model';

export const SELECT_SIZE_STYLES = ({ size }: { size: SELECT_SIZE }) => {
  const map = new Map<SELECT_SIZE, StyleObject>([
    [
      SELECT_SIZE.SMALL,
      {
        height: '40px',
        paddingRight: '10px',
        paddingLeft: '16px',
        fontSize: FONT.size_sm,

        [MOBILE]: {
          height: '48px',
          paddingRight: '12px',
          paddingLeft: '16px',
          fontSize: FONT.size_base,
        },
      },
    ],
    [
      SELECT_SIZE.MEDIUM,
      {
        height: '48px',
        paddingRight: '16px',
        paddingLeft: '16px',
        fontSize: FONT.size_base,

        [MOBILE]: {
          paddingRight: '12px',
        },
      },
    ],
    [
      SELECT_SIZE.LARGE,
      {
        height: '56px',
        paddingRight: '16px',
        paddingLeft: '16px',
        fontSize: FONT.size_lg,
      },
    ],
  ]);

  return map.get(size);
};

export const SELECT_KIND_STYLES = ({
  kind,
  theme,
}: {
  kind: SELECT_KIND;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<SELECT_KIND, StyleObject>([
    [
      SELECT_KIND.PRIMARY,
      {
        fontWeight: FONT.weight_normal,
        borderLeftColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderTopColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        backgroundColor: theme.colors.white900,

        ':hover': {
          borderTopColor: theme.colors.gray300,
          borderRightColor: theme.colors.gray300,
          borderBottomColor: theme.colors.gray300,
          borderLeftColor: theme.colors.gray300,
        },
      },
    ],
    [
      SELECT_KIND.SECONDARY,
      {
        fontWeight: FONT.weight_normal,
        borderLeftColor: theme.colors.gray100,
        borderRightColor: theme.colors.gray100,
        borderTopColor: theme.colors.gray100,
        borderBottomColor: theme.colors.gray100,
        backgroundColor: theme.colors.gray100,

        ':hover': {
          borderTopColor: theme.colors.gray300,
          borderRightColor: theme.colors.gray300,
          borderBottomColor: theme.colors.gray300,
          borderLeftColor: theme.colors.gray300,
        },
      },
    ],
    [
      SELECT_KIND.TERTIARY,
      {
        fontWeight: FONT.weight_medium,
        borderLeftColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderTopColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        backgroundColor: theme.colors.white900,
        ':hover': {
          borderTopColor: theme.colors.gray300,
          borderRightColor: theme.colors.gray300,
          borderBottomColor: theme.colors.gray300,
          borderLeftColor: theme.colors.gray300,
          color: theme.colors.accent500,
        },
      },
    ],
  ]);

  return map.get(kind);
};
