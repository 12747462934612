import { styled, useStyletron } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const Root = styled('div', {
  position: 'relative',
  overflow: 'hidden',
});

export const Content = styled('div', {
  overflow: 'auto',
});

const pseudoGradientStyles: StyleObject = {
  content: '""',
  position: 'absolute',
  right: 0,
  left: 0,
  display: 'block',
  pointerEvents: 'none',
  zIndex: 1,
};

export const useStyles = () => {
  const [css] = useStyletron();

  const topGradientClass = (
    isVisible: boolean,
    gradient?: {
      color?: string;
      height?: string;
    }
  ) =>
    css({
      transition: 'opacity 0.3s',
      opacity: isVisible ? 1 : 0,

      '::before': {
        ...pseudoGradientStyles,

        height: gradient?.height ?? '20px',
        top: 0,
        backgroundImage: `linear-gradient(0deg, ${
          gradient?.color ?? '#fff'
        }0 0, ${gradient?.color ?? '#fff'}f 100%)`,
      },
    });

  const bottomGradientClass = (
    isVisible: boolean,
    gradient?: {
      color?: string;
      height?: string;
    }
  ) =>
    css({
      transition: 'opacity 0.3s',
      opacity: isVisible ? 1 : 0,

      '::before': {
        ...pseudoGradientStyles,

        height: gradient?.height ?? '20px',
        bottom: 0,
        backgroundImage: `linear-gradient(180deg, ${
          gradient?.color ?? '#fff'
        }0 0, ${gradient?.color ?? '#fff'}f 100%)`,
      },
    });

  return {
    topGradientClass,
    bottomGradientClass,
  };
};
