import { CloseIcon, IS_NO_TOUCH, styled, useStyletron } from 'shared/ui';
import { theme } from 'pages/session-room/ui/theme';
import { StyleObject } from 'styletron-standard';

export const HeaderLeft = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  flexGrow: 1,
});

export const HeaderTitle = styled('h2', {
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: 1.67,
  color: 'currentColor',
});

export const HeaderCloseButton = styled('button', {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '24px 32px',
  color: theme.colors.gray,

  [IS_NO_TOUCH]: {
    transition: 'color 0.3s',

    ':hover': {
      color: theme.colors.gray700,
    },
  },
});

export const HeaderCloseIcon = styled(CloseIcon, {
  width: '24px',
  pointerEvents: 'none',
});

export const DragArea = styled('div', {
  userSelect: 'none',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const useStyles = () => {
  const [css] = useStyletron();

  const headerDarkStyles = {
    backgroundColor: theme.colors.blue,
    color: theme.colors.white,
    border: `1px solid rgba(255, 255, 255, 0.1)`,
  };

  const getHeaderStyles = (isDarkTheme: boolean) =>
    css({
      color: theme.colors.black,
      backgroundColor: theme.colors.white,
      borderRadius: '24px 24px 0 0',
      borderBottom: `1px solid ${theme.colors.gray}`,
      padding: '16px 80px 16px 32px',
      position: 'relative',
      userSelect: 'none',
      overflow: 'hidden',
      ...(isDarkTheme && headerDarkStyles),
    });

  const getDragStyles = (isDrag: boolean): StyleObject => ({
    cursor: isDrag ? 'grabbing' : 'grab',
  });

  return {
    getHeaderStyles,
    getDragStyles,
  };
};
