import { utcToZonedTime } from 'date-fns-tz';
import { getUserTimezone } from 'shared/lib';

export type ICustomDate = {
  /** just new Date(string from api) */
  rawDate: Date;
  /** date with offset fix */
  date: Date;
};

export class CustomDate implements ICustomDate {
  date = utcToZonedTime(new Date(this.rawDate), getUserTimezone());

  constructor(public rawDate: Date) {}
}
