import { FC } from 'react';
import { useCurrentOrganization } from 'entities/organization';
import { OrganizationLogo } from 'features/organization';
import { OrganizationInfo } from './styles';

export const ErrorPageOrganizationInfo: FC = () => {
  const { organization } = useCurrentOrganization();

  return (
    <OrganizationInfo>
      <OrganizationLogo
        name={organization.name}
        logoUrl={organization.logo_url}
      />
      {!!organization.support_info && (
        <div
          className="wysiwyg-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: organization.support_info }}
        />
      )}
    </OrganizationInfo>
  );
};
