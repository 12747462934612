import { useStyletron, MOBILE } from 'shared/ui';

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    overflow: 'hidden',

    [MOBILE]: {
      top: '80px',
    },
  });

  const panelBg = css({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    willChange: 'opacity',
    transform: 'translateZ(0)',
    cursor: 'pointer',
    animationDuration: '300ms',
    // eslint-disable-next-line
    // @ts-ignore
    animationName: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
  });

  const panelContent = css({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    willChange: 'transform',
    animationDuration: '300ms',
    animationTimingFunction: 'ease-out',
    // eslint-disable-next-line
    // @ts-ignore
    animationName: {
      from: {
        transform: 'translateX(100%)',
      },
      to: {
        transform: 'translateX(0)',
      },
    },
    [MOBILE]: {
      top: '80px',
      left: 0,
    },
  });

  return {
    rootClass,
    panelBg,
    panelContent,
  };
};
