import { useAppointmentCalendarModal } from 'entities/appointment';
import { useCallback } from 'react';
import { useDuplicateAppointmentMutation } from 'shared/api';
import { ScheduleNextIcon } from 'shared/ui';
import { AppointmentRescheduleProps } from './model';

export const useAppointmentScheduleNext = ({
  appointment,
}: AppointmentRescheduleProps) => {
  const [duplicate] = useDuplicateAppointmentMutation();

  const onSuccess = useCallback(
    async (newDate: Date | null) => {
      if (!newDate) {
        throw new Error('Date is null');
      }
      duplicate({ variables: { startTime: newDate, id: appointment.id } });
    },
    [appointment.id, duplicate]
  );

  const { open } = useAppointmentCalendarModal({
    icon: ScheduleNextIcon,
    title: 'SCHEDULE_NEXT.TITLE',
    successButton: 'SCHEDULE_NEXT.SUCCESS_BUTTON',
  });

  return useCallback(() => {
    open({ appointment, onSuccess });
  }, [open, appointment, onSuccess]);
};
