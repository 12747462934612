import { FC } from 'react';
import { DatePicker } from 'shared/ui';
import {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears,
  isEqual,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const DateRange: FC<{
  value: Date[];
  onChange: (date: Date[]) => void;
  monthsShown?: number;
}> = ({ value, onChange, monthsShown }) => {
  const { t } = useTranslation(['common']);
  const {
    rootClass,
    controlWrapperClass,
    controlListClass,
    controlItemClass,
    getControlButtonClass,
    calendarContainerClass,
  } = useStyles();

  const controls = [
    {
      title: t('common:DATE_RANGE.TODAY'),
      onClick: () => onChange([startOfToday(), endOfToday()]),
      isActive:
        (isEqual(value[0], startOfToday()) &&
          isEqual(value[1], endOfToday())) ||
        (isEqual(value[0], startOfToday()) && value.length === 1),
    },
    {
      title: t('common:DATE_RANGE.YESTERDAY'),
      onClick: () => onChange([startOfYesterday(), endOfYesterday()]),
      isActive:
        (isEqual(value[0], startOfYesterday()) &&
          isEqual(value[1], endOfYesterday())) ||
        (isEqual(value[0], startOfYesterday()) && value.length === 1),
    },
    {
      title: t('common:DATE_RANGE.THIS_WEEK'),
      onClick: () => onChange([startOfWeek(new Date()), endOfWeek(new Date())]),
      isActive:
        isEqual(value[0], startOfWeek(new Date())) &&
        isEqual(value[1], endOfWeek(new Date())),
    },
    {
      title: t('common:DATE_RANGE.LAST_WEEK'),
      onClick: () =>
        onChange([
          addWeeks(startOfWeek(new Date()), -1),
          addWeeks(endOfWeek(new Date()), -1),
        ]),
      isActive:
        isEqual(value[0], addWeeks(startOfWeek(new Date()), -1)) &&
        isEqual(value[1], addWeeks(endOfWeek(new Date()), -1)),
    },
    {
      title: t('common:DATE_RANGE.THIS_MONTH'),
      onClick: () =>
        onChange([startOfMonth(new Date()), endOfMonth(new Date())]),
      isActive:
        isEqual(value[0], startOfMonth(new Date())) &&
        isEqual(value[1], endOfMonth(new Date())),
    },
    {
      title: t('common:DATE_RANGE.LAST_MONTH'),
      onClick: () =>
        onChange([
          addMonths(startOfMonth(new Date()), -1),
          addMonths(endOfMonth(new Date()), -1),
        ]),
      isActive:
        isEqual(value[0], addMonths(startOfMonth(new Date()), -1)) &&
        isEqual(value[1], addMonths(endOfMonth(new Date()), -1)),
    },
    {
      title: t('common:DATE_RANGE.THIS_YEAR'),
      onClick: () => onChange([startOfYear(new Date()), endOfYear(new Date())]),
      isActive:
        isEqual(value[0], startOfYear(new Date())) &&
        isEqual(value[1], endOfYear(new Date())),
    },
    {
      title: t('common:DATE_RANGE.LAST_YEAR'),
      onClick: () =>
        onChange([
          addYears(startOfYear(new Date()), -1),
          addYears(endOfYear(new Date()), -1),
        ]),
      isActive:
        isEqual(value[0], addYears(startOfYear(new Date()), -1)) &&
        isEqual(value[1], addYears(endOfYear(new Date()), -1)),
    },
  ];

  return (
    <div className={rootClass}>
      <div className={controlWrapperClass}>
        <ul className={controlListClass}>
          {controls.map(({ title, onClick, isActive }) => (
            <li className={controlItemClass} key={title}>
              <button
                className={getControlButtonClass(isActive)}
                type="button"
                onClick={() => onClick?.()}
              >
                {title}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className={calendarContainerClass}>
        <DatePicker
          value={value}
          onChange={(dateValue) => {
            if (!dateValue) {
              return;
            }
            onChange(dateValue as Date[]);
          }}
          isAllDayAvailable
          range
          monthsShown={monthsShown}
        />
      </div>
    </div>
  );
};
