import { FC } from 'react';
import { StatefulPanel, StatefulPanelProps } from 'baseui/accordion';
import { StyleObject } from 'styletron-standard';
import { IconButton } from '../icon-button';
import { ChevronDownIcon } from '../icons';

export const AccordionPanel: FC<
  Omit<StatefulPanelProps, 'overrides'> & { headerStyles?: StyleObject }
> = ({ children, headerStyles, ...props }) => (
  <StatefulPanel
    overrides={{
      Header: {
        style: {
          paddingRight: 0,
          paddingBottom: 0,
          paddingTop: 0,
          paddingLeft: 0,
          ...headerStyles,
        },
      },
      PanelContainer: {
        style: {
          borderBottomWidth: 0,
        },
      },
      Content: {
        style: {
          paddingRight: 0,
          paddingBottom: 0,
          paddingTop: 0,
          paddingLeft: 0,
        },
      },
      ToggleIcon: {
        component: ({
          $disabled = false,
          $expanded = false,
        }: {
          $expanded?: boolean;
          $disabled?: boolean;
        }) => (
          <IconButton disabled={$disabled} type="button">
            <ChevronDownIcon
              style={{
                transform: $expanded ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.2s',
              }}
            />
          </IconButton>
        ),
      },
    }}
    {...props}
  >
    {children}
  </StatefulPanel>
);
