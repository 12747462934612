import { useStyletron } from 'shared/ui';

export const useStyles = () => {
  const [css] = useStyletron();

  const reactCropClass = css({
    display: 'block',
  });

  const imageClass = css({
    width: '100%',
  });

  const canvasClass = css({
    display: 'none',
  });

  return {
    reactCropClass,
    imageClass,
    canvasClass,
  };
};
