import { forwardRef } from 'react';
import { useStyletron, TEXT, MOBILE, DESKTOP } from 'shared/ui';
import { useHasAnyRoles } from 'shared/lib';
import { useTranslation } from 'react-i18next';
import { MenuList } from './model';
import { MenuItem } from './item/item';

export type MenuProps = {
  list?: MenuList[];
  onClick?: () => void;
  onClose?: () => void;
};

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ list, onClick, onClose }, ref) => {
    const [css, theme] = useStyletron();
    const { t } = useTranslation();
    const checkRoles = useHasAnyRoles;

    if (!list) {
      return null;
    }

    return (
      <div
        className={css({
          [DESKTOP]: {
            maxHeight: '50vh',
            overflow: 'auto',
          },
          [MOBILE]: {
            margin: '-16px',
          },
        })}
        ref={ref}
        data-menu
      >
        {list.map(
          (block, index) =>
            !!block.items.length && (
              <div
                className={css({
                  padding: '8px 0',
                  borderTop:
                    index !== 0 ? `1px solid ${theme.colors.gray200}` : 0,
                })}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {block.title && (
                  <h6
                    className={css({
                      padding: '8px 24px',
                      ...TEXT.xs_book,
                      color: theme.colors.gray400,
                    })}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {t(block.title as any)}
                  </h6>
                )}
                <ul
                  className={css({
                    display: 'flex',
                    flexDirection: 'column',

                    [MOBILE]: {
                      gap: '8px',
                    },
                  })}
                >
                  {block.items.map((item, subIndex) => {
                    const isEnabled = item.enableFor
                      ? checkRoles(item.enableFor)
                      : true;

                    if (!isEnabled) {
                      return null;
                    }

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`${index}-${subIndex}`}>
                        <MenuItem
                          onClick={onClick}
                          onClose={onClose}
                          item={item}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )
        )}
      </div>
    );
  }
);
