import { FC, useCallback } from 'react';
import {
  MODAL_ICON_TYPE,
  Button,
  BUTTON_SIZE,
  BUTTON_KIND,
  AssignDocumentIcon,
  CheckIcon,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import {
  useDocumentModal,
  DocumentModalProps,
} from 'modals/appointment/document-modal';
import { MODAL_TYPE, useModal } from 'shared/lib';
import { useStyles } from './styles';

const Footer: FC<{
  documentProps: DocumentModalProps;
  onClose: () => void;
}> = ({ documentProps, onClose }) => {
  const { t } = useTranslation(['appointments']);
  const { rootClass, iconClass } = useStyles();
  const openDocumentModal = useDocumentModal(documentProps);

  return (
    <div className={rootClass}>
      <Button
        size={BUTTON_SIZE.SMALL}
        kind={BUTTON_KIND.SECONDARY}
        type="button"
        startEnhancer={<AssignDocumentIcon className={iconClass} />}
        onClick={() => {
          onClose();
          openDocumentModal();
        }}
      >
        {t('appointments:PARTICIPANT_SUCCESS.ASSIGN')}
      </Button>
      <Button size={BUTTON_SIZE.SMALL} type="button" onClick={onClose}>
        {t('appointments:PARTICIPANT_SUCCESS.BACK')}
      </Button>
    </div>
  );
};

export const useParticipantSuccess = () => {
  const { t } = useTranslation(['appointments']);
  const { open, close } = useModal();

  const openSuccess = useCallback(
    ({
      name = t('appointments:PARTICIPANT_SUCCESS.USER'),
      documentProps,
    }: {
      name?: string;
      documentProps: DocumentModalProps;
    }) => {
      open(MODAL_TYPE.DEFAULT, {
        title: t('appointments:PARTICIPANT_SUCCESS.TITLE'),
        content: `${name} ${t('appointments:PARTICIPANT_SUCCESS.CONTENT')}`,
        icon: {
          type: MODAL_ICON_TYPE.SUCCESS,
          svg: (className) => <CheckIcon className={className} />,
        },
        footer: <Footer documentProps={documentProps} onClose={close} />,
      });
    },
    [open, close, t]
  );

  return openSuccess;
};
