import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { fullTimeFormat } from 'shared/lib';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_KIND,
  IconButton,
  ChevronDownIcon,
  Desktop,
} from 'shared/ui';
import { NoteMenu } from 'entities/note';
import { NoteBlockHeaderInterface } from './model';
import {
  Root,
  HeaderInfo,
  Title,
  useStyles,
  HeaderMeta,
  NoteId,
  Date,
  HeaderButtons,
} from './styles';

export const NoteBlockHeader: FC<NoteBlockHeaderInterface> = ({
  note,
  isOpen,
  onToggle,
  onEdit,
  onRemove,
  onNoteExport,
}) => {
  const { t } = useTranslation(['common']);
  const { chevronSmallIconStyle, chevronIconStyle } = useStyles(isOpen);

  return (
    <Root>
      <HeaderInfo>
        <Title>{note.title ?? '–'}</Title>

        <HeaderMeta>
          <Desktop>
            <NoteId>#{note.id}</NoteId>
          </Desktop>

          <Date>{fullTimeFormat(note.created_at.rawDate)}</Date>
        </HeaderMeta>
      </HeaderInfo>

      <HeaderButtons>
        <Desktop>
          {isOpen ? (
            <NoteMenu
              note={note}
              onEdit={onEdit}
              onNoteExport={onNoteExport}
              onRemove={onRemove}
            >
              <Button
                size={BUTTON_SIZE.SMALL}
                kind={BUTTON_KIND.SECONDARY}
                endEnhancer={
                  <ChevronDownIcon className={chevronSmallIconStyle} />
                }
              >
                {t('common:BUTTONS.MANAGE')}
              </Button>
            </NoteMenu>
          ) : null}
        </Desktop>

        <IconButton type="button" onClick={onToggle}>
          <ChevronDownIcon className={chevronIconStyle} />
        </IconButton>
      </HeaderButtons>
    </Root>
  );
};
