import { FC, forwardRef } from 'react';
import { Button as BaseButton } from 'baseui/button';
import {
  DELETE_BUTTON_STYLES,
  MOBILE,
  mergeStyles,
  useStyletron,
} from 'shared/ui';
import { IconButtonProps, ICON_BUTTON_KIND } from './model';
import { ICON_BUTTON_KIND_STYLES } from './util';

export const IconButton: FC<IconButtonProps> = forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(
  (
    {
      children,
      type,
      onClick,
      isLoading,
      kind = ICON_BUTTON_KIND.SECONDARY,
      isDeleteButton = false,
      style = {},
      disabled,
    },
    ref
  ) => {
    const [, theme] = useStyletron();

    return (
      <BaseButton
        ref={ref}
        overrides={{
          BaseButton: {
            style: mergeStyles([
              {
                alignItems: 'center',
                gap: '8px',
                flexShrink: 0,
                width: '40px',
                height: '40px',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                borderBottomLeftRadius: '4px',
                transitionProperty: 'background, color, border-color',
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                paddingLeft: 0,

                [MOBILE]: {
                  width: '48px',
                  height: '48px',
                },
              },
              ICON_BUTTON_KIND_STYLES({ kind, theme }) ?? {},
              DELETE_BUTTON_STYLES({ isDeleteButton, theme }),
              style,
            ]),
          },
        }}
        kind={kind}
        onClick={onClick}
        type={type}
        isLoading={isLoading}
        disabled={disabled}
      >
        {children}
      </BaseButton>
    );
  }
);
