export { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
export { ReactComponent as AdministrationIcon } from '../../../assets/icons/administration.svg';
export { ReactComponent as AlertIcon } from '../../../assets/icons/alert.svg';
export { ReactComponent as AmericanExpressIcon } from '../../../assets/icons/american-express.svg';
export { ReactComponent as AnalyticsIcon } from '../../../assets/icons/analytics.svg';
export { ReactComponent as AppearenceIcon } from '../../../assets/icons/appearence.svg';
export { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg';
export { ReactComponent as AssignDocumentIcon } from '../../../assets/icons/assign-document.svg';
export { ReactComponent as AttentionIcon } from '../../../assets/icons/attention.svg';
export { ReactComponent as BillingIcon } from '../../../assets/icons/billing.svg';
export { ReactComponent as BloodPressureIcon } from '../../../assets/icons/blood-pressure.svg';
export { ReactComponent as BrowserIcon } from '../../../assets/icons/browser.svg';
export { ReactComponent as BurgerIcon } from '../../../assets/icons/burger.svg';
export { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
export { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg';
export { ReactComponent as CheckCircleIcon } from '../../../assets/icons/check-circle.svg';
export { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
export { ReactComponent as CheckboxIcon } from '../../../assets/icons/checkbox.svg';
export { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
export { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/chevron-left.svg';
export { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
export { ReactComponent as ChevronUpIcon } from '../../../assets/icons/chevron-up.svg';
export { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
export { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg';
export { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
export { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
export { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
export { ReactComponent as DevicesIcon } from '../../../assets/icons/devices.svg';
export { ReactComponent as DispatcherIcon } from '../../../assets/icons/dispatcher.svg';
export { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
export { ReactComponent as DollarIcon } from '../../../assets/icons/dollar.svg';
export { ReactComponent as DotsHorizontalIcon } from '../../../assets/icons/dots-horizontal.svg';
export { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg';
export { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
export { ReactComponent as DragIcon } from '../../../assets/icons/drag.svg';
export { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
export { ReactComponent as EditorBoldIcon } from '../../../assets/icons/editor-bold.svg';
export { ReactComponent as EditorItalicIcon } from '../../../assets/icons/editor-italic.svg';
export { ReactComponent as EditorLinkIcon } from '../../../assets/icons/editor-link.svg';
export { ReactComponent as EditorListIcon } from '../../../assets/icons/editor-list.svg';
export { ReactComponent as EditorStrikethroughIcon } from '../../../assets/icons/editor-strikethrough.svg';
export { ReactComponent as EditorTextIcon } from '../../../assets/icons/editor-text.svg';
export { ReactComponent as EkgIcon } from '../../../assets/icons/ekg.svg';
export { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';
export { ReactComponent as ExpandIcon } from '../../../assets/icons/expand.svg';
export { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg';
export { ReactComponent as EyeCloseIcon } from '../../../assets/icons/eye-close.svg';
export { ReactComponent as EyeOpenIcon } from '../../../assets/icons/eye-open.svg';
export { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
export { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
export { ReactComponent as FullscreenIcon } from '../../../assets/icons/fullscreen.svg';
export { ReactComponent as FingerprintIcon } from '../../../assets/icons/fingerprint.svg';
export { ReactComponent as GlucoseIcon } from '../../../assets/icons/glucose.svg';
export { ReactComponent as GrommetListIcon } from '../../../assets/icons/grommet-list.svg';
export { ReactComponent as GroupIcon } from '../../../assets/icons/group.svg';
export { ReactComponent as HeartBeatIcon } from '../../../assets/icons/heart-beat.svg';
export { ReactComponent as HistoryIcon } from '../../../assets/icons/history.svg';
export { ReactComponent as HorizontalDotsIcon } from '../../../assets/icons/horizontal-dots.svg';
export { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
export { ReactComponent as InMeetingIcon } from '../../../assets/icons/in-meeting.svg';
export { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
export { ReactComponent as InputIcon } from '../../../assets/icons/input.svg';
export { ReactComponent as IntegrationIcon } from '../../../assets/icons/integration.svg';
export { ReactComponent as InviteIcon } from '../../../assets/icons/invite.svg';
export { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
export { ReactComponent as MastercardIcon } from '../../../assets/icons/mastercard.svg';
export { ReactComponent as MeetingRoomChatIcon } from '../../../assets/icons/meeting-room-chat.svg';
export { ReactComponent as MicrophoneIcon } from '../../../assets/icons/microphone.svg';
export { ReactComponent as NewChatIcon } from '../../../assets/icons/new-chat.svg';
export { ReactComponent as NotesIcon } from '../../../assets/icons/notes.svg';
export { ReactComponent as NotificationIcon } from '../../../assets/icons/notification.svg';
export { ReactComponent as NurseIcon } from '../../../assets/icons/nurse.svg';
export { ReactComponent as OffVolumeIcon } from '../../../assets/icons/off-volume.svg';
export { ReactComponent as OfflineIcon } from '../../../assets/icons/offline.svg';
export { ReactComponent as OnVolumeIcon } from '../../../assets/icons/on-volume.svg';
export { ReactComponent as OnlineIcon } from '../../../assets/icons/online.svg';
export { ReactComponent as OxygenIcon } from '../../../assets/icons/oxygen.svg';
export { ReactComponent as PaperClipIcon } from '../../../assets/icons/paper-clip.svg';
export { ReactComponent as PatientsIcon } from '../../../assets/icons/patients.svg';
export { ReactComponent as PauseIcon } from '../../../assets/icons/pause.svg';
export { ReactComponent as PaymentStatusPaidIcon } from '../../../assets/icons/payment-status-paid.svg';
export { ReactComponent as PaymentsIcon } from '../../../assets/icons/payments.svg';
export { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
export { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg';
export { ReactComponent as PractitionersIcon } from '../../../assets/icons/practitioners.svg';
export { ReactComponent as QuestionIcon } from '../../../assets/icons/question.svg';
export { ReactComponent as RadioIcon } from '../../../assets/icons/radio.svg';
export { ReactComponent as RecordIcon } from '../../../assets/icons/record.svg';
export { ReactComponent as RemindIcon } from '../../../assets/icons/remind.svg';
export { ReactComponent as ReportsIcon } from '../../../assets/icons/reports.svg';
export { ReactComponent as RescheduleIcon } from '../../../assets/icons/reschedule.svg';
export { ReactComponent as RoundedLogoIcon } from '../../../assets/icons/rounded-logo.svg';
export { ReactComponent as RsvpMaybeIcon } from '../../../assets/icons/rsvp-maybe.svg';
export { ReactComponent as RsvpNoIcon } from '../../../assets/icons/rsvp-no.svg';
export { ReactComponent as RsvpYesIcon } from '../../../assets/icons/rsvp-yes.svg';
export { ReactComponent as ScheduleNextIcon } from '../../../assets/icons/schedule-next.svg';
export { ReactComponent as ScheduleIcon } from '../../../assets/icons/schedule.svg';
export { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
export { ReactComponent as SecurityIcon } from '../../../assets/icons/security.svg';
export { ReactComponent as SentIcon } from '../../../assets/icons/sent.svg';
export { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
export { ReactComponent as ShareScreenIcon } from '../../../assets/icons/share-screen.svg';
export { ReactComponent as SheldIcon } from '../../../assets/icons/sheld.svg';
export { ReactComponent as SignInIcon } from '../../../assets/icons/sign-in.svg';
export { ReactComponent as SignOutIcon } from '../../../assets/icons/sign-out.svg';
export { ReactComponent as SignatureIcon } from '../../../assets/icons/signature.svg';
export { ReactComponent as StartVideoIcon } from '../../../assets/icons/start-video.svg';
export { ReactComponent as SupportIcon } from '../../../assets/icons/support.svg';
export { ReactComponent as SwitchCameraIcon } from '../../../assets/icons/switch-camera.svg';
export { ReactComponent as TemperatureIcon } from '../../../assets/icons/temperature.svg';
export { ReactComponent as TemplateIcon } from '../../../assets/icons/template.svg';
export { ReactComponent as TextIcon } from '../../../assets/icons/text.svg';
export { ReactComponent as TriangleLeftIcon } from '../../../assets/icons/triangle-left.svg';
export { ReactComponent as TriangleRightIcon } from '../../../assets/icons/triangle-right.svg';
export { ReactComponent as UndoIcon } from '../../../assets/icons/undo.svg';
export { ReactComponent as UnfullscreenIcon } from '../../../assets/icons/unfullscreen.svg';
export { ReactComponent as UnmuteIcon } from '../../../assets/icons/unmute.svg';
export { ReactComponent as VideoIcon } from '../../../assets/icons/video.svg';
export { ReactComponent as VisaIcon } from '../../../assets/icons/visa.svg';
export { ReactComponent as VitailsIcon } from '../../../assets/icons/vitails.svg';
export { ReactComponent as ZoomIcon } from '../../../assets/icons/zoom.svg';
