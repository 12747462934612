import { FC, useCallback } from 'react';
import { Publisher } from '@opentok/client';
import {
  HeaderActionButton,
  VolumeIcon,
  SwitchIcon,
  useStyles,
} from './styles';
import { EndCallButton } from '../end-call-button';
import { useSessionRoomState } from '../../context';

export const MobileHeader: FC = () => {
  const { rootClass, actionIconsClass } = useStyles();
  const { publisher, session, isHost } = useSessionRoomState();
  const handleMuteAll = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    session?.forceMuteAll([(publisher?.context as Publisher).stream!]);
  }, [publisher?.context, session]);
  const handleSwitchCamera = useCallback(() => {
    (publisher?.context as Publisher).cycleVideo();
  }, [publisher?.context]);

  return (
    <div className={rootClass}>
      <div className={actionIconsClass}>
        {isHost ? (
          <HeaderActionButton type="button" onClick={handleMuteAll}>
            <VolumeIcon />
          </HeaderActionButton>
        ) : null}

        <HeaderActionButton type="button" onClick={handleSwitchCamera}>
          <SwitchIcon />
        </HeaderActionButton>
      </div>

      <div>
        <EndCallButton />
      </div>
    </div>
  );
};
