import { StyleObject } from 'styletron-standard';
import { styled, useStyletron } from 'shared/ui';
import { theme } from 'pages/session-room/ui/theme';

export const MessageWrapper = styled('div', {
  marginTop: '24px',
});

export const Who = styled('div', {
  fontSize: '14px',
  lineHeight: 1.71,
  color: '#aaa8a8',
  marginLeft: '80px',
});

export const MessageBody = styled('div', {
  position: 'relative',
  paddingLeft: '64px',
});

export const Avatar = styled('div', {
  fontSize: '40px',
  width: '1em',
  height: '1em',
  borderRadius: '0.2em',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'currentColor',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
});

export const AvatarInitials = styled('div', {
  fontSize: '0.4em',
  color: theme.colors.white,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  pointerEvents: 'none',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
});

export const AvatarImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'relative',
  zIndex: 1,
});

export const MessageBalloon = styled('div', {
  display: 'inline-flex',
  verticalAlign: 'top',
  padding: '8px 16px',
  backgroundColor: 'currentColor',
  borderRadius: '16px',
});

export const useStyles = () => {
  const [css] = useStyletron();

  const getMessageColor = (isYou: boolean, isDark: boolean): StyleObject => ({
    color: isYou
      ? isDark
        ? 'rgba(17, 17, 17, 0.3)'
        : '#e5e5e4'
      : isDark
      ? '#3e8afe'
      : 'rgba(62, 138, 254, 0.1)',
  });

  const getTextColor = (isDark: boolean) =>
    css({
      fontSize: '16px',
      lineHeight: 1.5,
      color: isDark ? theme.colors.white : theme.colors.black,
    });

  return {
    getMessageColor,
    getTextColor,
  };
};
