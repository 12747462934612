import {
  FC,
  Children,
  isValidElement,
  useState,
  ReactElement,
  useMemo,
} from 'react';
import { Mobile } from 'shared/ui';
import { useStyles, TabList, Panel, Counter } from './styles';
import { BottomTabsProps } from './model';

export const BottomTabs: FC<BottomTabsProps> = ({
  children,
  defaultTabIndex = 0,
}) => {
  const { getButtonClass } = useStyles();
  const [activeIndex, setActiveIndex] = useState(defaultTabIndex);

  const tabElements = useMemo(
    () =>
      Children.toArray(children).filter(
        (child) => isValidElement(child)
        /** @todo add correct check by name */
        // && typeof child.type === 'function'
        // child.type.name === BottomTab.name
      ),
    [children]
  ) as ReactElement[];

  return (
    <>
      <TabList>
        {tabElements.map((tab, index) => {
          if (index !== activeIndex) {
            return null;
          }
          return tab.props.children;
        })}
      </TabList>

      <Mobile>
        <Panel>
          {tabElements.map((tab, index) => (
            <button
              className={getButtonClass(index === activeIndex)}
              type="button"
              onClick={() => setActiveIndex(index)}
              // eslint-disable-next-line react/no-array-index-key
              key={tab.key}
            >
              {tab.props.icon}
              {!!tab.props.counter && <Counter>{tab.props.counter}</Counter>}
            </button>
          ))}
        </Panel>
      </Mobile>
    </>
  );
};
