import { FC, ReactNode } from 'react';
import { Container } from './styles';

type Props = {
  className?: string;
  children: ReactNode | ReactNode[];
};

export const FormRow: FC<Props> = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);
