import {
  CheckIcon,
  ChevronUpIcon,
  IS_NO_TOUCH,
  MOBILE,
  styled,
  useStyletron,
} from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { theme } from '../theme';

const DEFAULT_BUTTON_SIZE = 48;

export const Root = styled('div', {
  fontSize: `${DEFAULT_BUTTON_SIZE}px`,
  position: 'relative',
});

export const MainButton = styled('button', {
  fontSize: '1em',
  textAlign: 'center',
  color: '#aaa8a8',

  [IS_NO_TOUCH]: {
    transition: 'color 0.2s',

    ':hover': {
      color: theme.colors.white,
    },
  },

  [MOBILE]: {
    minWidth: '74px',
  },
});

export const IconWrap = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  width: '1em',
  height: '1em',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: theme.colors.blue,
});

export const Title = styled('span', {
  display: 'block',
  fontSize: `${14 / DEFAULT_BUTTON_SIZE}em`,
  fontWeight: 700,
  lineHeight: 1.71,
  color: 'currentColor',
});

const activeMenuButtonStyles: StyleObject = {
  color: theme.colors.white,
  backgroundColor: theme.colors.blue,
};

export const MenuButton = styled('button', () => {
  const width = 16;
  const height = 24;

  return {
    width: `${width / DEFAULT_BUTTON_SIZE}em`,
    height: `${height / DEFAULT_BUTTON_SIZE}em`,
    position: 'absolute',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    top: `calc(0.5em - ${height / 2 / DEFAULT_BUTTON_SIZE}em)`,
    left: `calc(50% + 0.5em + ${4 / DEFAULT_BUTTON_SIZE}em)`,
    color: '#aaa8a8',

    [IS_NO_TOUCH]: {
      transition: 'color 0.2s, background-color 0.2s',

      ':hover': {
        ...activeMenuButtonStyles,
      },
    },
  };
});

export const MenuButtonIcon = styled(ChevronUpIcon, () => {
  const size = `${16 / DEFAULT_BUTTON_SIZE}em`;

  return {
    width: size,
    height: size,
    pointerEvents: 'none',
    color: 'currentColor',
  };
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ContextMenu = styled('div', {
  position: 'absolute',
  left: 'calc(50% - 0.5em)',
  bottom: 'calc(100% + 8px)',
  width: '260px',
  overflow: 'hidden',
  borderRadius: '8px',
  boxShadow: '0 36px 56px -4px rgba(14, 31, 53, 0.28)',
  border: '1px solid rgba(40, 42, 42, 0.4)',
  backgroundColor: theme.colors.transparentBlue,
  backdropFilter: 'blur(3px)',
  zIndex: 1,
  animationDuration: '200ms',
  animationName: {
    from: {
      transform: 'translateY(0.5em)',
      opacity: 0,
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
});

export const ContextMenuInner = styled('div', {
  padding: '16px',
});

export const ContextMenuTitle = styled('strong', {
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 1.33,
  color: theme.colors.white,
  display: 'block',
});

export const ContextMenuList = styled('ul', {
  marginTop: '8px',
});

export const ContextMenuItem = styled('li', {
  marginRight: '-8px',
  marginLeft: '-8px',
});

export const DeviceButton = styled('button', {
  padding: '8px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  borderRadius: '8px',
});

export const DeviceButtonIconWrap = styled('span', {
  flex: '0 0 16px',
  display: 'block',
  marginRight: '8px',
  paddingTop: '4px',
});

export const DeviceButtonIcon = styled(CheckIcon, {
  width: '16px',
  color: '#797c81',
  pointerEvents: 'none',
});

export const DeviceButtonLabel = styled('span', {
  display: 'block',
  color: theme.colors.white,
});

export const NotificationBalloon = styled('span', {
  display: 'block',
  backgroundColor: theme.colors.red,
  position: 'absolute',
  top: `-${2 / DEFAULT_BUTTON_SIZE}em`,
  left: `calc(50% + ${18 / DEFAULT_BUTTON_SIZE}em)`,
  width: `${8 / DEFAULT_BUTTON_SIZE}em`,
  height: `${8 / DEFAULT_BUTTON_SIZE}em`,
  borderRadius: '50%',
  pointerEvents: 'none',
});

export const useStyles = () => {
  const [css] = useStyletron();

  const getActiveMenuStyles = (isActive: boolean) =>
    isActive ? activeMenuButtonStyles : undefined;

  const getActiveButtonStyle = (isSelected: boolean) =>
    isSelected
      ? undefined
      : css({
          [IS_NO_TOUCH]: {
            transition: 'background-color 0.2s',

            ':hover': {
              backgroundColor: '#3e8afe',
            },
          },
        });

  const getActiveMainButtonStyle = (
    isActive: boolean
  ): StyleObject | undefined =>
    isActive
      ? {
          color: theme.colors.white,
        }
      : undefined;

  return {
    getActiveMenuStyles,
    getActiveButtonStyle,
    getActiveMainButtonStyle,
  };
};
