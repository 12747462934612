import { FC, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  NoteTemplate,
  useCreateNoteMutation,
  useNoteTemplatesQuery,
  useUpdateNoteMutation,
} from 'shared/api';
import {
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  Select,
  SELECT_KIND,
  Folder,
  FormControl,
  Input,
  INPUT_KIND,
  TextEditor,
  convertToHTML,
  TemplateIcon,
  Checkbox,
  useMobileMediaQuery,
} from 'shared/ui';
import { fullTimeFormat, getUserZonedDateNow } from 'shared/lib';
import { CreateNoteFormValues } from '../../../modals/appointment/view/tabs/notes/ui/note-form/model';
import { CreateNoteInterface, CreateNoteParticipant } from './model';
import { Title, useStyles } from './styles';
import { CreateNoteAddParticipant } from './add-participant';

export const CreateNote: FC<CreateNoteInterface> = ({
  currentUser,
  note,
  isFlat = false,
  onChange,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'appointments']);
  const isMobile = useMobileMediaQuery();
  const {
    headerClass,
    headerTextClass,
    contentClass,
    labelClass,
    getParticipantContainerClass,
    participantListClass,
    participantItemClass,
    participantTextClass,
    getParticipantBottomClass,
    participantAddClass,
    templateIconClass,
    titleCaptionClass,
    contentBottomClass,
    cancelButtonStyles,
    getSubmitButtonStyle,
  } = useStyles();

  // Memoize
  const defaultTitle = useMemo(() => fullTimeFormat(getUserZonedDateNow()), []);
  const isEdit = useMemo(() => !!note, [note]);
  const initialEditorContent = useMemo(() => {
    if (isEdit) {
      const blocksFromHTML = convertFromHTML(note?.content as string);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      return EditorState.createWithContent(contentState);
    }

    return EditorState.createEmpty();
  }, [isEdit, note?.content]);

  // State
  const [isShowParticipantInput, setIsShowParticipantInput] = useState(false);
  const [editorState, setEditorState] = useState(initialEditorContent);
  const [templateName, setTemplateName] = useState<NoteTemplate[]>();
  const [noteUsers, setNoteUsers] = useState<CreateNoteParticipant[]>(
    note
      ? note?.users.map((user) => ({
          id: user?.id as string,
          name: user?.name as string,
        }))
      : [...(currentUser ? [currentUser] : [])]
  );

  // Queries
  const { data: noteTemplatesResponse } = useNoteTemplatesQuery();
  const [createNote, { loading: isCreateNoteLoading }] = useCreateNoteMutation({
    onCompleted() {
      onChange?.();
    },
  });
  const [updateNote, { loading: isUpdateNoteLoading }] = useUpdateNoteMutation({
    onCompleted() {
      onChange?.();
    },
  });

  const validationSchema = yup.object().shape({
    content: yup.string().required(t('appointments:ERRORS.CONTENT_REQUIRED')),
    users_ids: yup
      .array(yup.string())
      .min(1, t('appointments:ERRORS.PARTICIPANT_REQUIRED')),
    owner_id: yup.string(),
  });

  const initialValues: CreateNoteFormValues = {
    title: note?.title ?? '',
    content: note?.content ?? '',
    users_ids: noteUsers.map((user) => user.id),
  };

  const onSubmit = ({
    title,
    content,
    users_ids: usersIds,
  }: CreateNoteFormValues) => {
    if (isEdit) {
      updateNote({
        variables: {
          id: note?.id as string,
          input: {
            title: title.trim().length === 0 ? defaultTitle : title,
            content,
            users_ids: usersIds,
          },
        },
      });
      return;
    }

    createNote({
      variables: {
        input: {
          title: title.trim().length === 0 ? defaultTitle : title,
          content,
          users_ids: usersIds,
        },
      },
    });
  };

  const handleChoose = useCallback(
    (newParticipant) => {
      setNoteUsers([...noteUsers, newParticipant]);
      setIsShowParticipantInput(false);
    },
    [noteUsers]
  );

  const CreateWrapper = useCallback(
    ({ children }) => {
      if (isMobile) {
        return (
          <>
            <Title>{t('appointments:VIEW.NOTES.ADD_SESSION_NOTE')}</Title>
            {children}
          </>
        );
      }

      return (
        <Folder
          header={
            isFlat ? null : (
              <div className={headerClass}>
                <p className={headerTextClass}>
                  {isEdit
                    ? t('appointments:VIEW.NOTES.EDIT_SESSION_NOTE')
                    : t('appointments:VIEW.NOTES.ADD_SESSION_NOTE')}
                </p>
              </div>
            )
          }
          contentStyles={{
            borderRadius: isFlat ? '8px' : undefined,
          }}
          content={children}
        />
      );
    },
    [headerClass, headerTextClass, isEdit, isFlat, isMobile, t]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, errors, setFieldValue, isValid }) => (
        <CreateWrapper>
          <div className={contentClass}>
            <fieldset>
              <div className={labelClass}>
                {t('appointments:VIEW.NOTES.WRITE_NOTE_FOR')}
              </div>

              <FormControl error={errors.users_ids}>
                <div
                  className={getParticipantContainerClass(!!errors.users_ids)}
                >
                  {noteUsers.length > 0 ? (
                    <ul className={participantListClass}>
                      {noteUsers.map((participant) => {
                        const { id, name } = participant;
                        return (
                          <li key={id} className={participantItemClass}>
                            <Checkbox
                              onChange={(e) => {
                                const target = e.target as HTMLInputElement;
                                const ids = target.checked
                                  ? [...values.users_ids, id]
                                  : values.users_ids.filter(
                                      (userId) => userId !== id
                                    );
                                setFieldValue('users_ids', ids);
                              }}
                              checked={values.users_ids.includes(id)}
                            >
                              <span className={participantTextClass}>
                                {name}
                              </span>
                            </Checkbox>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}

                  <div
                    className={getParticipantBottomClass(
                      noteUsers.length === 0
                    )}
                  >
                    {
                      // Show input for participant search and choose
                      isShowParticipantInput || noteUsers.length === 0 ? (
                        <CreateNoteAddParticipant
                          existsParticipants={noteUsers}
                          onChoose={(newParticipant) => {
                            // Add new id to Formik context
                            setFieldValue('users_ids', [
                              ...values.users_ids,
                              newParticipant.id,
                            ]);
                            handleChoose(newParticipant);
                          }}
                        />
                      ) : (
                        <button
                          className={participantAddClass}
                          type="button"
                          onClick={() => setIsShowParticipantInput(true)}
                        >
                          {t('appointments:VIEW.BUTTONS.ADD_PARTICIPANT')}
                        </button>
                      )
                    }
                  </div>
                </div>
              </FormControl>
            </fieldset>

            <fieldset>
              <div className={labelClass}>
                {t('appointments:VIEW.NOTES.SELECT_NOTE_TEMPLATE')}
              </div>
              <Select
                placeholder="Select"
                type="search"
                kind={SELECT_KIND.SECONDARY}
                startEnhancer={<TemplateIcon className={templateIconClass} />}
                options={noteTemplatesResponse?.noteTemplates as NoteTemplate[]}
                valueKey="id"
                labelKey="name"
                value={templateName}
                onChange={({ value }) => {
                  setTemplateName(value as NoteTemplate[]);
                  setFieldValue('content', value[0].content);

                  const blocksFromHTML = convertFromHTML(
                    (value[0] as NoteTemplate).content
                  );
                  const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                  );
                  const state = EditorState.createWithContent(contentState);
                  setEditorState(state);
                }}
              />
            </fieldset>

            <fieldset>
              <div className={labelClass}>
                {t('appointments:VIEW.NOTES.TITLE')}
              </div>
              <FormControl error={errors.title}>
                <Input
                  placeholder={defaultTitle}
                  kind={INPUT_KIND.SECONDARY}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    setFieldValue('title', target.value);
                  }}
                  value={values.title}
                  hasError={!!errors.title}
                />
              </FormControl>
              <p className={titleCaptionClass}>
                {t('appointments:VIEW.YOU_CAN_RENAME')}
              </p>
            </fieldset>

            <fieldset>
              <FormControl error={errors.content}>
                <TextEditor
                  editorState={editorState}
                  placeholder={t('appointments:VIEW.NOTES.WRITE_NOTE')}
                  onEditorStateChange={(newState) => {
                    setEditorState(newState);
                    setFieldValue(
                      'content',
                      convertToHTML(newState.getCurrentContent())
                    );
                  }}
                  hasError={!!errors.content}
                />
              </FormControl>

              <div className={contentBottomClass}>
                <Button
                  size={BUTTON_SIZE.SMALL}
                  kind={BUTTON_KIND.PRIMARY}
                  type="button"
                  onClick={() => handleSubmit()}
                  style={getSubmitButtonStyle(isValid)}
                  isLoading={isCreateNoteLoading || isUpdateNoteLoading}
                >
                  {t('common:BUTTONS.SAVE')}
                </Button>

                {isFlat ? null : (
                  <Button
                    size={BUTTON_SIZE.SMALL}
                    kind={BUTTON_KIND.SECONDARY}
                    type="button"
                    onClick={onClose}
                    style={cancelButtonStyles}
                  >
                    {t('common:BUTTONS.CANCEL')}
                  </Button>
                )}
              </div>
            </fieldset>
          </div>
        </CreateWrapper>
      )}
    </Formik>
  );
};
