/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from 'react';
import { Button } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { IconBaseProps } from 'react-icons';

interface FilterButtonProps {
  label: string;
  icon: React.ComponentType<IconBaseProps>;
  onFilterChange?: () => void;
  isSelected?: boolean; // To control selection from parent
}

const FilterButton: React.FC<FilterButtonProps> = ({
  label,
  icon: Icon,
  onFilterChange,
  isSelected,
}) => {
  // const [selected, setSelected] = React.useState(isSelected);

  const handleClick = () => {
    if (onFilterChange) {
      onFilterChange();
    }
  };

  return (
    <ButtonGroup
      mode="checkbox"
      selected={isSelected ? [0] : []}
      onClick={handleClick} // Call the handleClick directly
      overrides={{
        Root: {
          style: {
            display: 'flex',
            flexWrap: 'wrap',
          },
        },
      }}
    >
      <Button
        startEnhancer={() => <Icon size={18} />} // Use the passed icon component
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              display: 'flex',
              flexBasis: '100%',
              border: '1px solid',
              borderRadius: '8px',
              backgroundColor: isSelected
                ? $theme.colors.accent
                : 'transparent',
              borderColor: isSelected
                ? $theme.colors.accent
                : $theme.colors.accent,
              color: isSelected ? 'white' : $theme.colors.accent,
              ':hover': {
                backgroundColor: isSelected
                  ? $theme.colors.primary
                  : $theme.colors.accent,
              },
            }),
          },
        }}
      >
        {label}
      </Button>
    </ButtonGroup>
  );
};

export default FilterButton;
