import { isNil } from 'ramda';

export const splitTags: (
  tabs: string[],
  showCount?: number
) => [string[], string[]] = (tags, showCount) => {
  if (isNil(showCount)) {
    return [tags, []];
  }

  return [tags.slice(0, showCount), tags.slice(showCount)];
};
