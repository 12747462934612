export const COLORS = {
  accent50: 'var(--tru-accent50)',
  accent100: 'var(--tru-accent100)',
  accent200: 'var(--tru-accent200)',
  accent300: 'var(--tru-accent300)',
  accent400: 'var(--tru-accent400)',
  accent500: 'var(--tru-accent500)',
  accent600: 'var(--tru-accent600)',
  accent700: 'var(--tru-accent700)',
  accent800: 'var(--tru-accent800)',
  accent900: 'var(--tru-accent900)',

  gray50: 'var(--tru-gray50)',
  gray100: 'var(--tru-gray100)',
  gray200: 'var(--tru-gray200)',
  gray300: 'var(--tru-gray300)',
  gray400: 'var(--tru-gray400)',
  gray500: 'var(--tru-gray500)',
  gray600: 'var(--tru-gray600)',
  gray700: 'var(--tru-gray700)',
  gray800: 'var(--tru-gray800)',
  gray900: 'var(--tru-gray900)',

  red50: 'var(--tru-red50)',
  red100: 'var(--tru-red100)',
  red200: 'var(--tru-red200)',
  red300: 'var(--tru-red300)',
  red400: 'var(--tru-red400)',
  red500: 'var(--tru-red500)',
  red600: 'var(--tru-red600)',
  red700: 'var(--tru-red700)',
  red800: 'var(--tru-red800)',
  red900: 'var(--tru-red900)',

  yellow50: 'var(--tru-yellow50)',
  yellow100: 'var(--tru-yellow100)',
  yellow200: 'var(--tru-yellow200)',
  yellow300: 'var(--tru-yellow300)',
  yellow400: 'var(--tru-yellow400)',
  yellow500: 'var(--tru-yellow500)',
  yellow600: 'var(--tru-yellow600)',
  yellow700: 'var(--tru-yellow700)',
  yellow800: 'var(--tru-yellow800)',
  yellow900: 'var(--tru-yellow900)',

  green50: 'var(--tru-green50)',
  green100: 'var(--tru-green100)',
  green200: 'var(--tru-green200)',
  green300: 'var(--tru-green300)',
  green400: 'var(--tru-green400)',
  green500: 'var(--tru-green500)',
  green600: 'var(--tru-green600)',
  green700: 'var(--tru-green700)',
  green800: 'var(--tru-green800)',
  green900: 'var(--tru-green900)',

  blue50: 'var(--tru-blue50)',
  blue100: 'var(--tru-blue100)',
  blue200: 'var(--tru-blue200)',
  blue300: 'var(--tru-blue300)',
  blue400: 'var(--tru-blue400)',
  blue500: 'var(--tru-blue500)',
  blue600: 'var(--tru-blue600)',
  blue700: 'var(--tru-blue700)',
  blue800: 'var(--tru-blue800)',
  blue900: 'var(--tru-blue900)',

  indigo50: 'var(--tru-indigo50)',
  indigo100: 'var(--tru-indigo100)',
  indigo200: 'var(--tru-indigo200)',
  indigo300: 'var(--tru-indigo300)',
  indigo400: 'var(--tru-indigo400)',
  indigo500: 'var(--tru-indigo500)',
  indigo600: 'var(--tru-indigo600)',
  indigo700: 'var(--tru-indigo700)',
  indigo800: 'var(--tru-indigo800)',
  indigo900: 'var(--tru-indigo900)',

  purple50: 'var(--tru-purple50)',
  purple100: 'var(--tru-purple100)',
  purple200: 'var(--tru-purple200)',
  purple300: 'var(--tru-purple300)',
  purple400: 'var(--tru-purple400)',
  purple500: 'var(--tru-purple500)',
  purple600: 'var(--tru-purple600)',
  purple700: 'var(--tru-purple700)',
  purple800: 'var(--tru-purple800)',
  purple900: 'var(--tru-purple900)',

  pink50: 'var(--tru-pink50)',
  pink100: 'var(--tru-pink100)',
  pink200: 'var(--tru-pink200)',
  pink300: 'var(--tru-pink300)',
  pink400: 'var(--tru-pink400)',
  pink500: 'var(--tru-pink500)',
  pink600: 'var(--tru-pink600)',
  pink700: 'var(--tru-pink700)',
  pink800: 'var(--tru-pink800)',
  pink900: 'var(--tru-pink900)',

  white900: 'var(--tru-white900)',
  black: 'var(--tru-black)',
};

/**
 * Check if css variable goes from global `COLOR` object or string variable
 * otherwise use set hex, rgb, etc. color value.
 */
export const extractColorFromCSSVariable = (color: string) => {
  const cssVariable =
    color?.match(/\(([^)]+)\)/)?.[1] || (color?.startsWith('--') && color);

  return cssVariable
    ? getComputedStyle(document.documentElement)
        .getPropertyValue(cssVariable)
        .trim()
    : color;
};
