import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { Timer } from 'shared/lib';
import { Button } from 'shared/ui/button';
import { StyleObject } from 'styletron-standard';
import { NOTIFICATION_REMOVAL_SOURCE } from '../../model';
import { notificationsApi } from '../../store';
import { NotificationProps } from './model';
import {
  Container,
  Filler,
  Message,
  Root,
  Title,
  Wrapper,
  useStyles,
  CloseButton,
  CloseIcon,
  ActionsContainer,
  ButtonsContainer,
  LinksContainer,
  ViewLink,
  TextContainer,
} from './styles';

const TRANSITION_STYLES: { [key: string]: StyleObject } = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const Notification: FC<NotificationProps> = ({
  notification,
  remove,
}) => {
  const { fillerComputedClass, getIcon } = useStyles();
  const timer = useRef<Timer | null>(null);

  const hasActions = useMemo(
    () => !!notification.links?.length || !!notification.buttons?.length,
    [notification.links, notification.buttons]
  );

  const onClose = useCallback(
    (type: NOTIFICATION_REMOVAL_SOURCE) => {
      remove(type);
    },
    [remove]
  );

  const onEntered = useCallback(() => {
    if (notification.duration === 'none') {
      return;
    }
    if (!timer.current) {
      timer.current = new Timer(() => {
        onClose(NOTIFICATION_REMOVAL_SOURCE.TIMEOUT);
      }, notification.duration);
    }
  }, [notification.duration, onClose]);

  const onMouseEnter = useCallback(() => {
    timer.current?.pause();
  }, []);

  const onMouseLeave = useCallback(() => {
    timer.current?.resume();
  }, []);

  const onCloseBtnClick = useCallback(() => {
    onClose(NOTIFICATION_REMOVAL_SOURCE.CLICK);
  }, [onClose]);

  const onExited = useCallback(() => {
    notificationsApi.remove(notification.id);
  }, [notification.id]);

  useEffect(
    () => () => {
      timer.current?.clear();
    },
    []
  );

  return (
    <Transition
      mountOnEnter
      timeout={200}
      unmountOnExit
      onEntered={onEntered}
      in={!notification.hasBeenRemoved}
      onExited={onExited}
    >
      {(state) => (
        <Root
          style={{
            opacity: 0,
            transition: 'opacity 0.2s ease',
            ...TRANSITION_STYLES[state],
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <CloseButton onClick={onCloseBtnClick}>
            <CloseIcon />
          </CloseButton>
          <Wrapper>
            <Filler className={fillerComputedClass(notification.type)}>
              {getIcon(notification.type)}
            </Filler>
            <Container>
              <TextContainer>
                <Title>{notification.title}</Title>
                {!!notification.message && (
                  <Message>{notification.message}</Message>
                )}
              </TextContainer>
              {hasActions && (
                <ActionsContainer>
                  {!!notification.buttons?.length && (
                    <ButtonsContainer>
                      {notification.buttons.map((button, index) => (
                        <Button
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          color={button.color}
                          kind={button.kind}
                          onClick={button.onClick}
                        >
                          {button.message}
                        </Button>
                      ))}
                    </ButtonsContainer>
                  )}
                  {!!notification.links?.length && (
                    <LinksContainer>
                      {notification.links.map((link, index) => (
                        <Link
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          to={link.to}
                          onClick={link.onClick}
                        >
                          <ViewLink> {link.message}</ViewLink>
                        </Link>
                      ))}
                    </LinksContainer>
                  )}
                </ActionsContainer>
              )}
            </Container>
          </Wrapper>
        </Root>
      )}
    </Transition>
  );
};
