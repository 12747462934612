import { useStyletron, TEXT, DESKTOP, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const modalStyle: StyleObject = {
    maxWidth: '720px',
    padding: 0,
  };

  const subtitleStyle: StyleObject = {
    marginBottom: '16px',
    ...TEXT.base_medium,
  };

  const headerClass = css({
    [DESKTOP]: {
      padding: '16px 24px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottom: `2px solid ${theme.colors.gray200}`,
      backgroundColor: theme.colors.gray50,
    },
  });

  const titleClass = css({
    marginTop: '4px',
    ...TEXT.xl_medium,
  });

  const captionClass = css({
    marginTop: '4px',
    ...TEXT.base_book,
    color: theme.colors.gray400,

    [MOBILE]: {
      marginTop: 0,
      textAlign: 'center',
    },
  });

  const bodyClass = css({
    padding: '16px 24px',
    backgroundColor: theme.colors.white900,

    [MOBILE]: {
      padding: '24px 0',
    },
  });

  const packetListClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  });

  const spinnerStyle: StyleObject = {
    marginLeft: 'auto',
    marginRight: 'auto',
    borderTopColor: theme.colors.accent,
  };

  const documentListClass = css({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    border: `1px solid ${theme.colors.gray200}`,
    borderRadius: '8px',

    [MOBILE]: {
      padding: '0 16px',
    },
  });

  const documentItemClass = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: '16px 0',

    [MOBILE]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    },

    ':not(:first-child)': {
      borderTop: `1px solid ${theme.colors.gray200}`,
    },
  });

  const noticeClass = css({
    ...TEXT.sm_book,
    color: theme.colors.red500,
  });

  const footerClass = css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',

    [DESKTOP]: {
      padding: '12px 24px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      borderTop: `2px solid ${theme.colors.gray200}`,
      backgroundColor: theme.colors.gray50,
    },

    [MOBILE]: {
      position: 'sticky',
      bottom: 0,
      margin: '0 -16px -16px',
      padding: '16px',
      backgroundColor: theme.colors.white900,
      boxShadow:
        '0 -10px 20px rgba(0, 0, 0, 0.09), 0 -1px 2px rgba(0, 0, 0, 0.06)',
    },
  });

  const footerButtonStyle: StyleObject = {
    [MOBILE]: {
      flexGrow: 1,
    },
  };

  return {
    modalStyle,
    subtitleStyle,
    headerClass,
    titleClass,
    captionClass,
    bodyClass,
    packetListClass,
    spinnerStyle,
    documentListClass,
    documentItemClass,
    noticeClass,
    footerClass,
    footerButtonStyle,
  };
};
