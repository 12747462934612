export const handleStartSessionLink: (
  link: string | null | undefined,
  isMobile: boolean
) => string | null | undefined = (link, isMobile) => {
  if (!link || link.startsWith('/')) {
    return link;
  }
  const url = new URL(link);
  if (url.protocol === 'zoommtg:' && isMobile) {
    url.protocol = 'zoomus:';
  }

  return url.toString();
};
