import { useStyletron } from 'shared/ui';

export const useStyles = () => {
  const [css] = useStyletron();

  const formRow = (isFlat: boolean) =>
    css({
      marginTop: isFlat ? undefined : '24px',
    });

  return {
    formRow,
  };
};
