import { useAppointmentCalendarModal } from 'entities/appointment';
import { useCallback } from 'react';
import { useRescheduleAppointmentMutation } from 'shared/api';
import { RescheduleIcon } from 'shared/ui';
import { AppointmentRescheduleProps } from './model';

export const useAppointmentReschedule = ({
  appointment,
}: AppointmentRescheduleProps) => {
  const [reschedule] = useRescheduleAppointmentMutation();

  const onSuccess = useCallback(
    async (newDate: Date | null) => {
      if (!newDate) {
        throw new Error('Date is null');
      }
      reschedule({ variables: { startTime: newDate, id: appointment.id } });
    },
    [appointment.id, reschedule]
  );

  const { open } = useAppointmentCalendarModal({
    icon: RescheduleIcon,
    title: 'RESCHEDULE_MODAL.TITLE',
    successButton: 'RESCHEDULE_MODAL.SUCCESS_BUTTON',
  });

  return useCallback(() => {
    open({ appointment, onSuccess });
  }, [open, appointment, onSuccess]);
};
