import { useStyletron, TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const modalStyle: StyleObject = {
    maxWidth: '800px',
    textAlign: 'center',
  };

  const closeStyle: StyleObject = {
    right: '16px',
  };

  const titleClass = css({
    ...TEXT.xl_medium,
  });

  const gridClass = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
    marginTop: '24px',
  });

  const itemClass = css({
    padding: '24px',
    borderRadius: '8px',
    border: `1px solid ${theme.colors.gray200}`,
  });

  const iconClass = css({
    display: 'inline-block',
    verticalAlign: 'top',
    width: '40px',
    color: theme.colors.gray400,
  });

  const zoomIconClass = css({
    display: 'inline-block',
    verticalAlign: 'top',
    width: '116px',
  });

  const subtitleClass = css({
    marginTop: '16px',
    ...TEXT.base_medium,
  });

  const captionClass = css({
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  const buttonStyle: StyleObject = {
    marginTop: '24px',
  };

  const footerClass = css({
    marginTop: '16px',
  });

  const footerMediumTextClass = css({
    ...TEXT.sm_medium,
  });

  const footerNormalTextClass = css({
    ...TEXT.sm_book,
  });

  return {
    modalStyle,
    closeStyle,
    titleClass,
    gridClass,
    itemClass,
    iconClass,
    zoomIconClass,
    subtitleClass,
    captionClass,
    buttonStyle,
    footerClass,
    footerMediumTextClass,
    footerNormalTextClass,
  };
};
