import { FC } from 'react';
import { useStyletron, CloseIcon } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const CloseButton: FC<{
  onClick: () => void;
  className?: string;
  styles?: StyleObject;
}> = ({ className, onClick, styles }) => {
  const [css, theme] = useStyletron();

  const buttonClass = css({
    color: theme.colors.gray400,
    transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
    ':hover': {
      color: theme.colors.gray700,
    },
  });

  return (
    <button
      className={`${buttonClass} ${className}`}
      onClick={onClick}
      style={styles}
      type="button"
    >
      <CloseIcon />
    </button>
  );
};
