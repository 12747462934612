export const phoneFormat = (phone: string): string => {
  if (!phone) {
    return '';
  }

  const currentValue = phone.replace(/[^\d]/g, '').slice(-10);

  return [
    currentValue.slice(0, 3),
    currentValue.slice(3, 6),
    currentValue.slice(6, 10),
  ]
    .filter(Boolean)
    .join('-');
};
