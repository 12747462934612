import { FC } from 'react';
import { useStyletron } from 'shared/ui';

export const NumberCell: FC = ({ children }) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      })}
    >
      {children}
    </div>
  );
};
