import { RefObject } from 'react';

export function useScrollIntoView<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>
): (isIframe: boolean) => void {
  return (isIframe) => {
    if (isIframe) {
      const rect = elementRef.current?.getBoundingClientRect();
      window.scrollTo({
        top: rect?.top ?? 0,
        behavior: 'smooth',
      });
    } else {
      elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
}
