import { FC, useMemo } from 'react';
import { Select } from 'shared/ui';
import { getTimezoneOffset } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { IANA_TIMEZONES } from 'shared/lib';
import { TimezonePickerProps } from './model';

export const TimezonePicker: FC<TimezonePickerProps> = ({
  value,
  onChange,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation(['common']);

  const options = useMemo(
    () =>
      IANA_TIMEZONES.map(({ key, label }) => {
        const offset = getTimezoneOffset(key, new Date()) / 3_600_000;
        const offsetMinutes = offset * 60;

        return {
          id: key,
          label,
          offset: offsetMinutes === 0 ? 0 : offsetMinutes * -1,
        };
      }).sort((a, b) => {
        const offsetDelta = b.offset - a.offset;
        if (offsetDelta !== 0) return offsetDelta;

        if (typeof a.label === 'string' && typeof b.label === 'string') {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
        }
        return 0;
      }),
    []
  );

  const currentValue = useMemo(
    () => options.find((option) => option.id === value),
    [options, value]
  );

  return (
    <Select
      value={currentValue && [currentValue]}
      options={options}
      onChange={({ value: val }) => onChange?.(val[0])}
      placeholder={placeholder ?? t('common:TIMEZONE')}
      {...props}
    />
  );
};
