import { DESKTOP, TEXT, COLORS, styled } from 'shared/ui';

export const Root = styled('header', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '24px',

  [DESKTOP]: {
    marginTop: '16px',
  },
});

export const Title = styled('h1', {
  ...TEXT.xl3_bold,

  [DESKTOP]: {
    ...TEXT.xl4_bold,
  },
});

export const Description = styled('p', {
  ...TEXT.base_book,
  color: COLORS.gray400,
});
