import { FC, useMemo } from 'react';
import { IResizableImage } from 'shared/api';
import { useStyles } from './styles';

interface OrganizationLogoInterface {
  name: string;
  logoUrl?: IResizableImage | string | null;
  size?: number;
}

export const OrganizationLogo: FC<OrganizationLogoInterface> = ({
  name,
  logoUrl,
  size = 64,
}) => {
  const { wrapper, imageStyles, organizationAbbrStyles, organizationAbbrText } =
    useStyles(size);
  const organizationAbbr = useMemo(() => {
    const firstLetters = name
      .split(' ')
      .reduce((acc: string[], current) => [...acc, current.substring(0, 1)], [])
      .join('');

    return firstLetters.toUpperCase();
  }, [name]);

  const logo = useMemo(() => {
    if (typeof logoUrl === 'string') {
      return logoUrl;
    }
    return logoUrl?.srcset({ h: size });
  }, [size, logoUrl]);

  return (
    <div className={wrapper}>
      {logo ? (
        <img srcSet={logo} alt={name} className={imageStyles(size)} />
      ) : (
        <div className={organizationAbbrStyles}>
          <span className={organizationAbbrText}>{organizationAbbr}</span>
        </div>
      )}
    </div>
  );
};
