import { useCallback } from 'react';
import OT from '@opentok/client';
import { useGenerateOpentokTokenMutation } from 'shared/api';
import { useSessionRoomDispatch } from '../context';
import { getOTErrorMessage } from '../utils';
import { ChatSignal } from '../ui/chat/model';

export const useLoadSessionData = () => {
  const dispatch = useSessionRoomDispatch();
  const [fetchOpentokToken] = useGenerateOpentokTokenMutation();

  return useCallback(
    (appointmentId: string) => {
      dispatch({ type: 'LOAD_SESSION_ROOM_DATA' });
      fetchOpentokToken({
        variables: {
          appointment_id: appointmentId,
        },
        onCompleted: ({ generateOpentokToken }) => {
          const { REACT_APP_OPENTOK_API_KEY = '' } = process.env;
          const sid = generateOpentokToken?.sid;
          const token = generateOpentokToken?.token;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const session = OT.initSession(REACT_APP_OPENTOK_API_KEY, sid!);

          session.on(
            'streamPropertyChanged',
            ({ stream: { streamId }, changedProperty, newValue }) => {
              dispatch({
                type: 'STREAM_PROPERTY_CHANGED',
                payload: {
                  streamId,
                  changedProperty,
                  value: newValue as boolean,
                },
              });
            }
          );

          session.on('streamCreated', ({ stream }) => {
            const newSubscriber = session.subscribe(stream, undefined, {
              insertDefaultUI: false,
            });

            newSubscriber.on('videoElementCreated', ({ element }) => {
              dispatch({
                type: 'ADD_USER',
                payload: {
                  user: {
                    context: newSubscriber,
                    videoEl: element as HTMLVideoElement,
                    hasVideo: newSubscriber.stream?.hasVideo ?? false,
                    hasAudio: newSubscriber.stream?.hasAudio ?? false,
                  },
                },
              });
            });
          });

          session.on('streamDestroyed', ({ stream: { streamId } }) => {
            dispatch({
              type: 'REMOVE_USER',
              payload: {
                streamId,
              },
            });
          });

          // Chat
          session.on('signal', (signalEvent) => {
            const [, signalName] = signalEvent.type.split(':');

            if (signalName === ChatSignal.Message) {
              dispatch({
                type: 'REFRESH_MESSAGE_LIST',
                payload: {
                  signal: {
                    ...signalEvent,
                    session,
                  },
                },
              });
            }
          });
          window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (e) => {
              dispatch({
                type: 'REFRESH_CHAT_OPTIONS',
                payload: {
                  isDarkTheme: e.matches,
                },
              });
            });

          session.on('sessionConnected', () => {
            const newPublisher = OT.initPublisher(undefined, {
              insertDefaultUI: false,
            });

            session.publish(newPublisher, (error) => {
              if (error) {
                dispatch({
                  type: 'SET_SESSION_ROOM_ERROR',
                  payload: getOTErrorMessage(error.name),
                });
              }
            });

            newPublisher.on('videoElementCreated', (e) => {
              // Set publisher to store
              dispatch({
                type: 'ADD_PUBLISHER',
                payload: {
                  context: newPublisher,
                  videoEl: e.element as HTMLVideoElement,
                },
              });
            });

            newPublisher.on('streamCreated', () => {
              // Set session to store and render user view
              dispatch({
                type: 'LOAD_SESSION_ROOM_DATA_SUCCESS',
                payload: {
                  session,
                  appointmentId,
                },
              });
            });
          });

          session.on('sessionDisconnected', ({ reason }) => {
            session.off('sessionConnected');
            session.off('signal');

            if (reason === 'forceDisconnected') {
              dispatch({
                type: 'FORCE_DISCONNECT',
              });
            } else {
              dispatch({
                type: 'RESET_SESSION_ROOM_STATE',
              });
            }
          });

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          session.connect(token!, (error) => {
            if (error) {
              dispatch({
                type: 'SET_SESSION_ROOM_ERROR',
                payload: `${error.name} ${error.message}`,
              });
            }
          });

          return () => {
            session.disconnect();
          };
        },

        onError: (error) => {
          dispatch({
            type: 'SET_SESSION_ROOM_ERROR',
            payload: error.message,
          });
        },
      });
    },
    [dispatch, fetchOpentokToken]
  );
};
