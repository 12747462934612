import { styled, TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
});

export const Title = styled('h5', {
  marginBottom: '8px',
  ...TEXT.base_medium,
});

export const List = styled('ul', {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '8px',
});

export const ListWide = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const useStyles = () => {
  const toggleButtonStyles = (isBottom = false): StyleObject => {
    const offsetSide = isBottom
      ? { marginTop: '8px' }
      : { marginBottom: '8px' };

    return {
      width: '100%',
      height: '24px',
      paddingTop: 0,
      paddingBottom: 0,
      ...offsetSide,
      ...TEXT.xs_book,
    };
  };

  return {
    toggleButtonStyles,
  };
};
