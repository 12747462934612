/* eslint-disable @typescript-eslint/no-unused-vars */
// /* eslint-disable effector/no-getState */

import { useStore } from 'effector-react';
import { getTherapistsEvent } from './use-get-therapists';
import { $localFilters, setLocalFilters } from './therapist-filter.store';
import { TherapistFilters } from '../Model';

export const useTherapistFilterLogic = () => {
  const localFilters = useStore($localFilters);

  const handleSubmit = () => {
    console.log('======== handleSubmit ========');
    getTherapistsEvent();
  };

  const clearAllLocalFilters = () => {
    console.log('======== clearAllLocalFilters ========');
    setLocalFilters({
      gender: [],
      service: [],
      licensed_states: [],
    });
  };

  /* ******* TherapistFilter Handlers ******* */
  const handleGenderTagClick = (gender: string) => {
    const updatedFilters: TherapistFilters = {
      ...localFilters,
      gender: localFilters.gender?.includes(gender)
        ? localFilters.gender.filter((g) => g !== gender)
        : [...(localFilters.gender || []), gender],
    };
    setLocalFilters(updatedFilters);
  };

  const handleServiceClick = (service: string) => {
    const updatedFilters: TherapistFilters = {
      ...localFilters,
      service: localFilters.service?.includes(service)
        ? localFilters.service.filter((g) => g !== service)
        : [...(localFilters.service || []), service],
    };
    setLocalFilters(updatedFilters);
  };

  const handleLicensedStatesClick = (licensed_states: string[]) => {
    const updatedFilters: TherapistFilters = {
      ...localFilters,
      licensed_states,
    };
    setLocalFilters(updatedFilters);
  };

  const handleGenderButtonClick = (gender: string) => {
    const updatedFilters: TherapistFilters = {
      ...localFilters,
      gender: localFilters.gender?.includes(gender)
        ? localFilters.gender.filter((g) => g !== gender)
        : [...(localFilters.gender || []), gender],
    };
    setLocalFilters(updatedFilters);
    getTherapistsEvent();
  };

  return {
    localFilters,
    setLocalFilters,
    clearAllLocalFilters,
    handleGenderTagClick,
    handleServiceClick,
    handleLicensedStatesClick,
    handleGenderButtonClick,
    handleSubmit,
  };
};
