import { ChartOptions } from 'chart.js';
import { CHUNK_SIZE } from './ecg-chart';

export const getChartOptions: () => ChartOptions = () => ({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  animation: false,
  scales: {
    x: {
      display: false,
      tick: 1,
      max: CHUNK_SIZE,
    },
    y: {
      display: false,
      max: 255,
      min: 0,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
});
