import { styled } from 'shared/ui';
import { SESSION_ROOM_COLORS } from '../../../theme/colors';

export const VitalPlateWrapper = styled('div', {
  padding: '24px',
  borderRadius: '16px',
  backgroundColor: 'rgb(17, 17, 17, 0.3)',
  display: 'flex',
});

export const VitalDataWrap = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const VitalDataHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '8px',
  fontSize: '20px',
  lineHeight: 1.6,
});

export const VitalDataTitle = styled('h3', {
  fontWeight: 700,
  color: SESSION_ROOM_COLORS.white,
});

export const VitalDataUnits = styled('div', {
  color: SESSION_ROOM_COLORS.gray,
  fontSize: '24px',
});

export const VitalDataRange = styled('div', {
  display: 'inline-block',
  color: SESSION_ROOM_COLORS.gray,
  paddingLeft: '5px',
  fontSize: '14px',
});

export const VitalDataBody = styled('div', {
  display: 'flex',
  flex: 1,
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  flexDirection: 'column',
});

export const VitalDataValue = styled('div', {
  flexGrow: 1,
  textAlign: 'right',
  fontFamily: 'Arial, sans-serif',
  fontSize: '48px',
  fontWeight: 400,
  lineHeight: 1.11,
  color: SESSION_ROOM_COLORS.white,
  minWidth: '136px',
});

export const VitalExtraDataList = styled('ul', {
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '8px',
});

export const VitalExtraDataItem = styled('li', {
  fontSize: '20px',
  lineHeight: 1.2,
  color: SESSION_ROOM_COLORS.gray,
});
