import { TEXT } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { CHECKBOX_SIZE } from './model';

export const CHECKBOX_SIZE_STYLES = new Map<CHECKBOX_SIZE, StyleObject>([
  [
    CHECKBOX_SIZE.SMALL,
    {
      ...TEXT.sm_book,
    },
  ],
  [
    CHECKBOX_SIZE.MEDIUM,
    {
      ...TEXT.base_book,
    },
  ],
]);
