import { FC } from 'react';
import {
  useStyletron,
  styled,
  TEXT,
  Button,
  BUTTON_SIZE,
  MOBILE,
} from 'shared/ui';

interface NoItemsProps {
  icon: FC;
  title: string;
  text: string;
  buttonText?: string;
  onClick?: () => void;
  hasButton?: boolean;
}

export const NoItems: FC<NoItemsProps> = ({
  icon,
  title,
  text,
  buttonText,
  onClick,
  hasButton = true,
}) => {
  const [css, theme] = useStyletron();

  const Icon = styled(icon, {
    width: '40px',
    color: theme.colors.gray400,
  });

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '24px',
        borderRadius: '8px',
        border: `2px dashed ${theme.colors.gray200}`,

        [MOBILE]: {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      })}
    >
      <Icon />

      <div
        className={css({
          textAlign: 'center',
        })}
      >
        <p
          className={css({
            ...TEXT.base_medium,
          })}
        >
          {title}
        </p>
        <p
          className={css({
            ...TEXT.base_book,
            color: theme.colors.gray400,
          })}
        >
          {text}
        </p>
      </div>

      {hasButton && (
        <Button
          type="button"
          size={BUTTON_SIZE.SMALL}
          onClick={onClick}
          style={{
            [MOBILE]: {
              alignSelf: 'stretch',
            },
          }}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
