import { ReactNode } from 'react';
import { StyleObject } from 'styletron-standard';

export enum ModalCloseEvent {
  KeyboardEvent = 'KeyboardEvent',
  BackgroundClickEvent = 'BackgroundClickEvent',
  CloseButtonClickEvent = 'CloseButtonClickEvent',
}

export interface ModalProps {
  title?: string | ReactNode;
  children?: ReactNode;
  onClose?: (eventType: ModalCloseEvent) => void;
  isCatchCloseEvent?: boolean;
  style?: StyleObject;
  closeStyle?: StyleObject;
}
