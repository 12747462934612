import { useStyletron } from 'shared/ui';
import { theme } from '../theme';

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    backgroundColor: theme.colors.red,
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '8px 24px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 1.5,
    color: theme.colors.white,
    transition: 'background-color 0.2s',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        backgroundColor: '#f50709',
      },
    },
  });

  return {
    rootClass,
  };
};
