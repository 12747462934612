import { setContext } from '@apollo/client/link/context';
import { getIdToken } from 'shared/lib';

export const authLink = setContext((_, { headers, publicRequest }) => {
  if (publicRequest) {
    return { headers };
  }
  // get the authentication token if it exists
  const token = getIdToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});
