import { InputProps as BaseInputProps, InputOverrides } from 'baseui/input';
import { StyleObject } from 'styletron-standard';
import { Ref } from 'react';

export enum INPUT_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XL = 'xl',
}

export enum INPUT_KIND {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export type InputProps = Pick<
  BaseInputProps,
  | 'placeholder'
  | 'onChange'
  | 'onKeyDown'
  | 'onBlur'
  | 'value'
  | 'startEnhancer'
  | 'endEnhancer'
  | 'type'
  | 'autoComplete'
  | 'autoFocus'
  | 'id'
  | 'name'
  | 'disabled'
  | 'pattern'
> & {
  size?: INPUT_SIZE;
  kind?: INPUT_KIND;
  style?: StyleObject;
  hasError?: boolean;
  overrides?: InputOverrides;
  maxLength?: number;
  inputRef?: Ref<HTMLInputElement>;
};
