import { sample } from 'effector';
import { condition } from 'patronum';
import { Children, cloneElement, ReactElement } from 'react';
import {
  $panelStack,
  hideScrollBarFx,
  onAllModalCloseFx,
  unsetScrollBarFx,
} from '.';
import { $panel, closeAllPanels, openPanel } from '../current-panel';

// trigger on close on all modals in stack
sample({
  clock: closeAllPanels,
  source: $panelStack,
  filter: (panels) => !!panels.length,
  fn: (panels) => ({ panels }),
  target: onAllModalCloseFx,
});

// clear stack if close all modals
sample({
  clock: closeAllPanels,
  source: $panelStack,
  fn: () => [],
  target: $panelStack,
});

// push to stack current modal, and update props
sample({
  clock: $panel,
  source: $panelStack,
  fn: (stack, panel) => {
    const searchPanelIndex = stack.findIndex(({ id }) => id === panel?.id);
    if (!panel) {
      return stack.slice(0, stack.length - 1);
    }
    if (searchPanelIndex === -1) {
      return [...stack, panel];
    }
    return [
      ...stack.slice(0, searchPanelIndex),
      panel,
      ...stack.slice(searchPanelIndex + 1),
    ];
  },
  target: $panelStack,
});

// open previous if it exist
sample({
  clock: $panel,
  source: $panelStack,
  filter: (stack, panel) => stack.length > 0 && panel === null,
  fn: (stack) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lastEl = stack.slice(-1).pop()!;
    const children = Children.map(lastEl.sidebarProps.children, (child) => {
      if (!child) {
        return child;
      }
      const prevProps = (child as ReactElement)?.props;
      return cloneElement(child as ReactElement, {
        ...prevProps,
        props: { ...prevProps?.props, ...lastEl.props },
      });
    });
    return {
      ...lastEl,
      sidebarProps: { ...lastEl.sidebarProps, children },
    };
  },
  target: openPanel,
});

condition({
  source: $panelStack,
  if: (stack) => stack.length === 0,
  then: unsetScrollBarFx,
  else: hideScrollBarFx,
});
