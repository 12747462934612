import { FC, forwardRef } from 'react';
import { Input as BaseInput } from 'baseui/input';
import { useStyletron, mergeStyles } from 'shared/ui';
import { INPUT_SIZE_STYLES, INPUT_KIND_STYLES } from './util';
import { InputProps, INPUT_SIZE, INPUT_KIND } from './model';

export const Input: FC<InputProps> = forwardRef<BaseInput, InputProps>(
  (
    {
      size = INPUT_SIZE.MEDIUM,
      kind = INPUT_KIND.PRIMARY,
      style,
      hasError = false,
      overrides,
      ...props
    },
    ref
  ) => {
    const [, theme] = useStyletron();

    const getFocusStyle = (isFocused: boolean) => {
      if (!isFocused) {
        return {};
      }
      return {
        borderTopColor: `${theme.colors.accent} !important`,
        borderRightColor: `${theme.colors.accent} !important`,
        borderBottomColor: `${theme.colors.accent} !important`,
        borderLeftColor: `${theme.colors.accent} !important`,
      };
    };

    const getErrorStyle = (isError: boolean) => {
      if (!isError) {
        return {};
      }
      return {
        borderTopColor: `${theme.colors.red500} !important`,
        borderRightColor: `${theme.colors.red500} !important`,
        borderBottomColor: `${theme.colors.red500} !important`,
        borderLeftColor: `${theme.colors.red500} !important`,
      };
    };

    return (
      <BaseInput
        {...props}
        ref={ref}
        overrides={{
          Root: {
            style: ({ $isFocused }) =>
              mergeStyles([
                {
                  gap: '8px',
                  borderTopWidth: '1px',
                  borderRightWidth: '1px',
                  borderBottomWidth: '1px',
                  borderLeftWidth: '1px',
                  backgroundColor: theme.colors.gray100,
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                },
                INPUT_SIZE_STYLES({ size }) ?? {},
                INPUT_KIND_STYLES({ kind, theme }) ?? {},
                getFocusStyle($isFocused),
                getErrorStyle(hasError),
                style ?? {},
              ]),
          },
          InputContainer: {
            style: {
              fontSize: 'inherit',
              backgroundColor: 'initial',
              color: 'inherit',
            },
          },
          Input: {
            style: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              fontSize: 'inherit',
              color: 'inherit',
              '::placeholder': {
                color: theme.colors.gray400,
              },
            },
          },
          StartEnhancer: {
            style: {
              flexShrink: 0,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: 'initial',
            },
          },
          EndEnhancer: {
            style: {
              flexShrink: 0,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: 'initial',
            },
          },
          ...overrides,
        }}
      />
    );
  }
);
