import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationChannelsEnum } from 'shared/api';

export interface InviteMethodItem {
  type: NotificationChannelsEnum;
  label: string;
  hint: string;
  placeholder?: string;
}

export const useInviteMethods = () => {
  const { t } = useTranslation(['appointments']);

  return useMemo(
    (): InviteMethodItem[] => [
      {
        type: NotificationChannelsEnum.None,
        label: t('appointments:CREATE.INVITE_AND_REMINDERS.NONE.TITLE'),
        hint: t('appointments:CREATE.INVITE_AND_REMINDERS.NONE.HINT'),
      },
      {
        type: NotificationChannelsEnum.Mail,
        label: t('appointments:CREATE.INVITE_AND_REMINDERS.BY_EMAIL.TITLE'),
        hint: t('appointments:CREATE.INVITE_AND_REMINDERS.BY_EMAIL.HINT'),
        placeholder: t(
          'appointments:CREATE.INVITE_AND_REMINDERS.BY_EMAIL.PLACEHOLDER'
        ),
      },
      {
        type: NotificationChannelsEnum.Sms,
        label: t('appointments:CREATE.INVITE_AND_REMINDERS.BY_TEXT.TITLE'),
        hint: t('appointments:CREATE.INVITE_AND_REMINDERS.BY_TEXT.HINT'),
        placeholder: t(
          'appointments:CREATE.INVITE_AND_REMINDERS.BY_TEXT.PLACEHOLDER'
        ),
      },
    ],
    [t]
  );
};
