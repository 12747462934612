import { styled, TEXT, CloseIcon } from 'shared/ui';

export const Root = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '96px',
  paddingBottom: '96px',
});

export const Wrapper = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors.white900,
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
  borderRadius: '8px',
  padding: '40px',
  display: 'flex',
  width: '100%',
  maxWidth: '1400px',
}));

export const MainInfo = styled('div', {
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const ErrorHeader = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const ErrorIcon = styled('div', ({ $theme }) => ({
  fontSize: '64px',
  width: '1em',
  height: '1em',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '16px',
  flex: '0 0 1em',
  backgroundColor: $theme.colors.red100,
}));

export const ErrorIconSVG = styled(CloseIcon, ({ $theme }) => ({
  width: '0.5em',
  color: $theme.colors.red500,
}));

export const ErrorTitle = styled('h1', ({ $theme }) => ({
  ...TEXT.xl3_bold,
  color: $theme.colors.gray400,
  flexGrow: '1',
}));

export const ErrorInfoTitle = styled('h2', ({ $theme }) => ({
  ...TEXT.xl3_bold,
  color: $theme.colors.gray700,
}));

export const ErrorInfoText = styled('p', ({ $theme }) => ({
  ...TEXT.xl_book,
  color: $theme.colors.gray700,
  whiteSpace: 'pre-line',
}));

export const ErrorFooter = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '24px',
});

export const OrganizationInfo = styled('div', ({ $theme }) => ({
  flex: '0 0 272px',
  marginLeft: '81px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',

  '::before': {
    content: "''",
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 'calc(100% + 40px)',
    bottom: 0,
    width: '1px',
    backgroundColor: $theme.colors.gray200,
    pointerEvents: 'none',
  },
}));
