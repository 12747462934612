export const insertToList: <T>(
  list: T[],
  item: T,
  position?: 'top' | 'bottom'
) => T[] = (list, item, position = 'top') => {
  if (position === 'top') {
    return [item, ...list];
  }
  return [...list, item];
};
