/* eslint-disable no-param-reassign */
import { Plugin } from 'chart.js';
import { isNil } from 'ramda';

// Change background of canvas (need for download chart in PNG format)
export const customCanvasBackgroundColor: Plugin<'line'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, _, opt) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = (opt?.color as string) ?? '#fff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const corsair: Plugin<'line'> = {
  id: 'corsair',
  afterInit: (chart) => {
    chart.corsair = {
      x: 0,
      y: 0,
    };
  },
  afterEvent: (chart, args) => {
    const { inChartArea } = args;
    const { x, y } = args.event;

    chart.corsair = { x, y, canDraw: inChartArea };
    chart.draw();
  },
  afterDraw: (
    chart,
    _,
    opts: { width?: number; dash?: [number, number]; color?: string }
  ) => {
    const { ctx } = chart;
    const { top, bottom, left, right } = chart.chartArea;
    const { x, y, canDraw } = chart.corsair;
    if (!canDraw || isNil(x) || isNil(y)) return;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = opts.width ?? 0;
    ctx.strokeStyle = opts.color ?? 'black';
    ctx.setLineDash(opts.dash ?? []);
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);
    ctx.moveTo(left, y);
    ctx.lineTo(right, y);
    ctx.stroke();

    ctx.restore();
  },
};
