import { FC } from 'react';
import { useStyletron } from 'shared/ui';

export const StringCell: FC<{
  column?: boolean;
  alignRight?: boolean;
}> = ({ children, column = false, alignRight = false }) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: column ? 'column' : 'initial',
        alignItems: column ? 'initial' : 'center',
        gap: '4px',
        textAlign: alignRight ? 'right' : 'inherit',
      })}
    >
      {children}
    </div>
  );
};
