import EditorBoldIcon from 'assets/icons/editor-bold.svg';
import EditorItalicIcon from 'assets/icons/editor-italic.svg';
import EditorStrikethroughIcon from 'assets/icons/editor-strikethrough.svg';
import EditorLinkIcon from 'assets/icons/editor-link.svg';
import EditorListIcon from 'assets/icons/editor-list.svg';

export const TOOLBAR_OPTIONS = {
  options: ['inline', 'link', 'list'],
  inline: {
    options: ['bold', 'italic', 'strikethrough'],
    bold: { icon: EditorBoldIcon },
    italic: { icon: EditorItalicIcon },
    strikethrough: { icon: EditorStrikethroughIcon },
  },
  link: {
    options: ['link'],
    link: { icon: EditorLinkIcon },
  },
  list: {
    options: ['unordered'],
    unordered: { icon: EditorListIcon },
  },
};
