import { FC } from 'react';
import { Breadcrumbs as UiBreadcrumbs } from 'baseui/breadcrumbs';
import { TEXT, useStyletron } from 'shared/ui';

interface BreadcrumbsProps {
  list: {
    title: string;
  }[];
  activeIndex: number;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ list, activeIndex }) => {
  const [css, theme] = useStyletron();

  return (
    <UiBreadcrumbs
      overrides={{
        Root: {
          style: {
            border: `1px solid ${theme.colors.gray200}`,
            borderRadius: '8px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
          },
        },
        List: {
          style: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
        },
        ListItem: {
          style: {
            position: 'relative',
          },
        },
        Separator: () => (
          <span
            className={css({
              position: 'absolute',
              top: 0,
              right: 0,
              color: theme.colors.gray200,
            })}
          >
            <svg
              width="18"
              height="74"
              viewBox="0 0 18 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m1 1 16 36L1 73" stroke="currentColor" />
            </svg>
          </span>
        ),
      }}
    >
      {list.map(({ title }, index) => (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            padding: '16px',
            ...TEXT.base_book,
            color:
              activeIndex === index
                ? theme.colors.accent500
                : theme.colors.gray400,
          })}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <span
            className={css({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              border: '2px solid currentColor',
              flexShrink: 0,
            })}
          >
            {index + 1}
          </span>
          {title}
        </div>
      ))}
    </UiBreadcrumbs>
  );
};
