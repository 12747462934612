/* eslint-disable @typescript-eslint/no-unused-vars */

import { createEffect, createEvent } from 'effector';
import { TherapistFilters } from '../Model';

// Events
export const getTherapistsEvent = createEvent();

// Effects
export const fetchTherapistsFx = createEffect(
  async (filters: TherapistFilters) => {
    const filterQuery = new URLSearchParams(filters as any).toString();
    const response = await fetch(
      `https://api.${window.location.hostname.split('.').slice(-2)[0]}.${
        window.location.hostname.split('.').slice(-1)[0]
      }/users?${filterQuery}`
    );
    console.log('======== querying the api ========');
    return response.json();
  }
);
