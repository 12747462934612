import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalDotsIcon } from 'shared/ui';
import { ActionButton } from '../action-button';
import { InfoWindow } from '../info-window';

export const MoreButton: FC = () => {
  const { t } = useTranslation(['session-room']);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const handleMoreClick = useCallback(() => setIsShowInfo(true), []);

  return (
    <>
      <ActionButton icon={<HorizontalDotsIcon />} onClick={handleMoreClick}>
        {t('session-room:MORE')}
      </ActionButton>

      {isShowInfo ? <InfoWindow onClose={() => setIsShowInfo(false)} /> : null}
    </>
  );
};
