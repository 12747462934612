import { FC, useMemo } from 'react';
import { Avatar as UiAvatar, AvatarProps } from 'baseui/avatar';
import { IResizableImage } from 'shared/api';
import { AVATAR_SIZE } from './model';
import { AVATAR_SIZE_VALUES } from './util';

export const Avatar: FC<
  Pick<AvatarProps, 'overrides'> & {
    name?: string;
    size?: AVATAR_SIZE;
    src?: string | IResizableImage | null;
  }
> = ({ name = '', size, src, ...props }) => {
  const avatarSize = size ?? AVATAR_SIZE.MEDIUM;
  const srcValue = useMemo(() => {
    if (typeof src === 'string') {
      return src;
    }

    return (
      src?.get({
        h: (AVATAR_SIZE_VALUES.get(avatarSize) as number) * 2,
        w: (AVATAR_SIZE_VALUES.get(avatarSize) as number) * 2,
      }) ?? undefined
    );
  }, [src, avatarSize]);

  return (
    <UiAvatar
      name={name}
      size={`${AVATAR_SIZE_VALUES.get(avatarSize)}px`}
      src={srcValue}
      overrides={{
        Root: {
          style: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            boxShadow:
              '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
          },
        },
        Avatar: {
          style: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
        },
      }}
      {...props}
    />
  );
};
