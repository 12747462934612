import { useMemo } from 'react';
import { useStyletron } from 'shared/ui';
import { SORT_TYPE } from './model';

export const useStyles = (type?: SORT_TYPE) => {
  const [css, theme] = useStyletron();

  const rootClass = useMemo(
    () =>
      css({
        width: '24px',
        height: '24px',
        display: 'inline-block',
        verticalAlign: 'middle',
        pointerEvents: 'none',
      }),
    [css]
  );

  const [upArrowColor, downArrowColor] = [
    type === SORT_TYPE.DESC ? theme.colors.gray700 : theme.colors.gray400,
    type === SORT_TYPE.ASC ? theme.colors.gray700 : theme.colors.gray400,
  ];

  return {
    rootClass,
    upArrowColor,
    downArrowColor,
  };
};
