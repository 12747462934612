import {
  styled,
  useStyletron,
  SwitchCameraIcon,
  OffVolumeIcon,
} from 'shared/ui';
import { theme } from '../theme';

export const VolumeIcon = styled(OffVolumeIcon, {
  width: '24px',
  height: '24px',
  pointerEvents: 'none',
});

export const SwitchIcon = styled(SwitchCameraIcon, {
  width: '24px',
  height: '24px',
  pointerEvents: 'none',
});

export const HeaderActionButton = styled('button', {
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.gray,
});

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px',
    backgroundColor: theme.colors.transparentBlue,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  });

  const actionIconsClass = css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '24px',
  });

  return {
    rootClass,
    actionIconsClass,
  };
};
