import { TrustvideoTheme } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { ICON_BUTTON_KIND } from './model';

export const ICON_BUTTON_KIND_STYLES = ({
  kind,
  theme,
}: {
  kind: ICON_BUTTON_KIND;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<ICON_BUTTON_KIND, StyleObject>([
    [
      ICON_BUTTON_KIND.PRIMARY,
      {
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
      },
    ],
    [
      ICON_BUTTON_KIND.SECONDARY,
      {
        color: theme.colors.gray400,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderBottomWidth: '1px',
        borderColor: theme.colors.gray200,
        ':hover': {
          borderColor: theme.colors.gray300,
          color: theme.colors.gray700,
        },
      },
    ],
  ]);

  return map.get(kind);
};
