import { FC, forwardRef, useCallback } from 'react';
import { Radio as BaseRadio } from 'baseui/radio';
import { useStyletron } from '../theme';
import { RadioProps, RADIO_SIZE } from './model';
import { RADIO_SIZE_STYLES } from './util';

export const Radio: FC<RadioProps> = forwardRef<BaseRadio, RadioProps>(
  (
    {
      children,
      overrides,
      checked = false,
      size = RADIO_SIZE.MEDIUM,
      ...props
    },
    ref
  ) => {
    const [, theme] = useStyletron();

    const getBorderColor = useCallback(
      (isHovered: boolean, disabled: boolean) => {
        if (disabled) {
          return theme.colors.gray300;
        }
        if (checked) {
          return theme.colors.accent;
        }
        if (isHovered) {
          return theme.colors.gray400;
        }
        return theme.colors.gray300;
      },
      [checked, theme]
    );

    return (
      <BaseRadio
        {...props}
        ref={ref}
        overrides={{
          Root: {
            style: {
              alignSelf: 'stretch',
              marginTop: 0,
              marginBottom: 0,
            },
          },
          Label: {
            style: {
              width: '100%',
              paddingLeft: '8px',
              ...RADIO_SIZE_STYLES.get(size),
            },
          },
          RadioMarkInner: {
            style: {
              display: 'none',
            },
          },
          RadioMarkOuter: {
            style: ({ $isHovered, $disabled }) => ({
              alignSelf: 'flex-start',
              marginTop: '2px',
              marginBottom: '2px',
              marginRight: 0,
              marginLeft: 0,
              borderTopWidth: checked ? '6px' : '1px',
              borderRightWidth: checked ? '6px' : '1px',
              borderLeftWidth: checked ? '6px' : '1px',
              borderBottomWidth: checked ? '6px' : '1px',
              borderTopStyle: 'solid',
              borderRightStyle: 'solid',
              borderLeftStyle: 'solid',
              borderBottomStyle: 'solid',
              borderTopColor: getBorderColor($isHovered, $disabled),
              borderRightColor: getBorderColor($isHovered, $disabled),
              borderLeftColor: getBorderColor($isHovered, $disabled),
              borderBottomColor: getBorderColor($isHovered, $disabled),
              backgroundColor: 'initial',
              transitionProperty: 'border-color',
            }),
          },
          ...overrides,
        }}
      >
        {children}
      </BaseRadio>
    );
  }
);
