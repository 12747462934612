import { useStyletron, TEXT, MOBILE } from 'shared/ui';
import { RadioGroupOverrides, RadioOverrides } from 'baseui/radio';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const getRadioGroupOverrides = (hasError: boolean): RadioGroupOverrides => ({
    RadioGroupRoot: {
      style: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderTopColor: hasError ? theme.colors.red500 : theme.colors.gray200,
        borderRightColor: hasError ? theme.colors.red500 : theme.colors.gray200,
        borderBottomColor: hasError
          ? theme.colors.red500
          : theme.colors.gray200,
        borderLeftColor: hasError ? theme.colors.red500 : theme.colors.gray200,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        transition: `border-color 0.2s cubic-bezier(0, 0, 1, 1)`,
      },
    },
  });

  const getRadioOverrides = (isActive: boolean): RadioOverrides => ({
    Root: {
      style: {
        display: 'flex',
        width: '100%',
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: isActive ? theme.colors.accent50 : theme.colors.white,

        ':not(:first-child)': {
          borderTop: `1px solid ${theme.colors.gray200}`,
        },
      },
    },
  });

  const radioContentClass = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '40px',
    width: '100%',
  });

  const getLabelClass = (isActive: boolean, isDisabled: boolean) =>
    css({
      flexShrink: 0,
      ...TEXT.base_book,
      color: isDisabled
        ? theme.colors.gray400
        : isActive
        ? theme.colors.accent900
        : 'inherit',
    });

  const getHintClass = (isActive: boolean) =>
    css({
      ...TEXT.sm_book,
      color: isActive ? theme.colors.accent400 : theme.colors.gray400,
    });

  const inputWrapperClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '8px',
    marginLeft: '-28px',

    [MOBILE]: {
      marginBottom: '-8px',
    },
  });

  return {
    getRadioGroupOverrides,
    getRadioOverrides,
    radioContentClass,
    getLabelClass,
    getHintClass,
    inputWrapperClass,
  };
};
