export type SingleMeasurementType = {
  Key: string,
  Value: number
};

export const BiometricsLabelMap: { [key: string]: string } = {
  // ppm: "Heart Rate (ppm)",
  // bmi: "BMI",
  // snr: "Signal-to-Noise Ratio",
  // msi: "Metabolic Syndrome Indicator",
  // systolic: "Systolic Blood Pressure",
  // diastolic: "Diastolic Blood Pressure",
  // age: "Age",
  // breathing: "Breathing Rate (BPM)",
  // healthScore: "Health Score",
  // waistToHeight: "Waist-to-Height Ratio",
  // heartRateVariability: "Heart Rate Variability",
  // cardiacWorkload: "Cardiac Workload",
  // absi: "ABS Index",
  // cvdRisk: "Cardiovascular Disease Risk",
  // strokeRisk: "Stroke Risk",
  // heartAttackRisk: "Heart Attack Risk",
  // HypertensionRisk: "Hypertension Risk",
  // HypertriglyceridemiaRisk: "Hypertriglyceridemia Risk",
  // HypercholesterolemiaRisk: "Hypercholesterolemia Risk",
  // DiabetesRisk: "Diabetes Risk",
  // irregularHeartBeats: "Irregular Heartbeats",
  // measurementId: "Measurement ID",
  // avgStarRating: "Average Star Rating",
  HR_BPM: "Heart Rate (BPM)",
  BP_STROKE: "Blood Pressure Stroke Risk",
  PHYSIO_SCORE: "Physiological Score",
  RISKS_SCORE: "Risk Score",
  BP_DIASTOLIC: "Blood Pressure Diastolic",
  BP_SYSTOLIC: "Blood Pressure Systolic",
  MFBG_RISK_PROB: "Metabolic Syndrome Risk Probability",
  HRV_SDNN: "Heart Rate Variability SDNN",
  HPT_RISK_PROB: "Hypertension Risk Probability",
  BR_BPM: "Breathing Rate (BPM)",
  TG_RISK_PROB: "Triglyceride Risk Probability",
  BP_TAU: "Blood Pressure Tau",
  WAIST_TO_HEIGHT: "Waist-to-Height Ratio",
  OVERALL_METABOLIC_RISK_PROB: "Overall Metabolic Risk Probability",
  ABSI: "ABS Index",
  VITAL_SCORE: "Vital Score",
  BP_RPP: "Blood Pressure RPP",
  MSI: "Metabolic Syndrome Indicator",
  HDLTC_RISK_PROB: "HDL Cholesterol Risk Probability",
  BP_HEART_ATTACK: "Blood Pressure Heart Attack Risk",
  HEALTH_SCORE: "Health Score",
  WAIST_CIRCUM: "Waist Circumference",
  BMI_CALC: "BMI Calculation",
  PHYSICAL_SCORE: "Physical Score",
  DBT_RISK_PROB: "Diabetes Risk Probability",
  HBA1C_RISK_PROB: "HBA1C Risk Probability",
  BP_CVD: "Blood Pressure CVD Risk",
  FLD_RISK_PROB: "Fatty Liver Disease Risk Probability",
  IHB_COUNT: "Irregular Heartbeat Count",
  MENTAL_SCORE: "Mental Health Score",
  Notes: "Notes",
  sessionId: "Session ID",
  identifier: "Identifier",
  SESSION_ID: "Session ID",
  MEASUREMENT_ID: "Measurement ID",
  statusId: "Status ID"
};

export const BiometricsInfoMap: Record<
  string,
  { name: string; group: string; unit: string; range: string; description: string }
> = {
  "HEALTH_SCORE": {
    "name": "General Wellness Score",
    "group": "Overall",
    "unit": "unitless",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641 \ud83e\ude7a2."
  },
  "MENTAL_SCORE": {
    "name": "Mental Score",
    "group": "Overall",
    "unit": "unitless",
    "range": "1 to 5",
    "description": "Available after 30 seconds."
  },
  "PHYSICAL_SCORE": {
    "name": "Physical Score",
    "group": "Overall",
    "unit": "unitless",
    "range": "1 to 5",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "PHYSIO_SCORE": {
    "name": "Physiological Score",
    "group": "Overall",
    "unit": "unitless",
    "range": "1 to 5",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "RISKS_SCORE": {
    "name": "Risks Score",
    "group": "Overall",
    "unit": "unitless",
    "range": "1 to 5",
    "description": "Available after 30 seconds. Requires \ud83d\udc641 \ud83e\ude7a2."
  },
  "VITAL_SCORE": {
    "name": "Vitals Score",
    "group": "Overall",
    "unit": "unitless",
    "range": "1 to 5",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "BP_DIASTOLIC": {
    "name": "Diastolic Blood Pressure",
    "group": "Vitals",
    "unit": "mmHg",
    "range": "30 to 120",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "BP_SYSTOLIC": {
    "name": "Systolic Blood Pressure",
    "group": "Vitals",
    "unit": "mmHg",
    "range": "45 to 180",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "BR_BPM": {
    "name": "Breathing Rate",
    "group": "Vitals",
    "unit": "breaths / minute",
    "range": "1.2 to 35",
    "description": "Available after 30 seconds."
  },
  "HR_BPM": {
    "name": "Pulse Rate",
    "group": "Vitals",
    "unit": "beats / minute",
    "range": "0 to 140",
    "description": "Available after 5 seconds."
  },
  "IHB_COUNT": {
    "name": "Irregular Heartbeat Count",
    "group": "Vitals",
    "unit": "unitless",
    "range": "0 to 4",
    "description": "Available after 30 seconds."
  },
  "HBA1C_RISK_PROB": {
    "name": "Hemoglobin A1C Risk",
    "group": "Blood Biomarkers",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "MFBG_RISK_PROB": {
    "name": "Fasting Blood Glucose Risk",
    "group": "Blood Biomarkers",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "DBT_RISK_PROB": {
    "name": "Type 2 Diabetes Risk",
    "group": "Metabolic Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "FLD_RISK_PROB": {
    "name": "Fatty Liver Disease Risk",
    "group": "Metabolic Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "HDLTC_RISK_PROB": {
    "name": "Hypercholesterolemia Risk",
    "group": "Metabolic Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "HPT_RISK_PROB": {
    "name": "Hypertension Risk",
    "group": "Metabolic Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "OVERALL_METABOLIC_RISK_PROB": {
    "name": "Overall Metabolic Health Risk",
    "group": "Metabolic Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "TG_RISK_PROB": {
    "name": "Hypertriglyceridemia Risk",
    "group": "Metabolic Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "BP_CVD": {
    "name": "Cardiovascular Disease Risk",
    "group": "General Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641 \ud83e\ude7a2."
  },
  "BP_HEART_ATTACK": {
    "name": "Heart Attack Risk",
    "group": "General Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641 \ud83e\ude7a2."
  },
  "BP_STROKE": {
    "name": "Stroke Risk",
    "group": "General Risks",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after 30 seconds. Requires \ud83d\udc641 \ud83e\ude7a2."
  },
  "ABSI": {
    "name": "Body Shape Index",
    "group": "Physical",
    "unit": "unitless",
    "range": "6.19 to 8.83",
    "description": "Available after 5 seconds. Requires \ud83d\udc641."
  },
  "AGE": {
    "name": "Facial Skin Age",
    "group": "Physical",
    "unit": "years",
    "range": "13 to 120",
    "description": "Available after 5 seconds."
  },
  "BMI_CALC": {
    "name": "Body Mass Index",
    "group": "Physical",
    "unit": "kg/m\u00b2",
    "range": "10 to 65",
    "description": "Available after 5 seconds. Requires \ud83d\udc641."
  },
  "HEIGHT": {
    "name": "Estimated Height",
    "group": "Physical",
    "unit": "cm",
    "range": "120 to 220",
    "description": "Available after 5 seconds."
  },
  "WAIST_CIRCUM": {
    "name": "Waist Circumference",
    "group": "Physical",
    "unit": "cm",
    "range": "-",
    "description": "Available after 5 seconds. Requires \ud83d\udc641."
  },
  "WAIST_TO_HEIGHT": {
    "name": "Waist-to-Height Ratio",
    "group": "Physical",
    "unit": "%",
    "range": "25 to 70",
    "description": "Available after 5 seconds. Requires \ud83d\udc641."
  },
  "WEIGHT": {
    "name": "Estimated Weight",
    "group": "Physical",
    "unit": "kg",
    "range": "30 to 300",
    "description": "Available after 5 seconds."
  },
  "BP_RPP": {
    "name": "Cardiac Workload",
    "group": "Physiological",
    "unit": "dB",
    "range": "3.71 to 4.28",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "BP_TAU": {
    "name": "Vascular Capacity",
    "group": "Physiological",
    "unit": "",
    "range": "0.3 to 3",
    "description": "Available after 30 seconds. Requires \ud83d\udc641."
  },
  "HRV_SDNN": {
    "name": "Heart Rate Variability",
    "group": "Physiological",
    "unit": "ms",
    "range": "1 to 80",
    "description": "Available after 30 seconds."
  },
  "MSI": {
    "name": "Mental Stress Index",
    "group": "Mental",
    "unit": "unitless",
    "range": "1 to 5.9",
    "description": "Available after 30 seconds."
  },
  "SURVEY_ANXIETY_MODERATE": {
    "name": "Moderate Anxiety Rapid Assessment",
    "group": "Surveys",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after - seconds. Requires \ud83d\udc641."
  },
  "SURVEY_DEPRESSION_MODERATE": {
    "name": "Moderate Depression Rapid Assessment",
    "group": "Surveys",
    "unit": "%",
    "range": "0 to 100",
    "description": "Available after - seconds. Requires \ud83d\udc641."
  },
  "SNR": {
    "name": "Signal to Noise Ratio",
    "group": "Metadata",
    "unit": "dB",
    "range": "-10 to 20",
    "description": "Available after 5 seconds."
  }
};
