import { styled, useStyletron } from 'shared/ui';
import { theme } from 'pages/session-room/ui/theme';

export const FooterMessageInput = styled('input', {
  margin: 0,
  border: 0,
  outline: 'none',
  padding: '24px 32px',
  width: '100%',
  fontFamily: 'inherit',
  color: 'currentColor',
  fontSize: '16px',
  lineHeight: 1.5,
  background: 'none',

  '::placeholder': {
    color: theme.colors.gray,
    transition: 'color 0.3s',
  },

  ':focus::placeholder': {
    color: 'rgba(169, 169, 168, 0.75)',
  },
});

export const useStyles = () => {
  const [css] = useStyletron();

  const footerLightStyles = {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    borderTop: `1px solid ${theme.colors.gray}`,
  };

  const footerDarkStyles = {
    backgroundColor: theme.colors.blue,
    color: theme.colors.white,
    border: `1px solid rgba(255, 255, 255, 0.1)`,
  };

  const getFooterStyles = (isDarkTheme: boolean) =>
    css({
      borderRadius: '0 0 24px 24px',
      overflow: 'hidden',
      ...(isDarkTheme ? footerDarkStyles : footerLightStyles),
    });

  return {
    getFooterStyles,
  };
};
