import { useStyletron, TEXT, DESKTOP, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootStyle: StyleObject = {
    position: 'relative',
    maxWidth: '536px',
    width: '100%',
    margin: 'auto',
    borderRadius: '8px',
    boxShadow:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    backgroundColor: theme.colors.white900,
    overflow: 'hidden',
    animationDuration: '300ms',
    // eslint-disable-next-line
    // @ts-ignore
    animationName: {
      from: {
        opacity: 0,
        transform: 'translateY(24px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },

    [MOBILE]: {
      maxWidth: 'none',
      marginBottom: 0,
      padding: '16px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  };

  const bodyClass = css({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',

    [DESKTOP]: {
      padding: '24px',
    },

    [MOBILE]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  });

  const figureStyle: StyleObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '40px',
    height: '40px',
    borderRadius: '50%',

    [MOBILE]: {
      width: '64px',
      height: '64px',
    },
  };

  const iconStyle: StyleObject = {
    width: '24px',

    [MOBILE]: {
      width: '32px',
    },
  };

  const titleClass = css({
    ...TEXT.xl_medium,
  });

  const captionClass = css({
    marginTop: '4px',
    ...TEXT.base_book,
    color: theme.colors.gray400,
  });

  const footerClass = css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',

    [DESKTOP]: {
      padding: '12px 24px',
      borderTop: `2px solid ${theme.colors.gray200}`,
      backgroundColor: theme.colors.gray50,
    },

    [MOBILE]: {
      flexDirection: 'column-reverse',
      marginTop: '40px',
    },
  });

  const closeButtonClass = css({
    position: 'absolute',
    top: '8px',
    left: 0,
    padding: '12px 16px',
    transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
  });

  return {
    rootStyle,
    bodyClass,
    figureStyle,
    iconStyle,
    titleClass,
    captionClass,
    footerClass,
    closeButtonClass,
  };
};
