import * as React from 'react';
import { useStyletron } from 'baseui';
import { Card } from 'baseui/card';
import { Spinner } from 'baseui/spinner';
import { H5, Display4, Paragraph2 } from 'baseui/typography';
import { useEffect, useRef, useState } from 'react';
import { VitalPlate } from '../plate';

export default function Biometrics() {
  const [css, theme] = useStyletron();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [countdown, setCountdown] = useState(5);
  const [isStarted, setIsStarted] = useState(true);

  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  // Get list of media devices
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = mediaDevices.filter(
          (device) => device.kind === 'videoinput'
        );
        setDevices(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedDeviceId(videoDevices[2].deviceId);
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  // Start video stream with selected device
  useEffect(() => {
    if (!selectedDeviceId) return;

    const startVideoStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: selectedDeviceId },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing webcam:', error);
      }
    };

    startVideoStream();
  }, [selectedDeviceId]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    const drawProgress = () => {
      const { width } = canvas;
      const { height } = canvas;
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 2 - 10;

      ctx.clearRect(0, 0, width, height);
      ctx.beginPath();
      ctx.strokeStyle = theme.colors.backgroundTertiary;
      ctx.lineWidth = 4;
      ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
      ctx.stroke();

      const progress = countdown / 5;
      const startAngle = -Math.PI / 2;
      const endAngle = startAngle + Math.PI * 2 * (1 - progress);

      ctx.beginPath();
      ctx.strokeStyle = theme.colors.accent;
      ctx.lineWidth = 4;
      ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      ctx.stroke();

      const dotX = centerX + radius * Math.cos(endAngle);
      const dotY = centerY + radius * Math.sin(endAngle);
      ctx.beginPath();
      ctx.fillStyle = theme.colors.accent;
      ctx.arc(dotX, dotY, 6, 0, Math.PI * 2);
      ctx.fill();
    };

    const interval = setInterval(() => {
      if (isStarted && countdown > 0) {
        setCountdown((prev) => prev - 0.1);
        drawProgress();
      } else {
        setIsStarted(false);
      }
    }, 100);

    drawProgress();
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [
    countdown,
    isStarted,
    theme.colors.accent,
    theme.colors.backgroundTertiary,
  ]);

  return (
    <VitalPlate>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: theme.sizing.scale800,
        })}
      >
        <H5 color="#03fcff">Biometrics</H5>
        <select
          onChange={(e) => setSelectedDeviceId(e.target.value)}
          value={selectedDeviceId || ''}
          className={css({
            padding: theme.sizing.scale200,
            borderRadius: theme.borders.radius200,
          })}
        >
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${device.deviceId}`}
            </option>
          ))}
        </select>
      </div>

      <Card
        overrides={{
          Root: {
            style: {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: theme.borders.radius300,
              backgroundColor: '#1d202b',
              borderStyle: 'none',
              // position: 'relative',
              padding: '0',
              margin: '0',
              // border: '2px solid red',
            },
          },
        }}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0',
            margin: `0 0 ${theme.sizing.scale800} 0`,
            // border: '2px solid yellow',
          })}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              height: '300px',
              width: '300px',
              margin: `0 0 ${theme.sizing.scale800} 0`,
            })}
          >
            <canvas
              ref={canvasRef}
              width={400}
              height={400}
              className={css({
                width: '100%',
                height: '100%',
              })}
            />
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              className={css({
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                height: '90%',
                borderRadius: '50%',
                objectFit: 'cover',
              })}
            />
            {isStarted && (
              <div
                className={css({
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  height: '90%',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                })}
              />
            )}
          </div>
        </div>

        {isStarted && (
          <>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: theme.sizing.scale800,
              })}
            >
              <Spinner
                color={theme.colors.accent}
                size={theme.sizing.scale1200}
              />
            </div>

            <Display4 color={theme.colors.accent500}>
              Measurement Started
            </Display4>
            <Paragraph2 color={theme.colors.contentSecondary}>
              Please hold still for accurate results.
            </Paragraph2>
          </>
        )}

        {!isStarted && (
          <>
            <Display4 color={theme.colors.accent500}>
              Retrieving results...
            </Display4>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.sizing.scale800,
              })}
            >
              <Spinner
                color={theme.colors.accent}
                size={theme.sizing.scale1200}
              />
            </div>
          </>
        )}
      </Card>
    </VitalPlate>
  );
}
