import { setContext } from '@apollo/client/link/context';
import { getTabUUID } from 'shared/lib';

export const exportLink = setContext((_, { headers, exportFormat }) => {
  if (!exportFormat) {
    return undefined;
  }

  return {
    headers: {
      ...headers,
      EXPORT: 1,
      'EXPORT-FILE-EXTENSION': exportFormat ?? 'csv',
      'EXPORT-BROWSER-IDENTIFIER': getTabUUID(),
    },
  };
});
