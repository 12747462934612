import { FC, useMemo, useRef } from 'react';
import { useScrollYPosition } from 'react-use-scroll-position';

import { useStyles } from './styles';

type Props = {
  isBlur?: boolean;
  offset?: number;
};

export const ScrollBlur: FC<Props> = ({ isBlur, children, offset }) => {
  const root = useRef<HTMLDivElement | null>(null);
  const scrollY = useScrollYPosition();
  const { rootClass } = useStyles();

  const filterStyle = useMemo(() => {
    if (root?.current) {
      const scrollTop = root?.current?.getBoundingClientRect()?.top || 0;
      const scrollOffset = offset || 200;

      const blurPx =
        scrollTop <= scrollOffset
          ? (scrollOffset - scrollTop) / scrollOffset
          : 0;

      if (isBlur) {
        return `blur(${blurPx * 3}px)`;
      }
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlur, offset, scrollY]);

  return (
    <div ref={root} className={rootClass} style={{ filter: filterStyle }}>
      {children}
    </div>
  );
};
