import { TrustvideoTheme } from 'shared/ui';
import { MODAL_ICON_TYPE } from './model';

export const ICONS_COLOR = ({
  type,
  theme,
}: {
  type: MODAL_ICON_TYPE;
  theme: TrustvideoTheme;
}) => {
  const map = new Map<
    MODAL_ICON_TYPE,
    { color: string; backgroundColor: string }
  >([
    [
      MODAL_ICON_TYPE.ERROR,
      {
        backgroundColor: theme.colors.red100,
        color: theme.colors.red500,
      },
    ],
    [
      MODAL_ICON_TYPE.SUCCESS,
      {
        backgroundColor: theme.colors.green100,
        color: theme.colors.green500,
      },
    ],
  ]);

  return map.get(type);
};
