import { unionWith } from 'ramda';
import {
  FieldPolicy,
  KeyArgsFunction,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies';

export const pageStylePagination = (
  keyArgs: false | KeySpecifier | KeyArgsFunction = false
): FieldPolicy => ({
  keyArgs,
  read: (existing, { canRead }) => (canRead(existing) ? existing : undefined),
  merge(existing, incoming) {
    return {
      ...existing,
      ...incoming,
      data: unionWith(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a: any, b: any) => a.__ref === b.__ref,
        existing?.data ?? [],
        incoming?.data ?? []
      ),
    };
  },
});
