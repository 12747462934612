import { ReactNode } from 'react';
import { StyleObject } from 'styletron-standard';

export enum SidebarPanelEvent {
  KeyboardEvent = 'KeyboardEvent',
  BackgroundClickEvent = 'BackgroundClickEvent',
  CloseButtonClickEvent = 'CloseButtonClickEvent',
  CustomEvent = 'CustomEvent',
  LocationChange = 'LocationChange',
}

export interface SidebarPanelProps {
  children?: ReactNode;
  onClose?: (eventType: SidebarPanelEvent) => void;
  isCatchCloseEvent?: boolean;
  width?: string;
  rootStyles?: StyleObject;
}
