import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValid } from 'date-fns';
import {
  Participant,
  useUpdateAppointmentMutation,
  useCreatePatientMutation,
  User,
  NotificationChannelsEnum,
} from 'shared/api';
import { ObjectShape } from 'yup/lib/object';
import { getInviteParams, validationPhone } from 'shared/lib';
import { pick, last } from 'ramda';
import { useParticipantSuccess } from 'features/appointment';
import { DOCUMENT_TYPE } from 'modals/appointment/document-modal';
import { FormValues } from './model';
import { notifications } from '../../shared/ui';

export const useForm = (
  onClose: () => void,
  activeStepId: 1 | 2,
  participants: Participant[],
  appointmentId?: string,
  onCreate?: (value: User) => void,
  isAddingToChat?: boolean
) => {
  const { t } = useTranslation([
    'auth',
    'common',
    'participants',
    'appointments',
  ]);
  const openSuccess = useParticipantSuccess();

  const [updateAppointment, { loading: isUpdateAppointmentLoading }] =
    useUpdateAppointmentMutation({
      onCompleted({ updateAppointment: updateResponse }) {
        onClose();
        const participant = last(updateResponse?.participants ?? []);
        if (!participant || !appointmentId) {
          return;
        }
        openSuccess({
          name: participant.user.name,
          documentProps: {
            appointmentId,
            participantId: participant.id,
            caption: participant.user.name,
            type: DOCUMENT_TYPE.PARTICIPANT,
          },
        });
      },
      onError: (error) => {
        notifications.error({ title: error.message });
      },
    });

  const [createPatient, { loading: isCreatePatientLoading }] =
    useCreatePatientMutation({
      onCompleted(data) {
        if (!data.createPatient) {
          return;
        }

        const users = participants
          .map(({ user }) => user)
          .map(({ id, email, sms, default_notification_channel: channel }) => ({
            user_id: id,
            email,
            sms,
            default_notification_channel: channel,
          }));

        if (appointmentId === 'new') {
          onCreate?.(
            pick(
              [
                'id',
                'name',
                'email',
                'sms',
                'default_notification_channel',
                'roles',
              ],
              data.createPatient
            ) as User
          );
          onClose();
          return;
        }

        if (appointmentId) {
          updateAppointment({
            variables: {
              id: appointmentId,
              input: {
                participants: [
                  ...users,
                  {
                    user_id: data.createPatient.id,
                    ...pick(
                      ['email', 'sms', 'default_notification_channel'],
                      data.createPatient
                    ),
                  },
                ],
              },
            },
          });
        }

        if (isAddingToChat && onCreate && data.createPatient) {
          onCreate(data.createPatient as User);
          onClose();
        }
      },
    });

  const updateValidationSchema = yup.object().shape({
    id: yup.string(),
    participant: yup
      .object()
      .nullable(true)
      .required(t('appointments:ERRORS.PARTICIPANT_REQUIRED')),
    invite: yup
      .object()
      .shape({
        channel: yup.string().required(),
        value: yup
          .string()
          .when('channel', {
            is: NotificationChannelsEnum.Mail,
            then: (schema) =>
              schema
                .email(t('common:VALIDATION.EMAIL'))
                .required(t('common:VALIDATION.EMAIL')),
          })
          .when('channel', {
            is: NotificationChannelsEnum.Sms,
            then: (schema) =>
              schema
                .length(12, t('common:VALIDATION.PHONE', { count: 12 }))
                .required(t('common:VALIDATION.PHONE')),
          }),
      })
      .nullable(true)
      .required(t('appointments:ERRORS.SELECT_INVITE')),
  });

  const createValidationSchema = yup.object().shape({
    id: yup.string(),
    invite: yup
      .object()
      .shape({
        channel: yup.string().required(),
        value: yup
          .string()
          .when('channel', {
            is: NotificationChannelsEnum.Mail,
            then: (schema) =>
              schema
                .email(t('common:VALIDATION.EMAIL'))
                .required(t('common:VALIDATION.EMAIL')),
          })
          .when('channel', {
            is: NotificationChannelsEnum.Sms,
            then: (schema) =>
              validationPhone({ schema }).required(
                t('common:VALIDATION.PHONE')
              ),
          }),
      })
      .nullable(true)
      .required(t('appointments:ERRORS.SELECT_INVITE')),
    name: yup
      .string()
      .min(3, t('common:VALIDATION.MIN_CHARS', { count: 3 }))
      .max(50, t('common:VALIDATION.MAX_CHARS', { count: 50 }))
      .required(
        t('common:VALIDATION.REQUIRED', {
          field: t('participants:FORM.FIELDS.NAME.TITLE'),
        })
      ),
    timezone: yup.string().required(
      t('common:VALIDATION.REQUIRED', {
        field: t('participants:FORM.FIELDS.TIMEZONE.TITLE'),
      })
    ),
    DOB: yup.string().when('isRedoxEnabled', {
      is: true,
      then: yup
        .string()
        .length(10, t('auth:VALIDATE.INVALID_DATE'))
        .test(
          'date',
          t('auth:VALIDATE.INVALID_DATE'),
          (value) => !!value && isValid(new Date(value))
        )
        .required(t('common:VALIDATION.REQUIRED')),
    }),
    sex: yup.string().when('isRedoxEnabled', {
      is: true,
      then: yup.string().required(),
    }),
  });

  const initialValues: FormValues = {
    participant: null,
    invite: null,
    name: '',
    timezone: 'America/New_York',
    isRedoxEnabled: false,
    DOB: undefined,
    sex: undefined,
  };

  const onUpdateSubmit = ({ participant, invite }: FormValues) => {
    if (!participant) {
      return;
    }
    const users = participants
      .map(({ user }) => user)
      .filter(({ id }) => id !== participant.id)
      .map(({ id, email, sms, default_notification_channel: channel }) => ({
        user_id: id,
        email,
        sms,
        default_notification_channel: channel,
      }));

    if (appointmentId) {
      updateAppointment({
        variables: {
          id: appointmentId,
          input: {
            participants:
              participant && invite
                ? [
                    ...users,
                    {
                      user_id: participant.id,
                      ...getInviteParams(invite),
                    },
                  ]
                : [],
          },
        },
      });
    }

    if (isAddingToChat && onCreate) {
      onCreate(participant);
      onClose();
    }
  };

  const onCreateSubmit = ({ name, invite, timezone, DOB, sex }: FormValues) => {
    if (!invite || !timezone) {
      return;
    }
    createPatient({
      variables: {
        input: {
          name,
          timezone,
          birth_date: DOB ? new Date(DOB) : null,
          sex: sex ?? null,
          ...getInviteParams(invite),
        },
      },
    });
  };

  const onSubmitMethods = new Map<1 | 2, (values: FormValues) => void>([
    [1, onUpdateSubmit],
    [2, onCreateSubmit],
  ]);

  const validationSchemas = new Map<1 | 2, yup.ObjectSchema<ObjectShape>>([
    [1, updateValidationSchema],
    [2, createValidationSchema],
  ]);

  return {
    initialValues,
    onUpdateSubmit,
    onCreateSubmit,
    validationSchema: validationSchemas.get(activeStepId),
    onSubmit: onSubmitMethods.get(activeStepId),
    isLoading: isUpdateAppointmentLoading || isCreatePatientLoading,
  };
};
