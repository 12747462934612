import { MODAL_PANELS } from 'modals/model';
import { Context, useContext } from 'react';
import { ModalContext } from './model';
import { ModalCtx } from './provider';

export function useModalContext<T extends MODAL_PANELS>() {
  /** @hack for ts declaration (@webatom) */
  const ctx = useContext(ModalCtx as unknown as Context<ModalContext<T>>);

  if (!ctx) {
    throw new Error('useModalContext must be used within the ModalContext');
  }

  return ctx;
}
