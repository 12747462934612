import { FC, useCallback, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RequirementType,
  DocumentSubmissionStatusEnum,
  SignatureType,
} from 'shared/api';
import {
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  BUTTON_COLOR,
  DocumentIcon,
  SignatureIcon,
  Desktop,
  useMobileMediaQuery,
  Mobile,
} from 'shared/ui';
import { useNavigate } from 'react-router-dom';
import { DocumentHint } from 'entities/appointment';
import { DocumentCardProps } from './model';
import {
  useStyles,
  Root,
  Container,
  ActionsWrapper,
  ContentWrapper,
  TitleWrapper,
  ButtonIcon,
  CheckStyled,
} from './styles';

export const DocumentCard: FC<DocumentCardProps> = ({
  documentSubmission,
  isFlat = false,
}) => {
  const isMobile = useMobileMediaQuery();

  const { t } = useTranslation(['common', 'participant-dashboard']);
  const navigate = useNavigate();
  const {
    id,
    document,
    status,
    signed_by_current_user: signedByCurrentUser,
  } = documentSubmission;
  const [isSubmitted, isSigned] = useMemo(
    () => [
      status === DocumentSubmissionStatusEnum.Submitted,
      signedByCurrentUser || document.signature_type === SignatureType.None,
    ],
    [document.signature_type, signedByCurrentUser, status]
  );
  const WrapperComponent = isFlat ? Fragment : Root;
  const {
    signIconClass,
    buttonStyles,
    mobileSignButtonStyle,
    containerNotSignedStyle,
  } = useStyles(isSigned, isFlat, isSubmitted);

  const handleClick = useCallback(
    (submissionId: string) => {
      navigate(`/documents/${submissionId}`);
    },
    [navigate]
  );

  return (
    <WrapperComponent>
      <Container $style={containerNotSignedStyle}>
        <ContentWrapper>
          <TitleWrapper>
            <Desktop>
              <DocumentIcon />
            </Desktop>

            {document.name}
          </TitleWrapper>
          <DocumentHint submission={documentSubmission} />
        </ContentWrapper>

        <ActionsWrapper>
          {!isSubmitted ? (
            <Button
              size={BUTTON_SIZE.SMALL}
              kind={
                document.requirement === RequirementType.Before && !isMobile
                  ? BUTTON_KIND.PRIMARY
                  : BUTTON_KIND.SECONDARY
              }
              onClick={() => handleClick(id)}
              style={buttonStyles}
            >
              {isMobile ? (
                <ButtonIcon />
              ) : (
                t(
                  'participant-dashboard:APPOINTMENTS_CARD.DOCUMENTS.BUTTONS.COMPLETE'
                )
              )}
            </Button>
          ) : isSigned ? (
            <>
              <Mobile>
                <CheckStyled />
              </Mobile>

              <Button
                size={BUTTON_SIZE.SMALL}
                kind={BUTTON_KIND.SECONDARY}
                onClick={() => handleClick(id)}
                style={buttonStyles}
              >
                {isMobile ? (
                  <ButtonIcon />
                ) : (
                  t(
                    'participant-dashboard:APPOINTMENTS_CARD.DOCUMENTS.BUTTONS.VIEW'
                  )
                )}
              </Button>
            </>
          ) : (
            <>
              <Mobile>
                <CheckStyled />
                <Button
                  size={BUTTON_SIZE.SMALL}
                  kind={BUTTON_KIND.SECONDARY}
                  onClick={() => handleClick(id)}
                  style={buttonStyles}
                >
                  <ButtonIcon />
                </Button>
              </Mobile>

              <Button
                size={BUTTON_SIZE.SMALL}
                color={BUTTON_COLOR.GREEN}
                startEnhancer={<SignatureIcon className={signIconClass} />}
                onClick={() => handleClick(id)}
                style={mobileSignButtonStyle}
              >
                {t(
                  'participant-dashboard:APPOINTMENTS_CARD.DOCUMENTS.BUTTONS.SIGN'
                )}
              </Button>
            </>
          )}
        </ActionsWrapper>
      </Container>
    </WrapperComponent>
  );
};
