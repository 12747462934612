import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { VitalPlate, VitalPlateData } from '../plate';
import { Sp02Wrap } from './styles';
import { useSessionRoomState } from '../../../../context';

export const VitalSp02: FC = () => {
  const { t } = useTranslation(['session-room']);
  const lowHighRef = useRef({
    l: 0,
    h: 0,
  });
  const { vitalsData } = useSessionRoomState();
  const [lowestPR, highestPR] = useMemo(() => {
    const PRValue =
      typeof vitalsData.bloodOxy?.PR === 'number' ? vitalsData.bloodOxy?.PR : 0;
    const l =
      lowHighRef.current.l === 0
        ? PRValue
        : Math.min(lowHighRef.current.l, PRValue);
    const h =
      lowHighRef.current.h === 0
        ? PRValue
        : Math.max(lowHighRef.current.h, PRValue);

    lowHighRef.current = {
      l,
      h,
    };

    return [`L: ${l && l !== h ? l : '–'}`, `H: ${h && h !== l ? h : '–'}`];
  }, [vitalsData.bloodOxy?.PR]);
  const [sp02Value, PRValue] = useMemo(
    () => [vitalsData.bloodOxy?.SpO2 ?? '–', vitalsData.bloodOxy?.PR ?? '–'],
    [vitalsData.bloodOxy]
  );

  return (
    <VitalPlate>
      <Sp02Wrap>
        <VitalPlateData
          title={t('session-room:SENSORS.SP02')}
          units="%"
          value={sp02Value}
          color="#03fcff"
          extraData={[`Base: ${90}`]}
        />
        <VitalPlateData
          title={t('session-room:SENSORS.PR')}
          units="bpm"
          value={PRValue}
          style={{ width: '254px', maxWidth: '254px' }}
          extraData={[lowestPR, highestPR]}
        />
      </Sp02Wrap>
    </VitalPlate>
  );
};
