import { useStyletron, TEXT, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { PopoverOverrides } from 'baseui/popover';
import { SELECT_SIZE } from './model';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const dropdownStyle: StyleObject = {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    boxShadow:
      '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
    maxHeight: '220px',
    overflow: 'auto',

    [MOBILE]: {
      display: 'none',
    },
  };

  const iconsContainerClass = css({
    display: 'flex',
    alignItems: 'center',
  });

  const getChevronIconClass = (size?: SELECT_SIZE) =>
    css({
      width: size === SELECT_SIZE.SMALL ? '20px' : '24px',
      color: theme.colors.gray400,

      [MOBILE]: {
        width: '24px',
      },
    });

  const popoverOverrides: PopoverOverrides = {
    Body: {
      style: {
        marginTop: '1px',
        marginRight: '1px',
        marginBottom: '1px',
        marginLeft: '1px',
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: 'initial',
        zIndex: 102,
      },
    },
    Inner: {
      style: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: 'initial',
      },
    },
  };

  const getControlContainerStyle: StyleObject = {
    gap: '8px',
    cursor: 'pointer',
    paddingTop: 0,
    paddingBottom: 0,
    borderTopStyle: 'solid',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderLeftStyle: 'solid',
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '1px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    transitionProperty: 'all',
  };

  const singleValueStyle: StyleObject = {
    height: 'auto',
    marginLeft: 0,
  };

  const valueContainerStyle: StyleObject = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
  };

  const getDropdownItemStyle = (
    isHighlighted?: boolean,
    isDisabled?: boolean
  ): StyleObject => ({
    transitionProperty: 'color',
    transitionDuration: '0.2s',
    color: isHighlighted ? theme.colors.accent500 : 'inherit',
    opacity: isDisabled ? 0.3 : 1,

    [MOBILE]: {
      width: '100%',
      padding: '8px 16px',
    },
  });

  const optionContentStyle: StyleObject = {
    ...TEXT.base_book,
  };

  const inputContainerStyle: StyleObject = {
    marginLeft: 0,
  };

  const searchIconSvgStyle: StyleObject = {
    width: '24px',
    color: theme.colors.gray400,
  };

  const searchIconContainerStyle: StyleObject = {
    position: 'static',
    left: 'auto',
    width: 'auto',
  };

  const placeholderStyle: StyleObject = {
    color: theme.colors.gray400,
  };

  const focusStyle: StyleObject = {
    borderTopColor: `${theme.colors.accent} !important`,
    borderRightColor: `${theme.colors.accent} !important`,
    borderBottomColor: `${theme.colors.accent} !important`,
    borderLeftColor: `${theme.colors.accent} !important`,
  };

  const errorStyle: StyleObject = {
    borderTopColor: `${theme.colors.red500} !important`,
    borderRightColor: `${theme.colors.red500} !important`,
    borderBottomColor: `${theme.colors.red500} !important`,
    borderLeftColor: `${theme.colors.red500} !important`,
  };

  const modalListClass = css({
    display: 'flex',
    flexDirection: 'column',
    margin: '-8px -16px',
  });

  return {
    dropdownStyle,
    iconsContainerClass,
    getChevronIconClass,
    popoverOverrides,
    getControlContainerStyle,
    singleValueStyle,
    valueContainerStyle,
    getDropdownItemStyle,
    optionContentStyle,
    inputContainerStyle,
    searchIconSvgStyle,
    searchIconContainerStyle,
    placeholderStyle,
    focusStyle,
    errorStyle,
    modalListClass,
  };
};
