import { useStyletron, TEXT, MOBILE, DESKTOP } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const rootStyle: StyleObject = {
    margin: 'auto',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    maxWidth: '424px',
    backgroundColor: theme.colors.white900,
    boxShadow:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    padding: '40px 24px 24px',
    animationDuration: '300ms',
    // eslint-disable-next-line
    // @ts-ignore
    animationName: {
      from: {
        opacity: 0,
        transform: 'translateY(24px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },

    [DESKTOP]: {
      overflow: 'hidden',
    },
    [MOBILE]: {
      maxWidth: 'none',
      marginBottom: 0,
      padding: '16px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  };

  const closeButtonStyle: StyleObject = {
    position: 'absolute',
    top: '16px',
    right: '24px',
    transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',

    [DESKTOP]: {
      color: theme.colors.gray400,

      ':hover': {
        color: theme.colors.gray700,
      },
    },
    [MOBILE]: {
      top: '8px',
      left: 0,
      right: 'auto',
      padding: '12px 16px',
    },
  };

  const closeIconClass = css({
    display: 'block',
    pointerEvents: 'none',
  });

  const headerClass = css({
    position: 'sticky',
    zIndex: 1,
    top: 0,
    margin: '-16px -16px 0',
    padding: '16px 73px 24px',
    textAlign: 'center',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    background: `linear-gradient(${theme.colors.white900} 80%, transparent)`,
  });

  const titleClass = css({
    ...TEXT.xl_medium,
  });

  return {
    rootStyle,
    closeButtonStyle,
    closeIconClass,
    headerClass,
    titleClass,
  };
};
