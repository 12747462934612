import { forward, sample } from 'effector';
import {
  close as closeSidebar,
  open as openSidebar,
} from 'process/sidebar-panel';
import { closePanel, openPanel, closeAllPanels } from './index';

sample({
  clock: openPanel,
  filter: Boolean,
  fn: (panel) => ({ props: panel.sidebarProps, id: panel.id }),
  target: openSidebar,
});

forward({ from: closeAllPanels, to: closePanel });

forward({
  from: closePanel,
  to: closeSidebar,
});
