import { FC, useCallback, useMemo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPE, useModal } from 'shared/lib';
import { Button, BUTTON_KIND, BUTTON_SIZE } from 'shared/ui';

import { InfoModalProps, INFO_MODAL_TYPE } from './model';
import {
  Content,
  InfoBlock,
  Root,
  StyledCheckIcon,
  StyledCloseIcon,
  StyledUserIcon,
  Text,
  Title,
  IconWrapper,
  iconWrapperStyles,
} from './styles';

const TypeIcons = new Map<INFO_MODAL_TYPE, ReactNode>([
  [INFO_MODAL_TYPE.SUCCESS, <StyledCheckIcon />],
  [INFO_MODAL_TYPE.ERROR, <StyledCloseIcon />],
  [INFO_MODAL_TYPE.USER_ERROR, <StyledUserIcon />],
]);

const ModalContent: FC<InfoModalProps & { close: () => void }> = ({
  type = INFO_MODAL_TYPE.SUCCESS,
  title,
  text,
  buttons,
  noClose = false,
  isHideCloseButton = false,
  close,
}) => {
  const { t } = useTranslation(['common']);
  const iconWrapperColors = useMemo(() => iconWrapperStyles(type), [type]);
  const isContentAvailable = useMemo(() => title || text, [text, title]);
  const icon = useMemo(() => TypeIcons.get(type), [type]);

  return (
    <Root>
      <InfoBlock>
        {icon ? (
          <IconWrapper style={iconWrapperColors}>{icon}</IconWrapper>
        ) : null}

        {isContentAvailable ? (
          <Content>
            {title ? <Title>{title}</Title> : null}
            {text ? <Text>{text}</Text> : null}
          </Content>
        ) : null}
      </InfoBlock>

      <InfoBlock>
        {buttons}
        {!(noClose || isHideCloseButton) && (
          <Button
            type="button"
            kind={BUTTON_KIND.SECONDARY}
            size={BUTTON_SIZE.SMALL}
            onClick={close}
          >
            {t('common:BUTTONS.CLOSE')}
          </Button>
        )}
      </InfoBlock>
    </Root>
  );
};

export const useInfoModal = (props: InfoModalProps) => {
  const { open: openModal, close } = useModal();
  const open = useCallback(
    (openProps?: { isCatchCloseEvent?: boolean }) => {
      const isCatchCloseEvent = openProps?.isCatchCloseEvent ?? true;

      openModal(MODAL_TYPE.BASE, {
        children: (
          <ModalContent {...props} noClose={!isCatchCloseEvent} close={close} />
        ),
        isCatchCloseEvent,
      });
    },
    [close, openModal, props]
  );

  return {
    open,
    close,
  };
};
