import { BaseProvider } from 'baseui';
import { FC, useMemo } from 'react';
import { getTheme } from 'shared/ui';
import { $currentOrganization } from 'entities/organization';
import { useStore } from 'effector-react';

export const BasewebProvider: FC = ({ children }) => {
  const currentOrganization = useStore($currentOrganization);
  const theme = useMemo(
    () =>
      getTheme(
        currentOrganization ? JSON.parse(currentOrganization?.palette) : null
      ),
    [currentOrganization]
  );

  return <BaseProvider theme={theme}>{children}</BaseProvider>;
};
