import { FC, useEffect, useMemo, useRef } from 'react';
import { StyledSpinnerNext } from 'baseui/spinner';

import { useIntersectionObserver } from 'usehooks-ts';

import { SpinnerWrapper } from './styles';
import { NextSpinnerInterface } from './model';

export const NextSpinner: FC<NextSpinnerInterface> = ({
  intersectionProps = {},
  onIntersect,
}) => {
  const spinnerRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(spinnerRef, intersectionProps);
  const isLoadNext = useMemo(
    () => !!entry?.isIntersecting,
    [entry?.isIntersecting]
  );

  useEffect(() => {
    if (isLoadNext) {
      onIntersect();
    }
  }, [isLoadNext, onIntersect]);

  return (
    <SpinnerWrapper ref={spinnerRef}>
      <StyledSpinnerNext />
    </SpinnerWrapper>
  );
};
