import { useStyletron } from 'shared/ui';
import { theme } from '../theme';

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
    zIndex: 10,
    overflow: 'hidden',
  });

  const windowClass = css({
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    padding: '24px 16px',
    border: '1px solid rgba(40, 42, 42, 0.4)',
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.colors.transparentBlue,
    backdropFilter: 'blur(8.15485px)',
    zIndex: 10,
    animationDuration: '300ms',
    // eslint-disable-next-line
    // @ts-ignore
    animationName: {
      from: {
        transform: 'translateY(100%)',
      },
      to: {
        transform: 'translateY(0)',
      },
    },
  });

  const closeBgClass = css({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  const headerClass = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.white,
  });

  const sideClass = css({
    flex: 1,
  });

  const titleClass = css({
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '700',
  });

  const backButton = css({
    fontSize: '16px',
    lineHeight: '24px',
  });

  const bodyClass = css({
    marginTop: '24px',
  });

  const infoTable = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  });

  const infoTableRow = css({
    display: 'flex',
    gap: '24px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  });

  const infoTableRowLabel = css({
    flex: '0 0 96px',
    color: theme.colors.gray,
  });

  const infoTableRowValues = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    color: theme.colors.white,
  });

  return {
    rootClass,
    windowClass,
    closeBgClass,
    headerClass,
    sideClass,
    titleClass,
    backButton,
    bodyClass,
    infoTable,
    infoTableRow,
    infoTableRowLabel,
    infoTableRowValues,
  };
};
