import { useStyletron } from '../theme';
import { StepperItem } from './item';
import { StepperProps } from './model';
import { useStepperStyles } from './styles';

export function Stepper<ID extends string | number = number | string>({
  steps,
  activeStepId,
  onChange,
}: StepperProps<ID>) {
  const [css] = useStyletron();
  const { rootClass, listStyles, listItemStyleClass } = useStepperStyles();

  const handleClick = (id: ID) => {
    if (activeStepId === id) {
      return;
    }
    onChange?.(id);
  };

  const isCompleted = (index: number) => {
    const activeIndex = steps.findIndex(
      ({ id: stepId }) => stepId === activeStepId
    );
    return index < activeIndex;
  };

  return (
    <div className={rootClass}>
      <ol
        className={css({
          ...listStyles,
          gridTemplateColumns: `repeat(${steps.length}, 1fr)`,
        })}
      >
        {steps.map(({ id, label, link }, index) => (
          <li key={id} className={listItemStyleClass}>
            <StepperItem
              index={index}
              link={link}
              label={label}
              onClick={() => handleClick(id)}
              isLast={index === steps.length - 1}
              isActive={id === activeStepId}
              isCompleted={isCompleted(index)}
            />
          </li>
        ))}
      </ol>
    </div>
  );
}
