import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MicrophoneIcon, UnmuteIcon } from 'shared/ui';
import { ActionButton } from '../action-button';
import { MediaActionButton } from './model';

export const AudioButton: FC<MediaActionButton> = ({
  isOn,
  onToggleState,
  contextMenu = [],
}) => {
  const { t } = useTranslation(['session-room']);

  const [AudioStateIcon, audioStateText] = useMemo(
    () =>
      isOn
        ? [MicrophoneIcon, t('session-room:MUTE')]
        : [UnmuteIcon, t('session-room:UNMUTE')],
    [isOn, t]
  );

  const audioContextMenu = useMemo(
    () =>
      contextMenu.length > 1
        ? {
            title: t('session-room:SELECT_MIC'),
            list: contextMenu,
          }
        : undefined,
    [contextMenu, t]
  );

  return (
    <ActionButton
      icon={<AudioStateIcon />}
      onClick={onToggleState}
      contextMenu={audioContextMenu}
    >
      {audioStateText}
    </ActionButton>
  );
};
