import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Title } from './style';

export const VitalPulseRate: FC = () => {
  const { t } = useTranslation(['session-room']);
  const chart = useRef(null);

  return (
    <div>
      <Header>
        <Title>{t('session-room:SENSORS.PULSE_RATE')}</Title>
      </Header>

      <div ref={chart} />
    </div>
  );
};
