import { format } from 'date-fns';
import { useStore } from 'effector-react';
import { $ECGData, $lastEcgDate, resetECG } from 'pages/session-room/store';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ECGChart } from 'shared/ui';
import {
  Header,
  HeaderLeft,
  Title,
  StartTime,
  HeaderRight,
  ResetButton,
} from './style';

export const VitalEKG: FC = () => {
  const { t } = useTranslation(['session-room']);
  const lastDateUpdated = useStore($lastEcgDate);
  const data = useStore($ECGData);

  return (
    <div>
      <Header>
        <HeaderLeft>
          <Title>{t('session-room:SENSORS.ECG')}</Title>
          {lastDateUpdated && (
            <>
              <StartTime>
                {format(lastDateUpdated, 'EEE dd MMM h:mmaaa')}
              </StartTime>

              {/* <Duration>01:12</Duration> */}
            </>
          )}
        </HeaderLeft>

        {!!data.length && (
          <HeaderRight>
            <ResetButton onClick={() => resetECG()}>
              {t('session-room:RESET')}
            </ResetButton>
          </HeaderRight>
        )}
      </Header>
      {!!data.length && <ECGChart data={data} />}
    </div>
  );
};
