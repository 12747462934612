import { FC, useState, useEffect } from 'react';
import {
  useStyletron,
  Input,
  CalendarIcon,
  DatePicker,
  INPUT_SIZE,
  INPUT_KIND,
} from 'shared/ui';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { format, parse, isValid } from 'date-fns';
import InputMask from 'react-input-mask';
import { DateInputProps } from './model';

export const DateInput: FC<DateInputProps> = ({
  value,
  onChange,
  hasError,
  placeholder,
  size = INPUT_SIZE.MEDIUM,
  kind = INPUT_KIND.PRIMARY,
  popover = true,
  onKeyDown,
}) => {
  const [css, theme] = useStyletron();
  const getStringFromDate = (date: Date) => format(date, 'MM / dd / yyyy');
  const getDateFromString = (string: string) =>
    parse(string, 'MM / dd / yyyy', new Date());

  const [dateString, setDateString] = useState(
    value ? getStringFromDate(value) : ''
  );

  useEffect(() => {
    if (!dateString || !isValid(getDateFromString(dateString))) {
      onChange?.(undefined);
      return;
    }
    onChange?.(getDateFromString(dateString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString]);

  return (
    <StatefulPopover
      content={({ close }) => (
        <>
          {popover && (
            <div
              className={css({
                padding: '24px',
                borderRadius: '8px',
                backgroundColor: theme.colors.white,
              })}
            >
              <DatePicker
                value={value}
                onChange={(date) => {
                  setDateString(getStringFromDate(date as Date));
                  close();
                }}
                isAllDayAvailable
              />
            </div>
          )}
        </>
      )}
      overrides={{
        Body: {
          style: {
            position: 'absolute',
            zIndex: 3,
          },
        },
      }}
      placement={PLACEMENT.bottomLeft}
      ignoreBoundary
      accessibilityType="menu"
      autoFocus={false}
    >
      <div>
        <InputMask
          mask="99 / 99 / 9999"
          maskPlaceholder=""
          placeholder={placeholder}
          value={dateString}
          onKeyDown={onKeyDown}
          onChange={({ currentTarget }) => {
            setDateString(currentTarget.value);
          }}
          onBlur={({ currentTarget }) => {
            if (currentTarget.value.length === 11) {
              const formattedValue = currentTarget.value
                .split(' / ')
                .map((v) => v.padStart(2, '0'))
                .join(' / ');
              setDateString(formattedValue);
            }
          }}
        >
          <Input
            placeholder="MM / DD / YY"
            hasError={hasError}
            endEnhancer={
              <CalendarIcon className={css({ color: theme.colors.gray400 })} />
            }
            size={size}
            kind={kind}
          />
        </InputMask>
      </div>
    </StatefulPopover>
  );
};
