import { FC, useRef, useMemo } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

import { GradientSidesWrapperInterface } from './model';
import { Root, Content, useStyles } from './styles';

export const GradientSidesWrapper: FC<GradientSidesWrapperInterface> = ({
  wrapStyles,
  styles,
  children,
  gradient,
}) => {
  const { topGradientClass, bottomGradientClass } = useStyles();
  const blockRoot = useRef<HTMLDivElement>(null);
  const blockStart = useRef<HTMLDivElement>(null);
  const blockEnd = useRef<HTMLDivElement>(null);
  const observerOptions = useMemo(() => ({ root: blockRoot.current }), []);
  const topEntry = useIntersectionObserver(blockStart, observerOptions);
  const bottomEntry = useIntersectionObserver(blockEnd, observerOptions);
  const isTopIntersected = useMemo(
    () => !!topEntry?.isIntersecting,
    [topEntry]
  );
  const isBottomIntersected = useMemo(
    () => !!bottomEntry?.isIntersecting,
    [bottomEntry]
  );

  return (
    <Root style={wrapStyles}>
      <Content ref={blockRoot} style={styles}>
        <div
          ref={blockStart}
          className={topGradientClass(!isTopIntersected, gradient)}
        />
        {children}
        <div
          ref={blockEnd}
          className={bottomGradientClass(!isBottomIntersected, gradient)}
        />
      </Content>
    </Root>
  );
};
