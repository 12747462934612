import {
  DESKTOP,
  IS_NO_TOUCH,
  MOBILE,
  styled,
  TEXT,
  useStyletron,
} from 'shared/ui';
import { StyleObject } from 'styletron-standard';
import { PopoverOverrides } from 'baseui/popover';

export const Title = styled('h2', {
  ...TEXT.xl2_bold,
  marginBottom: '16px',
});

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const headerClass = css({
    padding: '24px',
  });

  const headerTextClass = css({
    ...TEXT.xl_medium,
  });

  const contentClass = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',

    [DESKTOP]: {
      padding: '24px',
    },
  });

  const labelClass = css({
    marginBottom: '8px',
    ...TEXT.base_medium,
  });

  const participantListClass = css({
    padding: '16px 0',
  });

  const participantItemClass = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':not(:first-child)': {
      marginTop: '16px',
    },
  });

  const participantTextClass = css({
    paddingLeft: '8px',
    ...TEXT.base_medium,
  });

  const getParticipantBottomClass = (isEmptyList?: boolean) =>
    css({
      padding: '16px 0',
      borderTop: isEmptyList ? undefined : `1px solid ${theme.colors.gray200}`,
    });

  const participantAddClass = css({
    ...TEXT.base_medium,
    color: theme.colors.accent,
  });

  const templateIconClass = css({
    width: '24px',
    color: theme.colors.gray400,
  });

  const titleCaptionClass = css({
    marginTop: '4px',
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  const contentBottomClass = css({
    display: 'flex',
    gap: '16px',
    marginTop: '16px',

    [MOBILE]: {
      flexDirection: 'row-reverse',
    },
  });

  const listWrapperClass = css({
    maxHeight: '259px',
    margin: '-8px -24px',
    overflow: 'auto',
  });

  const popoverOverrides: PopoverOverrides = {
    Body: {
      style: {
        zIndex: 102,
        width: '636px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: 'none',
        backgroundColor: 'initial',

        [MOBILE]: {
          width: 'calc(100% - 64px)',
        },
      },
    },
    Inner: {
      style: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '24px',
        paddingRight: '24px',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderTopColor: theme.colors.gray200,
        borderRightColor: theme.colors.gray200,
        borderBottomColor: theme.colors.gray200,
        borderLeftColor: theme.colors.gray200,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        boxShadow:
          '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
        backgroundColor: theme.colors.white900,
      },
    },
  };

  const popoverContainer = css({
    flexGrow: 1,
  });

  const itemClass = css({
    display: 'flex',
    alignItems: 'center',
    minHeight: '65px',
    padding: '8px 24px',
    transition: 'background-color 0.2s cubic-bezier(0, 0, 1, 1)',
    cursor: 'pointer',

    ':not(:first-child)': {
      borderTop: `1px solid ${theme.colors.gray200}`,
    },

    [IS_NO_TOUCH]: {
      ':hover': {
        backgroundColor: theme.colors.gray50,
      },
    },
  });

  const nameClass = css({
    ...TEXT.base_medium,
  });

  const captionClass = css({
    marginTop: '4px',
    ...TEXT.sm_book,
    color: theme.colors.gray400,
  });

  const spinnerStyle: StyleObject = {
    position: 'relative',
    zIndex: 1,
    marginRight: 'auto',
    marginLeft: 'auto',
    borderTopColor: theme.colors.accent,
  };

  const messageClass = css({
    position: 'relative',
    zIndex: 1,
  });

  const getParticipantContainerClass = (hasError: boolean) =>
    css({
      padding: '0 16px',
      border: `1px solid ${theme.colors.gray200}`,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '8px',
      borderColor: hasError ? theme.colors.red500 : theme.colors.gray200,
      transition: 'border-color 0.2s cubic-bezier(0, 0, 1, 1)',
    });

  const getSubmitButtonStyle = (isValid: boolean): StyleObject => ({
    opacity: isValid ? 1 : 0.33,

    [MOBILE]: {
      flex: '1 1 50%',
    },
  });

  const cancelButtonStyles: StyleObject = {
    [MOBILE]: {
      flex: '1 1 50%',
    },
  };

  return {
    headerClass,
    headerTextClass,
    contentClass,
    labelClass,
    participantListClass,
    participantItemClass,
    participantTextClass,
    participantAddClass,
    templateIconClass,
    titleCaptionClass,
    contentBottomClass,
    listWrapperClass,
    popoverOverrides,
    popoverContainer,
    itemClass,
    nameClass,
    captionClass,
    spinnerStyle,
    messageClass,
    cancelButtonStyles,
    getParticipantContainerClass,
    getSubmitButtonStyle,
    getParticipantBottomClass,
  };
};
