import { useState } from 'react';
import { useDebounce } from 'usehooks-ts';

export type ReturnDebounceValue<T> = [
  value: T,
  debounceValue: T,
  setValue: (value: T) => void
];

export function useDebounceValue<T>(
  initialValue: T,
  debounceMs = 300
): ReturnDebounceValue<T> {
  const [state, setState] = useState(initialValue);
  const value = useDebounce(state, debounceMs);
  return [state, value, setState];
}
