import { FC, memo } from 'react';
import { AlertProps } from './model';
import { useStyles } from './styles';

export const Alert: FC<AlertProps> = memo(
  ({ type, title, text, children, rootStyles, isFlat = true }) => {
    const { Root, Icon, Title, Text, Footer } = useStyles({
      type,
      rootStyles,
      isFlat,
    });

    return (
      <Root>
        <Icon />
        <Title>{title}</Title>
        {text ? <Text>{text}</Text> : null}

        {children ? <Footer>{children}</Footer> : null}
      </Root>
    );
  }
);
