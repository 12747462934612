import { FC, useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  LineElement,
  registerables,
  ChartData,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { ECGChartProps } from './model';
import { getChartOptions } from './options';
import { Wrapper } from './styles';

// Register all modules in chart
ChartJS.register(...registerables);
ChartJS.register(LineElement);

export const CHUNK_SIZE = 1750;

export const ECGChart: FC<ECGChartProps> = ({ data }) => {
  const chartRef = useRef<ChartJS>(null);
  const options = useMemo(() => getChartOptions(), []);

  const chunks = useMemo(() => {
    const res = [];
    for (let i = 0; i < data.length; i += CHUNK_SIZE) {
      res.push(data.slice(i, i + CHUNK_SIZE));
    }
    return res;
  }, [data]);

  const chartDataList: ChartData<'line'>[] = useMemo(
    () =>
      chunks.map((chunkData) => ({
        datasets: [{ data: chunkData.map(({ y }) => y) }],
        labels: [
          ...chunkData.map(({ x }) => x),
          ...new Array(CHUNK_SIZE - chunkData.length).fill(0),
        ] as number[],
      })),
    [chunks]
  );

  return (
    <div>
      {chartDataList.map((chartData, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Wrapper key={index}>
          <Chart
            options={options}
            type="line"
            data={chartData}
            ref={chartRef}
          />
        </Wrapper>
      ))}
    </div>
  );
};
