import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Participant, useRemindParticipantsMutation } from 'shared/api';
import { CONFIRM_KIND, useConfirm } from 'shared/ui';

type RemindParticipantsProps = {
  participants: Participant[] | Participant;
  appointmentId: string;
};

export const useRemindParticipants = () => {
  const [remindRequest] = useRemindParticipantsMutation();
  const { confirm } = useConfirm();
  const { t } = useTranslation(['appointments']);

  const remind = useCallback(
    ({ appointmentId, participants }: RemindParticipantsProps) => {
      const isMany = Array.isArray(participants) && participants.length > 1;

      const currentParticipant = Array.isArray(participants)
        ? participants[0]
        : participants;

      const name = isMany
        ? participants.map((p) => p.user.name).join(', ')
        : currentParticipant.user.name;

      const participantsIds = isMany
        ? participants.map(({ id }) => id)
        : [currentParticipant.id];

      confirm({
        title: t(
          isMany
            ? 'appointments:REMIND_MODAL.TITLE_SELECTED'
            : 'appointments:REMIND_MODAL.TITLE',
          { name }
        ),
        caption: t('appointments:REMIND_MODAL.SUMMARY', { name }),
        submitText: t('appointments:REMIND_MODAL.SUBMIT'),
        kind: CONFIRM_KIND.REMINDER,
      }).then(({ result }) => {
        if (result) {
          remindRequest({
            variables: {
              input: {
                appointment_id: appointmentId,
                participants_ids: participantsIds,
              },
            },
          });
        }
      });
    },
    [remindRequest, confirm, t]
  );

  return remind;
};
