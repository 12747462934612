import { useStyletron } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css] = useStyletron();

  const buttonRowClass = css({
    marginTop: '16px',
  });
  const iconClass = css({
    width: '20px',
  });
  const buttonStyle: StyleObject = {
    width: '100%',
  };

  return {
    buttonRowClass,
    iconClass,
    buttonStyle,
  };
};
