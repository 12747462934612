import { DESKTOP, MOBILE, styled } from '../theme';

export const Root = styled('div', {
  position: 'fixed',
  zIndex: 200,
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
});

export const Container = styled('div', {
  position: 'absolute',
  right: '20px',
  top: '20px',
  [DESKTOP]: {
    maxWidth: '424px',
    alignItems: 'flex-end',
  },
  display: 'flex',
  pointerEvents: 'auto',
  flexDirection: 'column',
  gap: '16px',
  [MOBILE]: {
    width: 'calc(100% - 40px)',
  },
});
