import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useStyletron, CheckIcon } from 'shared/ui';
import { StepperDivider } from './divider';
import { StepperItemProps } from './model';
import { useStepperItemStyles } from './styles';

export const StepperItem: FC<StepperItemProps> = ({
  isActive,
  isCompleted,
  isLast,
  label,
  link,
  index,
  onClick,
}) => {
  const [css, theme] = useStyletron();
  const {
    itemNumberClass,
    itemStyles,
    itemCompletedIconWrapperClass,
    iconClass,
  } = useStepperItemStyles();

  const renderButton = () => (
    <button
      className={css({
        ...itemStyles,
        color: isCompleted
          ? theme.colors.gray700
          : isActive
          ? theme.colors.accent500
          : theme.colors.gray400,
      })}
      type="button"
      onClick={onClick}
    >
      {isCompleted ? (
        <span className={itemCompletedIconWrapperClass}>
          <CheckIcon className={iconClass} />
        </span>
      ) : (
        <span className={itemNumberClass}>{index + 1}</span>
      )}
      {label}
      {!isLast && <StepperDivider />}
    </button>
  );

  return link ? <Link to={link}>{renderButton()}</Link> : renderButton();
};
