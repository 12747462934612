import { FC, ReactNode } from 'react';
import { useStyletron } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const Folder: FC<{
  header?: ReactNode;
  content?: ReactNode;
  style?: StyleObject;
  contentStyles?: StyleObject;
}> = ({ header, content, style, contentStyles }) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        ...style,
      })}
    >
      {header ? (
        <div
          className={css({
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            border: `1px solid ${theme.colors.gray200}`,
            backgroundColor: theme.colors.gray50,
          })}
        >
          {header}
        </div>
      ) : null}
      <div
        className={css({
          flexGrow: 1,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          border: `1px solid ${theme.colors.gray200}`,
          ...contentStyles,
        })}
      >
        {content}
      </div>
    </div>
  );
};
