import { FC, MouseEvent } from 'react';
import { useStyletron, TEXT } from 'shared/ui';
import { DocumentStatusIcon, DOCUMENT_STATUS } from '../document-status-icon';

interface DocumentIndicatorProps {
  total?: number;
  completed?: number;
  status?: DOCUMENT_STATUS;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

export const DocumentIndicator: FC<DocumentIndicatorProps> = ({
  total = 0,
  completed = 0,
  status = DOCUMENT_STATUS.NONE,
  onClick,
}) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      })}
      onClick={onClick}
      role="button"
      tabIndex={0}
      aria-hidden="true"
    >
      <DocumentStatusIcon status={status} />
      <p
        className={css({
          ...TEXT.base_book,
          whiteSpace: 'nowrap',
          color: theme.colors.gray400,
        })}
      >
        <span
          className={css({
            color: theme.colors.gray700,
          })}
        >
          {completed}
        </span>{' '}
        / {total}
      </p>
    </div>
  );
};
