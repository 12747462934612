import { FC, memo } from 'react';
import { PageHeaderInterface } from './model';
import { Root, Title, Description } from './styles';

export const PageHeader: FC<PageHeaderInterface> = memo(
  ({ title, description }) => (
    <Root>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </Root>
  )
);
