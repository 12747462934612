import { FC, forwardRef } from 'react';
import { useStyletron, TEXT } from 'shared/ui';
import {
  Textarea as BaseTextarea,
  TextareaProps as BaseTextareaProps,
} from 'baseui/textarea';
import { StyleObject } from 'styletron-standard';
import { InputOverrides } from 'baseui/input';

export type TextareaProps = Pick<
  BaseTextareaProps,
  'placeholder' | 'onChange' | 'value'
> & {
  style?: StyleObject;
  hasError?: boolean;
  overrides?: InputOverrides;
};

export const Textarea: FC<TextareaProps> = forwardRef<
  BaseTextarea,
  TextareaProps
>(({ style, hasError = false, overrides, ...props }, ref) => {
  const [, theme] = useStyletron();

  const getFocusStyle = (isFocused: boolean) => {
    if (!isFocused) {
      return {};
    }
    return {
      borderTopColor: `${theme.colors.accent} !important`,
      borderRightColor: `${theme.colors.accent} !important`,
      borderBottomColor: `${theme.colors.accent} !important`,
      borderLeftColor: `${theme.colors.accent} !important`,
    };
  };

  const getErrorStyle = (isError: boolean) => {
    if (!isError) {
      return {};
    }
    return {
      borderTopColor: `${theme.colors.red500} !important`,
      borderRightColor: `${theme.colors.red500} !important`,
      borderBottomColor: `${theme.colors.red500} !important`,
      borderLeftColor: `${theme.colors.red500} !important`,
    };
  };

  return (
    <BaseTextarea
      {...props}
      ref={ref}
      overrides={
        {
          Root: {
            style: ({ $isFocused }) => ({
              borderTopWidth: '1px',
              borderRightWidth: '1px',
              borderBottomWidth: '1px',
              borderLeftWidth: '1px',
              borderLeftColor: theme.colors.gray200,
              borderRightColor: theme.colors.gray200,
              borderTopColor: theme.colors.gray200,
              borderBottomColor: theme.colors.gray200,
              backgroundColor: theme.colors.white900,
              paddingTop: '12px',
              paddingBottom: '12px',
              paddingLeft: '16px',
              paddingRight: '16px',
              ...TEXT.base_book,

              ':hover': {
                borderTopColor: theme.colors.gray300,
                borderRightColor: theme.colors.gray300,
                borderBottomColor: theme.colors.gray300,
                borderLeftColor: theme.colors.gray300,
              },
              ...getFocusStyle($isFocused),
              ...getErrorStyle(hasError),
              ...style,
            }),
          },
          InputContainer: {
            style: {
              fontSize: 'inherit',
              backgroundColor: 'initial',
              color: 'inherit',
            },
          },
          Input: {
            style: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              fontSize: 'inherit',
              color: 'inherit',

              '::placeholder': {
                color: theme.colors.gray400,
              },
            },
          },
          ...overrides,
        } as InputOverrides
      }
    />
  );
});
