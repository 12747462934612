import { useStore } from 'effector-react';
import { FC } from 'react';
import { SidebarProvider } from './context/provider';
import { $panel } from './store';

export const SidebarManager: FC = () => {
  const panel = useStore($panel);

  return panel ? <SidebarProvider id={panel.id} props={panel.props} /> : null;
};
