import { useList } from 'effector-react';
import { FC } from 'react';
import { ModalBase, ModalDefault } from 'shared/ui';
import { MODAL_TYPE } from '.';
import { $modals, close } from './store';

export const ModalManager: FC = () =>
  useList($modals, {
    fn: (modal) => {
      if (modal.type === MODAL_TYPE.BASE) {
        return <ModalBase {...modal.props} onClose={() => close(modal.id)} />;
      }
      return <ModalDefault {...modal.props} onClose={() => close(modal.id)} />;
    },
    getKey: ({ id }) => id,
  });
