import { FC, useRef } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { Spinner, SpinnerWrapper, useStyles } from './styles';

type ToggleProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  isLoading?: boolean;
  displayLoading?: boolean;
};

export const Toggle: FC<ToggleProps> = ({
  value = false,
  onChange,
  disabled = false,
  isLoading = false,
  displayLoading = false,
}) => {
  const id = useRef(uuidV4());
  const { getContainerClass, inputClass, getThumbClass, getHoleClass } =
    useStyles();

  return (
    <label
      htmlFor={id.current}
      className={getContainerClass(value, disabled, isLoading, displayLoading)}
    >
      <input
        id={id.current}
        className={inputClass}
        type="checkbox"
        onChange={(e) => {
          if (disabled) {
            return;
          }
          onChange?.(e.target.checked);
        }}
        checked={value}
      />
      {isLoading && displayLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <div className={getThumbClass(value)}>
          <span className={getHoleClass(disabled)} />
        </div>
      )}
    </label>
  );
};
