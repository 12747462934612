import { useCallback } from 'react';
import { useStyletron, TEXT, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const colorLineStyle: StyleObject = {
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    bottom: '-1px',
    width: '4px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  };

  const buttonStyle: StyleObject = {
    width: '100%',
    ...TEXT.base_book,
    backgroundColor: theme.colors.gray50,
    color: theme.colors.gray400,
  };

  const getSelectContainerClass = useCallback(
    (isPaymentEnabled: boolean) =>
      css({
        position: 'relative',
        width: isPaymentEnabled ? '472px' : 'auto',

        [MOBILE]: {
          width: 'auto',
        },
      }),
    [css]
  );

  const chevronIconClass = css({
    width: '24px',
    marginLeft: 'auto',
    color: theme.colors.gray400,
  });

  const selectStyle: StyleObject = {
    position: 'relative',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '20px',
    ...TEXT.base_book,

    [MOBILE]: {
      paddingRight: '12px',
    },

    ':hover': {
      borderLeftColor: theme.colors.gray300,
      borderRightColor: theme.colors.gray300,
      borderTopColor: theme.colors.gray300,
      borderBottomColor: theme.colors.gray300,
      color: 'inherit',
    },
  };

  const selectTimeClass = css({
    color: theme.colors.gray400,
  });

  return {
    colorLineStyle,
    buttonStyle,
    getSelectContainerClass,
    chevronIconClass,
    selectStyle,
    selectTimeClass,
  };
};
