export const POPULAR_FILTERS = [
  'Behavioral Therapy',
  'Chronic Disease Management',
  'Routine Check-ups',
  'Screenings',
  'Speech Therapy',
];
export const GENDERS = ['Female', 'Male', 'Non-Binary', 'Other'];
export const APPOINTMENT_TYPES = ['In Person', 'Online'];
export const SERVICE = [
  'Behavioral Therapy',
  'Cardiology Services',
  'Chronic Disease Management',
  'Crisis Intervention',
  'Herbal Therapy',
  'Medication Management',
  'Neurology Services',
  'Occupational Therapy',
  'Oncology Services',
  'Physical Therapy',
  'Psychiatric Evaluation',
  'Pulmonology Services',
  'Routine Check-ups',
  'Screenings',
  'Speech Therapy',
  'Substance Use Treatment',
  'Therapy and Counseling',
  'Weight Management',
];
export const INSURANCES = [
  'Aetna',
  'United Medical Resources (UMR)',
  'Medicare',
  'Cigna and Evernorth',
  'Blue Cross',
  'Elevance',
  'UnitedHealthcare UHC | UBH',
  'Blue Shield',
  'Humana',
  'BlueCross BlueShield',
  'Oscar Health',
  'TRICARE',
  'Optum',
  'TriWest',
  'Oxford',
];

export const STATES_OPTIONS = [
  { id: 'AL', label: 'Alabama' },
  { id: 'AK', label: 'Alaska' },
  { id: 'AZ', label: 'Arizona' },
  { id: 'AR', label: 'Arkansas' },
  { id: 'CA', label: 'California' },
  { id: 'CO', label: 'Colorado' },
  { id: 'CT', label: 'Connecticut' },
  { id: 'DE', label: 'Delaware' },
  { id: 'DC', label: 'District of Columbia' },
  { id: 'FL', label: 'Florida' },
  { id: 'GA', label: 'Georgia' },
  { id: 'HI', label: 'Hawaii' },
  { id: 'ID', label: 'Idaho' },
  { id: 'IL', label: 'Illinois' },
  { id: 'IN', label: 'Indiana' },
  { id: 'IA', label: 'Iowa' },
  { id: 'KS', label: 'Kansas' },
  { id: 'KY', label: 'Kentucky' },
  { id: 'LA', label: 'Louisiana' },
  { id: 'ME', label: 'Maine' },
  { id: 'MD', label: 'Maryland' },
  { id: 'MA', label: 'Massachusetts' },
  { id: 'MI', label: 'Michigan' },
  { id: 'MN', label: 'Minnesota' },
  { id: 'MS', label: 'Mississippi' },
  { id: 'MO', label: 'Missouri' },
  { id: 'MT', label: 'Montana' },
  { id: 'NE', label: 'Nebraska' },
  { id: 'NV', label: 'Nevada' },
  { id: 'NH', label: 'New Hampshire' },
  { id: 'NJ', label: 'New Jersey' },
  { id: 'NM', label: 'New Mexico' },
  { id: 'NY', label: 'New York' },
  { id: 'NC', label: 'North Carolina' },
  { id: 'ND', label: 'North Dakota' },
  { id: 'OH', label: 'Ohio' },
  { id: 'OK', label: 'Oklahoma' },
  { id: 'OR', label: 'Oregon' },
  { id: 'PA', label: 'Pennsylvania' },
  { id: 'RI', label: 'Rhode Island' },
  { id: 'SC', label: 'South Carolina' },
  { id: 'SD', label: 'South Dakota' },
  { id: 'TN', label: 'Tennessee' },
  { id: 'TX', label: 'Texas' },
  { id: 'UT', label: 'Utah' },
  { id: 'VT', label: 'Vermont' },
  { id: 'VA', label: 'Virginia' },
  { id: 'WA', label: 'Washington' },
  { id: 'WV', label: 'West Virginia' },
  { id: 'WI', label: 'Wisconsin' },
  { id: 'WY', label: 'Wyoming' },
];
