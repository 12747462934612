import { useList } from 'effector-react';
import { FC, useCallback } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { NOTIFICATION_REMOVAL_SOURCE, StoredUserNotification } from './model';
import { $list, notificationsApi } from './store';
import { Container, Root } from './styles';
import { Notification } from './ui';

export const NotificationCenter: FC = () => {
  const remove = useCallback(
    (item: StoredUserNotification, type: NOTIFICATION_REMOVAL_SOURCE) => {
      item.onClose?.(type);

      notificationsApi.setToRemove(item.id);
    },
    []
  );
  const list = useList($list, {
    fn: (item) => (
      <Notification notification={item} remove={(type) => remove(item, type)} />
    ),
    getKey: ({ id }) => id,
  });

  return (
    <Root>
      <Container>
        <TransitionGroup component={null}>{list}</TransitionGroup>
      </Container>
    </Root>
  );
};
