import { styled, useStyletron, TEXT, MOBILE } from 'shared/ui';

export const TabList = styled('div', {
  [MOBILE]: {
    paddingBottom: '72px',
  },
});

export const Panel = styled('div', ({ $theme }) => ({
  position: 'fixed',
  zIndex: 10,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  gap: '24px',
  padding: '16px',
  backgroundColor: $theme.colors.white900,
  boxShadow: '0 -10px 20px rgba(0, 0, 0, 0.09), 0 -1px 2px rgba(0, 0, 0, 0.06)',
}));

export const Counter = styled('span', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  ...TEXT.xs_medium,
  backgroundColor: $theme.colors.red500,
  color: $theme.colors.white900,
}));

export const useStyles = () => {
  const [css, theme] = useStyletron();

  const getButtonClass = (isActive: boolean) =>
    css({
      display: 'inline-flex',
      gap: '4px',
      padding: '8px 12px',
      borderRadius: '8px',
      backgroundColor: isActive ? theme.colors.gray100 : 'initial',
      color: isActive ? theme.colors.gray700 : theme.colors.gray400,
      transition: '0.2s cubic-bezier(0, 0, 1, 1)',
      transitionProperty: 'background-color, color',
    });

  return {
    getButtonClass,
  };
};
