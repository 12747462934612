import { v4 as uuidV4 } from 'uuid';
import {
  DURATION,
  NOTIFICATION_INSERTION,
  NOTIFICATION_REMOVAL_SOURCE,
  NOTIFICATION_TYPE,
  UserNotification,
} from './model';
import { notificationsApi } from './store';

type NotificationProps = Pick<
  UserNotification,
  'title' | 'message' | 'buttons' | 'links'
>;

type INotification = NotificationProps | string;

type NotificationParams = {
  insert?: NOTIFICATION_INSERTION;
  duration?: DURATION;
  onClose?: (type: NOTIFICATION_REMOVAL_SOURCE) => void;
};

const DEFAULT_PARAMS: Omit<Required<NotificationParams>, 'onClose'> = {
  duration: 5000,
  insert: NOTIFICATION_INSERTION.TOP,
};

const addNotification = (
  notification: INotification,
  type: NOTIFICATION_TYPE,
  params?: NotificationParams
) => {
  const mergedParams = { ...DEFAULT_PARAMS, ...params };
  const id = uuidV4();
  const close = () => {
    notificationsApi.setToRemove(id);
  };

  if (typeof notification === 'string') {
    notificationsApi.add({
      type,
      insert: mergedParams.insert,
      notification: {
        id,
        title: notification,
        duration: mergedParams.duration,
        onClose: params?.onClose,
      },
    });
    return { id, close };
  }

  const duration =
    (notification.buttons?.length ?? 0) > 0 ||
    (notification.links?.length ?? 0) > 0
      ? 'none'
      : mergedParams.duration;

  notificationsApi.add({
    type,
    insert: mergedParams.insert,
    notification: {
      id,
      title: notification.title,
      message: notification.message,
      duration,
      onClose: params?.onClose,
      buttons: notification.buttons,
      links: notification.links,
    },
  });

  return { id, close };
};

export const notifications = {
  add: (
    type: NOTIFICATION_TYPE,
    notification: INotification,
    params?: NotificationParams
  ) => addNotification(notification, type, params),
  success: (notification: INotification, params?: NotificationParams) =>
    addNotification(notification, NOTIFICATION_TYPE.SUCCESS, params),
  warning: (notification: INotification, params?: NotificationParams) =>
    addNotification(notification, NOTIFICATION_TYPE.WARNING, params),
  error: (notification: INotification, params?: NotificationParams) =>
    addNotification(notification, NOTIFICATION_TYPE.ERROR, params),
  info: (notification: INotification, params?: NotificationParams) =>
    addNotification(notification, NOTIFICATION_TYPE.INFO, params),
  close: (id: string) => {
    notificationsApi.setToRemove(id);
  },
};
