import { FC } from 'react';
import { useStyletron, styled, TEXT, Desktop, Mobile } from 'shared/ui';
import {
  DocumentSubmission,
  DocumentSubmissionStatusEnum,
  RequirementType,
} from 'shared/api';
import { fullTimeFormat } from 'shared/lib';
import { useTranslation } from 'react-i18next';

export type DocumentHintProps = {
  submission: DocumentSubmission;
};

export const DocumentHint: FC<DocumentHintProps> = ({ submission }) => {
  const { t } = useTranslation(['common', 'appointments']);
  const [, theme] = useStyletron();
  const { status, submitted_at: submittedAt, document } = submission;

  const Hint = styled('p', () => ({
    ...TEXT.sm_book,
  }));

  if (status === DocumentSubmissionStatusEnum.Submitted) {
    return (
      <Hint $style={{ color: theme.colors.green500 }}>
        <Desktop>
          {t('common:COMPLETED_ON')} {fullTimeFormat(submittedAt.rawDate)}
        </Desktop>
        <Mobile>{t('common:COMPLETED')}</Mobile>
      </Hint>
    );
  }

  if (document.requirement !== RequirementType.None) {
    return (
      <Hint $style={{ color: theme.colors.red500 }}>
        {document.requirement === RequirementType.Before
          ? t('appointments:REQUIREMENT.REQUIRED_BEFORE')
          : t('appointments:REQUIREMENT.REQUIRED_AFTER')}
      </Hint>
    );
  }

  return null;
};
