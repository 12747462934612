export const hideGeneratedEmail = (email?: string | null) => {
  if (!email) {
    return '';
  }
  const isGenerated = /^patient-(.+)@trustvideo\.(.+)$/.test(email);
  const isDevice = /^trustvideouser\+(.+)@letstalkinteractive\.(.+)$/.test(
    email
  );

  if (isGenerated || isDevice) {
    return '';
  }
  return email;
};
