import { FC } from 'react';
import { formatDistanceStrict, addMinutes } from 'date-fns';
import {
  useStyletron,
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  ChevronDownIcon,
  useMobileMediaQuery,
} from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { Service } from 'shared/api';
import { useStyles } from './styles';

export const ServiceButton: FC<{
  hasError?: boolean;
  value: Service[];
  onClick?: () => void;
  isPaymentEnabled: boolean;
}> = ({ hasError = false, value, onClick, isPaymentEnabled }) => {
  const { t } = useTranslation(['appointments']);
  const [css, theme] = useStyletron();
  const {
    colorLineStyle,
    buttonStyle,
    getSelectContainerClass,
    chevronIconClass,
    selectStyle,
    selectTimeClass,
  } = useStyles();

  const isMobile = useMobileMediaQuery();
  const now = new Date();

  return !value[0] ? (
    <Button
      kind={BUTTON_KIND.SECONDARY}
      size={isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
      type="button"
      onClick={onClick}
      style={{
        ...buttonStyle,
        borderTopColor: hasError ? theme.colors.red500 : theme.colors.gray200,
        borderRightColor: hasError ? theme.colors.red500 : theme.colors.gray200,
        borderBottomColor: hasError
          ? theme.colors.red500
          : theme.colors.gray200,
        borderLeftColor: hasError ? theme.colors.red500 : theme.colors.gray200,

        ':hover': {
          borderTopColor: hasError ? theme.colors.red500 : theme.colors.gray300,
          borderRightColor: hasError
            ? theme.colors.red500
            : theme.colors.gray300,
          borderBottomColor: hasError
            ? theme.colors.red500
            : theme.colors.gray300,
          borderLeftColor: hasError
            ? theme.colors.red500
            : theme.colors.gray300,
          backgroundColor: theme.colors.gray50,
          color: 'inherit',
        },
      }}
    >
      {t('appointments:CREATE.SELECT_SERVICE')}
    </Button>
  ) : (
    <div className={getSelectContainerClass(isPaymentEnabled)}>
      <Button
        kind={BUTTON_KIND.SECONDARY}
        size={isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
        type="button"
        onClick={onClick}
        endEnhancer={<ChevronDownIcon className={chevronIconClass} />}
        style={selectStyle}
      >
        <span
          className={css({
            ...colorLineStyle,
            backgroundColor: value[0].booking_color
              ? theme.colors[`${value[0].booking_color as 'accent'}500`]
              : theme.colors.gray500,
          })}
        />
        <span>{value[0].name}</span>
        <span className={selectTimeClass}>
          {formatDistanceStrict(now, addMinutes(now, value[0].duration))}
        </span>
      </Button>
    </div>
  );
};
