import { Publisher } from '@opentok/client';
import { SessionRoomState, SessionRoomAction } from '../model';
import { generateMessage, matchDarkTheme } from '../utils';

export const initialSessionRoomState: SessionRoomState = {
  dataLoadingState: 'idle',
  session: null,
  isHost: false,
  publisher: null,
  users: [],
  error: null,
  appointmentId: null,
  talkingUser: null,
  pinnedStreamId: null,

  // Vitals
  isShowVitals: false,
  sessionPubNub: undefined,
  vitalsData: {
    status: 'pending',
  },

  // Chat
  isShowChat: false,
  chatOptions: {
    DOMObject: undefined,
    x: window.innerWidth / 2 - 336,
    y: window.innerHeight > 620 ? window.innerHeight / 2 - 320 : 0,
    isDragging: false,
    isShowNotification: false,
    isDarkTheme: matchDarkTheme(),
  },
  chatMessages: [],
};

export const sessionRoomReducer = (
  state: SessionRoomState,
  action: SessionRoomAction
): SessionRoomState => {
  switch (action.type) {
    case 'LOAD_SESSION_ROOM_DATA':
      return { ...state, dataLoadingState: 'loading' };

    case 'LOAD_SESSION_ROOM_DATA_SUCCESS':
      return {
        ...state,
        session: action.payload.session,
        isHost: action.payload.session.capabilities.forceDisconnect === 1,
        error: null,
        dataLoadingState: 'ready',
        appointmentId: action.payload.appointmentId,
      };

    case 'SET_SESSION_ROOM_ERROR':
      return {
        ...state,
        dataLoadingState: 'error',
        error: action.payload,
      };

    case 'FORCE_DISCONNECT':
      return {
        ...state,
        dataLoadingState: 'disconnected',
      };

    case 'RESET_SESSION_ROOM_STATE':
      return initialSessionRoomState;

    case 'ADD_PUBLISHER':
      return {
        ...state,
        publisher: {
          context: action.payload.context as Publisher,
          videoEl: action.payload.videoEl as HTMLVideoElement,
          hasVideo: action.payload.context?.stream?.hasVideo ?? true,
          hasAudio: action.payload.context?.stream?.hasAudio ?? true,
        },
      };

    case 'ADD_USER': {
      const users = [...state.users, action.payload.user];
      let { talkingUser } = state;

      if (talkingUser === null && users.length > 0) {
        const [firstUser] = users;
        const id = firstUser.context.stream?.streamId;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const userData = JSON.parse(firstUser.context.stream!.connection.data);

        talkingUser = {
          id,
          name: userData.name,
        };
      }

      return {
        ...state,
        users,
        talkingUser,
      };
    }

    case 'REMOVE_USER': {
      const users = state.users.filter(
        (user) => user.context.stream?.streamId !== action.payload.streamId
      );
      const talkingUser = (() => {
        if (state.talkingUser?.id === action.payload.streamId) {
          return users.length === 0
            ? null
            : () => {
                const [firstUser] = users;
                const id = firstUser.context.stream?.streamId;
                const userData = JSON.parse(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  firstUser.context.stream!.connection.data
                );

                return {
                  id,
                  name: userData.name,
                };
              };
        }

        return state.talkingUser;
      })();
      const pinnedStreamId =
        state.pinnedStreamId === action.payload.streamId
          ? null
          : state.pinnedStreamId;

      return <SessionRoomState>{
        ...state,
        users,
        talkingUser,
        pinnedStreamId,
      };
    }

    case 'SET_TALKING_USER':
      if (
        action.payload.talkingUser?.id === state.talkingUser?.id ||
        action.payload.talkingUser?.id ===
          state.publisher?.context.stream?.streamId
      ) {
        return state;
      }

      return {
        ...state,
        talkingUser: action.payload.talkingUser,
      };

    case 'SET_PINNED_STREAM_ID':
      return {
        ...state,
        pinnedStreamId: action.payload.pinnedStreamId,
      };

    case 'STREAM_PROPERTY_CHANGED':
      return {
        ...state,
        publisher:
          state.publisher?.context.stream?.streamId === action.payload.streamId
            ? {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...state.publisher!,
                [action.payload.changedProperty]: action.payload.value,
              }
            : state.publisher,
        // eslint-disable-next-line
        // @ts-ignore
        users: state.users.reduce((acc, current) => {
          if (current.context.stream?.streamId === action.payload.streamId) {
            return [
              ...acc,
              {
                ...current,
                [action.payload.changedProperty]: action.payload.value,
              },
            ];
          }

          return [...acc, current];
        }, []),
      };

    case 'TOGGLE_SHOW_VITALS':
      return {
        ...state,
        isShowVitals: action.payload.isShow,
      };

    case 'SET_PUBNUB_INSTANCE':
      return {
        ...state,
        sessionPubNub: {
          instance: action.payload.instance,
          channels: action.payload.channels,
        },
      };

    case 'SET_VITALS_DATA':
      return {
        ...state,
        vitalsData: {
          ...state.vitalsData,
          ...action.payload,
        },
      };

    case 'TOGGLE_SHOW_CHAT':
      return {
        ...state,
        isShowChat: action.payload.isShow,
      };

    case 'REFRESH_CHAT_OPTIONS':
      return {
        ...state,
        chatOptions: {
          ...state.chatOptions,
          ...action.payload,
        },
      };

    case 'REFRESH_MESSAGE_LIST':
      return {
        ...state,
        chatMessages: [
          ...state.chatMessages,
          ...generateMessage(action.payload.signal),
        ],
        chatOptions: {
          ...state.chatOptions,
          isShowNotification: !state.isShowChat,
        },
      };

    default:
      return state;
  }
};
