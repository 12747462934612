import { FC, Suspense } from 'react';
import { StyledSpinnerNext } from 'baseui/spinner';
import { SidebarPanel } from '../sidebar-panel';
import { SidebarPanelProps } from '../model';
import { useStyles } from './styles';

export const SidebarPanelBase: FC<SidebarPanelProps> = ({
  children,
  onClose,
  width = '800px',
  rootStyles,
}) => {
  const { rootClass, spinnerWrapperClass } = useStyles();

  return (
    <SidebarPanel onClose={onClose}>
      <div className={rootClass(width, rootStyles)}>
        <Suspense
          fallback={
            <div className={spinnerWrapperClass}>
              <StyledSpinnerNext />
            </div>
          }
        >
          {children}
        </Suspense>
      </div>
    </SidebarPanel>
  );
};
