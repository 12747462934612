import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getIdToken, getRefreshToken } from 'shared/lib';

const RequireAuth: FC = ({ children }) => {
  const token = getIdToken() ?? getRefreshToken();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default RequireAuth;
