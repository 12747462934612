import { FC, useEffect, forwardRef } from 'react';
import { Calendar, CalendarProps } from 'baseui/datepicker';
import {
  isToday,
  isSameDay,
  startOfToday,
  isBefore,
  format,
  getMonth,
  endOfDay,
} from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon, useStyletron } from 'shared/ui';
import {
  Appointment,
  SlotsQueryVariables,
  useSlotsLazyQuery,
} from 'shared/api';
import { DayCell } from './day-cell';

type DatePickerProps = Pick<
  CalendarProps,
  'value' | 'minDate' | 'maxDate' | 'range' | 'monthsShown'
> & {
  onChange?: (value: CalendarProps['value']) => void;
  canSelectNotAvailable?: boolean;
  appointments?: Appointment[];
  hostId?: string | null;
  isAllDayAvailable?: boolean;
  isAppointmentDots?: boolean;
  clickable?: boolean;
};

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  canSelectNotAvailable = true,
  appointments = [],
  hostId,
  isAllDayAvailable = false,
  isAppointmentDots = false,
  clickable = true,
  minDate,
  maxDate,
  range,
  monthsShown,
}) => {
  const [css, theme] = useStyletron();
  const [getSlots, { data: slotData }] = useSlotsLazyQuery();

  const availability = (date: Date) => {
    const currentWeekDay = format(date, 'EEEE').toLowerCase();
    return !!slotData?.slots.some(
      (slot) => slot?.weekday.toLowerCase() === currentWeekDay
    );
  };

  useEffect(() => {
    if (isAllDayAvailable) {
      return;
    }
    let variables: SlotsQueryVariables = {};
    if (hostId) {
      variables = { id: hostId };
    }
    getSlots({
      variables,
    });
  }, [getSlots, hostId, isAllDayAvailable]);

  return (
    <Calendar
      value={value}
      peekNextMonth
      onChange={({ date }) => {
        if (Array.isArray(date) && date.length === 2) {
          onChange?.([date[0], endOfDay(date[1])]);
          return;
        }
        onChange?.(date);
      }}
      minDate={minDate}
      maxDate={maxDate}
      range={range}
      monthsShown={monthsShown}
      overrides={{
        Root: {
          style: {
            display: 'block',
          },
        },
        CalendarContainer: {
          style: {
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            overflow: 'hidden',
            border: `1px solid ${theme.colors.gray200}`,
            boxShadow:
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
          },
        },
        CalendarHeader: {
          style: {
            justifyContent: 'center',
            minHeight: 'auto',
            paddingTop: 0,
            paddingBottom: '24px',
            paddingRight: '2px',
            paddingLeft: '2px',
          },
        },
        PrevButton: {
          style: {
            marginRight: 'auto',
          },
        },
        NextButton: {
          style: {
            marginLeft: 'auto',
          },
        },
        PrevButtonIcon: () => (
          <ChevronLeftIcon
            className={css({
              width: '24px',
              color: theme.colors.gray400,
              transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
              ':hover': {
                color: theme.colors.gray700,
              },
            })}
          />
        ),
        NextButtonIcon: () => (
          <ChevronRightIcon
            className={css({
              width: '24px',
              color: theme.colors.gray400,
              transition: 'color 0.2s cubic-bezier(0, 0, 1, 1)',
              ':hover': {
                color: theme.colors.gray700,
              },
            })}
          />
        ),
        MonthContainer: {
          style: {
            display: 'grid',
            gridTemplateColumns: `repeat(${monthsShown}, 1fr)`,
            gap: '40px',
          },
        },
        MonthHeader: {
          style: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
          },
        },
        Month: {
          style: {
            display: 'block',
          },
        },
        MonthYearSelectButton: {
          style: {
            marginLeft: '2px',
            marginRight: '2px',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '1.5',
            pointerEvents: 'none',
          },
        },
        MonthYearSelectIconContainer: {
          style: {
            display: 'none',
          },
        },
        WeekdayHeader: {
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            height: 'auto',
            paddingTop: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            fontSize: '14px',
            lineHeight: 1.43,
            fontWeight: 400,
            color: theme.colors.gray400,
          },
        },
        Week: {
          style: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gridTemplateRows: '40px',
            marginBottom: 0,
            ':not(:first-child)': {
              borderTop: '1px solid var(--tru-gray200)',
            },
          },
        },
        Day: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-unstable-nested-components
          component: forwardRef<HTMLButtonElement, any>((dayProps, ref) => (
            <DayCell
              {...dayProps}
              ref={ref}
              canSelectNotAvailable={canSelectNotAvailable}
              isOutsideMonth={getMonth(dayProps.$date) !== dayProps.$month}
              isToday={isToday(dayProps.$date)}
              isOutdate={
                !isAllDayAvailable && isBefore(dayProps.$date, startOfToday())
              }
              isAvailable={availability(dayProps.$date) || isAllDayAvailable}
              isAppointmentDots={isAppointmentDots}
              clickable={clickable}
              appointmentColors={appointments
                .filter((appointment) =>
                  isSameDay(dayProps.$date, appointment.start_time.date)
                )
                .map(
                  (appointment) =>
                    appointment.service?.booking_color || theme.colors.gray700
                )}
            />
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          )) as any,
        },
      }}
    />
  );
};
