import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useSessionRoomState } from '../../context';

export const InfoWindowTable: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { infoTable, infoTableRow, infoTableRowLabel, infoTableRowValues } =
    useStyles();

  const { publisher, users } = useSessionRoomState();
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('id') ?? '–';
  const list = useMemo(() => {
    const mergedUsers = [publisher, ...users];
    const host = mergedUsers.find(
      (user) =>
        // eslint-disable-next-line
        // @ts-ignore
        user?.context.stream.connection.permissions.forceDisconnect === 1
    );
    // eslint-disable-next-line
    // @ts-ignore
    const participants = mergedUsers.filter(
      (user) =>
        // eslint-disable-next-line
        // @ts-ignore
        user?.context.stream.connection.permissions.forceDisconnect !== 1
    );
    const participantNames =
      participants.length === 0
        ? ['–']
        : participants.map(
            (participant) =>
              JSON.parse(participant?.context.stream?.connection.data as string)
                .name
          );

    return [
      {
        label: t('session-room:MEETING_ID'),
        value: [appointmentId],
      },
      {
        label: t('session-room:HOST'),
        value: [
          host?.context.stream?.connection.data
            ? JSON.parse(host.context.stream?.connection.data).name
            : '–',
        ],
      },
      {
        label: t('session-room:PARTICIPANTS'),
        value: participantNames,
      },
    ];
  }, [appointmentId, publisher, t, users]);

  return (
    <div className={infoTable}>
      {list.map(({ label, value }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`info-table-row-${index}`} className={infoTableRow}>
          <div className={infoTableRowLabel}>{label}</div>
          <div className={infoTableRowValues}>
            {value.map((valueItem, valueIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`info-table-value-${index}-${valueIndex}`}>
                {valueItem}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
