export type DocumentModalProps = {
  participantId: string;
  documentIds?: string[];
  caption: string;
  type: DOCUMENT_TYPE;
  appointmentId: string;
};

export type SyncDocumentSubmissionsFormValues = {
  appointment_id: string;
  participant_id: string;
  document_ids: string[];
};

export enum KEYS {
  PACKETS,
  DOCUMENTS,
}

export enum DOCUMENT_TYPE {
  PARTICIPANT,
  HOST,
}
