export enum ChatSignal {
  Message = 'textMessage',
}

export interface ChatOptionsInterface {
  DOMObject?: HTMLDivElement;
  x?: string | number;
  y?: string | number;
  isDragging?: boolean;
  isShowNotification?: boolean;
  isDarkTheme?: boolean;
}
