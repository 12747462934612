import { SnackbarProvider } from 'baseui/snackbar';

import { CookiesProvider } from 'react-cookie';
import { Suspense, useMemo } from 'react';
import { StyledSpinnerNext } from 'baseui/spinner';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from 'pages';
import {
  ConfirmContainer,
  withStyle,
  styled,
  NotificationCenter,
} from 'shared/ui';
import {
  ModalManager,
  SharedContextProvider,
  setTabUUID,
  getStoredAccentColor,
  setSessionSearchParams,
} from 'shared/lib';
import { SidebarManager } from 'process/sidebar-panel';
import {
  StyletronProvider,
  CurrentOrganizationProvider,
  ApolloProvider,
  BasewebProvider,
} from './providers';

import './styles';

// set all search params before app init
setSessionSearchParams();

// set unique uuid for current tab in browser
setTabUUID();

function App() {
  const isSessionRoom = useMemo(
    () => window.location.pathname.indexOf('meeting-room') !== -1,
    []
  );
  const SpinnerWrap = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: isSessionRoom ? '#111' : undefined,
  });
  const accentColor = getStoredAccentColor();
  const Spinner = withStyle(StyledSpinnerNext, ({ $theme }) => ({
    borderTopColor: isSessionRoom
      ? 'a9a9a8'
      : accentColor ?? $theme.colors.accent,
  }));

  return (
    <ApolloProvider>
      <CookiesProvider>
        <StyletronProvider>
          <Suspense
            fallback={
              <SpinnerWrap>
                <Spinner />
              </SpinnerWrap>
            }
          >
            <BrowserRouter>
              <BasewebProvider>
                <CurrentOrganizationProvider>
                  <SnackbarProvider>
                    <SharedContextProvider>
                      <NotificationCenter />
                      <ConfirmContainer>
                        <AppRouter />
                        <ModalManager />
                        <SidebarManager />
                      </ConfirmContainer>
                    </SharedContextProvider>
                  </SnackbarProvider>
                </CurrentOrganizationProvider>
              </BasewebProvider>
            </BrowserRouter>
          </Suspense>
        </StyletronProvider>
      </CookiesProvider>
    </ApolloProvider>
  );
}

export default App;
