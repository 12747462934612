import { useMemo } from 'react';
import { StyleObject } from 'styletron-standard';
import { useStyletron, TEXT, DESKTOP, MOBILE } from '../theme';

export const useStyles = () => {
  const [, theme] = useStyletron();

  const headStyles: StyleObject = useMemo(
    () => ({
      height: '56px',

      [MOBILE]: {
        display: 'none',
      },
    }),
    []
  );

  const headerCellSharedStyle: StyleObject = useMemo(
    () => ({
      ...TEXT.sm_medium,
      borderBottomWidth: '2.5px',
      borderTopColor: theme.colors.gray200,
      borderRightColor: theme.colors.gray200,
      borderBottomColor: theme.colors.gray200,
      borderLeftColor: theme.colors.gray200,
      paddingTop: '18px',
      paddingBottom: '18px',

      ':hover': {
        backgroundColor: theme.colors.gray100,
      },
      ':first-child': {
        paddingLeft: '24px',
      },
      ':last-child': {
        paddingRight: '24px',
      },
    }),
    [theme]
  );

  const bodyCellStyles: StyleObject = useMemo(
    () => ({
      borderBottomWidth: '0.5px',
      borderTopColor: theme.colors.gray200,
      borderRightColor: theme.colors.gray200,
      borderBottomColor: theme.colors.gray200,
      borderLeftColor: theme.colors.gray200,
      paddingTop: '12px',
      paddingBottom: '12px',
      verticalAlign: 'middle',

      [MOBILE]: {
        padding: '16px',
        wordBreak: 'break-all',
      },
      ':first-child': {
        [DESKTOP]: {
          paddingLeft: '24px',
        },
      },
      ':last-child': {
        [DESKTOP]: {
          paddingRight: '24px',
        },
      },
    }),
    [theme]
  );

  return { headStyles, headerCellSharedStyle, bodyCellStyles };
};
