import { FC, useEffect } from 'react';
import { usePagination } from 'shared/lib';
import { useStyles } from './styles';
import { TableWrapperProps } from './model';
import { TablePagination } from '../pagination';

export const TableWrapper: FC<TableWrapperProps> = ({
  children,
  totalItems,
  onChangePage,
  currentPage: inputCurrentPage,
}) => {
  const { rootClass } = useStyles();
  const {
    setPage,
    totalPages,
    currentPage,
    endIndex,
    startIndex,
    totalItems: internalTotalItems,
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
  } = usePagination({
    totalItems,
    initialPage: inputCurrentPage ?? 1,
  });

  useEffect(() => {
    if (currentPage > 0) {
      onChangePage?.(currentPage);
    }
  }, [currentPage, onChangePage]);

  useEffect(() => {
    if (!!inputCurrentPage && inputCurrentPage !== currentPage) {
      setPage(inputCurrentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCurrentPage, setPage]);

  return (
    <div className={rootClass}>
      {children}
      <TablePagination
        endIndex={endIndex}
        startIndex={startIndex}
        totalCount={internalTotalItems}
        totalPages={totalPages}
        currentPage={currentPage}
        onChange={(nextPage) => setPage(nextPage)}
        setNextPage={setNextPage}
        setPreviousPage={setPreviousPage}
        nextEnabled={nextEnabled}
        previousEnabled={previousEnabled}
      />
    </div>
  );
};
