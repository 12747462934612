import { CheckboxProps as BaseCheckboxProps } from 'baseui/checkbox';

export enum CHECKBOX_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export type CheckboxProps = BaseCheckboxProps & {
  size?: CHECKBOX_SIZE;
};
