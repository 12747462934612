import { mergeDeepRight } from 'ramda';
import { useCallback } from 'react';
import { SidebarPanelEvent, SidebarPanelProps } from 'shared/ui';
import { v4 as uuidV4 } from 'uuid';
import { ModalPanelProps, MODAL_PANELS } from '../model';
import { ModalProvider } from './context';
import { openPanel, closePanel, closeAllPanels } from './store';

// https://effector.dev/docs/conventions/best-practices/
import './store/init';

export function useModalPanel<T extends MODAL_PANELS = MODAL_PANELS>({
  name,
  props: defaultProps,
  panelProps,
}: {
  name: T;
  panelProps?: SidebarPanelProps;
  props?: Partial<ModalPanelProps[T]>;
}) {
  const open = useCallback(
    (props?: Partial<ModalPanelProps[T]>) => {
      const id = uuidV4();

      const contentProps = mergeDeepRight(
        defaultProps ?? {},
        props ?? {}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any;

      openPanel({
        id,
        name,
        props: contentProps,
        sidebarProps: {
          children: <ModalProvider props={contentProps} name={name} />,
          ...panelProps,
          onClose: (eventType) => {
            if (eventType === SidebarPanelEvent.LocationChange) {
              closeAllPanels();
              return;
            }
            panelProps?.onClose?.(eventType);
            closePanel();
          },
        },
      });
    },
    [name, defaultProps, panelProps]
  );

  return {
    open,
    close: closePanel,
  };
}
