import { FC, Key, useCallback, useEffect, useState } from 'react';
import {
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  Checkbox,
  Checkgroup,
  CheckgroupOptions,
  Desktop,
  NextSpinner,
  styled,
  TabOverrideObject,
  Tabs,
} from 'shared/ui';
import { useModal, MODAL_TYPE, useEvent } from 'shared/lib';
import { useTranslation } from 'react-i18next';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Tab } from 'baseui/tabs-motion';
import {
  AppointmentDocument,
  AppointmentQuery,
  AppointmentQueryVariables,
  Document,
  DocumentPacket,
  QueryDocumentsOrderByColumn,
  RequirementType,
  SortOrder,
  useDocumentPacketsQuery,
  useDocumentsListQuery,
  useSyncDocumentSubmissionsMutation,
} from 'shared/api';
import { Formik } from 'formik';
import * as yup from 'yup';
import { lensPath, set } from 'ramda';
import { useStyles } from './style';
import {
  DOCUMENT_TYPE,
  DocumentModalProps,
  KEYS,
  SyncDocumentSubmissionsFormValues,
} from './model';

const Content: FC<
  DocumentModalProps & {
    onClose: () => void;
  }
> = ({ participantId, documentIds, caption, type, onClose, appointmentId }) => {
  const { t } = useTranslation(['common', 'appointments']);
  const {
    subtitleStyle,
    headerClass,
    titleClass,
    captionClass,
    bodyClass,
    packetListClass,
    spinnerStyle,
    documentListClass,
    documentItemClass,
    noticeClass,
    footerClass,
    footerButtonStyle,
  } = useStyles();

  const [activeKey, setActiveKey] = useState<Key>(KEYS.DOCUMENTS);

  const {
    data: documentsResponse,
    loading: isDocumentsLoading,
    fetchMore: documentsFetchMore,
  } = useDocumentsListQuery({
    variables: {
      first: 25,
      page: 1,
      orderBy: [
        {
          column: QueryDocumentsOrderByColumn.Name,
          order: SortOrder.Asc,
        },
      ],
    },
  });

  const documentsHasMorePage =
    documentsResponse?.documents?.paginatorInfo?.hasMorePages ?? false;
  const documentsCurrentPage =
    documentsResponse?.documents?.paginatorInfo?.currentPage ?? null;

  const documentsHandleFetchMore = useEvent(() => {
    if (
      documentsHasMorePage &&
      !isDocumentsLoading &&
      documentsCurrentPage !== null
    ) {
      documentsFetchMore({
        variables: {
          page: documentsCurrentPage + 1,
        },
      });
    }
  });

  const documents = (documentsResponse?.documents?.data as Document[]) || [];

  const {
    data: packetsResponse,
    loading: isPacketsLoading,
    fetchMore: fetchMorePackets,
  } = useDocumentPacketsQuery({
    variables: { first: 25, page: 1 },
  });

  const packetsHasMorePage =
    packetsResponse?.documentPackets?.paginatorInfo?.hasMorePages ?? false;
  const packetsCurrentPage =
    packetsResponse?.documentPackets?.paginatorInfo.currentPage ?? null;

  const packetsHandleFetchMore = useEvent(() => {
    if (
      packetsHasMorePage &&
      !isPacketsLoading &&
      packetsCurrentPage !== null
    ) {
      fetchMorePackets({
        variables: {
          page: packetsCurrentPage + 1,
        },
      });
    }
  });

  const packets =
    (packetsResponse?.documentPackets?.data as DocumentPacket[]) || [];

  useEffect(() => {
    if (packetsResponse?.documentPackets?.data.length) {
      setActiveKey(KEYS.PACKETS);
    }
  }, [packetsResponse]);

  const [
    syncDocumentSubmissions,
    { loading: isSyncDocumentSubmissionsLoading },
  ] = useSyncDocumentSubmissionsMutation({
    onCompleted() {
      onClose();
    },
    update(cache, { data }) {
      cache.updateQuery<AppointmentQuery, AppointmentQueryVariables>(
        {
          query: AppointmentDocument,
          variables: {
            id: appointmentId as string,
          },
        },
        (prevData) => {
          if (type === DOCUMENT_TYPE.PARTICIPANT) {
            const participantIndex =
              prevData?.appointment?.participants?.findIndex(
                ({ id }) => id === participantId
              ) ?? -1;
            if (participantIndex === -1) {
              return prevData;
            }
            return set(
              lensPath([
                'appointment',
                'participants',
                participantIndex,
                'document_submissions',
              ]),
              data?.syncDocumentSubmissions,
              prevData
            );
          }
          if (type === DOCUMENT_TYPE.HOST) {
            return set(
              lensPath([
                'appointment',
                'host_participant',
                'document_submissions',
              ]),
              data?.syncDocumentSubmissions,
              prevData
            );
          }
          return prevData;
        }
      );
    },
  });

  const validationSchema = yup.object().shape({
    appointment_id: yup.string(),
    participant_id: yup.string(),
    document_ids: yup.array(yup.string()).min(0),
  });

  const initialValues: SyncDocumentSubmissionsFormValues = {
    appointment_id: appointmentId as string,
    participant_id: participantId,
    document_ids: documentIds ?? [],
  };

  const onSubmit = ({
    appointment_id: appointmentIdValue,
    participant_id: participantIdValue,
    document_ids: documentIdsValue,
  }: SyncDocumentSubmissionsFormValues) => {
    syncDocumentSubmissions({
      variables: {
        input: {
          appointment_id: appointmentIdValue,
          participant_id: participantIdValue,
          document_ids: documentIdsValue,
        },
      },
    });
  };

  const requirementText = {
    [RequirementType.Before]: t('appointments:REQUIREMENT.REQUIRED_BEFORE'),
    [RequirementType.After]: t('appointments:REQUIREMENT.REQUIRED_AFTER'),
    [RequirementType.None]: null,
  };

  const Subtitle = styled('h5', subtitleStyle);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue, isValid }) => (
        <>
          <header className={headerClass}>
            <Desktop>
              <h3 className={titleClass}>
                {t('appointments:CREATE.ASSIGN_DOCUMENTS')}
              </h3>
            </Desktop>
            <p className={captionClass}>{caption}</p>
          </header>
          <div className={bodyClass}>
            {!isDocumentsLoading && !isPacketsLoading ? (
              <Tabs
                activeKey={activeKey}
                onChange={(e) => setActiveKey(e.activeKey)}
                wideTabs
              >
                {packets.length && (
                  <Tab
                    title={t('appointments:CREATE.PACKETS')}
                    key={KEYS.PACKETS}
                    overrides={TabOverrideObject}
                  >
                    <>
                      <Subtitle>
                        {t('appointments:CREATE.SELECT_PACKET')}
                      </Subtitle>
                      <ul className={packetListClass}>
                        {packets.map(({ id, name, documents: docs }) => {
                          const options =
                            docs?.map((doc) => ({
                              id: doc?.id,
                              name: doc?.name,
                              notice: doc && requirementText[doc.requirement],
                            })) || [];
                          return (
                            <li key={id}>
                              <Checkgroup
                                title={name}
                                options={options as CheckgroupOptions}
                                value={values.document_ids}
                                onChange={(val) => {
                                  setFieldValue('document_ids', val);
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>

                      {packetsHasMorePage && (
                        <NextSpinner
                          onIntersect={packetsHandleFetchMore}
                          intersectionProps={{
                            rootMargin: '0px 0px 256px 0px',
                          }}
                        />
                      )}
                    </>
                  </Tab>
                )}
                <Tab
                  title={t('appointments:CREATE.DOCUMENTS')}
                  key={KEYS.DOCUMENTS}
                  overrides={TabOverrideObject}
                >
                  <>
                    <Subtitle>
                      {t('appointments:CREATE.SELECT_DOCUMENTS')}
                    </Subtitle>
                    <ul className={documentListClass}>
                      {documents.map(({ id, name, requirement }) => {
                        const notice = requirementText[requirement];
                        return (
                          <li key={id} className={documentItemClass}>
                            <Checkbox
                              onChange={(e) => {
                                const target = e.target as HTMLInputElement;
                                const ids = target.checked
                                  ? [...values.document_ids, id]
                                  : values.document_ids.filter(
                                      (documentId) => documentId !== id
                                    );
                                setFieldValue('document_ids', ids);
                              }}
                              checked={values.document_ids.includes(id)}
                            >
                              {name}
                            </Checkbox>
                            {notice && (
                              <span className={noticeClass}>{notice}</span>
                            )}
                          </li>
                        );
                      })}
                    </ul>

                    {documentsHasMorePage && (
                      <NextSpinner
                        onIntersect={documentsHandleFetchMore}
                        intersectionProps={{
                          rootMargin: '0px 0px 256px 0px',
                        }}
                      />
                    )}
                  </>
                </Tab>
              </Tabs>
            ) : (
              <StyledSpinnerNext $style={spinnerStyle} />
            )}
          </div>
          <div className={footerClass}>
            <Button
              type="button"
              size={BUTTON_SIZE.SMALL}
              kind={BUTTON_KIND.SECONDARY}
              onClick={onClose}
              style={footerButtonStyle}
            >
              {t('common:BUTTONS.CANCEL')}
            </Button>

            <Button
              type="button"
              size={BUTTON_SIZE.SMALL}
              onClick={() => handleSubmit()}
              isLoading={isSyncDocumentSubmissionsLoading}
              disabled={
                !isValid ||
                (!values.document_ids.length && !documentIds?.length)
              }
              style={footerButtonStyle}
            >
              {t('common:BUTTONS.SAVE')}
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

export const useDocumentModal = (props: DocumentModalProps) => {
  const { t } = useTranslation(['appointments']);
  const { open, close } = useModal();
  const { modalStyle } = useStyles();

  return useCallback(() => {
    open(MODAL_TYPE.BASE, {
      title: t('appointments:CREATE.ASSIGN_DOCUMENTS'),
      children: <Content onClose={close} {...props} />,
      style: modalStyle,
    });
  }, [open, close, props, modalStyle, t]);
};
