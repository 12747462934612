import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StartVideoIcon, VideoIcon } from 'shared/ui';
import { ActionButton } from '../action-button';
import { MediaActionButton } from './model';

export const VideoButton: FC<MediaActionButton> = ({
  isOn,
  onToggleState,
  contextMenu = [],
}) => {
  const { t } = useTranslation(['session-room']);

  const [VideoStateIcon, videoStateText] = useMemo(
    () =>
      isOn
        ? [VideoIcon, t('session-room:STOP_VIDEO')]
        : [StartVideoIcon, t('session-room:START_VIDEO')],
    [isOn, t]
  );

  const videoContextMenu = useMemo(
    () =>
      contextMenu.length > 1
        ? {
            title: t('session-room:SELECT_CAMERA'),
            list: contextMenu,
          }
        : undefined,
    [contextMenu, t]
  );

  return (
    <ActionButton
      icon={<VideoStateIcon />}
      onClick={onToggleState}
      contextMenu={videoContextMenu}
    >
      {videoStateText}
    </ActionButton>
  );
};
