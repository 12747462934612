import { useStyletron, MOBILE } from 'shared/ui';

export const useStyles = () => {
  const [css] = useStyletron();

  const rootClass = css({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 101,
    display: 'flex',
    padding: '24px',
    overflow: 'auto',

    [MOBILE]: {
      padding: 0,
    },
  });

  const modalBg = css({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    willChange: 'opacity',
    transform: 'translateZ(0)',
    cursor: 'pointer',
    animationDuration: '200ms',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    animationName: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
  });

  return {
    rootClass,
    modalBg,
  };
};
