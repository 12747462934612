import { styled } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

export const Image = styled('img', {
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
});

export const useStyles = () => {
  const modalStyle: StyleObject = {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: 0,
    backgroundColor: 'initial',
  };

  return {
    modalStyle,
  };
};
