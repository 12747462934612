import { useMemo } from 'react';
import {
  AlertIcon,
  CloseIcon,
  InfoIcon,
  styled,
  useStyletron,
  TEXT,
} from 'shared/ui';
import { AlertStyles } from './model';

// @TODO (@web.atom) fix use styled
export const useStyles = ({ type, rootStyles = {}, isFlat }: AlertStyles) => {
  const [, theme] = useStyletron();

  const typeStyles = useMemo(
    () => ({
      warning: {
        icon: AlertIcon,
        iconColor: theme.colors.yellow500,
        titleColor: theme.colors.yellow900,
        textColor: theme.colors.yellow700,
        backgroundColor: theme.colors.yellow50,
        borderColor: theme.colors.yellow200,
      },
      error: {
        icon: CloseIcon,
        iconColor: theme.colors.red500,
        titleColor: theme.colors.red900,
        textColor: theme.colors.red700,
        backgroundColor: theme.colors.red50,
        borderColor: theme.colors.red200,
      },
      info: {
        icon: InfoIcon,
        iconColor: theme.colors.accent,
        titleColor: theme.colors.accent,
        textColor: theme.colors.accent,
        backgroundColor: theme.colors.accent50,
        borderColor: theme.colors.accent200,
      },
    }),
    [theme]
  );

  const flatStyles = useMemo(
    () =>
      isFlat
        ? {}
        : {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: typeStyles[type].borderColor,
            boxShadow:
              '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
          },
    [isFlat, typeStyles, type]
  );

  const Root = styled('div', {
    position: 'relative',
    padding: '16px 16px 16px 56px',
    borderRadius: '8px',
    width: '100%',
    backgroundColor: typeStyles[type].backgroundColor,
    ...flatStyles,
    ...rootStyles,
  });

  const Icon = styled(typeStyles[type].icon, {
    position: 'absolute',
    top: '16px',
    left: '16px',
    width: '24px',
    color: typeStyles[type].iconColor,
  });

  const Title = styled('h6', {
    ...TEXT.base_medium,
    color: typeStyles[type].titleColor,
  });

  const Text = styled('p', {
    ...TEXT.base_book,
    color: typeStyles[type].textColor,
    marginTop: '8px',
  });

  const Footer = styled('div', {
    display: 'flex',
    gap: '24px',
    ...TEXT.sm_medium,
    color: typeStyles[type].titleColor,
    marginTop: '16px',
  });

  return {
    Root,
    Icon,
    Title,
    Text,
    Footer,
  };
};
