import {
  AppointmentHistory,
  AppointmentHistoryActivityEnum,
  Causer,
} from 'shared/api';
import { fullTimeFormat } from 'shared/lib';
import {
  CloseIcon,
  RescheduleIcon,
  InMeetingIcon,
  DocumentIcon,
  SignatureIcon,
  DeleteIcon,
  PatientsIcon,
  COLORS,
  PractitionersIcon,
  CheckIcon,
  EmailIcon,
  PhoneIcon,
  RemindIcon,
  ExportIcon,
} from 'shared/ui';

export type DescriptionItem = {
  message: string;
  props?: Record<string, string>;
} | null;

const getMeta = (type: AppointmentHistoryActivityEnum) => {
  switch (type) {
    case AppointmentHistoryActivityEnum.AppointmentCancelled:
      return {
        title: 'appointments:VIEW.HISTORY.CANCELLED' as const,
        icon: CloseIcon,
        iconColor: COLORS.red500,
      };
    case AppointmentHistoryActivityEnum.AppointmentCreated:
      return {
        title: 'appointments:VIEW.HISTORY.CREATED' as const,
        titleColor: COLORS.green500,
      };
    case AppointmentHistoryActivityEnum.AppointmentEnded:
      return {
        title: 'appointments:VIEW.HISTORY.ENDED' as const,
        icon: InMeetingIcon,
        iconColor: COLORS.red500,
      };
    case AppointmentHistoryActivityEnum.AppointmentRescheduled:
      return {
        title: 'appointments:VIEW.HISTORY.RESCHEDULED' as const,
        icon: RescheduleIcon,
      };
    case AppointmentHistoryActivityEnum.AppointmentStarted:
      return {
        title: 'appointments:VIEW.HISTORY.STARTED' as const,
        icon: InMeetingIcon,
        iconColor: COLORS.green500,
      };
    case AppointmentHistoryActivityEnum.AppointmentUpdated:
      return {
        title: 'appointments:VIEW.HISTORY.UPDATED' as const,
        icon: RescheduleIcon,
      };
    case AppointmentHistoryActivityEnum.AppointmentRequestCreated:
      return {
        title: 'appointments:VIEW.HISTORY.REQUEST_CREATED' as const,
        icon: PractitionersIcon,
      };
    case AppointmentHistoryActivityEnum.AppointmentRequestApproved:
      return {
        title: 'appointments:VIEW.HISTORY.REQUEST_APPROVED' as const,
        icon: CheckIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentParticipantAttached:
      return {
        title: 'appointments:VIEW.HISTORY.PARTICIPANTS_ATTACHED' as const,
        icon: PatientsIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentParticipantDetached:
      return {
        title: 'appointments:VIEW.HISTORY.PARTICIPANTS_DETACHED' as const,
        icon: DeleteIcon,
        iconColor: COLORS.red500,
      };

    case AppointmentHistoryActivityEnum.AppointmentParticipantJoined:
    case AppointmentHistoryActivityEnum.AppointmentParticipantJoinedBeforeHost:
      return {
        title: 'appointments:VIEW.HISTORY.PARTICIPANTS_JOINED' as const,
        icon: InMeetingIcon,
        iconColor: COLORS.green500,
      };

    case AppointmentHistoryActivityEnum.AppointmentParticipantRejoined:
      return {
        title: 'appointments:VIEW.HISTORY.PARTICIPANTS_REJOINED' as const,
        icon: InMeetingIcon,
        iconColor: COLORS.green500,
      };

    case AppointmentHistoryActivityEnum.AppointmentParticipantLeft:
      return {
        title: 'appointments:VIEW.HISTORY.PARTICIPANTS_LEFT' as const,
        icon: InMeetingIcon,
        iconColor: COLORS.red500,
      };

    case AppointmentHistoryActivityEnum.AppointmentInviteEmail:
      return {
        title: 'appointments:VIEW.HISTORY.INVITE_EMAIL' as const,
        icon: EmailIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentInviteText:
      return {
        title: 'appointments:VIEW.HISTORY.INVITE_TEXT' as const,
        icon: PhoneIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentReminderEmail:
      return {
        title: 'appointments:VIEW.HISTORY.REMINDER_EMAIL' as const,
        icon: RemindIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentReminderText:
      return {
        title: 'appointments:VIEW.HISTORY.REMINDER_TEXT' as const,
        icon: RemindIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentDocumentAdded:
      return {
        title: 'appointments:VIEW.HISTORY.DOCUMENTS_ADDED' as const,
        icon: DocumentIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentDocumentRemoved:
      return {
        title: 'appointments:VIEW.HISTORY.DOCUMENTS_REMOVED' as const,
        icon: DeleteIcon,
        iconColor: COLORS.red500,
      };

    case AppointmentHistoryActivityEnum.AppointmentDocumentFilled:
      return {
        title: 'appointments:VIEW.HISTORY.DOCUMENTS_FILLED' as const,
        icon: DocumentIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentDocumentSigned:
      return {
        title: 'appointments:VIEW.HISTORY.DOCUMENTS_SIGNED' as const,
        icon: SignatureIcon,
      };

    case AppointmentHistoryActivityEnum.AppointmentDocumentDownloaded:
      return {
        title: 'appointments:VIEW.HISTORY.DOCUMENTS_DOWNLOADED' as const,
        icon: ExportIcon,
      };

    default:
      return null;
  }
};

export const getLogData = ({
  name,
}: {
  name: AppointmentHistoryActivityEnum;
}) => getMeta(name);

const getDescription: (
  log: AppointmentHistory,
  properties: Record<string, string>
) => DescriptionItem = (log, properties) => {
  switch (log.log_name) {
    case AppointmentHistoryActivityEnum.AppointmentRequestCreated:
      return {
        message: 'appointments:VIEW.HISTORY.DESCRIPTIONS.REQUEST_CREATED',
        props: properties,
      };
    default:
      return { message: log.description };
  }
};

export const makeDescription: (log: AppointmentHistory) => DescriptionItem = (
  log
) => {
  const props = log.properties ? JSON.parse(log.properties) : [];
  return getDescription(log, {
    ...props,
    date: log.created_at?.date ? fullTimeFormat(log.created_at.rawDate) : '',
  });
};

export const getCreatorName = (causer?: Causer | null) => {
  if (!causer) {
    return null;
  }

  switch (causer.__typename) {
    case 'Participant':
      return causer.user.name;
    case 'User':
      return causer.name;
    default:
      return null;
  }
};
