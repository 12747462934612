import { FC, useMemo } from 'react';
import { useStyletron, TEXT, MOBILE } from 'shared/ui';
import { StyleObject } from 'styletron-standard';

type PagePillProps = {
  onClick?: () => void;
  isActive?: boolean;
  isDots?: boolean;
};

export const PagePill: FC<PagePillProps> = ({
  isActive = false,
  isDots = false,
  onClick,
  children,
}) => {
  const [css, theme] = useStyletron();

  const baseStyles: StyleObject = useMemo(
    () => ({
      height: '40px',
      width: '56px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: '1px',
      borderStyle: 'solid',
      userSelect: 'none',
      borderColor: theme.colors.gray200,
      backgroundColor: isActive ? theme.colors.gray200 : theme.colors.white,
      ...TEXT.sm_book,
      color: isActive ? theme.colors.gray700 : theme.colors.gray400,

      [MOBILE]: {
        width: '49px',
      },
    }),
    [theme, isActive]
  );

  const hoverStyles: StyleObject = useMemo(
    () => ({
      ':hover': {
        backgroundColor: isActive ? theme.colors.gray200 : theme.colors.gray50,
        borderColor: isActive ? theme.colors.gray200 : theme.colors.gray300,
        color: theme.colors.gray700,
      },
    }),
    [theme, isActive]
  );

  return !isDots ? (
    <button
      className={css({ ...hoverStyles, ...baseStyles })}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  ) : (
    <span className={css({ ...baseStyles })}>...</span>
  );
};
