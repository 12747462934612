import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FingerprintIcon } from 'shared/ui';
import { ActionButton } from '../action-button';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';

export const ToggleBiometricsButton: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { isShowBiometrics } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();

  const toggleBiometrics = useCallback(() => {
    // Dispatch action to toggle biometrics visibility
    dispatch({
      type: 'TOGGLE_SHOW_BIOMETRICS',
      payload: {
        isShow: !isShowBiometrics,
      },
    });

  }, [dispatch, isShowBiometrics]);

  return (
    <ActionButton icon={<FingerprintIcon />} onClick={toggleBiometrics}>
      {t('session-room:BIOMETRICS')}
    </ActionButton>
  );
};
